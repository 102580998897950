import React, { useEffect, useState } from "react";
import { Select } from "antd";
import CutlistSetting from "./CutlistSetting";
import { ITable, ITableBody, ITableHeader, ITableRow, ITD, ITH } from "../../shared_components/ui_component_library/ITable";

export const Cutlist = ({ modal_json, handle_ui_response, update_view, set_page_loader }) => {
    const [dummy_modal_json, set_dummy_modal_json] = useState([]);

    useEffect(() => {
        set_dummy_modal_json(JSON.parse(JSON.stringify(modal_json)))
    }, [modal_json]);

    const handleCoreLibFn = async (route, form) => {
        set_page_loader({
            show: true,
            text: "Loading cutlist..."
        });

        setTimeout(async () => {
            let resp = await window.Promisify(window.Module[route](form))
            handle_ui_response(resp)
            // send_analytics({ category: "Cutlist type changed", type: form, interface: "cutlist view modal" })

            set_page_loader({
                show: false,
                text: "Loading cutlist..."
            });
        }, 10)
    }

    return (
        <div className="inf-h-full flex_column">
            {
                dummy_modal_json && dummy_modal_json.table_type ?
                    <div className='inf-mb-2' style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='inf-mr-3'>{dummy_modal_json.table_type.name}</div>
                        <div className="inf-ml-4 flex-1-mw" style={{display: 'flex', textAlign: 'left'}}>
                            <Select label="Cutlist type" type={dummy_modal_json.table_type.type} value={dummy_modal_json.table_type.value} onChange={(e) => { if(dummy_modal_json.table_type.route){handleCoreLibFn(dummy_modal_json.table_type.route, e)} }} style={{ fontSize: '14px', flex: 1, minWidth: '40px' }}>
                                {
                                    dummy_modal_json.table_type.type === 'select' && dummy_modal_json.table_type.options ? dummy_modal_json.table_type.options.map((option, idx) => (
                                        <Select.Option key={idx} value={option.id}> {option.name} </Select.Option>
                                    ))
                                        : ''
                                }
                            </Select>
                        </div>
                        <CutlistSetting handle_ui_response={handle_ui_response} modal_json={modal_json} update_view={update_view} />
                    </div>

                    : ''
            }
            <ITable coloumnSeparated={true} rowSeparated={true} hover={true} bordered={true} style_container={{ outline: '1px solid var(--light-border-color)', outlineOffset: '-1px', flex: 1, overflow: 'auto', whiteSpace: 'nowrap', fontSize: '12px', userSelect: 'none' }}>
                <ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
                    {
                        dummy_modal_json && dummy_modal_json.col && dummy_modal_json.col.length > 0 ?
                            dummy_modal_json.col.map((col, idx) =>
                                <ITH key={idx} style={{ textTransform: "capitalize" }}><div className='flex items-center'>
                                    {/* {col.type === 'checkbox' ? <Checkbox className='mr-2' indeterminate={is_indeterminate(col.id)} checked={checkAll(col.id)} onClick={(e) => { onCheckAllChange(e, col.id) }}></Checkbox> : ''} */}
                                    <div>{col.name}</div></div>
                                </ITH>
                            )
                            : <ITH>No Items</ITH>
                    }
                </ITableHeader>
                <ITableBody>
                    {
                        dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length > 0 && dummy_modal_json.units.map((unit, idx) => (
                            <ITableRow key={idx}>
                                {
                                    dummy_modal_json.col && dummy_modal_json.col.length > 0 && !unit.special &&
                                    dummy_modal_json.col.map((col, idx) =>
                                        <ITD key={idx}>
                                            {/* {col.type === 'checkbox' ? <Checkbox_input_for_restore_modal unit={unit} col={col} reRenderModal={reRenderModal} /> : ''} */}
                                            {col.type === 'number' || col.type === 'text' ? unit[col.id] : ''}
                                            {col.type === 'image' ? <img style={{ color: 'white', maxHeight: '100%', maxWidth: '100%', height: "auto", width: '130px' }} title={unit[col.id] ? unit[col.id] : ''} src={unit[col.id] ? unit[col.id] : "-"} /> : ''}
                                            {col.type === 'multi_data' ?
                                                <React.Fragment>
                                                    {
                                                        unit[col.id] && unit[col.id].length && unit[col.id].map((x, idx) => (
                                                            <div key={idx}>{x}</div>
                                                        ))
                                                    }
                                                </React.Fragment>
                                                : ''
                                            }
                                        </ITD>
                                    )
                                }
                                {
                                    unit.special ?
                                        <ITD key={idx} colspan={dummy_modal_json.col.length} align='start'>
                                            <b>{unit.special}</b>
                                        </ITD> : ''
                                }
                            </ITableRow>
                        ))
                    }
                </ITableBody>
            </ITable>
        </div>
    )
}