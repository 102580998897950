import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Input, Modal, Row } from 'reactstrap';
import InfurniaMainNavbar from "../InfurniaMainNavbar";
import { COLORS } from '../../utils/color';
import reactCSS from 'reactcss';
import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal';
import { useAlert } from 'react-alert';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Account_Settings_Modal, New_Project_Modal } from '../CommonModals';
import Icons from '../../shared_components/ui_component_library/Icons';
import {useTranslation} from 'react-i18next'

const MainPageLayout = ({ children, store_details, org_key, logout, set_page_loader, viewParam, SideBarSearch}) => {
    const [show_new_project_modal, set_show_new_project_modal] = useState(false)
    const {t} = useTranslation()
    
    // Account Settings removed from sidebar
    // const [show_account_settings, set_show_account_settings] = useState(false);
    // const close_account_settings = () => {
    //     set_show_account_settings(false)
    // }
    // const open_account_settings = () => {
    //     set_show_account_settings(true)
    // }

    const history = useHistory();
    const location = useLocation()

    const close_new_project_modal = () => {
        set_show_new_project_modal(false)
    }

    const open_new_project_modal = () => {
        set_show_new_project_modal(true)
    }

    const changeView = (newView) => {
        const searchParams = new URLSearchParams();
        // const searchParams = new URLSearchParams(location.search);
        // Update the 'view' query parameter
        searchParams.set('view', newView);

        // Replace the current URL with the updated query parameter
        history.push({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }; 

    return (
        <React.Fragment>
            <New_Project_Modal open={show_new_project_modal} org_key={org_key} handle_close={close_new_project_modal}/>
            {/* <Account_Settings_Modal open={show_account_settings} handle_close={close_account_settings} store_details={store_details} set_page_loader={set_page_loader}></Account_Settings_Modal> */}

            <div className='infurnia-main-page'>
                <InfurniaMainNavbar store_details={store_details} org_key={org_key} logout={logout} set_page_loader={set_page_loader}/>
                <div className='inf-flex inf-w-full' style={{ height: 'calc(100% - var(--infurnia-navbar-height))' }}>
                    <div className='infurnia-sidebar'>
                        <div className='inf-flex inf-flex-col inf-items-center inf-w-full inf-h-full'>
                            <div className='inf-w-full inf-sidebar-profile'>
                                {/* <div className='inf-mb-2 inf-font-medium' style={{color: 'var(--inf-theme-gray-400)', fontStyle: 'italic'}}>{"Hello Designer :)"}</div> */}
                                <div className='inf-text-lg flex_property inf-mb-1'>
                                    <div className='infurnia-avatar inf-mr-2'>{store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim()[0] : ':)'}</div>
                                    <div style={{flex: 1}} className='inf-font-light lines1_elipsis' title={store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim().split(' ')[0] : t('Anonymous User')}>{store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim().split(' ')[0] : t('Anonymous User')}</div>
                                </div>
                                <div className='inf-font-light flex_property' style={{color: 'var(--inf-theme-gray-500)'}}><div className='inf-mr-2 flex_center' style={{height: '32px', width: '32px'}}><img style={{height: '28px'}} src='/resources/MainPageIcons/org_placeholder.svg'></img></div><div style={{flex: 1}} className='lines1_elipsis' title={store_details && store_details.store_name ? store_details.store_name : '-'}>{store_details && store_details.store_name ? store_details.store_name : '-'}</div></div>
                            </div>
                            <div className='inf-w-full inf-sidebar-actions'>
                                {SideBarSearch}
                                { window.Module.is_feature_enabled('project_creation') ? <Button id="new_project_button" onClick={open_new_project_modal} className='inf-mb-4'><div className='inf-flex inf-items-center inf-justify-center'> <img height={'16px'} className='inf-mr-2' src='/resources/MainPageIcons/create-new-folder-outline.svg'></img>{t(`New Project`)}</div></Button> : '' }
                                {/* <Button id="open_project_button" style={{...styles.main_page_button_light, marginBottom:'30px'}} onClick={open_open_project_modal}>Open Project</Button> */}
                                <div className='inf-sidebar-options-container'>
                                    <div className={`${viewParam === 'recent' ? 'sidebar-option-active' : ''} inf-sidebar-option`} onClick={() => {changeView('recent')}}><div className='inf-flex inf-items-center'> <img style={{height: '16px'}} className='inf-mr-2' src='/resources/MainPageIcons/recent.svg'></img>{t(`Recently Accessed`)}</div></div>
                                    <div className={`${viewParam === 'projects' ? 'sidebar-option-active' : ''} inf-sidebar-option`} onClick={() => {changeView('projects')}}><div className='inf-flex inf-items-center'> <img style={{height: '16px'}} className='inf-mr-2' src='/resources/MainPageIcons/folder-outline.svg'></img>{t(`All Projects`)}</div></div>
                                    <div className={`${viewParam === 'deleted-items' ? 'sidebar-option-active' : ''} inf-sidebar-option`} onClick={() => {changeView('deleted-items')}}><div className='inf-flex inf-items-center'> <img style={{height: '16px'}} className='inf-mr-2' src='/resources/MainPageIcons/delete.svg'></img>{t(`Deleted Items`)}</div></div>
                                    {/* <div className={`inf-sidebar-option`} onClick={open_account_settings}><div className='inf-flex inf-items-center'> <img className='inf-mr-2' src='/resources/MainPageIcons/user.svg'></img>Account Settings</div></div> */}
                                </div>
                            </div>

                            {
                                org_key !== 'zest' && org_key != 'livspace' ?
                                <div className='inf-w-full inf-sidebar-helpmenu'>
                                    <div style={{ color: 'var(--inf-theme-gray-400)', fontStyle: 'italic', fontSize: '14px' }}>{"Resources to help you"}</div>
                                    {org_key ? '' : <div className='hover_blue flex_property cp'> <Icons style={{ fontSize: '16px' }} className='mr-2' name={'youtube'} /><a className='hover_blue' href='https://www.youtube.com/c/InfurniaTechnologies/playlists' target='_blank' title="Tutorials in youtube" >Tutorials in youtube</a></div>}
                                    {/* {window.Module.API.segment_id ? <div id='my_custom_intercom_link' className='hover_blue flex_property cp' title="Need Help" ><Icons style={{ fontSize: '16px' }} className='mr-2' name={'chat'} /><div className='underline_onhover'>Chat with customer support</div></div> : '' } */}
                                    <div className='hover_blue flex_property cp'> <Icons style={{ fontSize: '16px' }} className='mr-2' name={'book'} /><a className='hover_blue' href='https://help.infurnia.com/en/' target='_blank' title="Help Documentation" >Help Documentation</a></div>

                                    {/* <a href='https://infurnia.com' target='_blank' className='hover_blue' title="Infurnia Website" >Infurnia.com</a> */}
                                </div>
                                : ''
                            }
                            {/* <div className='inf-w-full inf-sidebar-helpmenu' style={{ visibility: org_key ? 'hidden' : 'visible' }}>
                                <div className='hover_blue flex_property cp'> <Icons style={{fontSize: '16px'}} className='mr-2' name={'globe'} /><a className='hover_blue' href='https://infurnia.com' target='_blank' title="Explore Infurnia.com" >Explore Infurnia.com</a></div>
                            </div> */}
                        </div>
                    </div>
                    <div className='infurnia-card-container'>
                        {children}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainPageLayout;