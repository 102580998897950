import React, { useContext, useEffect, useState } from "react";
import { send_analytics } from "../../utils/send_analytics";
import IImg from "../IImg";
import Icons from "../../shared_components/ui_component_library/Icons";
import to_fixed from "../../utils/to_fixed";
import { isNonEmptyArray } from "../../shared_components/utils/array";
import { handleError } from "../../utils/errors";
import DesignViewContext from "../../views/EditDesign/DesignViewContext";
import IButton from "../../shared_components/ui_component_library/IButton";
import { ButtonsDropdown } from "../../shared_components/ui_component_library/ButtonsDropdown";
import { COLORS } from "../../utils/color";

const hide_canvas = () => {
    try {

    } catch (err) {
        console.error(`Error in hide_canvas `, err)
        handleError(err);
    }
}

const set_canvas_position = () => {
    try {

    } catch (err) {
        console.error(`Error in set_canvas_position `, err)
        handleError(err);
    }
}

const show_canvas = () => {
    try {

    } catch (err) {
        console.error(`Error in show_canvas `, err)
        handleError(err);
    }
}

// const get_progress_gradient = (current, total, rightBg = 'ffffff', dull = true) => {
//     let gradient = ''
//     if (current == total) {
//         gradient = `linear-gradient(90deg, #23C16B${dull ? '6D' : ''} 100%, #${rightBg} 100%)`
//     } else {
//         gradient = `linear-gradient(90deg, #FFB323${dull ? '6D' : ''} ${to_fixed((current / total) * 100, 2)}%, #${rightBg} ${to_fixed((current / total) * 100, 2)}%)`
//     }
//     return gradient;
// }

// const get_progress_background = (current, total) => {
//     let bg = '';
//     if (current == total) {
//         bg = `#EBFFF7`
//     } else if (current != 0) {
//         bg = `#FFFCF0`
//     }
//     return bg
// }

const PieChartProgress = ({ size = 100, progress = 50, color, trackColor }) => {
    const radius = size / 2; // Radius of the circle
    const angle = (progress / 100) * 360; // Angle based on progress
    const isLargeArc = angle > 180 ? 1 : 0; // Check if arc is larger than 180 degrees

    // Calculate end point for the arc
    const x = radius + radius * Math.cos((angle - 90) * (Math.PI / 180));
    const y = radius + radius * Math.sin((angle - 90) * (Math.PI / 180));

    // Path for the progress arc
    const progressPath = `
M ${radius},${radius}
L ${radius},0
A ${radius},${radius} 0 ${isLargeArc} 1 ${x},${y}
Z
`;

    return (
        <svg width={size} height={size} className="relative">
            {/* Track Circle */}
            <circle
                cx={radius}
                cy={radius}
                r={radius}
                fill={trackColor}
                className="text-gray-200"
            />
            {/* Progress Arc */}
            <path d={progressPath} fill={color} />
        </svg>
    );
};

const CircularProgress = ({ current, total, color = "#FFB323", trackColor = "#E6E9EE" }) => {
    return (
        <div className="flex_property gap-2">
            {
                current == total ?
                    <>
                        <Icons name="check" style={{ color: '#23C16B', width: '16px', height: '16px' }} />
                        <span className="text-[#23C16B] font-bold">Done</span>
                    </> :
                    <>
                        <PieChartProgress size={18} progress={to_fixed((current / total) * 100, 0)} color={color} trackColor={trackColor} />
                        <div className={current == 0 ? `text-[${trackColor}]` : `text-[${color}]`}>{to_fixed((current / total) * 100, 0)}%</div>
                    </>
            }
        </div>
    )
}

const BoardLayoutNav = ({ active_board_layout_screen, set_active_board_layout_screen, board_layout_sheets, board_layout_sheet_types }) => {

    const back_link = () => {
        let new_screen = ""
        if (active_board_layout_screen == "sheet") {
            new_screen = "all_sheets"
        } else if (active_board_layout_screen == "all_sheets") {
            new_screen = "all_sheet_types"
        } else if (active_board_layout_screen == "all_sheet_types") {
            new_screen = "all_sheet_types"
        }
        set_active_board_layout_screen(new_screen)
    }

    return (
        <div className="flex_property bg-white inf-px-4 inf-py-2 rounded-md  inf-gap-2">
            <div className='cp' onClick={back_link}><Icons style={{ color: 'black' }} name={"back"} className={"mr-2"} /></div>
            <div className={`cp ${active_board_layout_screen == "all_sheet_types" ? "inf-font-semibold" : ""}`} onClick={() => { set_active_board_layout_screen("all_sheet_types") }}>All Board Types</div>
            {
                ["all_sheets", "sheet"].includes(active_board_layout_screen) ?
                    <>
                        <div> &gt; </div>
                            <div className={`cp ${active_board_layout_screen == "all_sheets" ? "inf-font-semibold" : ""}`} onClick={() => { set_active_board_layout_screen("all_sheets") }}>{board_layout_sheet_types && board_layout_sheet_types.active_sheet_type && board_layout_sheet_types.active_sheet_type.name}</div>
                    </> : ''
            }
            {
                active_board_layout_screen == "sheet" ?
                    <>
                        <div> &gt; </div>
                            <div className={`cp inf-font-semibold`}>{board_layout_sheets && board_layout_sheets.active_sheet && board_layout_sheets.active_sheet.name}</div>
                    </> : ''
            }
        </div>
    )
}

const SideInfo = ({board_layout_sheet_types}) => {

    const get_active_board = () => {
        return board_layout_sheet_types && board_layout_sheet_types.sheet_types.find(x => board_layout_sheet_types.active_sheet_type && (board_layout_sheet_types.active_sheet_type.id == x.id));
    }

    const active_board = get_active_board() || {};

    return (
        <div className="inf-h-full overflow-auto rounded-xl inf-p-4" style={{width: '270px', background: 'white'}}>
            <div className="flex_column inf-gap-3 overflow-hidden">
                <div style={{ fontWeight: 400 }} className={`flex_between`}>
                    <span className="text-inf_theme_gray_600">L:</span>{to_fixed(active_board && active_board.dimension && active_board.dimension.L, 0)}
                    <span className="text-inf_theme_gray_600 ">W:</span>{to_fixed(active_board && active_board.dimension && active_board.dimension.W, 0)}
                    <span className="text-inf_theme_gray_600 ">T:</span>{to_fixed(active_board && active_board.dimension && active_board.dimension.T, 0)}
                </div>
                <div className="flex_between">
                    <div>Margins(mm):</div>
                    <div className="text-inf_theme_gray_800">{active_board && active_board.margin && active_board.margin.V} x {active_board && active_board.margin && active_board.margin.H}</div>
                </div>
                <hr className="inf-w-full" />
                <div className="flex_between">
                    <div>No. of Panels:</div>
                    <div className="text-inf_theme_gray_800">{active_board && active_board.num_of_panels}</div>
                </div>
                <div className="flex_between">
                    <div>Grain:</div>
                    <span className="text-inf_theme_gray_800">{active_board && active_board.grain_direction}</span>
                </div>
                <div className="flex_between">
                    <div>Type:</div>
                    <span className="text-inf_theme_gray_800">{active_board && active_board.lamination_type}</span>
                </div>
                <div className="flex_between">
                    <div>Yield:</div>
                    <span className="text-inf_theme_gray_800">{to_fixed(active_board.yield * 100, 0)}%</span>
                </div>
                <hr className="inf-w-full" />
                <div className="flex_column inf-gap-1">
                    <div>Core Material:</div>
                    <div className="text-inf_theme_gray_800 lines2_elipsis" title={active_board && active_board.core_material && active_board.core_material.sku_name}>{active_board && active_board.core_material && active_board.core_material.sku_name}</div>
                </div>
                <div className="flex_column inf-gap-1">
                    <div>External Finish:</div>
                    <div title={active_board && active_board.external_finish && active_board.external_finish.sku_name} className="lines2_elipsis">{active_board && active_board.external_finish && active_board.external_finish.sku_name}</div>
                    <IImg fallbackSrc="/images/no_image.svg" src={active_board && active_board.external_finish && active_board.external_finish.display_pic ? active_board.external_finish.display_pic : ''} className="rounded-md inf-border" style={{width: '64px', height: '64px'}} />
                </div>
                <div className="flex_column inf-gap-1">
                    <div>Internal Finish:</div>
                    <div title={active_board && active_board.internal_finish && active_board.internal_finish.sku_name} className="lines2_elipsis">{active_board && active_board.internal_finish && active_board.internal_finish.sku_name}</div>
                    <IImg fallbackSrc="/images/no_image.svg" src={active_board && active_board.internal_finish && active_board.internal_finish.display_pic || ''} className="rounded-md inf-border" style={{width: '64px', height: '64px'}} />
                </div>
            </div>
        </div>
    );
}

const BoardSheetCard = ({sheet, switch_board_layout_sheet, board_layout_sheet_types, work_order_id, published_key="notpublished"}) => {
    return(
        <div className="rounded-lg cp inf-p-4 bg-white" onClick={() => { switch_board_layout_sheet(sheet.id) }}>
            <div className="flex_column inf-gap-3">
                    <div className="flex_between">
                        <b>{sheet.name}</b>
                        <div className="flex_property inf-gap-1">
                            <Icons name={"panels"} />
                            <div>{sheet.num_of_panels} Panels</div>
                        </div>
                    </div>
                    <IImg src={`FS_${work_order_id}_${published_key}_${board_layout_sheet_types.active_sheet_type.coreId}_Sheet_${Number(sheet.id) + 1}.png`} style={{ aspectRatio: '2/1', objectFit: 'contain' }} />
            </div>
        </div> 
    )
}

const BoardSheetListing = ({board_layout_sheet_types, board_layout_sheets, switch_board_layout_sheet, work_order_id, board_layout_sheet_needs_updated_preview}) => {
    const [c, set_c] = useState(0);

    const {set_page_loader} = useContext(DesignViewContext)

    const get_published_key = () => {
        try{
            let work_order_details = JSON.parse(window.Module.get_work_order_details());
            return work_order_details.published;
        }catch(err){
            console.error(`Error in get_published_key `, err)
            handleError(err);
        }
        return 'notpublished'
    }

    let published_key = get_published_key();

    useEffect(() => {
        let regen_sheets = [];
        for (let sheet of board_layout_sheets.sheets) {
            let image_key = `FS_${work_order_id}_${published_key}_${board_layout_sheet_types.active_sheet_type.coreId}_Sheet_${Number(sheet.id) + 1}.png`
            if (board_layout_sheet_needs_updated_preview[image_key] || !window.FS.root.contents[image_key]) {
                regen_sheets.push({ sheet_number: sheet.id, board_number: board_layout_sheet_types.active_sheet_type.id, board_id: board_layout_sheet_types.active_sheet_type.coreId })
            }
        }
        if (regen_sheets.length) {
            set_page_loader({ show: true, text: 'Loading Board Layout Sheets...' });
            setTimeout(async () => {
                for (let sheet of regen_sheets) {
                    await window.Promisify(window.Module.generate_sheet_images_for_boards(JSON.stringify(sheet)));
                    let image_key = `FS_${work_order_id}_${published_key}_${sheet.board_id}_Sheet_${Number(sheet.sheet_number) + 1}.png`
                    board_layout_sheet_needs_updated_preview[image_key] = false
                }
                set_page_loader({ show: false })
                set_c(c + 1)
            }, 50)
        }

    }, [board_layout_sheet_types, board_layout_sheets, board_layout_sheet_needs_updated_preview]);

    return (
        <div className="inf-h-full" style={{display: 'flex'}}>
            <SideInfo board_layout_sheet_types={board_layout_sheet_types}/>
            <div className="inf-h-full overflow-auto flex-1-mw">
                <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))' }} className="inf-p-4">
                    {
                        board_layout_sheets && isNonEmptyArray(board_layout_sheets.sheets) ? board_layout_sheets.sheets.map((sheet, idx) => (
                            <BoardSheetCard key={`${idx}_${c}`} sheet={sheet} board_layout_sheet_types={board_layout_sheet_types} switch_board_layout_sheet={switch_board_layout_sheet} work_order_id={work_order_id} published_key={published_key} />
                        )) : ''
                    }
                </div>
            </div>
        </div>
    )
}

const BoardLayoutHeader = ({ board_layout_sheets, board_layout_sheet_types, active_board_layout_screen, set_active_board_layout_screen, set_board_layout_sheet_needs_updated_preview, work_order_id }) => {

    const {handle_ui_response, update_view} = useContext(DesignViewContext)

    const force_regen = async () => {
        var resp = await window.Promisify(window.Module.toolbar_router("force_regen_board_layout"));
        handle_ui_response(resp)
        set_board_layout_sheet_needs_updated_preview((prev) => {
            let copy = JSON.parse(JSON.stringify(prev))
            for (let key in copy) {
                if (key.includes(work_order_id)) {
                    copy[key] = true;
                }
            }
            return copy
        })
    }

    const edit_board_layout_settings = async () => {
        var resp = await window.Promisify(window.Module.toolbar_router("board_layout_settings"));
        handle_ui_response(resp);
        update_view();
        // send_analytics({ category: "Board Settings Opened" })
    }

    const get_total_sheets_tracking = () => {
        let total_sheets = 0;
        board_layout_sheet_types && board_layout_sheet_types.sheet_types.forEach((sheet_type) => {
            total_sheets += sheet_type.num_of_sheets;
        })
        return { total_sheets }
    }

    const get_total_panels_tracking = () => {
        let total_panels = 0;
        board_layout_sheet_types && board_layout_sheet_types.sheet_types.forEach((sheet_type) => {
            total_panels += sheet_type.num_of_panels;
        })
        return { total_panels }
    }

    const get_active_sheet = () => {
        return board_layout_sheets && board_layout_sheets.sheets.find(x => board_layout_sheets.active_sheet && (board_layout_sheets.active_sheet.id == x.id));
    }

    let { total_sheets } = get_total_sheets_tracking();
    let { total_panels } = get_total_panels_tracking();

    return (
        <div className={`flex_between`}>
            <BoardLayoutNav active_board_layout_screen={active_board_layout_screen} set_active_board_layout_screen={set_active_board_layout_screen} board_layout_sheet_types={board_layout_sheet_types} board_layout_sheets={board_layout_sheets}/>
            {
                active_board_layout_screen == "all_sheet_types" ?
                    <div className="text-inf_theme_gray_700 inf-flex inf-gap-2">
                        <div className="board_layout_info_card">Board types: {<span>{board_layout_sheet_types.sheet_types.length}</span>}</div>
                        <div className="board_layout_info_card">Total Sheets: {<span>{total_sheets}</span>}</div>
                        <div className="board_layout_info_card">Total Panels: {<span>{total_panels}</span>}</div>
                    </div> : ''
            }
            {
                active_board_layout_screen == "all_sheets" ?
                    <div className="text-inf_theme_gray_700 inf-flex inf-gap-2">
                        <div className="board_layout_info_card">Sheets: {<span>{board_layout_sheets.sheets.length}</span>}</div>
                        <div className="board_layout_info_card">Panels: {<span>{total_panels}</span>}</div>
                    </div> : ''
            }
            {
                active_board_layout_screen == "sheet" || active_board_layout_screen == "all_sheet_types" ?
                    <div className={`flex items-start justify-between`}>
                        <div className="flex_property">
                            {active_board_layout_screen == "sheet" ?
                                <div className="flex_property inf-gap-2">
                                    <div className="board_layout_info_card text-inf_theme_gray_700">Panels in sheet: {<span>{get_active_sheet().num_of_panels}</span>}</div>
                                </div>
                                : <></>}
                            {
                                active_board_layout_screen == "all_sheet_types" ?
                                    <div className="flex_property inf-gap-3">
                                        <IButton onClick={edit_board_layout_settings}>Board Layout Setting</IButton>
                                        <ButtonsDropdown button_list={[{ display_name: "Force Re-generate Board Layout", customOnClick: force_regen }]} customToggle={
                                            <div className='inf-p-2 cp rounded inf-border bg-white'>
                                                <Icons name={'horizontal_options'} style={{fontSize: '16px'}} />
                                            </div>
                                        }/>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div> : ''
            }
        </div>
    )
};



const BoardTypeCard = ({ board, switch_board_layout_sheet_type }) => {
    return (
        <div className="rounded-lg cp" style={{ background: 'white' }} onClick={() => { switch_board_layout_sheet_type(board.id) }}>
            <div className="flex_column inf-gap-2 inf-p-4">
                <div className="flex_between">
                    <b>{board.name}</b>
                    <div className={`flex_property   `}>
                        <span className="text-inf_theme_gray_600">L:&nbsp;</span>{to_fixed(board.dimension && board.dimension.L, 0)}
                        <span className="ml-1 text-inf_theme_gray_600">W:&nbsp;</span>{to_fixed(board.dimension && board.dimension.W, 0)}
                        <span className="ml-1 text-inf_theme_gray_600">T:&nbsp;</span>{to_fixed(board.dimension && board.dimension.T, 0)}
                    </div>
                </div>
                <div className="flex_property">
                    <div style={{ width: '60px' }} className="text-inf_theme_gray_600">Core:</div>
                    <div title={board && board.core_material && board.core_material.sku_name || ""} className={` flex-1-mw  lines1_elipsis`}>{board && board.core_material && board.core_material.sku_name || ""}</div>
                </div>
                <div className="flex_property">
                    <div style={{ width: '60px' }} className="text-inf_theme_gray_600">External:</div>
                    <div className={` flex-1-mw   flex_property`}>
                        <IImg fallbackSrc="/images/no_image.svg" src={board && board.external_finish && board.external_finish.display_pic || ""} className="rounded inf-border" style={{ width: '40px', height: '24px', objectFit: 'cover' }} />
                        <div title={board && board.external_finish && board.external_finish.sku_name || ""} className="ml-2 flex-1-mw lines1_elipsis">{board && board.external_finish && board.external_finish.sku_name || ""}</div>
                    </div>
                </div>
                <div className="flex_property">
                    <div style={{ width: '60px' }} className="text-inf_theme_gray_600">Internal:</div>
                    <div className={` flex-1-mw   flex_property`}>
                        <IImg fallbackSrc="/images/no_image.svg" src={board && board.internal_finish && board.internal_finish.display_pic || ""} className="rounded inf-border" style={{ width: '40px', height: '24px', objectFit: 'cover' }} />
                        <div title={board && board.internal_finish && board.internal_finish.sku_name || ""} className="ml-2 flex-1-mw lines1_elipsis">{board && board.internal_finish && board.internal_finish.sku_name || ""}</div>
                    </div>
                </div>
            </div>
            <hr style={{ margin: '0px' }} />
            <div className="inf-py-2 inf-px-4 flex_between">
                <div className="flex_property inf-gap-2">
                    <div className="text-inf_theme_gray_600">Sheets:</div>
                    <div>{`${board.num_of_sheets}`}</div>
                </div>
                <div className="w-[1px] h-[15px] bg-inf_theme_gray_400 rounded"></div>
                <div className="flex_property inf-gap-2">
                    <div className="text-inf_theme_gray_600">Panels:</div>
                    <div>{`${board.num_of_panels}`}</div>
                </div>
                <div className="w-[1px] h-[15px] bg-inf_theme_gray_400 rounded"></div>
                <div className="flex_property inf-gap-2">
                    <div className="ml-2 text-inf_theme_gray_600">Yield:</div>
                    <div>{to_fixed(board.yield * 100, 0)}%</div>
                </div>
            </div>
        </div>
    )
}

const BoardTypeListing = ({ board_layout_sheet_types, switch_board_layout_sheet_type }) => {
    const { toolbar_on_click } = useContext(DesignViewContext)
   
    const open_board_layout_violations = async () => {
        await toolbar_on_click("board_layout_settings_without_bulk_edit");
        // send_analytics({ category: "Board Violations Opened" })
    }
    const get_violated_sheet_types = () => {
        let sheet_types = [];
        for (let sheet_type of (board_layout_sheet_types && board_layout_sheet_types.violated_sheet_types || [])) {
            sheet_types.push(sheet_type)
        }
        for (let sheet_type of (board_layout_sheet_types && board_layout_sheet_types.sheet_types || [])) {
            if (sheet_type.violations && sheet_type.violations.length) {
                sheet_types.push(sheet_type)
            }
        }
        return sheet_types;
    }
    return (
        <div className="inf-h-full flex_column inf-gap-2">
            {
                get_violated_sheet_types().length ?
                        <div className=" rounded-lg p-3 flex_between bg-white" style={{border: '2px solid ' + COLORS.button_danger}}>
                            <div className="inf-flex">
                                Boards with violations {`(${get_violated_sheet_types().length})`}:
                                <div className="inf-flex inf-gap-2">
                                    {
                                        get_violated_sheet_types().map((board, idx) => {
                                            return (
                                                <>
                                                    {
                                                        idx <= 4 ?
                                                            <div className="inf-font-bold text-[#FF5247] inf-flex inf-gap-2">
                                                                <div> {idx != 0 ? '|' : ''} </div>
                                                                <div> {board.name} </div>
                                                            </div> : idx == 5 ? <div className="inf-font-bold text-[#FF5247] flex">{`... (${get_violated_sheet_types().length - 5} more)`}</div> : ''
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{fontStyle: 'italic', textDecoration: 'underline', color: COLORS.blue6, cursor: 'pointer'}} onClick={open_board_layout_violations}> View all violations </div>
                        </div>
                        : ''
            }
            <div className="flex-1-mh overflow-auto">
                <div style={{ display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))' }}>
                    {
                        board_layout_sheet_types && isNonEmptyArray(board_layout_sheet_types.sheet_types) ? board_layout_sheet_types.sheet_types.map((board, idx) => (
                            <BoardTypeCard key={idx} board={board} switch_board_layout_sheet_type={switch_board_layout_sheet_type} />
                        )) : ''
                    }
                </div>
            </div>
        </div>
    )
};

const BoardSheetView = ({board_layout_sheet_types}) => {
    const {handle_ui_response} = useContext(DesignViewContext)
    const has_missing_tools = () => {
        try {
            return window.Module.has_missing_tools("board_layout_nc", "")
        } catch (err) {
            console.error(`Error in has_missing_tools `, err)
        }
    }

    const trigger_missing_tools_modal = async () => {
        try {
            let resp = await window.Promisify(window.Module.show_missing_tools_modal("board_layout_nc", ""))
            handle_ui_response(resp)
        } catch (err) {
            console.error(`Error in trigger_missing_tools_modal `, err)
        }
    }

    return (
        <div className="inf-h-full" style={{display: 'flex'}}>
            <SideInfo board_layout_sheet_types={board_layout_sheet_types}/>
            <div className="inf-px-4 inf-h-full flex-1-mw">
                <div className="overflow-hidden inf-h-full inf-w-full" id="board-layout" />
            </div>
            {/* {
                has_missing_tools() ?
                    <>
                        {createPortal(<div className="absolute" style={{ zIndex: 102, right: canvas_width / 2, bottom: canvas_height, transform: 'translate(-50%, 40px)' }}>
                            <Button className="bg-[#F9DADE] underline text-[#B21200] normal-case text-sm font-normal" onClick={trigger_missing_tools_modal}>Some tools are missing</Button>
                        </div>, document.body)}
                    </> : ''
            } */}
        </div>
    )
};


const BoardLayout = ({ nesting_mode_prop, nesting_type_prop, board_layout_sheet_types, board_layout_sheets, handle_ui_response, update_view, active_board_layout_screen, set_active_board_layout_screen, board_layout_sheet_needs_updated_preview, set_board_layout_sheet_needs_updated_preview }) => {

    // const [active_board_layout_screen, set_active_board_layout_screen] = useState("all_sheet_types");
    const [board_layout_generated, set_board_layout_generated] = useState(false);
    // const [ board_layout_sheet_needs_updated_preview, set_board_layout_sheet_needs_updated_preview ] = useState({});

    const [canvas_width, set_canvas_width] = useState();
    const [canvas_height, set_canvas_height] = useState();
    

    const generate_board_layout = async (nesting_mode_prop, nesting_type_prop) => {
        set_board_layout_generated(false)
        // await wait_for_all_core_threads();
        let additional = {}
        additional['nesting_mode'] = nesting_mode_prop
        additional['nesting_type'] = nesting_type_prop
        var resp = await window.Promisify(window.Module.enter_board_layout_mode(JSON.stringify(additional)));
        handle_ui_response(resp);
    }

    useEffect(() => {
        // let analytics_data = { category: "Board Layout Clicked" }
        // send_analytics(analytics_data)

        // generate_board_layout(nesting_mode_prop, nesting_type_prop);
        set_active_board_layout_screen("all_sheet_types")

        // return () => {
        //     hide_canvas();
        //     window.Module.go_back_onclick();
        //     window.Module.navbar_3d_click();
        //     set_board_layout_generated(false);
        // }
    }, []);

    useEffect(() => {
        if (board_layout_generated) {
            update_view();
            // set_canvas_super_impose_id('board-layout')

            if (active_board_layout_screen == "sheet") show_canvas();
            set_canvas_position("board-layout");
            let firstDiv = document.getElementById('board-layout')
            if (firstDiv) {
                const { width, height } = firstDiv.getBoundingClientRect();
                set_canvas_width(width)
                set_canvas_height(height)
            }
        }
    }, [board_layout_generated, active_board_layout_screen]);

    useEffect(() => {
        if (active_board_layout_screen != "sheet") hide_canvas()
    }, [active_board_layout_screen]);

    useEffect(() => {
        if (board_layout_sheets && board_layout_sheets.sheets && board_layout_sheets.sheets.length) {
            get_missing_tools()
        }
    }, [board_layout_sheet_types, board_layout_sheets]);



    const get_missing_tools = () => {
        let resp = window.Module.get_tool_missing_list()
        console.log('get_missing_tools', resp);
    }

    const switch_board_layout_sheet_type = (id) => {
        window.Module.switch_board_layout_sheet_type(Number(id));
        update_view();
        set_active_board_layout_screen("all_sheets");
    }

    const switch_board_layout_sheet = (id) => {
        window.Module.switch_board_layout_sheet(Number(id));
        update_view();
        set_active_board_layout_screen("sheet");
    }

    const get_work_order_id = () => {
        try{
            let work_order_details = JSON.parse(window.Module.get_work_order_details());
            return work_order_details.id;
        }catch(err){
            console.error(`Error in get_work_order_id `, err)
            handleError(err);
        }
        return ''
    }

    let work_order_id = get_work_order_id();

    return (
        <div id="board_layout_view">
            <BoardLayoutHeader board_layout_sheet_types={board_layout_sheet_types} board_layout_sheets={board_layout_sheets} active_board_layout_screen={active_board_layout_screen} set_active_board_layout_screen={set_active_board_layout_screen} set_board_layout_sheet_needs_updated_preview={set_board_layout_sheet_needs_updated_preview} work_order_id={work_order_id}/>
            <div className={`flex-1-mh`}>
                {
                    active_board_layout_screen == "all_sheet_types" ?
                        <BoardTypeListing board_layout_sheet_types={board_layout_sheet_types} switch_board_layout_sheet_type={switch_board_layout_sheet_type} />
                    :
                    active_board_layout_screen == "all_sheets" ?
                        <BoardSheetListing board_layout_sheet_types={board_layout_sheet_types} board_layout_sheets={board_layout_sheets} switch_board_layout_sheet={switch_board_layout_sheet} work_order_id={work_order_id} board_layout_sheet_needs_updated_preview={board_layout_sheet_needs_updated_preview}/>
                        :
                        <BoardSheetView board_layout_sheet_types={board_layout_sheet_types}/>

                }
            </div>
        </div>
    )
}

export default BoardLayout;
