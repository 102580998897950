/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useParams } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, Navbar, Button, Input, Card, CardBody, CardHeader, Col, Nav, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalHeader, ModalBody, NavItem, ModalFooter} from "reactstrap";
import reactCSS from 'reactcss';
import { useAlert } from 'react-alert'
import Card_Layout from '../../components/Card_Layout/';
import { COLORS } from '../../utils/color';
import Search from '../../components/Search';
import "./style.css";

import dummy_tick from '../../utils/dummy_tick';
import Single_Card from '../../components/Single_Card';
import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal/index.js';
import { Account_Settings_Modal, InfurniaPlansModal } from '../../components/CommonModals/index.js';
import {useTranslation} from 'react-i18next'
import { send_analytics } from '../../utils/send_analytics.js';
import get_project_overridden_fields from '../../utils/get_admin_prefs.js';

const styles =  reactCSS({
    'default': {
        // main_div:{
        //     background: 'url("/resources/images/site_image.jpeg") 50% fixed',
        //     height: '100vh',
        //     width : '100%',
        //     position: 'relative',
        //     backgroundSize: 'cover',
        //     overflow: 'hidden'

        // },
        // layer: {
        //     width: '100%',
        //     height: '100vh',
        //     position: 'absolute',
        //     left: '0',
        //     top: '0',
        //     background: COLORS.layer_color,
        // },
        // nav_bar: {
        //     background: COLORS.primary,
        //     color: COLORS.white,
        //     height:'55px',
        //     // marginBottom:'15px',

        // },
        main_div:{
            background: COLORS.white,
            // background: 'url("/resources/images/site_image.jpeg") 50% fixed',
            height: '100vh',
            width : '100%',
            backgroundSize: 'cover',
            overflow: 'hidden'

        },
        layer: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '0',
            top: '0',
            // background: COLORS.theme_color,
        },
        nav_bar: {
            background: COLORS.nav_bar_light,
            color: COLORS.black,
            height:'55px',
            width:'100%',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
            zIndex:2,
        },
        infurnia_logo:{
            maxWidth: '100px',
            maxHeight: '40px',
            width: '100%',
            objectFit: 'contain'
        },
        nav_bar_icons_top:  {
            marginBottom: '0px',
            padding:'8px',
            display:'flex',
            alignItems:'center',
            height:'40px'
        },
        nav_bar_icons: {
            lineHeight: '20px',
            marginBottom: '0px',
            padding:'8px',
            marginRight:'10px',
            color:COLORS.black,
        },
        nav_bar_icons_light: {
            lineHeight: '20px',
            marginBottom: '0px',
            padding:'8px',
            marginRight:'10px',
            color:COLORS.black,
        },
    },
});

const design_card_styles = reactCSS({
    'default':{
        design_card_outermost: {
            position: 'relative',
            paddingBottom:'calc(56.25% + 20px)',
            margin:'15px',
            marginTop:'20px'
        },
        design_card_outer: {
            position:'absolute',
            top:'0',
            bottom:'0',
            left:'0',
            right:'0',
        },
        design_card: {
            overflow:'hidden',
            textAlign:'center',
            height: '100%'
        },
        image: {
            height:'150px',
            marginLeft:'50%',
            transform:'translateX(-50%)',
            cursor:'pointer'
        },
    }
})


const Edit_Project_Modal = ({ open, project_name, client_name, client_details, update_project, project_status_id, handle_close, active_project, set_page_loader, order_id }) => {

    const [ status_selected, set_status_selected ]=useState('null')
    const [ updated_project_name, set_updated_project_name ]=useState('')
    const [ updated_order_id, set_updated_order_id ]=useState('')
    const [ updated_client_name, set_updated_client_name ]=useState('')
    const [ updated_client_details, set_updated_client_details ]=useState('')
    const [ status_options, set_status_options ]=useState([])
    const alert = useAlert();
    const [project_id_overridden, set_project_id_overridden] = useState("Project ID");
    const [client_name_overridden, set_client_name_overridden] = useState("Client Name");
    const [client_details_overridden, set_client_details_overridden] = useState("Client Details");

    const get_project_fields = async () => {
        try{
            let project_fields = await get_project_overridden_fields();
            set_project_id_overridden(project_fields.project_ID_overridden);
            set_client_name_overridden(project_fields.client_name_overridden);
            set_client_details_overridden(project_fields.client_details_overridden);
        }catch(err) {
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const on_click_close = () => {
        set_updated_project_name('')
        set_updated_client_name('')
        set_updated_order_id('')
        set_status_selected('null')
        set_updated_client_details('');
        set_project_id_overridden("Project ID");
        set_client_name_overridden("Client Name");
        set_client_details_overridden("Client Details");
        handle_close()
    }

    const validate_project_name = (project_name) => {
        var re = /^[a-zA-Z0-9 ]+$/
        return project_name.trim() && re.test(project_name)
    }

    const submit_values = () => {
        if(validate_project_name(updated_project_name)){
            update_project(updated_project_name,updated_client_name, updated_client_details, status_selected, updated_order_id)
            handle_close()
        }else{
            alert.error('Special Characters Not permitted. Please modify the Project Name')
        }
    }

    useEffect(() => {
        if(open){
            if(project_name){
                set_updated_project_name(project_name);
            }
            if(client_name){
                set_updated_client_name(client_name);
            }
            if(client_details){
                set_updated_client_details(client_details);
            }
            if(order_id){
                set_updated_order_id(order_id);
            }
            if(project_status_id){
                set_status_selected(project_status_id)
            }
            get_project_fields();

            window.Promisify(window.Module.get_store_status())
            .then(resp => {
                // console.log("resp proj d", resp )
                set_status_options(JSON.parse(resp));
                if(!project_status_id){
                    set_status_selected(JSON.parse(resp)[0].id);
                }
            })
        }
    },[open, project_name, client_name, client_details, project_status_id, order_id ])

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={on_click_close}>
            {/* <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.black , color:COLORS.white}}>
                        <span>Edit Project</span>
                        <span style={{cursor:'pointer', fontSize:'24px', fontWeight:'700'}} onClick={on_click_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'250px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Project Name*</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_project_name} onChange={(e) => set_updated_project_name(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Client Name</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_client_name} onChange={(e) => set_updated_client_name(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Project ID</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_order_id} onChange={(e) => set_updated_order_id(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center' , height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Project Status</span>
                                <Input style={{flex:3 , height:"30px", border:0, borderBottom:'1px solid #ced4da', fontSize:'12px'}} outlined='false' type="select" value={status_selected} onChange={(e) => set_status_selected(e.target.value)}>
                                    {status_options.map((itm,idx) => (
                                        <option key={idx} value={itm.id}>{itm.status}</option>
                                    ))}
                                </Input>
                            </Col>  
                        </Row>
                        <Row>
                            <Col style={{textAlign:'center',marginTop:'30px'}}>
                                <Button onClick={submit_values} style={{backgroundColor:COLORS.black, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button" >Update</Button>
                            </Col>  
                        </Row>
                    </Col>
                </Row>
            </Card> */}
            <IModalHeader toggle={on_click_close}>
                Edit Project
            </IModalHeader>
            <IModalBody>
                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>Project Name*</span>
                    <Input style={{width:'280px'}} type="text" value={updated_project_name} onChange={(e) => set_updated_project_name(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>{client_name_overridden}</span>
                    <Input style={{width:'280px'}} type="text" value={updated_client_name} onChange={(e) => set_updated_client_name(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>{client_details_overridden}</span>
                    <Input style={{width:'280px'}} type="text" value={updated_client_details} onChange={(e) => set_updated_client_details(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>{project_id_overridden}</span>
                    <Input style={{width:'280px'}} type="text" value={updated_order_id} onChange={(e) => set_updated_order_id(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>Project Status</span>
                    <Input style={{width:'280px'}} type="select" value={status_selected} onChange={(e) => set_status_selected(e.target.value) }>
                        <option value='null'>None</option>
                        {status_options.map((itm,idx) => (
                            <option key={idx} value={itm.id}>{itm.status}</option>
                        ))}
                    </Input>
                </div>
            </IModalBody>

            <IModalFooter style={{}}> 
                <Button style={{marginRight:'32px'}} className='primary_button_default' onClick={submit_values}>Update</Button>
            </IModalFooter>
        </Modal>
        
    )    
}

const New_Design_Modal = ({ org_key, open, handle_close, active_project, set_page_loader }) => {

    const [all_design_templates, set_all_design_templates] = useState([]);
    const [store_design_templates, set_store_design_templates] = useState([]);
    const history = useHistory();
    const [template_selection, set_template_selection] = useState('');
    const [search_string, set_search_string] = useState('');
    const [display_store_templates, set_display_store_templates] = useState([]);
    const [display_all_design_templates, set_display_all_design_templates] = useState([]);
    const {t} = useTranslation()

    useEffect(() => {
        if(open){
            window.Promisify(window.Module.fetch_design_templates())
            .then(response_string => {
                var all_templates=JSON.parse(response_string) 
                console.log('all_templates =================>', all_templates)
                set_all_design_templates(all_templates.global_templates);
                set_display_all_design_templates(all_templates.global_templates)
                set_display_store_templates(all_templates.org_templates);
                set_store_design_templates(all_templates.org_templates);
            })
        }
    },[open])

    useEffect(() => {
        set_display_all_design_templates(all_design_templates.filter(x => x.name && x.name.toLocaleLowerCase().includes(search_string.toLocaleLowerCase())))
        set_display_store_templates(store_design_templates.filter(x => x.name && x.name.toLocaleLowerCase().includes(search_string.toLocaleLowerCase())))
    }, [search_string]);

    const image_onclick_fn = async (list_item, active_project_id) => {
        set_search_string('')
        set_page_loader({
            show: true,
            text: 'Creating New Design...'
        },async () => {
            //await dummy_tick(0);
            setTimeout(async() => {  
                if(active_project_id){
                    var list_item_stringify=JSON.stringify(list_item)
                    var response1 = await window.Promisify(window.Module.add_design_from_template(list_item_stringify, active_project_id))
                    // var response= JSON.parse(response1)
                    if(window['analytics']){
                        try{
                            let analytics_data = {
                                "category": "create_design",
                                "label": 'template-'+(list_item.id),
                            }
                            send_analytics(analytics_data)
                        }catch(err){
                            console.error(err);
                        }
                    }
                    
                    var add_design_interval = setInterval(async () => {
                        // var resp = await window.Promisify(window.Module.get_clone_design_status());
                        var resp = await window.Promisify(window.Module.get_await_core_status("new_design"));
                        var resp_json = JSON.parse(resp);
                        console.log(resp_json["status"] + " status")
                        if(resp_json["status"] == "execution_done"){
                            clearInterval(add_design_interval);
                            if(resp_json["response_code"]=="out_of_license"){
                                set_page_loader({
                                    show: false,
                                    text: 'Please wait'
                                });	
                            }else if(resp_json["response_code"]=="couldn't_create_design"){
                                set_page_loader({
                                    show: false,
                                    text: 'Please wait'
                                });	
                            }else{
                                var url = '/design/'+resp_json["response_code"];
                                (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                                history.push(url)
                            }
                        }
                    },100)

                    
                    
                    // set_page_loader({
                    //     show: false,
                    //     text: ''
                    // })

                }    
            }, 0);
        })
    }

    return(
        <Modal className="modal-dialog-centered" size='xl' isOpen={open} toggle={ () => {handle_close(); set_search_string('')}}>
            {/* <Card className="bg-secondary shadow border-0">
                <CardHeader style = {{backgroundColor: COLORS.primary , color:COLORS.white}} >
                    <div className="text-muted text-center mt-2 mb-3">
                        <h4>Choose a Template
                        	<button style={{align:"left"}} aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handle_close}>
                                x
                            </button>
                        </h4>
                    </div>
                </CardHeader>
                <CardBody style = {{backgroundColor:COLORS.white }} className="text-center px-lg-2 py-lg-2">
                    {store_design_templates.length>0?(<b>Store Templates</b>):''}
                    {store_design_templates.length>0?(<br />):''}
                    {store_design_templates.length>0?(<Card_Layout list={store_design_templates} no_of_cards={4} image_onclick_fn={image_onclick_fn} is_template='true' active_project_id={active_project} set_page_loader={set_page_loader} />):''}
                    {store_design_templates.length>0?(<br />):''}
                    {store_design_templates.length>0?(<b>Infurnia Templates</b>):''}
                    {store_design_templates.length>0?(<br />):''}
                    <Card_Layout list={all_design_templates} no_of_cards={4} image_onclick_fn={image_onclick_fn} is_template='true' active_project_id={active_project} set_page_loader={set_page_loader} />
                </CardBody>
            </Card> */}
            <IModalHeader toggle={ () => {handle_close(); set_search_string('')}}>Template Selection</IModalHeader>
            <IModalBody>
                <div style={{height:'600px'}}>
                {/* <Navbar>
                    <Nav>
                        <div style={{padding:'4px 16px 4px 0px', borderRight:'1px solid #E8E8E8'}}>Templates</div>
                        <div style={{padding:'4px 0px 4px 16px'}}>Favourites</div>
                    </Nav>
                    <Nav className="justify-content-end">
                        <Input id="design_search_input" style={{width:'200px', borderRadius:'5px', marginRight:'16px', fontSize:'12px' }} placeholder='Search the Template' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input>
                    </Nav>
                </Navbar> */}
                <Navbar>
                    <Nav>
                        <div style={{padding:'4px 16px 4px 0px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === '' ? '700' : '400', fontSize:'14px' }} onClick={() => set_template_selection('')}>{t(`All Templates`)}</div>
                        <div style={{padding:'4px 16px 4px 16px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === 'org' ? '700' : '400', fontSize:'14px'}} onClick={() => set_template_selection('org')}>{t(`Org Templates`)}</div>
                        <div style={{padding:'4px 16px 4px 16px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === 'store' ? '700' : '400', fontSize:'14px'}} onClick={() => set_template_selection('store')}>{t(`Default Templates`)}</div>
                        {/* <div style={{padding:'4px 0px 4px 16px', fontSize:'14px'}}>Featured Templates</div> */}
                    </Nav>
                    <Nav className="justify-content-end">
                        <div className = 'search_container_ideal'>
                            <span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
                            <Input id='template_search' style={{width:'200px', marginRight:'16px', fontSize:'12px', padding:'6px 12px 6px 12px', border:'0px' }} placeholder='Search the Template' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input>
                        </div>
                        {/* <Search list={all_design_templates} set_list={set_display_all_design_templates} search_value='name'></Search> */}
                    </Nav>
                    {/* <Nav className="justify-content-end">
                        <div></div>
                    </Nav> */}
                </Navbar>
                <div style={{height:'540px', overflow:'auto'}}>
                {
                    template_selection === 'org' ?
                    
                        display_store_templates && display_store_templates.length ?
                        <Card_Layout list={display_store_templates} no_of_cards={4} image_onclick_fn={image_onclick_fn} is_template='true' active_project_id={active_project} set_page_loader={set_page_loader} />
                        :
                        <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:'40px'}}>
                            
                            <div style={{maxWidth:'450px', textAlign:'center'}}>
                                No Org Templates Found. You can create your own custom org templates by marking any design as template.
                             </div>
                             <div>
                                or
                            </div>
                            <div>
                                Use Defaults Templates to start designing.
                            </div>
                        </div>
                    
                    :
                    template_selection === 'store' ?
                    <Card_Layout list={display_all_design_templates} no_of_cards={4} image_onclick_fn={image_onclick_fn} is_template='true' active_project_id={active_project} set_page_loader={set_page_loader} />
                    :
                    <Card_Layout list={[...display_store_templates , ...display_all_design_templates]} no_of_cards={4} image_onclick_fn={image_onclick_fn} is_template='true' active_project_id={active_project} set_page_loader={set_page_loader} />
            }
            </div>
            </div>
            </IModalBody>
        </Modal>
    )    
}

const Designs = ({org_key, active_project_id, set_page_loader, Filler, submit_for_delete_confirm, store_details, init, logout}) => {
    
    const [ show_edit_project_modal, set_show_edit_project_modal ] = useState(false)
    const [ show_new_design_modal, set_show_new_design_modal ] = useState(false)
    const [ all_designs, set_all_designs ] = useState([])
    const [ project_designer_id, set_project_designer_id ] = useState('')
    const [ project_name, set_project_name ] = useState('')
    const [ client_name, set_client_name ] = useState('')
    const [ client_details, set_client_details ] = useState('')
    const [ order_id, set_order_id ] = useState('')
    const [ project_status_id, set_project_status_id ] = useState('')
    const [ recent_card_div_height, set_recent_card_div_height ] = useState('')
	const [user_id, set_user_id] = useState('');
    const [search_string, set_search_string] = useState('');
    const [display_designs, set_display_designs] = useState([]);
    const [sort_value, set_sort_value] = useState(0);
    const [is_sort_open, set_is_sort_open] = useState(false);
    const [sortby_text, set_sortby_text] = useState('Recent');
    const [all_designs_copy, set_all_designs_copy] = useState([]);
    const [is_project_navigation_open, set_is_project_navigation_open] = useState(false);
    const [project_list, set_project_list] = useState([]);
    const [ admin_link, set_admin_link ] = useState('/admin.infurnia.com/')
	const [ mes_link, set_mes_link ] = useState('https://mes.infurnia.com')
    const [is_profile_dropdown_open, set_is_profile_dropdown_open] = useState(false);
    const [show_account_settings, set_show_account_settings] = useState(false);
    const [show_current_subscription, set_show_current_subscription] = useState(false);
    const [ status_options, set_status_options ]=useState([]);
    const [project_id_overridden, set_project_id_overridden] = useState("Project ID");
    const [client_name_overridden, set_client_name_overridden] = useState("Client Name");
    const [client_details_overridden, set_client_details_overridden] = useState("Client Details");

    const get_project_fields = async () => {
        try{
            let project_fields = await get_project_overridden_fields();
            set_project_id_overridden(project_fields.project_ID_overridden);
            set_client_name_overridden(project_fields.client_name_overridden);
            set_client_details_overridden(project_fields.client_details_overridden);
        }catch(err) {
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const history = useHistory(); 
    const alert = useAlert();
    const {t} = useTranslation()

    const sort_by_recent =  (a , b) => {
        if(a.last_activity_at > b.last_activity_at) return -1;

        return 1;
    }

    const sort_by_name_ascending =  (a , b) => {
        if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;

        return -1;
    }

    const sort_by_name_descending =  (a , b) => {
        if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return -1;

        return 1;
    }

    const sort_by_newest =  (a , b) => {
        if(a.created_at > b.created_at) return -1;

        return 1;
    }

    const sort_by_oldest =  (a , b) => {
        if(a.created_at > b.created_at) return 1;

        return -1;
    }


    const fetch_project_all_designs = () => {
        try{
        set_page_loader({
            show: true,
            text: 'Fetching designs...'
        },async () => {
            var response_string = await window.Promisify(window.Module.load_designs(active_project_id));
            if(response_string == "Unauthorized"){
                alert.error("You do not have access to this project");
                close_project_onclick();
            }else if(response_string == "Error"){
                alert.error("Something went wrong while fetching this project");
            }else{
                var project_all_designs = response_string && response_string.length && JSON.parse(response_string);

                if(project_all_designs){
                    set_all_designs(project_all_designs)
                    //set_display_designs(project_all_designs) 
                    console.log('project_all_designs=========>', project_all_designs) 
                }

                update_project_details();
            }

            set_page_loader({
                show: false,
                text: 'Fetching designs...'
            })
        })
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const update_project_details = async () => {
        try{
        var project_response_string = await window.Promisify(window.Module.get_project(active_project_id));
        if(project_response_string == "Unauthorized"){
            alert.error("You do not have access to this project");
            close_project_onclick();
        }else if(project_response_string == "Error"){
            alert.error("Something went wrong while fetching this project");
        }else{
            var project_details = project_response_string && JSON.parse(project_response_string);

            if(project_details){
                set_project_name(project_details.name)
                set_order_id(project_details.order_id)
                set_client_name(project_details.client_name)
                set_client_details(project_details.client_details)
                set_project_status_id(project_details.project_status_id)
                set_project_designer_id(project_details.user_id)
            }
        }
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const fetech_project_list = async() => {
        try{
        var response = await window.Promisify(window.Module.getProjects())
        var response_obj = response && JSON.parse(response)
        if(response_obj && response_obj.length){
            set_project_list(response_obj)
        }
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    // const fetch_projectDetails = () => {  
    //     return new Promise((resolve,reject) => {
    //         set_page_loader({
    //             show: true,
    //             text: 'Fetching projects...'
    //         },async () => {
    //             //await dummy_tick(0);
    //             var response = await window.Promisify(window.Module.getProjects())
    //             // set_initialized(true)
    //             var response_obj = response && JSON.parse(response)
    //             // if(response_obj && response_obj.length){
    //             //     set_list(response_obj)
    //             // }
    //             set_page_loader({
    //                 show: false,
    //                 text: 'Fetching projects...'
    //             })
    //             resolve();
    //         }) 
    //     }).catch(err => {
    //         //console.error(err);
    //         return Promise.reject(err);
    //     })
    // }

    const close_edit_project_modal = () => {
        set_show_edit_project_modal(false)
    }

    const open_edit_project_modal = () => {
        set_show_edit_project_modal(true)
    }

    const close_new_design_modal = () => {
        set_show_new_design_modal(false)
    }

    const open_new_design_modal = () => {
        set_show_new_design_modal(true)
    }

    const image_onclick_fn = (current_design_branch_id) => {
        set_page_loader({
            show: true,
            text: 'Loading your design...'
        })
        setTimeout(() => {  
            if(current_design_branch_id){ 
                var url = '/design/'+current_design_branch_id;
                (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
                history.push(url)
            }
        },0)

    }

    const open_blank_design = async () => {
        // "4920553065714004094"
        set_page_loader({
            show: true,
            text: 'Creating New Design...'
        },async () => {
            var selected_template = {"Project":{"client_details":null,"client_name":null,"consumer_id":null,"created_at":"2018-03-02T14:09:44.000Z","deleted_at":null,"designer_id":16,"id":"6360236061750650192","name":"Design Templates","project_details":null,"project_status_id":null,"reference_project_id":null,"store_id":"1504672400687","updated_at":"2018-03-02T14:09:44.000Z"},"component":null,"created_at":"2018-10-11T09:25:52.000Z","deleted_at":null,"design_branch_id":"cbff249f2cf75851","designer_id":16,"display_pic":{"created_at":"2019-09-17T07:17:23.000Z","deleted_at":null,"id":"2b3b11b4dce06c77","image":"./image_uploads/4920553065714004094.JPG","updated_at":"2019-09-17T07:17:23.000Z","x_size":null,"y_size":null},"display_pic_id":"2b3b11b4dce06c77","id":"4920553065714004094","name":"Blank","project_id":"6360236061750650192","status":"Designing","template":true,"updated_at":"2019-09-17T07:17:23.000Z"};
            var list_item_stringify=JSON.stringify(selected_template)
            var response1 = await window.Promisify(window.Module.add_design_from_template(list_item_stringify, active_project_id))
            var response= JSON.parse(response1)
            set_page_loader({
                show: false,
                text: 'Creating New Design...'
            })

            var url = '/design/'+response;
            (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
            history.push(url)
        })
    }

    const copy_design = (branch_id, project_id, name, advanced_paste_options) => {
        set_page_loader({
			show: true,
			text: 'Copying your design...'
		});

		setTimeout(async() => {
            var response;
            if(advanced_paste_options)
			    response = await window.Promisify(window.Module.clone_design(branch_id, project_id, name, JSON.stringify(advanced_paste_options)))
            else
                response = await window.Promisify(window.Module.clone_design(branch_id, project_id, name, JSON.stringify({})))
            var clone_design_interval = setInterval(async () => {
                // var resp = await window.Promisify(window.Module.get_clone_design_status());
                var resp = await window.Promisify(window.Module.get_await_core_status("clone_design"));
                var resp_json = JSON.parse(resp);
                console.log(resp_json["status"] + " status")
                if(resp_json["status"] == "execution_done"){
                    clearInterval(clone_design_interval);
                    if(resp_json["response_code"]=="out_of_license"){
                        alert.error("Out of license to create new design")
                    }else if(resp_json["response_code"]=="couldn't_create_design"){
                        alert.error("Couldn't copy design");
                    }else{
                        initiate_component();
                        alert.success("Design copied successfully");
                    }
                    set_page_loader({
                        show: false,
                        text: 'Please wait'
                    });	
                }
            },100)

		},0)
    }

    const rename_design = (design_id, name) => {
        set_page_loader({
			show: true,
			text: 'Renaming design...'
		});

		setTimeout(async() => {  
			var response = await window.Promisify(window.Module.rename_design(design_id, name))
            initiate_component();

            alert.success("Design renamed successfully");

			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
    }

    const update_design_additional_properties = (design_id, additional_properties) => {
        set_page_loader({
			show: true,
			text: 'Updating design additional properties...'
		});

		setTimeout(async() => {  
			var response = await window.Promisify(window.Module.update_design_additional_properties(design_id, additional_properties))
            initiate_component();
            alert.success('Design additional properties updated')

			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
    }



    const delete_design = (design_id) => {
        set_page_loader({
            show: true,
            text: 'Deleting design...'
        });

		setTimeout(async() => {  
            try{
                var response = await window.Promisify(window.Module.delete_design(design_id))
                response = JSON.parse(response)
                if(response && response.action == 'TOAST_ERROR'){
                    alert.error(response.message)
                }else{
                    alert.success('Design Deleted Successfully')
                }
                
                initiate_component();
            }catch(error){
                alert.error("Unable to Delete Design. Please try later.")
                console.error("Unable to Delete Design. Please try later.", error)
            }
            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        }, 0)
    }

    const update_project = (project_name, client_name, client_details, project_status_id, order_id) => {
        
        set_page_loader({
			show: true,
			text: 'Updating project...'
		});

		setTimeout(async() => {  
			var response = await window.Promisify(window.Module.update_project(active_project_id, project_name, client_name, project_status_id, order_id, client_details))
            initiate_component();

			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
    }

    
    const initiate_component = () => {
		set_user_id(window.Module.get_active_user().designer_id);
        fetch_project_all_designs();
        get_project_fields();
        // fetch_projectDetails();
        // fetech_project_list()
        var element = document.getElementById('recent_card_div');
        var height = element && element.offsetHeight;
        height && set_recent_card_div_height(Math.floor(height/3));
    }

    const close_project_onclick = () => {
        var url = '/';
        (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
        history.push(url) 
    }

    const view_designs_onclick = (id) => {
        var url = '/project/'+id;
        (org_key&&window.location.origin.includes("infurnia.com")?(url = "/"+org_key + url):null);
        history.push(url)
        //window.location()
    }

    const toggle_fullscreen = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {  
                document.documentElement.requestFullScreen();  
            } else if (document.documentElement.mozRequestFullScreen) {  
                document.documentElement.mozRequestFullScreen();  
            } else if (document.documentElement.webkitRequestFullScreen) {  
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);  
            }  
        }else {  
            if (document.cancelFullScreen) {  
                document.cancelFullScreen();  
            } else if (document.mozCancelFullScreen) {  
                document.mozCancelFullScreen();  
            } else if (document.webkitCancelFullScreen) {  
                document.webkitCancelFullScreen();  
            }  
        } 
    }

    // useEffect(() => {
    //     if(search_string){
    //         set_display_designs(all_designs_copy.filter(item => item.name.toLowerCase().includes(search_string.toLocaleLowerCase())))
    //     }else{
    //         set_display_designs(all_designs_copy)
    //     }

    //     //set_sort_value(sort_value)
    //     //console.log("display designs" , display_designs , search_string , all_designs)
    // }, [search_string, all_designs_copy]);

    useEffect(() => {
        // console.log("display designs 3", display_designs)
    }, [display_designs]);

    useEffect(() => {
        // console.log("display designs 2", all_designs_copy)
    }, [all_designs_copy]);

    useEffect(() => {
        // console.log("display designs 4", sort_value)
    }, [sort_value]);

    useEffect(() => {
        // console.log("display designs 1", all_designs)
        // set_search_string(search_string)
        // set_sort_value(sort_value)
    }, [all_designs]);

    useEffect(() => {
        // console.log("project list ", project_list)
    }, [project_list]);

    useEffect(() => {
        let temp_all_designs = [...all_designs]
        if(sort_value === 0){
            temp_all_designs.sort(sort_by_recent)
            set_all_designs_copy(temp_all_designs)
            set_sortby_text('Recent')
        }
        if(sort_value === 1){
            temp_all_designs.sort(sort_by_name_ascending)
            set_all_designs_copy(temp_all_designs)
            set_sortby_text('A-Z')
        }
        if(sort_value === 2){
            temp_all_designs.sort(sort_by_name_descending)
            set_all_designs_copy(temp_all_designs)
            set_sortby_text('Z-A')
        }
        if(sort_value === 3){
            temp_all_designs.sort(sort_by_newest)
            set_all_designs_copy(temp_all_designs)
            set_sortby_text('Newest')
        }
        if(sort_value === 4){
            temp_all_designs.sort(sort_by_oldest)
            set_all_designs_copy(temp_all_designs)
            set_sortby_text('Oldest')
        }
        
    }, [sort_value, all_designs]);

    useEffect(() => {
        if(active_project_id){ 
            initiate_component();
            set_admin_link(window.Module.API.admin_path);
			set_mes_link(window.Module.API.mes_app_path)
        }
        window.redirect_target = 'designs'
    },[])
    
    useEffect(() => {
        window.Promisify(window.Module.get_store_status())
        .then(resp => {
            set_status_options(JSON.parse(resp));
        })
    }, [store_details]);

    useEffect(() => {
        if(project_name){
            document.title = project_name + (store_details && store_details.store_name ? " - " + store_details.store_name : "");
        }
    }, [project_name]);

    return (
        <div>
            <Edit_Project_Modal 
                open={show_edit_project_modal}
                handle_close={close_edit_project_modal}
                project_name={project_name}
                order_id={order_id}
                client_name={client_name}
                client_details={client_details}
                project_status_id={project_status_id}
                update_project={update_project}
                active_project={active_project_id}
                set_page_loader={set_page_loader}
            />
            <InfurniaPlansModal open={show_current_subscription} handle_close={() => set_show_current_subscription(false)}/>
            <Account_Settings_Modal open={show_account_settings} handle_close={() => set_show_account_settings(false)} store_details={store_details} set_page_loader={set_page_loader}></Account_Settings_Modal>
            <New_Design_Modal open={show_new_design_modal} org_key={org_key} handle_close={close_new_design_modal} active_project={active_project_id} set_page_loader={set_page_loader} />
            <div style={styles.main_div}>
                <div style={styles.layer}>
                </div>
                <Navbar style={styles.nav_bar}>
                    <Nav><img style={styles.infurnia_logo} src={store_details&&store_details.logo_path?store_details.logo_path :"/resources/images/infurnia_black.svg"} /></Nav>
                    {/* <Nav> 
                        <Breadcrumb style={{padding:'0px !important'}} tag="nav" listTag="div" >
                            <BreadcrumbItem active tag="span">{active_project_id}</BreadcrumbItem>
                        </Breadcrumb>
                    </Nav> */}
                    <Nav className="justify-content-end" style={{alignItems:'center'}}>
                        <a href={admin_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px', fontSize:'14px', marginRight:'16px', color:COLORS.black}} title="Admin portal" ><div className='flex_property'> <img src='/resources/nav_icons_new/newtab.svg' className='mr-2' /> {t(`Admin`)}</div></a>
						{
							store_details && store_details.active_add_ons && store_details.active_add_ons.length && store_details.active_add_ons.find(o => ['1', '4', '6', '7'].includes(o.id)) ?
							<a href={mes_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px', fontSize:'14px', marginRight:'16px', color:COLORS.black}} title="Mes portal" ><div className='flex_property'> <img src='/resources/nav_icons_new/newtab.svg' className='mr-2' /> {t(`Mes`)}</div></a>
							:''
						}
                        <div className='vl_end'></div>
                        <Dropdown isOpen={is_profile_dropdown_open} toggle={() => set_is_profile_dropdown_open(!is_profile_dropdown_open)}>
                            {/* <DropdownToggle> */}
                                <DropdownToggle style={{borderRadius:'50%', height:'32px', width:'32px', margin:'2px 0px', color:'rgba(0, 120, 255, 1)', backgroundColor:'rgba(0, 120, 255, 0.1)', border:'1px solid white', justifyContent:'center', display:'flex', alignItems:'center',marginLeft:'8px',marginRight:'8px', textTransform:'uppercase', fontWeight:500}}>{store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim()[0] : ':)'}</DropdownToggle>
                            {/* </DropdownToggle> */}
                            <DropdownMenu style={{marginTop:'11px'}}>
                                <DropdownItem onClick={() => set_show_account_settings(true)}>
                                    Account Settings
                                </DropdownItem>
                                <DropdownItem onClick={() => set_show_current_subscription(true)}>
                                    Current Subscription
                                </DropdownItem>
                                {/* <DropdownItem>
                                    Switch Organization
                                </DropdownItem> */}
                                <DropdownItem onClick={logout}>
                                    Logout
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons_top}} onClick={toggle_fullscreen}><img data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" src={'/resources/nav_icons_new/fullscreen.svg'}></img></a>
                        <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons_top}} onClick={close_project_onclick}><img data-toggle="tooltip" data-placement="bottom" title="Home Page" src={'/resources/nav_icons_new/back.svg'}></img></a>
                        {/* <a style={{cursor:'pointer', fontSize:'24px'}} onClick={logout}><i style={styles.nav_bar_icons} data-toggle="tooltip" data-placement="bottom" title="Logout" className="fa fa-sign-out hover_blue"></i></a> */}
                        {/* <i onClick={close_project_onclick} style={{...styles.nav_bar_icons,cursor:'pointer'}} data-toggle="tooltip" data-placement="bottom" title="Home Page" className="fa fa-nav fa-times fa-2x hover_blue"></i> */}
                    </Nav>
                </Navbar>
                {
                    // store_details.suspension_status ?
                    // <Row>
                    //     <Col className='flex_center DMSans trail_extension' style={{margin:0, height:'40px', width:'100%'}}>
                    //         <div style={{fontWeight:'700', fontSize:'12px'}}>Your free trial has expired you can only view items</div>
                    //     </Col>
                    // </Row>
                    // :'' 
                }
                <Navbar style={{width:'100%' , height: '48px', backgroundColor:COLORS.theme_color , padding:0}}>
                    <Nav> 
                        {/* <i onClick={close_project_onclick} style={{...styles.nav_bar_icons_light,cursor:'pointer',fontSize:'24px', margin:'0px 8px', }} data-toggle="tooltip" data-placement="bottom" title="Home Page" className="fa fa-nav fa-chevron-left hover_blue"></i> 
                        <div className='vl'></div> */}
                        {/* <a style={{cursor:'pointer'}}><i style={{...styles.nav_bar_icons_light, fontSize:'24px' , marginLeft:'8px' , marginRight:'8px'}} onClick={() => user_id!=project_designer_id?'':open_edit_project_modal()} data-toggle="tooltip" data-placement="bottom" title={user_id!=project_designer_id?"Only the original author can modify the project":"Modify Project"} className={"fa fa-nav fa-pencil hover_blue" + (user_id!=project_designer_id?" disabled_fa_icon":"")}></i></a> */}
                        {/* <Dropdown style={{height:'32px', alignSelf:'center'}} isOpen={is_project_navigation_open} toggle={() => set_is_project_navigation_open(!is_project_navigation_open)}>
                            <DropdownToggle style={{borderRadius:'5px', borderColor: 'transparent', marginRight:'16px', fontSize:'14px', fontWeight: 'bold',backgroundColor:'transparent', color:'black', alignItems:'center', display:'flex'}}>{project_name}<span style={{marginLeft:'4px', display:'flex',alignItems:'center'}}><i style={{fontSize:'14px', padding: '2px', color:'white'}} className='fa fa-caret-down'></i></span> </DropdownToggle>
                            <DropdownMenu container="body">
                                {
                                    project_list && project_list.length ? project_list.map(x => {
                                        return <DropdownItem onClick={() => view_designs_onclick(x.id)} >{x.name}</DropdownItem>
                                        //console.log("project", x.name)
                                    })
                                    :<DropdownItem>No Projets to navigate</DropdownItem>
                                }
                            </DropdownMenu>
                        </Dropdown> */}
                        <div className='show_on_hover' style={{borderRadius:'5px', borderColor: 'transparent', marginLeft:'24px', marginRight:'8px', fontSize:'14px',backgroundColor:'transparent', color:'black', alignItems:'center', display:'flex'}}>{project_name}
                            <div className='bottom' id='tooltip'>
                            <div className='tooltip-arrow'/>
                                <div style={{backgroundColor:'black',color:'white', borderRadius:'6px'}}>
                                    <div style={{padding: '16px 16px 8px 16px', display:'flex'}}>
                                        <span style={{flex:2}}>{client_name_overridden}:</span> <span style={{flex:3}}>{client_name ? client_name : '-'}</span>
                                    </div>
                                    <div style={{padding: '8px 16px', display:'flex'}}>
                                        <span style={{flex:2}}>{project_id_overridden}:</span> <span style={{flex:3}}>{order_id ? order_id : '-'}</span>
                                    </div>
                                    <div style={{padding: '8px 16px 16px 16px', display:'flex'}}>
                                        <span style={{flex:2}}>Project Status:</span> <span style={{flex:3}}>{project_status_id ? status_options.find(item => item.id === project_status_id) ? status_options.find(item => item.id === project_status_id).status : project_status_id : '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons_top}} onClick={() => user_id!=project_designer_id?'':open_edit_project_modal()}><img data-toggle="tooltip" data-placement="bottom" title={user_id!=project_designer_id?"Only the original author can modify the project":"Modify Project"} src={'/resources/nav_icons_new/edit.svg'}></img></a>
                    </Nav>
                    <Nav className='justify-content-center'>
                        <Search list={all_designs_copy} set_list={set_display_designs} search_value='name'></Search>
                    </Nav>
                    <Nav className='justify-content-end'>
                        {/* <Search list={all_designs_copy} set_list={set_display_designs} search_value='name'></Search> */}
                        {/* <Input id="design_search_input" style={{width:'200px', borderRadius:'2px', marginRight:'16px', fontSize:'12px' }} placeholder='Search by Design name' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input> */}
                        <Dropdown isOpen={is_sort_open} toggle={() => set_is_sort_open(!is_sort_open)}>
                            <DropdownToggle style={{borderRadius:'2px', marginRight:'16px', fontSize:'12px',backgroundColor:'white', color:'#22272E', alignItems:'center', display:'flex',width:'auto'}}>Sort By: {sortby_text} <span style={{marginLeft:'4px', display:'flex',alignItems:'center'}}><i style={{fontSize:'14px', padding: '2px'}} className='fa fa-caret-down'></i></span> </DropdownToggle>
                            <DropdownMenu container="body">
                                <DropdownItem onClick={() => set_sort_value(0)}>Recent</DropdownItem>
                                <DropdownItem onClick={() => set_sort_value(1)}>A-Z</DropdownItem>
                                <DropdownItem onClick={() => set_sort_value(2)}>Z-A</DropdownItem>
                                <DropdownItem onClick={() => set_sort_value(3)}>Newest</DropdownItem>
                                <DropdownItem onClick={() => set_sort_value(4)}>Oldest</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Navbar>
                <Container style={{margin: '0px', width:"100%", maxWidth:"none"}} >
                {/* <Row style={{width:'100%' , height: '48px', marginBottom:'15px' , backgroundColor:'#38475B' , padding:0}}>

                </Row> */}
                <Row id='recent_card_div' style={{margin:0, width:'100%', height:store_details.suspension_status ? 'calc(100% - 158px)' :'calc(100% - 118px)', position:'absolute', top:all_designs.length?'118px':'103px', left:0}}>
                    {
                        !all_designs.length?
                        (
                            // <Col xs={12} sm={6} md={4} lg={3} style={{ width:'100%',marginBottom:'20px'}}> 
                            //     <div style={{ position:'relative', paddingBottom:'56.25%', margin:'15px', marginTop:'20px'}}  >  
                            //         <div style={{ position:'absolute', top:'0', left:'0', bottom:'0', right:'0'}}  >  
                            //             <div style={{  height:"100%", width:'100%', cursor:'pointer'}}  >  
                            //                 <img onClick={open_new_design_modal} style={{  width:'100%', height:"100%"}} src="/resources/images/new-design.png"/>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </Col>

                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer', width:'100%', height:"100%"}}  >  
                                <img onClick={() =>{project_designer_id!=user_id?alert.error('Cannot create designs in Non owned Projects'):open_new_design_modal()}} style={{  width:'20%', marginBottom:'48px'}} title={project_designer_id==user_id?"Create New Design":"Cannot create designs in Non owned Projects"} src="/resources/images/new_design.png"/>
                            </div>

                        ):
                        <Col style={{padding:'15px 0px 0px', height:'100%', overflow:'auto'}}>
                            {all_designs && all_designs.length ? <Card_Layout user_id={user_id} fetch_project_all_designs={fetch_project_all_designs} copy_design={copy_design} rename_design={rename_design} delete_design={delete_design} update_design_additional_properties={update_design_additional_properties} submit_for_delete_confirm={submit_for_delete_confirm} active_project_id={active_project_id} open_new_design_modal={open_new_design_modal} list={display_designs} image_onclick_fn={image_onclick_fn} no_of_cards={3} page_name="designs" set_page_loader={set_page_loader}  project_designer_id={project_designer_id}/> : ''}
                            {/* <Single_Card page_name="projects" list={display_designs}></Single_Card> */}
                        </Col>
                    }
                    {/* <Col style={{padding:0, height:'100%', overflow:'auto'}}>
                        {all_designs && all_designs.length ? <Card_Layout user_id={user_id} fetch_project_all_designs={fetch_project_all_designs} copy_design={copy_design} rename_design={rename_design} delete_design={delete_design} update_design_additional_properties={update_design_additional_properties} submit_for_delete_confirm={submit_for_delete_confirm} active_project_id={active_project_id} open_new_design_modal={open_new_design_modal} list={display_designs} image_onclick_fn={image_onclick_fn} no_of_cards={3} page_name="designs" set_page_loader={set_page_loader}  /> : ''}
                    </Col> */}
                </Row>
                
                {/* <div style={{display:"flex", flexDirection:'row', justifyContent:'space-between'}}>
                    <div style={{flex:2}}><img onClick={open_new_design_modal} style={{height:'220px', width:'100%',marginTop:'5px' , marginLeft:'50%', transform:'translateX(-50%)', cursor:'pointer'}} src="/resources/images/new-design.png"/></div>
                
                    <div style={{flex:7}}>{initialized && all_designs && all_designs.length ? <Card_Layout list={all_designs} no_of_cards={4} page_name="designs" set_page_loader={set_page_loader}  /> : <Designs_Filler />}</div>
                </div> */}
                </Container>
            </div>
        </div>    
    );
}

export {Edit_Project_Modal, Designs};

// <Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={handle_close}>
//             <Card className="bg-secondary shadow border-0">
//                 <CardHeader style = {{backgroundColor: COLORS.primary , color:COLORS.white}} >
//                     <div className="text-muted text-center mt-2 mb-3">
//                         <h4>Edit Project
//                         </h4>
//                     </div>
//                 </CardHeader>
//                 <CardBody style = {{backgroundColor:COLORS.white }} className="px-lg-5 py-lg-5">
//                     <Form role="form">
//                         <Row>
//                             <Col>
//                                 <FormGroup >
//                                     <Label className="form-control-label" htmlFor="input-username" >Project Name*</Label>
//                                     <Input placeholder="Project Name" type="text" value={updated_project_name} onChange={(e) => set_updated_project_name(e.target.value) } />
//                                 </FormGroup>
//                             </Col>  
//                         </Row>
//                         <Row>
//                             <Col>
//                                 <FormGroup>
//                                     <Label className="form-control-label" htmlFor="input-username">Project Status</Label>
//                                         <Input type="select" placeholder='Project Status' name="Project Status" value={status_selected} onChange={(e) => set_status_selected(e.target.value)} >
//                                             <option value='testing'>Testing Phase</option>
//                                             <option value='design'>Design Phase</option>
//                                             <option value='execution'>Execution Phase</option>
//                                             <option value='delivered'>Delivered</option>
//                                             <option value='initial'>Initial Stage</option>
//                                             <option value='productionD'>Presentation</option>
//                                             <option value='remeasurement'>Remeasurement Drawing</option>
//                                         </Input>
//                                 </FormGroup>
//                             </Col>  
//                         </Row>
//                         <div className="text-center">
//                             <Button className="my-4" style={{backgroundColor:COLORS.button_primary}} type="button">Update</Button>&nbsp;
//                             <Button className="my-4" style={{backgroundColor:COLORS.button_danger}} type="button" onClick={on_click_close} >Cancel</Button>
//                         </div>
//                     </Form>
//                 </CardBody>
//             </Card>
//         </Modal>