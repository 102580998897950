const get_project_overridden_fields = async() => {
    try{
        console.log("Calling store pref");
        let res = {client_name_overridden : "Client Name", client_details_overridden : "Client Details", project_ID_overridden : "Project ID"}
        let store_pref = window.Module.get_store_preferences();
        if(typeof store_pref == "string") {
            store_pref = JSON.parse(store_pref);
        }
        if(store_pref && store_pref.other_admin_preferences && store_pref.other_admin_preferences.customise_project_fields && store_pref.other_admin_preferences.customise_project_fields.fields && store_pref.other_admin_preferences.customise_project_fields.fields) {
            let fields = store_pref.other_admin_preferences.customise_project_fields.fields;
            if(fields.client_name_overridden) {
                res.client_name_overridden = fields.client_name_overridden;
            }
            if(fields.client_details_overridden) {
                res.client_details_overridden = fields.client_details_overridden;
            }
            if(fields.project_ID_overridden) {
                res.project_ID_overridden = fields.project_ID_overridden;
            }
        }
        return res;
    }catch(err) {
        return Promise.reject({err, info : "Error in get project overridden fields"})
    }
}

export default get_project_overridden_fields ;