/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Modal, Navbar, Button, Input, Card, Col, Nav, Row, tbody, thead, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import reactCSS from 'reactcss';

import Card_Layout from '../../components/Card_Layout/';
import { useAlert } from 'react-alert'
import { COLORS } from '../../utils//color.js'
import "./style.css";
import dummy_tick from '../../utils/dummy_tick';
import Search from '../../components/Search';
import Single_Card from '../../components/Single_Card';
import general_fetch from '../../utils/fetch';
import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal/index.js';
import ISearch from '../../components/ISearch';
import MainPageLayout from '../../components/MainPageLayout/index.js';
import ImageCarousel from '../../components/ImageCarousel/index.js';
import generic_sort from '../../utils/generic_sort.js';
import { toReadableDate } from '../../utils/time_utils.js';
// import { InfurniaPlansModal } from '../../components/CommonModals/index.js';
import { useTranslation } from 'react-i18next';
import GridLayout from '../../components/GridLayout/index.js';
import { Addn_prop_modal, Copy_Design_Modal, Edit_Design_Modal, Share_Branch_Modal_New, InfurniaPlansModal } from '../../components/CommonModals/index.js';
import DesignsLayout from '../../components/DesignsLayout/index.js';
import get_project_overridden_fields from '../../utils/get_admin_prefs.js';

// import { Edit_Project_Modal } from '../Designs';

// const styles = reactCSS({
//     'default': {
//         main_div: {
//             background: COLORS.theme_color,
//             // background: 'url("/resources/images/site_image.jpeg") 50% fixed',
//             height: '100vh',
//             width: '100%',
//             backgroundSize: 'cover',
//             overflow: 'hidden',
//         },
//         layer: {
//             width: '100%',
//             height: '100%',
//             position: 'absolute',
//             left: '0',
//             top: '0',
//             // background: COLORS.theme_color,
//         },
//         nav_bar: {
//             background: COLORS.nav_bar_light,
//             color: COLORS.black,
//             height: '55px',
//             width: '100%',
//             boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
//         },
//         infurnia_logo: {
//             maxWidth: '120px',
//             maxHeight: '40px',
//             width: '100%',
//             objectFit: 'contain'
//         },
//         nav_bar_icons: {
//             marginBottom: '0px',
//             padding: '8px',
//             display: 'flex',
//             alignItems: 'center',
//             height: '40px'
//         },
//         main_page_button_dark: {
//             color: COLORS.white,
//             border: '1px solid',
//             borderColor: COLORS.blue6,
//             borderRadius: '4px',
//             background: COLORS.blue6,
//             fontSize: '14px',
//             padding: '10px',
//             width: '80%',
//             textAlign: 'center',
//             fontWeight: 'bold'
//         },
//         main_page_button_light: {
//             color: COLORS.black,
//             border: '1px solid #000000',
//             borderRadius: '4px',
//             background: COLORS.white,
//             fontSize: '14px',
//             padding: '10px',
//             width: '80%',
//             textAlign: 'center',
//             fontWeight: 'bold'
//         },
//         recent_designs_title: {
//             color: COLORS.black,
//             fontSize: '16px',
//             paddingLeft: '10px',
//             width: '100%',
//             textAlign: 'left',
//             borderLeft: '2px solid #1ABB9C',
//             height: '20px',

//         }
//     },
// });

// const design_card_styles = reactCSS({
//     'default': {
//         design_card_outermost: {
//             position: 'relative',
//             paddingBottom: 'calc(56.25% + 20px)',
//             margin: '15px',
//             marginTop: '20px'
//         },
//         design_card_outer: {
//             position: 'absolute',
//             top: '0',
//             bottom: '0',
//             left: '0',
//             right: '0',
//         },
//         design_card: {
//             overflow: 'hidden',
//             textAlign: 'center',
//             height: '100%'
//         },
//         image: {
//             height: '150px',
//             marginLeft: '50%',
//             transform: 'translateX(-50%)',
//             cursor: 'pointer'
//         },
//     }
// })


const changeProject = (id, history) => {
    const searchParams = new URLSearchParams();
    // const searchParams = new URLSearchParams(location.search);
    // Update the 'view' query parameter
    searchParams.set('project', id);

    // Replace the current URL with the updated query parameter
    history.push({
        pathname: window.location.pathname,
        search: searchParams.toString(),
    });
};

const get_image_src = (item_img_src) => {
    return item_img_src ? (item_img_src.includes('resources/') ? (item_img_src) : (window.Module.API.server_path + item_img_src)) : '/resources/images/site_image.jpeg';
}

// const Account_Settings_Modal = ({open, handle_close, store_details, set_page_loader}) => {
//     const history = useHistory();
//     const alert = useAlert()
//     const [all_store_options, set_all_store_options] = useState([]);
// 	const [display_store_options, set_display_store_options] = useState([]);
//     const [selected_bu_json, set_selected_bu_json] = useState({});
// 	const [all_business_units, set_all_business_units] = useState();

//     const get_store_data = async() => {
//         var resp_store_details = await general_fetch({url:'/user/get_attached_stores'})
//         if(resp_store_details && resp_store_details.stores && resp_store_details.stores.length>0){
//             set_all_store_options(resp_store_details.stores)
//         }
//     }

//     const fetch_attached_stores = async () => {
//         try {
//             set_page_loader({ show: true, text: 'Fetching Orgs...' })
//             let resp = await general_fetch({ url: '/user/get_attached_stores' })
//             set_page_loader({ show: false })
//             set_all_store_options(resp.stores)
//             set_all_business_units(resp.business_units)
//             let a = {}
//             if (resp.stores && resp.stores.length) {
//                 resp.stores.forEach(single_store => {
//                     if (single_store.business_unit_ids && single_store.business_unit_ids.length == 1) {
//                         a[single_store.id] = single_store.business_unit_ids[0]
//                     }
//                 })
//                 set_selected_bu_json(a)
//             }
//         } catch (err) {
//             set_page_loader({ show: false })
//             console.log(err)
//         }
//     }

//     const switch_store = async(item) => {
//         var resp_onclick_store = await general_fetch({url:'/user/set_active_store', body:{selected_store_id:item.id}})
//         history.push('/')
//         window.location.reload()
//     }

//     const switch_store_or_bu = async (store_id) => {
//         if (store_id && selected_bu_json[store_id]) {
//             try {
//                 set_page_loader({ show: true, text: 'Switching Org' })
//                 let resp1 = await general_fetch({ url: '/user/set_active_store', body: { selected_store_id: store_id } })
//                 let resp2 = await general_fetch({ url: '/user/set_current_business_unit', body: { business_unit_id: selected_bu_json[store_id] } })
//                 set_page_loader({ show: false })
//                 history.push('/')
//                 window.location.reload()
//             } catch (err) {
//                 set_page_loader({ show: false })
//                 alert.error('Error while switching Business Unit')
//                 console.log(err)
//             }
//         } else {
//             alert.error('Please select a Business Unit')
//         }
//     }

//     const onclick_handleclose = () => {
// 		handle_close()
// 		set_all_store_options([])
// 		set_selected_bu_json({})
// 		set_all_business_units({})
// 	}

//     const update_selected_bu_json = (store_id, bu_id) => {
// 		let a = JSON.parse(JSON.stringify(selected_bu_json))
// 		a[store_id] = bu_id
// 		set_selected_bu_json(a)
// 	}

//     useEffect(() => {
//         if(open){
//             fetch_attached_stores()
//         }
//     }, [open, store_details]);

//     // useEffect(() => {
//     //     console.log("store selected_bu_json", selected_bu_json)
//     // }, [selected_bu_json]);

//     return (
//         <Modal className='modal-dialog-centered' isOpen={open} toggle={onclick_handleclose} size='lg'>
//             <IModalHeader toggle={onclick_handleclose}>
//                 Account Settings
//             </IModalHeader>
//             <IModalBody style={{height:'550px', overflow:'auto'}}>
//                 <div style={{display:'flex', flexDirection:'column'}}>
//                     <div style={{display:'flex', flexDirection:'row', margin:'12px 0px', padding:'0px 32px'}}>
//                         <span style={{display:'flex', alignItems:'center', flex:1, fontWeight:'700', fontSize:'16px'}}>User Details</span>                    </div>
//                     <div style={{display:'flex', flexDirection:'row', margin:'12px 0px', padding:'0px 32px'}}>
//                         <span style={{display:'flex', alignItems:'center', flex:1}}>Name</span>
//                         <div style={{display:'flex', alignItems:'center', flex:3, border:'1px solid rgba(197, 199, 207, 1)', padding:'6px 12px', color:'rgba(103, 104, 120, 1)'}}>{store_details ? store_details.designer_name : ':)'}</div>
//                     </div>
//                     <div style={{display:'flex', flexDirection:'row', margin:'12px 0px', padding:'0px 32px'}}>
//                         <span style={{display:'flex', alignItems:'center', flex:1}}>Email Id</span>
//                         <div style={{display:'flex', alignItems:'center', flex:3, border:'1px solid rgba(197, 199, 207, 1)', padding:'6px 12px', color:'rgba(103, 104, 120, 1)'}}>{store_details ? store_details.designer_email : ':)'}</div>
//                     </div>
//                 </div>
//                 {
//                     <div style={{display:'flex', flexDirection:'column'}}>
//                         <div style={{display:'flex', flexDirection:'row', margin:'12px 0px', padding:'0px 32px'}}>
//                             <span style={{display:'flex', alignItems:'center', flex:1, fontWeight:'700', fontSize:'16px'}}>User Organizations</span>
// 							<div style={{marginRight:'-16px'}}>
// 								<ISearch list={all_store_options} set_list={set_display_store_options} search_parameters={['name']} />
// 							</div>
//                         </div>
//                         <div style={{padding:'0px 32px'}}>
//                         {
//                             display_store_options && display_store_options.length ? display_store_options.map((item,idx) => (
//                                 <div key={idx} style={{display: 'flex', border:'1px solid rgba(197, 199, 207, 1)'}}>
//                                     <div style={{flex:2, display:'flex', flexDirection:'row', padding:'12px 32px'}}>{item.name}</div>
//                                     <div style={{flex:2, display:'flex', flexDirection:'row', padding:'12px 32px'}}>
//                                     {
// 										all_business_units && item && item.business_unit_ids && item.business_unit_ids.length > 1 ?
// 										<Input type='select' value={selected_bu_json[item.id]} onChange={(e) => update_selected_bu_json(item.id, e.target.value)} style={{height:'28px', width:'150px', fontSize:'14px', padding:'0px 4px'}}>
// 											<option value=''>Select BU</option>
// 										{
// 											all_business_units && item && item.business_unit_ids && item.business_unit_ids.length ? item.business_unit_ids.map((business_unit) => (
// 												<option value={business_unit}>{all_business_units[business_unit] ? all_business_units[business_unit].name : ''}</option>
// 											)):''
// 										}
// 										</Input>:''
// 									}
//                                     </div>
//                                     <div onClick= {!(window.Module.get_active_user().store_id == item.id && window.Module.get_active_user().business_unit_id == selected_bu_json[item.id]) ? () => switch_store_or_bu(item.id) : null} style={{flex:1, display:'flex', flexDirection:'row', padding:'12px 32px', cursor:'pointer', color: !(window.Module.get_active_user().store_id == item.id && window.Module.get_active_user().business_unit_id == selected_bu_json[item.id]) ? COLORS.blue6 : 'green'}}>{ !(window.Module.get_active_user().store_id == item.id && window.Module.get_active_user().business_unit_id == selected_bu_json[item.id]) ? "Switch here" : "Active" }</div>
//                                 </div>
//                             ))
//                             :
//                             <div>No Stores</div>
//                         }
//                         </div>
//                     </div>
//                 }
//             </IModalBody>
//         </Modal>
//     )
// }

// const New_Project_Modal = ({ org_key, open, handle_close, user, onclick_submit }) => {

//     const [project_name, set_project_name] = useState('')
//     const [client_name, set_client_name] = useState('')
//     const [order_id, set_order_id] = useState('')
//     const [new_project_id, set_new_project_id] = useState('')
//     const history = useHistory();
//     const alert = useAlert()

//     const get_default_project_name = async () => {
//         var response = await window.Promisify(window.Module.get_number_of_projects());
//         var default_project_name = "Project" + (Number(response) + 1);
//         set_project_name(default_project_name);
//     }

//     useEffect(() => {
//         if (open) {
//             get_default_project_name();
//         }
//     }, [open])

//     const onclick_close = () => {
//         handle_close();
//         set_project_name('');
//         set_order_id('');
//         set_client_name('');
//     }

//     const is_client_name_enforced = () => {
//         if (window.Module && window.Module['is_client_name_enforced']) {
//             return window.Module.is_client_name_enforced()
//         } else {
//             return false;
//         }
//     }

//     const is_project_id_enforced = () => {
//         if (window.Module && window.Module['is_project_id_enforced']) {
//             return window.Module.is_project_id_enforced()
//         } else {
//             return false;
//         }
//     }

//     const onclick_submit_half = async () => {
//         if (!client_name && is_client_name_enforced()) {
//             alert.error("Client name is required")
//         } else if (!order_id && is_project_id_enforced()) {
//             alert.error("Project ID is required")
//         } else {
//             var resp = await onclick_submit(project_name, client_name, order_id)
//             if (project_name && resp) {
//                 set_project_name('')
//                 set_client_name('')
//                 handle_close()
//                 //console.log(resp);
//                 var url = '/project/' + resp;
//                 (org_key && window.location.origin.includes("infurnia.com") ? (url = "/" + org_key + url) : null);
//                 history.push(url)
//             }
//         }
//     }

//     return (
//         // <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_close}>
//         //     <Card className="bg-secondary shadow border-0">
//         //         <Row>
//         //             <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.white}}>
//         //                 <span>New Project</span>
//         //                 <span style={{cursor:'pointer'}} onClick={onclick_close}>x</span>
//         //             </Col>
//         //         </Row>
//         //         <Row>
//         //             <Col style={{height:'220px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
//         //                 <Row>
//         //                     <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
//         //                         <span style={{flex:1, fontSize:'14px'}}>Project Name*</span>
//         //                         <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={project_name} onChange={(e) => set_project_name(e.target.value)} placeholder="Enter a name" />
//         //                     </Col>
//         //                 </Row>
//         //                 <Row>
//         //                     <Col style={{width:'100%', display:'flex', alignItems:'center' , height:'28px', marginBottom:'10px'}}>
//         //                         <span style={{flex:1, fontSize:'14px'}}>Client Name{is_client_name_enforced()?'*':''}</span>
//         //                         <Input placeholder="Enter a name" style={{flex:3 , height:"25px", border:0, borderBottom:'1px solid #ced4da'}} outlined='false' type="text" value={client_name} onChange={(e) => set_client_name(e.target.value)} />
//         //                     </Col>  
//         //                 </Row>
//         //                 <Row>
//         //                     <Col style={{width:'100%', display:'flex', alignItems:'center' , height:'28px', marginBottom:'10px'}}>
//         //                         <span style={{flex:1, fontSize:'14px'}}>Project ID{is_project_id_enforced()?'*':''}</span>
//         //                         <Input placeholder="Enter an ID" style={{flex:3 , height:"25px", border:0, borderBottom:'1px solid #ced4da'}} outlined='false' type="text" value={order_id?order_id:''} onChange={(e) => set_order_id(e.target.value)} />
//         //                     </Col>  
//         //                 </Row>
//         //                 <Row>
//         //                     <Col style={{textAlign:'center',marginTop:'30px'}}>
//         //                         <Button style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button" onClick={onclick_submit_half}>Submit</Button>
//         //                     </Col>  
//         //                 </Row>
//         //             </Col>
//         //         </Row>
//         //     </Card>
//         // </Modal>

//         <Modal className='modal-dialog-centered' isOpen={open} toggle={onclick_close} size='md'>
//             <IModalHeader /*style={{height:'60px'}}*/ toggle={onclick_close}>New Project</IModalHeader>
//             <IModalBody /*style={{fontSize:'14px'}}*/>
//                 <div className='new_project_modal_div'>
//                     <span style={{ width: '30%', display: 'flex', alignItems: 'center' }}>Project Name*</span>
//                     <Input className='common_input_styling' style={{ width: '70%' }} type='text' placeholder='Project Name' value={project_name} onChange={(e) => set_project_name(e.target.value)}></Input>
//                 </div>

//                 <div className='new_project_modal_div'>
//                     <span style={{ width: '30%', display: 'flex', alignItems: 'center' }}>Client Name{is_client_name_enforced() ? '*' : ''}</span>
//                     <Input className='common_input_styling' style={{ width: '70%' }} type='text' placeholder='Client Name' onChange={(e) => set_client_name(e.target.value)}></Input>
//                 </div>

//                 <div className='new_project_modal_div'>
//                     <span style={{ width: '30%', display: 'flex', alignItems: 'center' }}>Project ID{is_project_id_enforced() ? '*' : ''}</span>
//                     <Input className='common_input_styling' style={{ width: '70%' }} type='text' placeholder='Project ID' onChange={(e) => set_order_id(e.target.value)}></Input>
//                 </div>
//             </IModalBody>
//             <IModalFooter /*style={{justifyContent:'end', border:'0px'}}*/>
//                 <Button /*style={{backgroundColor:'rgba(0, 0, 0, 1)', color:'white', marginBottom:'48px', border:'0px', padding:'8px 24px', borderRadius:'6px', fontSize:'16px', marginRight:'32px'}}*/ className='primary_button_default' onClick={onclick_submit_half}>Create Project</Button>
//             </IModalFooter>
//         </Modal>
//     )
// }

// const Open_Project_Modal = ({ org_key, open, handle_close, user, show_alert_box, list, Filler, initialized, message }) => {

//     const [search_string, set_search_string] = useState('');
//     const [all_project_list_display, set_all_project_list_display] = useState([])
//     const [user_id, set_user_id] = useState('')

//     const onclick_handleclose = () => {
//         handle_close()
//     }

//     useEffect(() => {
//         if (open) {
//             set_search_string("")
//             set_all_project_list_display(list)

//             set_user_id(window.Module.get_active_user().designer_id);
//         }
//     }, [open, list])


//     useEffect(() => {
//         var temp_list = [...list];
//         if (search_string) {
//             temp_list = temp_list.filter((project) => project && project.name && project.name.toLowerCase().includes(search_string.toLowerCase()))
//         }
//         set_all_project_list_display(temp_list)
//     }, [search_string])

//     return (
//         <Modal className="modal-dialog-centered" size="xl" isOpen={open} toggle={onclick_handleclose}>
//             {/* <Card className="bg-secondary shadow border-0" style={{height:'500px'}}>
//                 <Row style={{backgroundColor: COLORS.modal_heading , color:COLORS.white}} >
//                     <Col style={{height:'70px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', backgroundColor: COLORS.modal_heading , color:COLORS.white, padding:'0px 30px'}}>
//                         <span style={{flex:'3', textAlign:"left"}}>Projects</span>
//                         <span style={{flex:'2', textAlign:"left"}}><Input id="open_project_modal_search_input" style={{height:'30px', width:'100%', borderRadius:0 }} placeholder='Search by Project name' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)} /></span>
//                         <span style={{flex:'1', textAlign:"right"}}><span style={{cursor:'pointer'}} onClick={onclick_handleclose}>x</span></span>
//                     </Col>
//                 </Row>
//                 <Row><Col style={{backgroundColor: COLORS.white, height:'430px', overflow:'auto' , padding:'24px'}}>
//                     {
//                         all_project_list_display && all_project_list_display.length ? (<Project_Table org_key={org_key} user_id={user_id} show_alert_box={show_alert_box} all_project_list_display={all_project_list_display} />) : (<Filler initialized={initialized} message={message}/>)
//                     }
//                 </Col></Row>
//             </Card> */}
//             <div style={{ backgroundColor: 'black', color: 'white', height: '60px', display: 'flex', alignItems: 'center' }}>
//                 <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//                     <span style={{ display: 'flex', flex: 1, paddingLeft: '16px', alignItems: 'center' }}><h5 style={{ margin: '4px' }}>Projects</h5></span>
//                     <span style={{ display: 'flex', flex: 2, textAlign: "left", alignItems: 'center' }}><Input id="open_project_modal_search_input" style={{ height: '30px', borderRadius: '2px', fontSize: '12px' }} placeholder='Search by Project name' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)} /></span>
//                     <span style={{ flex: 1, textAlign: "right", paddingRight: '16px', fontSize: '24px', fontWeight: '700' }}><span style={{ cursor: 'pointer' }} onClick={onclick_handleclose}>×</span></span>
//                 </div>
//             </div>
//             <ModalBody>
//                 {/* <div style={{display:'flex'}}>
//                     <span style={{flex:'3', textAlign:"left"}}>Projects</span>
//                     <span style={{flex:'2', textAlign:"left"}}><Input id="open_project_modal_search_input" style={{height:'30px', width:'100%', borderRadius:0 }} placeholder='Search by Project name' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)} /></span>
//                     <span style={{flex:'1', textAlign:"right"}}><span style={{cursor:'pointer'}} onClick={onclick_handleclose}>x</span></span>
//                 </div> */}
//                 <div style={{ height: '500px', overflow: 'auto' }}>
//                     {all_project_list_display && all_project_list_display.length ? (<Project_Table org_key={org_key} user_id={user_id} show_alert_box={show_alert_box} all_project_list_display={all_project_list_display} />) : (<Filler initialized={initialized} message={message} />)}
//                 </div>
//             </ModalBody>
//         </Modal>
//     )
// }

const Edit_Project_Modal = ({ open, project_name, client_name, client_details, update_project, project_status_id, handle_close, active_project, set_page_loader, order_id }) => {

    const [ status_selected, set_status_selected ]=useState('null')
    const [ updated_project_name, set_updated_project_name ]=useState('')
    const [ updated_order_id, set_updated_order_id ]=useState('')
    const [ updated_client_name, set_updated_client_name ]=useState('')
    const [updated_client_details, set_updated_client_details] = useState('');
    const [ status_options, set_status_options ]=useState([])
    const alert = useAlert();
    const [project_id_overridden, set_project_id_overridden] = useState("Project ID");
    const [client_name_overridden, set_client_name_overridden] = useState("Client Name");
    const [client_details_overridden, set_client_details_overridden] = useState("Client Details");

    const get_project_fields = async () => {
        try{
            let project_fields = await get_project_overridden_fields();
            set_project_id_overridden(project_fields.project_ID_overridden);
            set_client_name_overridden(project_fields.client_name_overridden);
            set_client_details_overridden(project_fields.client_details_overridden);
        }catch(err) {
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const on_click_close = () => {
        set_updated_project_name('')
        set_updated_client_name('')
        set_updated_client_details('')
        set_updated_order_id('')
        set_status_selected('null');
        set_project_id_overridden("Project ID");
        set_client_name_overridden("Client Name");
        set_client_details_overridden("Client Details");
        handle_close()
    }
    const validate_project_name = (project_name) => {
        var re = /^[a-zA-Z0-9 ]+$/
        return project_name.trim() && re.test(project_name)
    }

    const submit_values = () => {
        if(validate_project_name(updated_project_name)){
            update_project(active_project,updated_project_name,updated_client_name, updated_client_details, status_selected, updated_order_id)
            // handle_close()
            on_click_close()
        } else {
            alert.error('Special Characters Not permitted. Please modify the Project Name')
        }
    }

    useEffect(() => {
        if (open) {
            if (project_name) {
                set_updated_project_name(project_name);
            }
            if (client_name) {
                set_updated_client_name(client_name);
            }
            if(client_details){
                set_updated_client_details(client_details);
            }
            if(order_id){
                set_updated_order_id(order_id);
            }
            if (project_status_id) {
                set_status_selected(project_status_id)
            }
            get_project_fields();
            window.Promisify(window.Module.get_store_status())
                .then(resp => {
                    // console.log("resp proj d", resp )
                    set_status_options(JSON.parse(resp));
                    // if(!project_status_id){
                    //     set_status_selected(JSON.parse(resp)[0].id);
                    // }
                })
        }
    },[open, project_name, client_name, client_details, project_status_id, order_id ])

    return (
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={on_click_close}>
            {/* <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.black , color:COLORS.white}}>
                        <span>Edit Project</span>
                        <span style={{cursor:'pointer', fontSize:'24px', fontWeight:'700'}} onClick={on_click_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'250px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Project Name*</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_project_name} onChange={(e) => set_updated_project_name(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Client Name</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_client_name} onChange={(e) => set_updated_client_name(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Project ID</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_order_id} onChange={(e) => set_updated_order_id(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center' , height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Project Status</span>
                                <Input style={{flex:3 , height:"30px", border:0, borderBottom:'1px solid #ced4da', fontSize:'12px'}} outlined='false' type="select" value={status_selected} onChange={(e) => set_status_selected(e.target.value)}>
                                    {status_options.map((itm,idx) => (
                                        <option key={idx} value={itm.id}>{itm.status}</option>
                                    ))}
                                </Input>
                            </Col>  
                        </Row>
                        <Row>
                            <Col style={{textAlign:'center',marginTop:'30px'}}>
                                <Button onClick={submit_values} style={{backgroundColor:COLORS.black, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button" >Update</Button>
                            </Col>  
                        </Row>
                    </Col>
                </Row>
            </Card> */}
            <IModalHeader toggle={on_click_close}>
                Edit Project
            </IModalHeader>
            <IModalBody>
                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>Project Name*</span>
                    <Input style={{ width: '280px' }} type="text" value={updated_project_name} onChange={(e) => set_updated_project_name(e.target.value)} />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>{client_name_overridden}</span>
                    <Input style={{ width: '280px' }} type="text" value={updated_client_name} onChange={(e) => set_updated_client_name(e.target.value)} />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>{client_details_overridden}</span>
                    <Input style={{width:'280px'}} type="text" value={updated_client_details} onChange={(e) => set_updated_client_details(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{width:'120px', fontSize:'14px', display:'flex', alignItems:'center'}}>{project_id_overridden}</span>
                    <Input style={{width:'280px'}} type="text" value={updated_order_id} onChange={(e) => set_updated_order_id(e.target.value) } />
                </div>

                <div className='edit_project_modal_div'>
                    <span style={{ width: '120px', fontSize: '14px', display: 'flex', alignItems: 'center' }}>Project Status</span>
                    <Input style={{ width: '280px' }} type="select" value={status_selected} onChange={(e) => set_status_selected(e.target.value)}>
                        <option value='null'>None</option>
                        {status_options.map((itm, idx) => (
                            <option key={idx} value={itm.id}>{itm.status}</option>
                        ))}
                    </Input>
                </div>
            </IModalBody>

            <IModalFooter style={{}}>
                <Button style={{ marginRight: '32px' }} className='primary_button_default' onClick={submit_values}>Update</Button>
            </IModalFooter>
        </Modal>

    )
}


const Project_Table = ({ org_key, show_alert_box, all_project_list_display, user_id, project_status, update_project, Filler }) => {
    const history = useHistory();
    const [is_project_settings_open, set_is_project_settings_open] = useState(false);
    const [is_edit_modal_open, set_is_edit_modal_open] = useState(false);
    const [active_list, set_active_list] = useState({});
    const {t} = useTranslation();
    const [project_id_overridden, set_project_id_overridden] = useState("Project ID");
    const [client_name_overridden, set_client_name_overridden] = useState("Client Name");
    const [client_details_overridden, set_client_details_overridden] = useState("Client Details");

    const get_project_fields = async () => {
        try{
            let project_fields = await get_project_overridden_fields();
            set_project_id_overridden(project_fields.project_ID_overridden);
            set_client_name_overridden(project_fields.client_name_overridden);
            set_client_details_overridden(project_fields.client_details_overridden);
        }catch(err) {
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const view_designs_onclick = (id) => {
        var url = '/project/' + id;
        (org_key && window.location.origin.includes("infurnia.com") ? (url = "/" + org_key + url) : null);
        history.push(url)
    }

    const handle_close = () => {
        set_project_id_overridden("Project ID");
        set_client_name_overridden("Client Name");
        set_client_details_overridden("Client Details");
        set_is_edit_modal_open(false)
    }

    // const Edit_Project_From_List = (open, project_name, client_name, update_project, project_status_id, handle_close, active_project_id, order_id) => {
    //     return(
    //         <Edit_Project_Modal open={open} project_name={project_name} client_name={client_name} project_status_id={project_status_id} handle_close={handle_close} update_project={update_project} active_project={active_project_id} order_id={order_id} ></Edit_Project_Modal>
    //     )
    // }

    useEffect(() => {
        get_project_fields();
        console.log('edit', is_edit_modal_open)
    }, [is_edit_modal_open]);

    return (
        <React.Fragment>
            <Edit_Project_Modal open={is_edit_modal_open} project_name={active_list.name} client_name={active_list.client_name} client_details={active_list.client_details} update_project={update_project} project_status_id={active_list.project_status_id} handle_close={handle_close} active_project={active_list.id} order_id={active_list.order_id}></Edit_Project_Modal>
        <table id='project_table' style={{height:'100%', width:'100%', fontSize:'13px', position:'relative'}}>
            <thead style={{position:'sticky', top:'0px', backgroundColor: 'white'}}>
                <tr style={{display:'flex', height:'60px', alignItems:'center', textAlign:'left', border:'1px solid #E6E9EE'}}>
                    {/* <th style={{flex:1}}></th> */}
                    <th style={{flex:10, borderRight:'1px solid rgba(230, 233, 238, 1)', padding:'6px 12px'}}><span><b>Project Name</b></span><br /></th>
                    <th style={{flex:7, borderRight:'1px solid rgba(230, 233, 238, 1)', padding:'6px 12px'}}><span><b>{client_name_overridden}</b></span> <br /></th>
                    <th style={{flex:5, borderRight:'1px solid rgba(230, 233, 238, 1)', padding:'6px 12px'}}><span><b>{project_id_overridden}</b></span> <br /></th>
                    <th style={{flex:5, borderRight:'1px solid rgba(230, 233, 238, 1)', padding:'6px 12px'}}><span><b>Status</b></span> <br /></th>
                    <th style={{flex:5, padding:'6px 12px'}}><span><b>Last Update</b></span> <br /></th>
                    <th style={{flex:5, padding:'6px 12px', minWidth: '120px'}}><span></span><br /></th>
                    <th style={{flex:2, padding:'6px 12px', minWidth: '60px'}}><span></span><br /></th>
                    {/* <th style={{flex:1}}></th> */}
                </tr>
            </thead>
            <tbody>
                {
                    all_project_list_display && all_project_list_display.length ? all_project_list_display.map((list_item, index) => (
                        <tr key={index} className='table_custom_strip show_on_hover' style={{display:'flex', height:'50px', alignItems:'center', textAlign:'left', borderBottom: index===all_project_list_display.length-1 ? '1px solid #E6E9EE' : 'none'}}>
                            {/* <td style={{flex:1}}></td> */}
                            <td title={list_item.name?list_item.name:"-"} className='lines1_elipsis' style={{flex:10}}>{list_item.name?list_item.name:"-"}</td>
                            <td title={list_item.client_name?list_item.client_name:"-"} className='lines1_elipsis' style={{flex:7}}>{list_item.client_name?list_item.client_name:"-"}</td>
                            <td title={list_item.order_id?list_item.order_id:"-"} className='lines1_elipsis' style={{flex:5}}>{list_item.order_id?list_item.order_id:"-"}</td>
                            <td style={{flex:5}}>{list_item.project_status_id && list_item.project_status_id != 'null' ? project_status ? project_status.find(x => x.id == list_item.project_status_id) ? project_status.find(x => x.id == list_item.project_status_id).status: list_item.project_status_id : list_item.project_status_id :"-"}</td>
                            <td style={{flex:5}}>{list_item.last_activity_at ? toReadableDate(list_item.last_activity_at):"-"}</td>
                            {/* <td style={{flex:5}}><Moment format="DD/MM/YYYY">{list_item.last_activity_at ? list_item.last_activity_at:"-"}</Moment></td> */}
                            <td style={{flex:5, minWidth: '120px'}} ><div onClick={() => {view_designs_onclick(list_item.id)}} className="hide" style={{cursor:'pointer', color:'rgba(0, 120, 255, 1)'}}>{t(`View Designs`)}</div></td>
                            {user_id==list_item.user_id?(<td style={{flex:2, minWidth: '60px'}} >
                                <Dropdown isOpen={is_project_settings_open === list_item.id} toggle={() => {if(is_project_settings_open === list_item.id){set_is_project_settings_open(false)}else{set_is_project_settings_open(list_item.id)}}}>
                                    <DropdownToggle className='hide' style={{borderRadius:'2px', backgroundColor:'white', color:'#22272E', alignItems:'center', width:'32px', height:'32px', textAlign:'center', padding:'0px'}}><i style={{fontSize:'18px', padding: '2px'}} className='fa fa-cog'></i> </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem onClick={() => {
                                            set_is_edit_modal_open(!is_edit_modal_open)
                                            set_active_list(list_item)
                                        }}>Edit Project</DropdownItem>
                                        <DropdownItem onClick={() => show_alert_box(list_item.id)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {/* <i style={{fontSize:'18px', color:'red', cursor:'pointer'}} onClick={() => show_alert_box(list_item.id)} className='fa fa-trash hide'/> */}
                                </td>)
                                    : (<td style={{ flex: 2, minWidth: '60px' }} ></td>)}
                                {/* <td style={{flex:1}}></td> */}
                            </tr>
                        )) :
                            <div style={{ padding: '6px 12px', height: '40px', color: 'black', backgroundColor: 'white' }}>{t(`No Projects to list. Try changing Search parameter or create New Project.`)}</div>
                    }
                </tbody>
            </table>
        </React.Fragment>
    )
}

const RecentProjectCard = ({ project, project_to_design_map = {} }) => {

    const history = useHistory()

    return (
        // <div /*className='inf-flex-grid-item inf-px-5 inf-mb-9'*/>
        <div style={{ cursor: 'pointer', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)', borderRadius: '4px', overflow: 'hidden' }} /*onClick={pre_image_onclick_fn}*/ onClick={() => { changeProject(project.id, history) }} /*className='scale_105'*/>
            <div style={{ position: 'relative' }} className='show_on_hover'>
                {/* <img style={{ width: '100%', objectFit: 'cover' }} src={get_image_src()} /> */}
                <ImageCarousel images={project_to_design_map[project.id] ? project_to_design_map[project.id].map(x => get_image_src(x.display_pic.image)) : []} />
                {/* <div className='hide' style={{...styles.text_elipsis, color:COLORS.black, fontWeight:500, padding:'6px 12px 0px 12px', backgroundColor:'transparent', fontSize:'12px', position:'absolute', bottom:'6px', fontStyle:'italic', textAlign:'center', width: '100%' }} title={''}>{'needs work'}</div> */}
            </div>
            <div className='inf-p-3' style={{ background: '#676878' }}>
                <div className='inf-font-medium lines1_elipsis inf-mb-1' style={{ color: 'whire' }} title={project && project.name ? project.name : ''}>{project && project.name ? project.name : ''}</div>
                <div className='lines1_elipsis inf-text-xs' style={{ color: 'var(--inf-theme-gray-400)' }} title={project && project.client_name ? project.client_name : '-'}>{project && project.client_name ? project.client_name : '-'}</div>
            </div>
        </div>
        // </div>
    )
}

const ProjectsGrid = ({ list_display }) => {
    return (
        <div className='inf-p-2' /*className='inf-py-3 inf-flex-grid inf-wrap-5-lg inf-wrap-2-md inf-gap-0'*/ style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))`, }}>
            {
                list_display && list_display.length ?
                    list_display.map((list_item, idx) => (
                        <RecentProjectCard project={list_item} />
                    ))
                    :
                    // <Filler initialized={initialized} message='Please create new project and create design.' />
                    ''
            }
        </div>
    )
}

const RecentProjects = ({ list_display }) => {
    const {t} = useTranslation()
    return (
        <div className='inf-px-9'>
            {/* // <div className='inf-flex inf-flex-col inf-px-5 inf-text-left' style={{ width: '100%' }}> */}
            <div className='inf-py-3'>
                <div className='inf-text-base inf-font-semibold'><div className='inf-flex inf-items-center'> <img className='inf-mr-2' src='/resources/MainPageIcons/folder-outline-dark.svg'></img>{t(`Recent Projects`)}</div></div>
            </div>
            <div style={{ flex: 1 }}>
                <ProjectsGrid list_display={list_display} />
            </div>
            {/* // </div> */}
        </div>
    )
}

const MainPage = ({ org_key, user, set_page_loader, Filler, store_details, submit_for_delete_confirm, init, init_needed, set_init_needed, logout }) => {


    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const [viewParam, setViewParam] = useState(searchParams.get('view') || 'recent');
    const [projectParam, setProjectParam] = useState('');

    const [show_new_project_modal, set_show_new_project_modal] = useState(false)
    const [show_open_project_modal, set_show_open_project_modal] = useState(false)
    const [show_account_settings, set_show_account_settings] = useState(false);
    const [show_current_subscription, set_show_current_subscription] = useState(false);
    const [is_profile_dropdown_open, set_is_profile_dropdown_open] = useState(false);
    const [recent_designs, set_recent_designs] = useState([])
    const [all_designs, set_all_designs] = useState([]);
    const [recent_card_div_height, set_recent_card_div_height] = useState('')
    const [projects_view, set_projects_view] = useState(false);
    const [list_display, set_list_display] = useState([]);
    const [user_id, set_user_id] = useState('')
    const [project_status, set_project_status] = useState([]);
    const [project_to_design_map, set_project_to_design_map] = useState({});
    const [is_grid_view, set_is_grid_view] = useState(false);
    const [is_create_project_enabled, set_is_create_project_enabled] = useState(false);
    // const [all_store_options, set_all_store_options] = useState([]);
    // const [recent_project_designs, set_recent_project_designs] = useState([]);

    const [delete_indicator, set_delete_indicator] = useState(false)
    const [list, set_list] = useState([])
    const history = useHistory();
    const alert = useAlert()

    const [initialized, set_initialized] = useState(false);
    const {t} = useTranslation()


    // const [ show_edit_design_modal, set_show_edit_design_modal] = useState(false);
    // const [ show_copy_design_modal, set_show_copy_design_modal] = useState(false);
    // const [ show_addn_prop_modal, set_show_addn_prop_modal] = useState(false);
    // const [ show_share_design, set_show_share_design] = useState(false);
    // const [ active_item, set_active_item] = useState('');
    // const [is_lock_warning_open, set_is_lock_warning_open] = useState(false);

    // const onclick_open_modal = (active_item, modal_type) => {
    // 	set_active_item(active_item?active_item:'')
    // 	if(modal_type==='edit_design'){
    // 		set_show_edit_design_modal(true)
    // 	}
    // 	if(modal_type==='copy_design'){
    // 		set_show_copy_design_modal(true)
    // 	}
    // 	if(modal_type==='addn_prop'){
    // 		set_show_addn_prop_modal(true)
    // 	}
    // 	if(modal_type==='lock_design'){
    // 		set_is_lock_warning_open(true)
    // 	}
    // 	if(modal_type==='share_design'){
    // 		set_show_share_design(true)
    // 	}
    // } 

    // const onclick_close_modal = (modal_type) => {
    // 	set_active_item('')
    // 	if(modal_type==='edit_design'){
    // 		set_show_edit_design_modal(false)
    // 	}
    // 	if(modal_type==='copy_design'){
    // 		set_show_copy_design_modal(false)
    // 	}
    // 	if(modal_type==='addn_prop'){
    // 		set_show_addn_prop_modal(false)
    // 	}
    // 	if(modal_type==='share_design'){
    // 		set_show_share_design(false)
    // 	}
    // }



    // const lock_design = async (item) => {
    // 	set_active_item(active_item?active_item:'')
    // 	try{
    // 		console.log(item)
    // 		var resp = await window.Promisify(window.Module.lock_design(item.id));
    // 		alert.success("Design locked successfully");
    // 		// fetch_project_all_designs();
    // 		onclick_close_lock_warning_modal()
    // 	}catch(err){
    // 		alert.error("Unable to lock design, Please try again later");
    // 		console.error(err);
    // 	}
    // }

    // const onclick_close_lock_warning_modal = () => {
    // 	set_active_item('')
    // 	set_is_lock_warning_open(false)
    // }

    // const Lock_Warning_Modal = () => {
    // 	return(
    // 		<Modal className='modal-dialog-centered' isOpen={is_lock_warning_open} toggle={onclick_close_lock_warning_modal} size='md'>
    // 			{/* <ModalHeader style={{ backgroundColor:'rgba(34, 61, 95, 1)', color:'white'}} toggle={onclick_close}>New Project</ModalHeader> */}
    // 			<IModalBody style={{fontSize:'14px', backgroundColor: 'white', padding: '36px 16px', borderRadius:'4px'}}>
    // 				<div style={{textAlign: 'center', marginBottom: '24px'}}>
    // 					Once the design is locked, you will not be able to edit it until an admin unlocks it. Are you sure you want to lock the design?
    // 				</div>
    // 				<div className='flex_center'> 
    // 					<Button onClick={() => lock_design(active_item)} className='primary_button_default mr-2'>Submit</Button>
    // 					<Button onClick={onclick_close_lock_warning_modal} className='secondary_button_default'>Cancel</Button>
    // 				</div>
    // 			</IModalBody>
    // 		</Modal>
    // 	)
    // }

    // const request_unlock_design = async (item) => {
    // 	set_active_item(active_item?active_item:'')
    // 	try{
    // 		var resp = await window.Promisify(window.Module.request_unlock_design(item.id));
    // 		alert.success("Design unlock request sent successfully");
    // 	}catch(err){
    // 		alert.error("Unable to request for unlock, Please try again later");
    // 		console.error(err);
    // 	}
    // }

    // const mark_branch_as_template = async (item) => {
    // 	try{
    // 		var resp = await window.Promisify(window.Module.mark_branch_as_template(item.current_design_branch_id));
    // 		console.log(resp)
    // 		if(resp == "UNAUTHORIZED_ACCESS"){
    // 			alert.error("Insufficient permissions to mark as a template. Project manager role is required")
    // 		}else{
    // 		alert.success("Design is now a template design");
    // 		// fetch_project_all_designs();
    // 		}
    // 	}catch(err){
    // 		alert.error("Unable to mark design as a template. Please try again later");
    // 		console.error(err);
    // 	}
    // }

    // const unmark_branch_as_template = async (item) => {
    // 	try{
    // 		var resp = await window.Promisify(window.Module.unmark_branch_as_template(item.current_design_branch_id));
    // 		console.log(resp)
    // 		if(resp == "UNAUTHORIZED_ACCESS"){
    // 			alert.error("Insufficient permissions to unmark as a template. Project manager role is required")
    // 		}else{
    // 		alert.success("Design is no longer a template design");
    // 		// fetch_project_all_designs();
    // 		}
    // 	}catch(err){
    // 		alert.error("Unable to mark design as not a template. Please try again later");
    // 		console.error(err);
    // 	}
    // }



    // const copy_design = (branch_id, project_id, name) => {
    //     set_page_loader({
    // 		show: true,
    // 		text: 'Copying your design...'
    // 	});

    // 	setTimeout(async() => {
    // 		var response = await window.Promisify(window.Module.clone_design(branch_id, project_id, name))
    //         var clone_design_interval = setInterval(async () => {
    //             var resp = await window.Promisify(window.Module.get_clone_design_status());
    //             var resp_json = JSON.parse(resp);
    //             console.log(resp_json["status"] + " status")
    //             if(resp_json["status"] == "execution_done"){
    //                 clearInterval(clone_design_interval);
    //                 if(resp_json["response_code"]=="out_of_license"){
    //                     alert.error("Out of license to create new design")
    //                 }else if(resp_json["response_code"]=="couldn't_create_design"){
    //                     alert.error("Couldn't copy design");
    //                 }else{
    //                     // initiate_component();
    //                     alert.success("Design copied successfully");
    //                 }
    //                 set_page_loader({
    //                     show: false,
    //                     text: 'Please wait'
    //                 });	
    //             }
    //         },100)

    // 	},0)
    // }

    // const rename_design = (design_id, name) => {
    //     set_page_loader({
    // 		show: true,
    // 		text: 'Renaming design...'
    // 	});

    // 	setTimeout(async() => {  
    // 		var response = await window.Promisify(window.Module.rename_design(design_id, name))
    //         // initiate_component();

    //         alert.success("Design renamed successfully");

    // 		set_page_loader({
    // 			show: false,
    // 			text: 'Please wait'
    // 		});	
    // 	},0)
    // }

    // const update_design_additional_properties = (design_id, additional_properties) => {
    //     set_page_loader({
    // 		show: true,
    // 		text: 'Updating design additional properties...'
    // 	});

    // 	setTimeout(async() => {  
    // 		var response = await window.Promisify(window.Module.update_design_additional_properties(design_id, additional_properties))
    //         // initiate_component();
    //         alert.success('Design additional properties updated')

    // 		set_page_loader({
    // 			show: false,
    // 			text: 'Please wait'
    // 		});	
    // 	},0)
    // }



    // const delete_design = (design_id) => {
    //     set_page_loader({
    //         show: true,
    //         text: 'Deleting design...'
    //     });

    // 	setTimeout(async() => {  
    //         try{
    //             var response = await window.Promisify(window.Module.delete_design(design_id))
    //             response = JSON.parse(response)
    //             if(response && response.action == 'TOAST_ERROR'){
    //                 alert.error(response.message)
    //             }else{
    //                 alert.success('Design Deleted Successfully')
    //             }

    //             // initiate_component();
    //         }catch(error){
    //             alert.error("Unable to Delete Design. Please try later.")
    //             console.error("Unable to Delete Design. Please try later.", error)
    //         }
    //         set_page_loader({
    //             show: false,
    //             text: 'Please wait'
    //         });
    //     }, 0)
    // }


    // ---------------------------------------------------------

    const fetch_projectDetails_core = async () => {
        try{
        var response = await window.Promisify(window.Module.getProjects())
        set_initialized(true)
        var response_obj = response && JSON.parse(response)
        if (response_obj) {
            set_list(response_obj)
        }
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }
    }

    const fetchProjectAndDesignDetailsCore = async () => {
        try{
        var response = await window.Promisify(window.Module.getProjectsAndDesigns())
        set_initialized(true)
        var response_obj = response && JSON.parse(response)
        console.log('response projects and designs', response_obj)
        if (response_obj && response_obj.projects) {
            let project_ids = Object.keys(response_obj.projects)
            set_list(project_ids.map(id => response_obj["projects"][id]))
        }
        if (response_obj && response_obj.designs) {
            let temp_map = {}
            let design_ids = Object.keys(response_obj.designs)
            design_ids.map(id => {
                if (!temp_map[response_obj["designs"][id].project_id]) {
                    temp_map[response_obj["designs"][id].project_id] = []
                }
                temp_map[response_obj["designs"][id].project_id].push(response_obj["designs"][id])
            })
            set_project_to_design_map(temp_map)
        }
        }catch(err){
            if(window['sentry_capture_exception']){
                window['sentry_capture_exception']("",err);
            }
        }

    }

    const fetch_projectDetails = async () => {
        return new Promise((resolve, reject) => {
            set_page_loader({
                show: true,
                text: 'Fetching projects...'
            });

            setTimeout(async () => {
                await fetch_projectDetails_core();

                set_page_loader({
                    show: false,
                    text: 'Please wait'
                });
                resolve();
            }, 0)
        }).catch(err => {
            //console.error(err);
            return Promise.reject(err);
        })
    }

    const fetchProjectAndDesignDetails = async () => {
        return new Promise((resolve, reject) => {
            set_page_loader({
                show: true,
                text: 'Fetching projects...'
            });

            setTimeout(async () => {
                await fetchProjectAndDesignDetailsCore();

                set_page_loader({
                    show: false,
                    text: 'Please wait'
                });
                resolve();
            }, 0)
        }).catch(err => {
            //console.error(err);
            return Promise.reject(err);
        })
    }

    const update_project = (active_project_id, project_name, client_name, client_details, project_status_id, order_id) => {
        set_page_loader({
            show: true,
            text: 'Updating project...'
        });

        setTimeout(async () => {
            console.log("proj id", active_project_id)
			var response = await window.Promisify(window.Module.update_project(active_project_id, project_name, client_name, project_status_id, order_id, client_details))
            fetch_projectDetails();

            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        }, 0)
    }

    const onclick_delete = (id) => {
        try {
            if (id) {
                set_page_loader({
                    show: true,
                    text: 'Deleting project...'
                }, async () => {
                    //await dummy_tick(0);
                    try {
                        var response = await window.Promisify(window.Module.deleteProject(id));
                        var obj_response = response && JSON.parse(response)

                        if (obj_response && obj_response.response_code == -1) {
                            if (obj_response.action == 'TOAST_ERROR') {
                                alert.error(obj_response.message)
                            } else {
                                alert.error("Could not delete the project. Something went wrong.")
                            }
                        } else {
                            set_list(obj_response)
                            alert.success('Project deleted successfully')
                        }
                    }
                    catch (ee) {
                        alert.error('Project not found')
                    }

                    // await fetch_projectDetails_core();
                    await fetch_recent_designs_core();

                    set_page_loader({
                        show: false,
                        text: 'Deleting project...'
                    })
                })
            }
        }
        catch (ee) {
            //console.log(ee);
            alert.error('Request failed')
        }
    }

    // window.onload = maxWindow;
    // const maxWindow = () => {
    //     window.moveTo(0, 0);

    //     if (document.all) {
    //         top.window.resizeTo(screen.availWidth, screen.availHeight);
    //     }

    //     else if (document.layers || document.getElementById) {
    //         if (top.window.outerHeight < screen.availHeight || top.window.outerWidth < screen.availWidth) {
    //             top.window.outerHeight = screen.availHeight;
    //             top.window.outerWidth = screen.availWidth;
    //         }
    //     }
    // }


    const show_alert_box = (id) => {
        submit_for_delete_confirm(
            () => onclick_delete(id),
            () => { },
            'This will delete the project and all its designs permanently. Are you sure you want to proceed?'
        )
    }

    const fetch_recent_designs_core = async () => {
        try{
            set_initialized(false);
            var response = await window.Promisify(window.Module.get_recent_designs());
            set_initialized(true);
            var rd = JSON.parse(response);
            if (rd && rd.length) {
                let temp_map = {}
                rd.map(x => {
                    if (!temp_map[x.project_id]) {
                        temp_map[x.project_id] = []
                    }
                    temp_map[x.project_id].push(x)
                })
                set_project_to_design_map(temp_map)
            }
            set_all_designs(rd)
            var rd1 = rd && rd.slice(0, 10);
            // console.log("recent_designs: ",rd1)
            set_recent_designs(rd1)
        }catch(err){
            console.log(err);
            window.sentry_capture_exception("",err);
        }
    }

    const fetch_recent_designs = async () => {
        set_page_loader({
            show: true,
            text: 'Fetching recent designs...'
        })

        setTimeout(async () => {
            await fetch_recent_designs_core();
            set_page_loader({
                show: false,
                text: 'Fetching recent designs...'
            })
        }, 0)
    }

    const close_new_project_modal = () => {
        set_show_new_project_modal(false)
    }

    const open_new_project_modal = () => {
        set_show_new_project_modal(true)
    }

    const close_open_project_modal = () => {
        set_show_open_project_modal(false)
        set_list([]);
    }

    const open_open_project_modal = () => {
        fetch_projectDetails()
            .then(() => {
                set_show_open_project_modal(true);
            })
    }

    const close_account_settings = () => {
        set_show_account_settings(false)
    }

    const image_onclick_fn = (current_design_branch_id) => {
        set_page_loader({
            show: true,
            text: 'Loading your design'
        })
        setTimeout(async () => {
            //await dummy_tick(0);
            if (current_design_branch_id) {
                var url = '/design/' + current_design_branch_id;
                (org_key && window.location.origin.includes("infurnia.com") ? (url = "/" + org_key + url) : null);
                history.push(url)
            }
        }, 0)
    }

    const view_designs_onclick = (id) => {
        var url = '/project/' + id;
        (org_key && window.location.origin.includes("infurnia.com") ? (url = "/" + org_key + url) : null);
        history.push(url)
    }


    // const designs_in_project = async (id) => {
    //     let resp
    //     console.log("designs_in_project", id)


    //         console.log("designs_in_project json out")
    //         resp = await window.Promisify(window.Module.load_designs("733959ccb75945ad"))
    //         console.log("designs_in_project json", JSON.parse(resp))
    //         // set_recent_project_designs(JSON.parse(resp))
    //     return JSON.parse(resp)
    // } 


    useEffect(async () => {
        if (init_needed) {
            set_init_needed(false);
            var init_resp = await init()
            if (init_resp) {
                return;
            }
        }

        if (!window.location.origin.includes("infurnia.com")) {
            document.title = window.location.origin.split('.')[1];
        } else {
            document.title = "Infurnia"
        }
        fetch_recent_designs();
        fetch_projectDetails();
        // fetchProjectAndDesignDetails();
        var element = document.getElementById('recent_card_div');
        var height = element && element.offsetHeight;
        height && set_recent_card_div_height(Math.floor(height / 2));
        // var resp_store_details = await general_fetch({url:'/user/get_attached_stores'})
        // if(resp_store_details && resp_store_details.length>0){
        //     set_all_store_options(resp_store_details)

        window.redirect_target = 'home'
        // }
        set_is_create_project_enabled(window.Module.is_feature_enabled('project_creation'))
    }, [])

    // useEffect(() => {
    //     console.log("account modal", show_account_settings)
    // }, [show_account_settings]);

    useEffect(() => {
        set_user_id(window.Module.get_active_user().designer_id);
        window.Promisify(window.Module.get_store_status())
            .then(resp => {
                set_project_status(JSON.parse(resp));
            })
        // console.log("designs_in_project outside if", list)

        // if(list.length){
        // console.log("designs_in_project inside if")
        //     designs_in_project(list[0].id)
        // }
    }, [list]);



    useEffect(() => {
        // Update the state when the query parameter changes
        setViewParam(searchParams.get('view') || 'recent');
        setProjectParam(searchParams.get('project') || '');
    }, [location.search]);

    useEffect(() => {
        console.log('project_to_design_map', project_to_design_map)
    }, [project_to_design_map]);

    const RecentDesignCard = ({ design }) => {
        return (
            // <div /*className='inf-flex-grid-item inf-px-5 inf-mb-9'*/>
            <div style={{ cursor: 'pointer', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)', borderRadius: '4px', overflow: 'hidden', border: '1px solid var(--light-border-color)' }} onClick={() => { image_onclick_fn(design.current_design_branch_id) }} className='scale_105'>
                <div style={{ position: 'relative', aspectRatio: '16/9' }} className='show_on_hover'>
                    <img style={{ width: '100%', objectFit: 'cover', borderBottom: '1px solid var(--light-border-color)' }} src={get_image_src(design.display_pic.image)} />
                    {
                        // <div className='hide' style={{ ...styles.text_elipsis, color: COLORS.black, fontWeight: 500, padding: '6px 12px 0px 12px', backgroundColor: 'transparent', fontSize: '12px', position: 'absolute', bottom: '6px', fontStyle: 'italic', textAlign: 'center', width: '100%' }} title={''}>{'needs work'}</div>
                    }
                </div>
                <div className='inf-px-3 inf-py-2' style={{ background: 'white' }}>
                    <div className='inf-font-medium lines1_elipsis inf-mb-1' title={design && design.name ? design.name : ''}>{design && design.name ? design.name : ''}</div>
                    <div className='lines1_elipsis inf-text-xs' style={{ color: 'var(--inf-theme-gray-500)' }} title={design.Project && design.Project.name ? design.Project.name : ''}>{design.Project && design.Project.name ? design.Project.name : ''}</div>
                </div>
            </div>
            // </div>
        )
    }

    const RecentDesigns = (
        <div className='inf-px-9'>
            {/* <div className='inf-flex inf-flex-col inf-px-5 inf-text-left' style={{ width: '100%' }}> */}
            <div className='inf-py-3'>
                <div className='inf-text-base inf-font-semibold'><div className='inf-flex inf-items-center'> <img className='inf-mr-2' src='/resources/MainPageIcons/file-outline.svg'></img>{t(`Recent Designs`)}</div></div>
            </div>
            <div style={{ flex: 1 }}>
                <div /*className='inf-py-3 inf-flex-grid inf-wrap-5-lg inf-wrap-2-md inf-gap-0'*/ style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))` }}>
                    {
                        initialized && recent_designs && recent_designs.length ?
                            // <Card_Layout recent_card_div_height={recent_card_div_height} list={recent_designs} image_onclick_fn={image_onclick_fn} no_of_cards={3} page_name='main' set_page_loader={set_page_loader} />
                            recent_designs.map((list_item, idx) => (
                                <RecentDesignCard key={list_item.current_design_branch_id} design={list_item} />
                            ))
                            // <GridLayout items={recent_designs} gap={16} />
                            :
                            <Filler initialized={initialized} message='Please create new project and create design.' />
                    }
                </div>
            </div>
            {/* </div> */}
        </div>
    )

    // const DesignsNav = ({project_details}) => {
    //     const [sort_value, set_sort_value] = useState(0);
    //     return (
    //     <div style={{ width: '100%', height: '48px', backgroundColor: COLORS.theme_color, padding: 0 }}>
    //         <div>
    //             {/* <i onClick={close_project_onclick} style={{...styles.nav_bar_icons_light,cursor:'pointer',fontSize:'24px', margin:'0px 8px', }} data-toggle="tooltip" data-placement="bottom" title="Home Page" className="fa fa-nav fa-chevron-left hover_blue"></i> 
    //                     <div className='vl'></div> */}
    //             {/* <a style={{cursor:'pointer'}}><i style={{...styles.nav_bar_icons_light, fontSize:'24px' , marginLeft:'8px' , marginRight:'8px'}} onClick={() => user_id!=project_designer_id?'':open_edit_project_modal()} data-toggle="tooltip" data-placement="bottom" title={user_id!=project_designer_id?"Only the original author can modify the project":"Modify Project"} className={"fa fa-nav fa-pencil hover_blue" + (user_id!=project_designer_id?" disabled_fa_icon":"")}></i></a> */}
    //             {/* <Dropdown style={{height:'32px', alignSelf:'center'}} isOpen={is_project_navigation_open} toggle={() => set_is_project_navigation_open(!is_project_navigation_open)}>
    //                         <DropdownToggle style={{borderRadius:'5px', borderColor: 'transparent', marginRight:'16px', fontSize:'14px', fontWeight: 'bold',backgroundColor:'transparent', color:'black', alignItems:'center', display:'flex'}}>{project_name}<span style={{marginLeft:'4px', display:'flex',alignItems:'center'}}><i style={{fontSize:'14px', padding: '2px', color:'white'}} className='fa fa-caret-down'></i></span> </DropdownToggle>
    //                         <DropdownMenu container="body">
    //                             {
    //                                 project_list && project_list.length ? project_list.map(x => {
    //                                     return <DropdownItem onClick={() => view_designs_onclick(x.id)} >{x.name}</DropdownItem>
    //                                     //console.log("project", x.name)
    //                                 })
    //                                 :<DropdownItem>No Projets to navigate</DropdownItem>
    //                             }
    //                         </DropdownMenu>
    //                     </Dropdown> */}
    //             <div className='show_on_hover' style={{ borderRadius: '5px', borderColor: 'transparent', marginLeft: '24px', marginRight: '8px', fontSize: '14px', backgroundColor: 'transparent', color: 'black', alignItems: 'center', display: 'flex' }}>{project_details.project_name}
    //                 {/* <div className='bottom' id='tooltip'>
    //                     <div className='tooltip-arrow' />
    //                     <div style={{ backgroundColor: 'black', color: 'white', borderRadius: '6px' }}>
    //                         <div style={{ padding: '16px 16px 8px 16px', display: 'flex' }}>
    //                             <span style={{ flex: 2 }}>Client Name:</span> <span style={{ flex: 3 }}>{client_name ? client_name : '-'}</span>
    //                         </div>
    //                         <div style={{ padding: '8px 16px', display: 'flex' }}>
    //                             <span style={{ flex: 2 }}>Project ID:</span> <span style={{ flex: 3 }}>{order_id ? order_id : '-'}</span>
    //                         </div>
    //                         <div style={{ padding: '8px 16px 16px 16px', display: 'flex' }}>
    //                             <span style={{ flex: 2 }}>Project Status:</span> <span style={{ flex: 3 }}>{project_status_id ? status_options.find(item => item.id === project_status_id) ? status_options.find(item => item.id === project_status_id).status : project_status_id : '-'}</span>
    //                         </div>
    //                     </div>
    //                 </div> */}
    //             </div>
    //             {/* <a className="blue_hover black_text hover_toolbar" style={{ cursor: 'pointer', ...styles.nav_bar_icons_top }} onClick={() => user_id != project_designer_id ? '' : open_edit_project_modal()}><img data-toggle="tooltip" data-placement="bottom" title={user_id != project_designer_id ? "Only the original author can modify the project" : "Modify Project"} src={'/resources/nav_icons_new/edit.svg'}></img></a> */}
    //         </div>
    //         {/* <Nav className='justify-content-center'>
    //             <Search list={all_designs_copy} set_list={set_display_designs} search_value='name'></Search>
    //         </Nav> */}
    //         <div className='justify-content-end'>
    //             {/* <Search list={all_designs_copy} set_list={set_display_designs} search_value='name'></Search> */}
    //             {/* <Input id="design_search_input" style={{width:'200px', borderRadius:'2px', marginRight:'16px', fontSize:'12px' }} placeholder='Search by Design name' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input> */}
    //             <UncontrolledDropdown>
    //                 <DropdownToggle style={{ borderRadius: '2px', marginRight: '16px', fontSize: '12px', backgroundColor: 'white', color: '#22272E', alignItems: 'center', display: 'flex', width: 'auto' }}>Sort By: {sort_value} <span style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}><i style={{ fontSize: '14px', padding: '2px' }} className='fa fa-caret-down'></i></span> </DropdownToggle>
    //                 <DropdownMenu container="body">
    //                     <DropdownItem onClick={() => set_sort_value(0)}>Recent</DropdownItem>
    //                     <DropdownItem onClick={() => set_sort_value(1)}>A-Z</DropdownItem>
    //                     <DropdownItem onClick={() => set_sort_value(2)}>Z-A</DropdownItem>
    //                     <DropdownItem onClick={() => set_sort_value(3)}>Newest</DropdownItem>
    //                     <DropdownItem onClick={() => set_sort_value(4)}>Oldest</DropdownItem>
    //                 </DropdownMenu>
    //             </UncontrolledDropdown>
    //         </div>
    //     </div>
    // )
    //     }

    // const Design_Dropdown = ({ active_item, ...props}) => {
    //     const [dropdown_open, set_dropdown_open] = useState(false);
    //     const [store_type, set_store_type] = useState("");
    //     const [admin_account, set_admin_account] = useState(false);
    //     useEffect(() => {
    //         if (dropdown_open) {
    //             set_store_type(window.Module.get_active_user().store_type)
    //             set_admin_account(window.Module.get_active_user().admin_account)
    //         }
    //     }, [dropdown_open])

    //     return (
    //         <Dropdown isOpen={dropdown_open} toggle={() => set_dropdown_open(!dropdown_open)} {...props}>
    //             <DropdownToggle /*className="btn-circle mr-0"*/ color='grey' style={{ backgroundColor: 'white' }} ><span className="fa fa-bars" /></DropdownToggle>
    //             <DropdownMenu container="body">
    //                 <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => user_id == active_item.user_id ? onclick_open_modal(active_item, 'edit_design') : ''}>Edit Name</DropdownItem>
    //                 <DropdownItem onClick={() => onclick_open_modal(active_item, 'copy_design')}>Copy</DropdownItem>
    //                 <DropdownItem onClick={() => onclick_open_modal(active_item, 'share_design')}>Share</DropdownItem>
    //                 {store_type != "professional" ? (active_item && !active_item.locked ? (
    //                     <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => { user_id == active_item.user_id ? onclick_open_modal(active_item, 'lock_design') : '' }} >
    //                         {'Lock'}
    //                     </DropdownItem>
    //                 ) : (
    //                     <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => { user_id == active_item.user_id ? request_unlock_design(active_item) : '' }} >
    //                         {'Request Unlock'}
    //                     </DropdownItem>
    //                 )) : ''}
    //                 {(store_type != "professional" && active_item.current_branch_details && active_item.current_branch_details.org_template == 0) ? (<DropdownItem className={(!admin_account) ? 'disabled_dropdown_item' : ''} title={(!admin_account) ? "Only the right admin can mark the design as a template" : ""} onClick={() => (admin_account) ? mark_branch_as_template(active_item) : ''} >Mark as template</DropdownItem>) : ''}
    //                 {(store_type != "professional" && active_item.current_branch_details && active_item.current_branch_details.org_template == 1) ? (<DropdownItem className={(!admin_account) ? 'disabled_dropdown_item' : ''} title={(!admin_account) ? "Only the right admin can mark the design as a template" : ""} onClick={() => (admin_account) ? unmark_branch_as_template(active_item) : ''} >Unmark as template</DropdownItem>) : ''}
    //                 <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => user_id == active_item.user_id ? onclick_open_modal(active_item, 'addn_prop') : ''} >Additional Properties</DropdownItem>
    //                 {(active_item && !active_item.locked) ? (
    //                     ''
    //                     // <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => user_id == active_item.user_id ? (submit_for_delete_confirm(() => delete_design(active_item.id), () => { }, 'This will delete the design permanently. Are you sure?')) : ''} ><span style={{ color: 'red' }}>Delete</span></DropdownItem>
    //                 ) : ''}
    //             </DropdownMenu>
    //         </Dropdown>
    //     )
    //     }

    //     const DesignCard = ({design}) => {
    //         return(
    //             <div /*className='inf-flex-grid-item inf-px-5 inf-mb-9'*/ style={{position: 'relative'}}>
    //                 <Design_Dropdown active_item={design} style={{position: 'absolute', top: '16px', right: '16px', zIndex: 2}}/>
    //                 <div style={{ cursor: 'pointer', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)', position: 'relative' }} onClick={() => { image_onclick_fn(design.current_design_branch_id) }} className='scale_105'>
    //                     <div style={{ position: 'relative', aspectRatio: '16/9' }} className='show_on_hover'>
    //                         <img style={{ width: '100%', objectFit: 'cover' }} src={get_image_src(design.display_pic.image)} />
    //                         {/* <div className='hide' style={{...styles.text_elipsis, color:COLORS.black, fontWeight:500, padding:'6px 12px 0px 12px', backgroundColor:'transparent', fontSize:'12px', position:'absolute', bottom:'6px', fontStyle:'italic', textAlign:'center', width: '100%' }} title={''}>{'needs work'}</div> */}
    //                     </div>
    //                     <div className='inf-px-3 inf-py-2' style={{ background: '#FFFFFF80', position: 'absolute', bottom: 0, width: '100%' }}>
    //                         <div className='inf-font-medium lines1_elipsis inf-mb-1'  style={{ color: 'var(--inf-theme-gray-700)' }} title={design && design.name ? design.name : ''}>{design && design.name ? design.name : ''}</div>
    //                         {/* <div className='lines1_elipsis inf-text-xs' style={{ color: 'var(--inf-theme-gray-500)' }} title={design.Project && design.Project.name ? design.Project.name : ''}>{design.Project && design.Project.name ? design.Project.name : ''}</div> */}
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }

    // const DesignsLayout = ({ designs }) => {
    //     return (
    //         <div className='px-3'>
    //             {/* <div className='inf-flex inf-flex-col inf-px-5 inf-text-left' style={{ width: '100%' }}> */}
    //             {/* <div className='inf-py-3'>
    //             <div className='inf-text-base inf-font-semibold'><div className='inf-px-5 inf-flex inf-items-center'> <img className='inf-mr-2' src='/resources/MainPageIcons/file-outline.svg'></img>Recent Designs</div></div>
    //         </div> */}


    //             <div style={{ flex: 1 }}>
    //                 <div /*className='inf-py-3 inf-flex-grid inf-wrap-5-lg inf-wrap-2-md inf-gap-0'*/ style={{ display: 'grid', gap: '36px', gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))` }}>
    //                     {
    //                         designs && designs.length ?
    //                             // <Card_Layout recent_card_div_height={recent_card_div_height} list={designs} image_onclick_fn={image_onclick_fn} no_of_cards={3} page_name='main' set_page_loader={set_page_loader} />
    //                             <React.Fragment>
    //                                 <div className='flex_center inf-flex-col' style={{background:'white', borderRadius: '4px', border: '2px dashed var(--inf-theme-gray-400)', cursor: 'pointer'}}>
    //                                     <img className='inf-my-5' /*title={project_designer_id == user_id ? "Create New Design" : "Cannot create designs in Non owned Projects"}*/ src="/resources/icons/new_design.svg" width={'40px'}/>
    //                                     <div className='inf-text-base inf-mb-5'>Start New Design</div>
    //                                 </div>
    //                                 {
    //                                     designs.map((list_item, idx) => (
    //                                        <DesignCard design={list_item}/>
    //                                     ))
    //                                 }
    //                             </React.Fragment>
    //                             :
    //                             //TODO change filler to new design image
    //                             <Filler initialized={initialized} message='Please create new project and create design.' />
    //                     }
    //                 </div>
    //             </div>
    //             {/* </div> */}
    //         </div>
    //     )
    // }

    // const Designs = ({designs}) => {
    //     const [filtered_designs, set_filtered_designs] = useState([]);
    //     const [sortby_text, set_sortby_text] = useState('');
    //     const [sort_value, set_sort_value] = useState(0);

    //     const sort_by_recent =  (a , b) => {
    //         if(a.last_activity_at > b.last_activity_at) return -1;

    //         return 1;
    //     }

    //     const sort_by_name_ascending =  (a , b) => {
    //         if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return 1;

    //         return -1;
    //     }

    //     const sort_by_name_descending =  (a , b) => {
    //         if(a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) return -1;

    //         return 1;
    //     }

    //     const sort_by_newest =  (a , b) => {
    //         if(a.created_at > b.created_at) return -1;

    //         return 1;
    //     }

    //     const sort_by_oldest =  (a , b) => {
    //         if(a.created_at > b.created_at) return 1;

    //         return -1;
    //     }

    //     useEffect(() => {
    //         let temp_all_designs = [...designs]
    //     if(sort_value === 0){
    //         temp_all_designs.sort(sort_by_recent)
    //         set_filtered_designs(temp_all_designs)
    //         set_sortby_text('Recent')
    //     }
    //     if(sort_value === 1){
    //         temp_all_designs.sort(sort_by_name_ascending)
    //         set_filtered_designs(temp_all_designs)
    //         set_sortby_text('A-Z')
    //     }
    //     if(sort_value === 2){
    //         temp_all_designs.sort(sort_by_name_descending)
    //         set_filtered_designs(temp_all_designs)
    //         set_sortby_text('Z-A')
    //     }
    //     if(sort_value === 3){
    //         temp_all_designs.sort(sort_by_newest)
    //         set_filtered_designs(temp_all_designs)
    //         set_sortby_text('Newest')
    //     }
    //     if(sort_value === 4){
    //         temp_all_designs.sort(sort_by_oldest)
    //         set_filtered_designs(temp_all_designs)
    //         set_sortby_text('Oldest')
    //     }
    //     }, [designs, sort_value]);
    //     return (
    //         <React.Fragment>
    //             <Navbar style={{width:'100%' , height: '48px', backgroundColor:COLORS.theme_color , padding:0}}>
    //                 <Nav> 
    //                     {/* <i onClick={close_project_onclick} style={{...styles.nav_bar_icons_light,cursor:'pointer',fontSize:'24px', margin:'0px 8px', }} data-toggle="tooltip" data-placement="bottom" title="Home Page" className="fa fa-nav fa-chevron-left hover_blue"></i> 
    //                     <div className='vl'></div> */}
    //                     {/* <a style={{cursor:'pointer'}}><i style={{...styles.nav_bar_icons_light, fontSize:'24px' , marginLeft:'8px' , marginRight:'8px'}} onClick={() => user_id!=project_designer_id?'':open_edit_project_modal()} data-toggle="tooltip" data-placement="bottom" title={user_id!=project_designer_id?"Only the original author can modify the project":"Modify Project"} className={"fa fa-nav fa-pencil hover_blue" + (user_id!=project_designer_id?" disabled_fa_icon":"")}></i></a> */}
    //                     {/* <Dropdown style={{height:'32px', alignSelf:'center'}} isOpen={is_project_navigation_open} toggle={() => set_is_project_navigation_open(!is_project_navigation_open)}>
    //                         <DropdownToggle style={{borderRadius:'5px', borderColor: 'transparent', marginRight:'16px', fontSize:'14px', fontWeight: 'bold',backgroundColor:'transparent', color:'black', alignItems:'center', display:'flex'}}>{project_name}<span style={{marginLeft:'4px', display:'flex',alignItems:'center'}}><i style={{fontSize:'14px', padding: '2px', color:'white'}} className='fa fa-caret-down'></i></span> </DropdownToggle>
    //                         <DropdownMenu container="body">
    //                             {
    //                                 project_list && project_list.length ? project_list.map(x => {
    //                                     return <DropdownItem onClick={() => view_designs_onclick(x.id)} >{x.name}</DropdownItem>
    //                                     //console.log("project", x.name)
    //                                 })
    //                                 :<DropdownItem>No Projets to navigate</DropdownItem>
    //                             }
    //                         </DropdownMenu>
    //                     </Dropdown> */}
    //                     {/* <div className='show_on_hover' style={{borderRadius:'5px', borderColor: 'transparent', marginLeft:'24px', marginRight:'8px', fontSize:'14px',backgroundColor:'transparent', color:'black', alignItems:'center', display:'flex'}}>{project_name} */}
    //                         {/* <div className='bottom' id='tooltip'>
    //                         <div className='tooltip-arrow'/>
    //                             <div style={{backgroundColor:'black',color:'white', borderRadius:'6px'}}>
    //                                 <div style={{padding: '16px 16px 8px 16px', display:'flex'}}>
    //                                     <span style={{flex:2}}>Client Name:</span> <span style={{flex:3}}>{client_name ? client_name : '-'}</span>
    //                                 </div>
    //                                 <div style={{padding: '8px 16px', display:'flex'}}>
    //                                     <span style={{flex:2}}>Project ID:</span> <span style={{flex:3}}>{order_id ? order_id : '-'}</span>
    //                                 </div>
    //                                 <div style={{padding: '8px 16px 16px 16px', display:'flex'}}>
    //                                     <span style={{flex:2}}>Project Status:</span> <span style={{flex:3}}>{project_status_id ? status_options.find(item => item.id === project_status_id) ? status_options.find(item => item.id === project_status_id).status : project_status_id : '-'}</span>
    //                                 </div>
    //                             </div>
    //                         </div> */}
    //                     {/* </div> */}
    //                     {/* <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons_top}} onClick={() => user_id!=project_designer_id?'':open_edit_project_modal()}><img data-toggle="tooltip" data-placement="bottom" title={user_id!=project_designer_id?"Only the original author can modify the project":"Modify Project"} src={'/resources/nav_icons_new/edit.svg'}></img></a> */}
    //                 </Nav>
    //                 {/* <Nav className='justify-content-center'>
    //                     <Search list={all_designs_copy} set_list={set_display_designs} search_value='name'></Search>
    //                 </Nav> */}
    //                 <Nav className='justify-content-end'>
    //                     {/* <Search list={all_designs_copy} set_list={set_display_designs} search_value='name'></Search> */}
    //                     {/* <Input id="design_search_input" style={{width:'200px', borderRadius:'2px', marginRight:'16px', fontSize:'12px' }} placeholder='Search by Design name' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input> */}
    //                     <UncontrolledDropdown>
    //                         <DropdownToggle style={{borderRadius:'2px', marginRight:'16px', fontSize:'12px',backgroundColor:'white', color:'#22272E', alignItems:'center', display:'flex',width:'auto'}}>Sort By: {sortby_text} <span style={{marginLeft:'4px', display:'flex',alignItems:'center'}}><i style={{fontSize:'14px', padding: '2px'}} className='fa fa-caret-down'></i></span> </DropdownToggle>
    //                         <DropdownMenu container="body">
    //                             <DropdownItem onClick={() => set_sort_value(0)}>Recent</DropdownItem>
    //                             <DropdownItem onClick={() => set_sort_value(1)}>A-Z</DropdownItem>
    //                             <DropdownItem onClick={() => set_sort_value(2)}>Z-A</DropdownItem>
    //                             <DropdownItem onClick={() => set_sort_value(3)}>Newest</DropdownItem>
    //                             <DropdownItem onClick={() => set_sort_value(4)}>Oldest</DropdownItem>
    //                         </DropdownMenu>
    //                     </UncontrolledDropdown>
    //                 </Nav>
    //             </Navbar>
    //             <DesignsLayout designs={filtered_designs}/>
    //         </React.Fragment>
    //     )
    // }


    const Projects = (
        <div className='px-3'>
            {/* <div className='inf-text-left inf-px-5 inf-flex inf-flex-col' style={{ height: '100%', width: '100%' }}> */}
            <div className='inf-py-3'>
                <div className='inf-text-base inf-px-5 inf-flex inf-items-center inf-justify-between'>
                    <div className='inf-flex inf-items-center inf-font-semibold'>
                        <img className='inf-mr-2' src='/resources/MainPageIcons/folder-outline-dark.svg'></img>
                        Viewing All Projects
                    </div>
                    <div className='inf-flex inf-items-center'>
                        <div className='inf-mr-2'>{`Displaying ${list_display && list_display.length} projects`}</div>
                        <div className='inf-mr-2' onClick={() => set_is_grid_view(false)}>list</div>
                        <div onClick={() => set_is_grid_view(true)}>{t(`grid`)}</div>
                    </div>
                </div>
            </div>
            <div className='px-3' style={{ flex: 1 }}>
                {
                    is_grid_view ?
                        <ProjectsGrid list_display={list_display} />
                        :
                        <Project_Table org_key={org_key} show_alert_box={show_alert_box} all_project_list_display={list_display} user_id={user_id} project_status={project_status} update_project={update_project} Filler={Filler} />
                }
            </div>
            {/* </div> */}
        </div>
    )

    return (
        // <div>
        //     <div style={styles.main_div}>
        //         <div style={styles.layer}>
        //         </div>
        //         <Navbar style={{...styles.nav_bar, zIndex:2}} id="navbarSupportedContent">

        //             <Nav><img style={styles.infurnia_logo} src={store_details&&store_details.logo_path?store_details.logo_path :"/resources/images/infurnia_black.svg"} /></Nav>
        //             <Nav />
        //             <Nav className="justify-content-end" style={{alignItems:'center'}}>
        //                 <a href={admin_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px', fontSize:'14px', marginRight:'16px', color:COLORS.black}} title="Admin portal" >Go to Admin Portal</a>
        //                 <div className='vl_end'></div>
        //                 {/* <a style={{cursor:'pointer', fontSize:'24px'}} onClick={logout}><i style={styles.nav_bar_icons} data-toggle="tooltip" data-placement="bottom" title="Logout" className="fa fa-sign-out hover_blue"></i></a> */}
        //                 <Dropdown isOpen={is_profile_dropdown_open} toggle={() => set_is_profile_dropdown_open(!is_profile_dropdown_open)}>
        //                     {/* <DropdownToggle> */}
        //                         <DropdownToggle style={{borderRadius:'50%', height:'32px', width:'32px', margin:'2px 0px', color:'rgba(0, 120, 255, 1)', backgroundColor:'rgba(0, 120, 255, 0.1)', border:'1px solid white', justifyContent:'center', display:'flex', alignItems:'center',marginLeft:'8px',marginRight:'8px', textTransform:'uppercase', fontWeight: 500}}>{store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim()[0] : ':)'}</DropdownToggle>
        //                     {/* </DropdownToggle> */}
        //                     <DropdownMenu style={{marginTop:'11px'}}>
        //                         <DropdownItem onClick={() => set_show_account_settings(true)}>
        //                             Account Settings
        //                         </DropdownItem>
        //                         {/* <DropdownItem>
        //                             Switch Organization
        //                         </DropdownItem> */}
        //                         <DropdownItem onClick={logout}>
        //                             Logout
        //                         </DropdownItem>
        //                     </DropdownMenu>
        //                 </Dropdown>
        //                 <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons}} onClick={toggle_fullscreen}><img data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" src={'/resources/nav_icons_new/fullscreen.svg'}></img></a>
        //                 <a className="blue_hover black_text hover_toolbar" style={{cursor:'pointer', ...styles.nav_bar_icons}} onClick={logout}><img data-toggle="tooltip" data-placement="bottom" title="Logout" src={'/resources/nav_icons_new/logout.svg'}></img></a>
        //                 {/* <i onClick={close_project_onclick} style={{...styles.nav_bar_icons,cursor:'pointer'}} data-toggle="tooltip" data-placement="bottom" title="Home Page" className="fa fa-nav fa-times fa-2x hover_blue"></i> */}
        //             </Nav>

        //         </Navbar>
        //         <div style={{width:"100%", height:'calc(100% - 55px)', position:'absolute', top:'55px', left:0}} >
        //             <Row style={{margin:0, height:"100%", overflow:'auto'}}>
        //                 <Col className='col-md-12 col-lg-2' style={{ height:'auto', background:'white', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.1)'}}>
        //                     {/* <div style={{color:COLORS.black, fontSize:'18px', textAlign:'center', marginTop:'40px' }}>Welcome to</div>
        //                     <div style={{color:COLORS.black, fontSize:'24px', textAlign:'center', marginTop:'10px', fontWeight:'700px', marginBottom:'20px' }}>{store_details && store_details.store_name?store_details.store_name: ''}</div>
        //                     <Row style={{margin:0, marginBottom:'30px'}}>
        //                         <Col style={{padding:0}}>
        //                             <Button id="new_project_button" onClick={open_new_project_modal} style={styles.main_page_button_dark}>New Project</Button>
        //                         </Col>
        //                     </Row>
        //                     <Row style={{margin:0}}>
        //                         <Col style={{padding:0}}>
        //                             <Button id="open_project_button" style={styles.main_page_button_light} onClick={open_open_project_modal}>Open Project</Button>
        //                         </Col>
        //                     </Row>
        //                     <div style={{display: 'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-end', height:'auto', width:'100%', position:'absolute', bottom:'43px', left:'0px'}}>
        //                         <a href='https://infurnia.com' target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', margin:'8px 0px', paddingLeft:'38px', fontSize:'14px', color:'black'}} title="Infurnia Website" >Infurnia.com</a>
        //                         <a href='https://www.youtube.com/c/InfurniaTechnologies/playlists' target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', margin:'8px 0px', paddingLeft:'38px', fontSize:'14px', color:'black'}} title="Infurnia Tutorial" >Tutorial Links</a>
        //                         <a href={admin_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', margin:'8px 0px', paddingLeft:'38px', fontSize:'14px', color:'black'}} title="Need Help" ><i className='fa fa-question-circle' style={{marginRight:'8px'}}></i>Need Help</a>
        //                     </div> */}
        //                     <div style={{width:'100%', height: '100%', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
        //                         <div>
        //                             <div style={{color:COLORS.black, fontSize:'18px', textAlign:'center', marginTop:'40px' }}>Welcome to</div>
        //                             <div style={{color:COLORS.black, fontSize:'24px', textAlign:'center', marginTop:'10px', fontWeight:'700px'}}><img style={styles.infurnia_logo} src={store_details && store_details.logo_path?store_details.logo_path :"/resources/images/infurnia_black.svg"} /></div>
        //                             <div style={{color:COLORS.black, fontSize:'24px', textAlign:'center', marginTop:'10px', fontWeight:'700px'}}>{store_details && store_details.store_name?store_details.store_name: ''}</div>
        //                         </div>
        //                         <div style={{width:'100%'}}>
        //                             <Button id="new_project_button" onClick={open_new_project_modal} style={{...styles.main_page_button_dark, marginBottom:'30px'}}>New Project</Button>
        //                             {/* <Button id="open_project_button" style={{...styles.main_page_button_light, marginBottom:'30px'}} onClick={open_open_project_modal}>Open Project</Button> */}
        //                             <Button id="open_project_button" style={styles.main_page_button_light} onClick={() => {if(!projects_view){fetch_projectDetails()}; set_projects_view(!projects_view)}}> {projects_view? "Recent Designs" : "View All Projects"}</Button>
        //                         </div>
        //                         <div className='help_menu' style={{visibility:org_key?'hidden':'visible'}}>
        //                             <a href='https://infurnia.com' target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', margin:'8px 0px', paddingLeft:'38px', fontSize:'14px', color:'black'}} title="Infurnia Website" >Infurnia.com</a>
        //                             <a href='https://www.youtube.com/c/InfurniaTechnologies/playlists' target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', margin:'8px 0px', paddingLeft:'38px', fontSize:'14px', color:'black'}} title="Infurnia Tutorial" >Tutorial Links</a>
        //                             {/* <a href={admin_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', margin:'8px 0px', paddingLeft:'38px', fontSize:'14px', color:'black'}} title="Need Help" ><i className='fa fa-question-circle' style={{marginRight:'8px'}}></i>Need Help</a> */}
        //                         </div>
        //                     </div>
        //                 </Col>

        //                 {
        //                     !projects_view ?
        //                         <Col lg={10} md={12} style={{ height:'100%',width:'100%'}}>
        //                             {
        //                                 // store_details.suspension_status ?
        //                                 // <Row>
        //                                 //     <Col className='flex_center DMSans trail_extension' style={{margin:0, height:'40px', width:'100%'}}>
        //                                 //         <div style={{fontWeight:'700', fontSize:'12px'}}>Your free trial has expired you can only view items</div>
        //                                 //     </Col>
        //                                 // </Row>
        //                                 // :'' 
        //                             }
        //                             <Row>
        //                                 <Col style={{margin:0, height:'50px', width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
        //                                     <div style={{fontWeight:'700', padding:'0px 0px 0px 15px', fontSize:'18px'}}>Recent Designs</div>
        //                                 </Col>
        //                             </Row> 
        //                             <div style={{ height: store_details.suspension_status ? 'calc(100% - 90px)' : 'calc(100% - 50px)',width:'100%', overflow:'auto'}}>
        //                                 {initialized && recent_designs && recent_designs.length ?<Card_Layout recent_card_div_height={recent_card_div_height} list={recent_designs} image_onclick_fn={image_onclick_fn} no_of_cards={3} page_name='main'  set_page_loader={set_page_loader} />:<Filler initialized={initialized} message='Please create new project and create design.'/>}
        //                                 {/* <Col lg={3} md={4} style={{width:'auto'}}>
        //                                     {initialized && list && list.length ? list.map(async(item) => {
        //                                         let x = await designs_in_project(item.id)
        //                                         // .then(resp => {
        //                                         //     x = resp
        //                                         // console.log('x', x)
        //                                         // })
        //                                         console.log('xo', x)
        //                                         return <Single_Card page_name="projects" list={x}></Single_Card>
        //                                     }) : ''}
        //                                     <Single_Card page_name="projects" list={designs_in_project()} ></Single_Card>
        //                                 </Col> */}
        //                             </div>
        //                         </Col>
        //                         :
        //                         <Col lg={10} md={12} style={{ height:'100%',width:'100%'}}>
        //                             <div style={{ height:'100%',width:'100%'}}>
        //                                 {
        //                                     // store_details.suspension_status ?
        //                                     // <Row>
        //                                     //     <Col className='flex_center DMSans trail_extension' style={{margin:0, height:'40px', width:'100%'}}>
        //                                     //         <div style={{fontWeight:'700', fontSize:'12px'}}>Your free trial has expired you can only view items</div>
        //                                     //     </Col>
        //                                     // </Row>
        //                                     // :'' 
        //                                 }
        //                                 <div style={{ height: store_details.suspension_status ? 'calc( 100% - 40px )': '100%' ,width:'100%', padding:'48px 48px 0px 48px'}}>
        //                                     <Navbar style={{padding:0, marginBottom:'20px'}}>
        //                                         <Nav>
        //                                             <h4>Viewing All Projects</h4>
        //                                         </Nav>
        //                                         <Nav className='justify-content-end'>
        //                                             <ISearch list={list} set_list={set_list_display} search_parameters={['name', 'client_name', 'order_id']} placeholder={"Seach by Project Name, Client Name, Project Id"} style_inner={{width: '300px'}}></ISearch>
        //                                         </Nav>
        //                                     </Navbar>
        //                                     {/* <Navbar>
        //                                         <div style={{padding:'4px 16px 4px 0px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === '' ? '700' : '400', fontSize:'14px' }} onClick={() => set_template_selection('')}></div>
        //                                     </Navbar> */}
        //                                     <div style={{height: 'calc(100% - 80px)', overflow:'auto', position:'relative'}}>
        //                                         <Project_Table org_key={org_key} show_alert_box={show_alert_box} all_project_list_display={list_display} user_id={user_id} project_status={project_status} update_project={update_project} Filler={Filler}/>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </Col>
        //                 }

        //             </Row>
        //         </div>
        //     </div>
        //     <Account_Settings_Modal open={show_account_settings} handle_close={close_account_settings} store_details={store_details} set_page_loader={set_page_loader}></Account_Settings_Modal>
        //     <New_Project_Modal open={show_new_project_modal} org_key={org_key} handle_close={close_new_project_modal} user={user} onclick_submit={onclick_submit} />
        //     <Open_Project_Modal org_key={org_key} open={show_open_project_modal} handle_close={close_open_project_modal} user={user} show_alert_box={show_alert_box} list={list} Filler={Filler} initialized={initialized} message='Please create New Project.'/> 
        // </div>  
        <React.Fragment>
            <MainPageLayout store_details={store_details} org_key={org_key} logout={logout} set_page_loader={set_page_loader} viewParam={viewParam}
                SideBarSearch={<ISearch list={list} set_list={set_list_display} search_parameters={['name', 'client_name', 'order_id']} placeholder={"Seach"} style_inner={{ width: '100%' }} style_outer={{ margin: '0px 0px var(--spacing-3) 0px' }}></ISearch>}
            >
                <div className='inf-flex inf-flex-col inf-p-5 inf-text-left' style={{ width: '100%', height: '100%' }}>
                    {
                        projectParam ?
                            // <Designs designs={all_designs.filter(x => x.project_id === projectParam)}/>
                            <DesignsLayout org_key={org_key} active_project_id={projectParam} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm} store_details={store_details} init={init} logout={logout} />
                            :
                            viewParam == 'recent' ?
                                <React.Fragment>
                                    {/* {RecentProjects} */}
                                    <RecentProjects list_display={list_display} />
                                    {RecentDesigns}
                                </React.Fragment>
                                :
                                Projects
                    }
                </div>
            </MainPageLayout>
            {/* <Lock_Warning_Modal></Lock_Warning_Modal>
        <Edit_Design_Modal open={show_edit_design_modal} rename_design={rename_design} handle_close={onclick_close_modal} active_design={active_item} />
        <Copy_Design_Modal open={show_copy_design_modal} copy_design={copy_design} active_project_id={projectParam} handle_close={onclick_close_modal} active_design={active_item} set_page_loader={set_page_loader} user_id={user_id}/>
        <Addn_prop_modal open={show_addn_prop_modal} update_design_additional_properties={update_design_additional_properties} active_project_id={projectParam} handle_close={onclick_close_modal} active_design={active_item} set_page_loader={set_page_loader} />
        <Share_Branch_Modal_New open={show_share_design} handle_close={() => onclick_close_modal('share_design')} active_design={active_item} set_page_loader={set_page_loader} /> */}
        </React.Fragment>
    );
}

export { MainPage };