export const wait_for_all_core_threads = () => (new Promise((res,rej) => {
	var interval_core = setInterval(() => {
		if(window.Module.are_other_core_threads_busy() || window.Module.are_internal_core_threads_busy() || window.Module.are_graphics_core_threads_busy()){
			console.log("Waiting for corelib and graphics internal threads to finish");
		}else{
			console.log("Finished waiting for corelib and graphics internal threads");
			res();
			clearInterval(interval_core);
		}
	},60);
}));