/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {FormGroup, CardHeader, Input, Button, Row, Col, Collapse, Card, CardBody, Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardFooter, Modal, UncontrolledTooltip, UncontrolledDropdown } from 'reactstrap';
import MaterialDropdown from '../../components/MaterialDropdown/';
import CompositeDropdown from '../../components/CompositeDropdown/';
import repopulate_panel from '../../utils/panel_repopulation';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';

import { useAlert } from 'react-alert'
import { COLORS } from '../../utils/color';
import { Image_Icon, Fa_Icon, Text_Icon } from '../Unit/'
import { Tree, Switch, Tooltip, Dropdown as AntDropdown, Checkbox } from 'antd';
import FocusWithin from '../FocusWithin';
import './style.css'
import set_panel_search_show from '../../utils/set_panel_search_show';
import { ChromePicker } from 'react-color';
import kelvinToHex from '../../utils/kelvinToRGB';
// import populate_panel_search from '../../utils/populate_panel_search';
import HatchingDropdown from '../HatchingDropdown';
import get_image_url_from_FS, { get_image_src } from '../../utils/get_image_url_from_FS.js';
import { UncontrolledCollapse } from '../ICollpsable/index.js';
import {useTranslation} from 'react-i18next'
import DnD from '../DnD/index.js';
import { send_analytics } from '../../utils/send_analytics';
import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal/index.js';
import { handleError } from '../../utils/errors.js';
import sleep from '../../utils/sleep.js';
import { CheckboxInputNew, ColorInputNew, CompositeSelectNew, DimensionInput, HatchingSelectnNew, ImageMapSelectNew, LayerInputNew, ListItemNew, MaterialSelectNew, MultiComponentInput, PanelSection, RefLevelDimensionInputNew, RotationInputNew, SelectAndDimensionInputNew, SelectInputNew, TextInputNew, ToggleInputNew } from '../PanelComponents/index.js';
import TriggerTooltip from '../TriggerTooltip/index.js';
import Icons from '../../shared_components/ui_component_library/Icons/index.js';

const dummy_tick = (t) => (new Promise((res,rej) => {
    var timer = setTimeout(() => {
        res();
        clearTimeout(timer);
    },t)
})); 

const color_presets = ["#010103", "#F1D543", "#C17A36", "#943028", "#643370", "#333E95", "#323F77", "#3B6643", "#807A3D", "#6F7071"]

const styles_panel = {
    text_elipsis : {
        whiteSpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingTop: 5
	},
	input_styles:{
		padding: 0,
		height: '24px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		paddingLeft:'6px',
		borderRadius:'2px'
	},
	input_styles_small:{
		padding: 0,
		height: '24px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		paddingLeft:'6px',
		borderRadius:'2px'
	},
	collapse:{
    	position:'absolute',
    	left:'71px',
	    top: '100px',
    	width: '270px',
        height: 'calc(100% - 100px)',
    },
};

const getPanelItemWidth = (parent_item, item, metric_system) => {
	if (parent_item.configuration_type == 'group_by_2' && (item.type == 'dimension_input' && !item.expression_available_parameters) || item.type == 'text_input' || item.type == 'toggle' || item.type == 'select_input' || item.type == 'colorpicker') {
		return '50%'
	} else if (parent_item.group_child_inputs && ((item.type == 'dimension_input' && (metric_system == 'mm' || item.is_unitless_true) && !item.expression_available_parameters) || item.type == 'text_input' || item.type == 'colorpicker' || (item.type == 'select_input'))) {
		return '33.3%'
	}
	return '100%'
}

const MCD_SingleItem = ({ item, common_onchange_handler, icon_on_click }) => {
	const [input_val, set_input_val] = useState('')
	const [triggered_by, set_triggered_by] = useState({esc: false});
    const {t} = useTranslation()

	const handleChange = (event) => {
		common_onchange_handler()
		set_input_val(event.target.value)
	};

	const handleSubmit = () => {
		if(!triggered_by.esc){
			icon_on_click({route: item.onchange_route, form: {id: item.id, value: input_val}})
		}else{
			triggered_by.esc = false
		}
	}

	useEffect(() => {
		set_input_val(item.default_value)
	}, [item]);

	return (
		<div style={{ display: 'flex', flex: item.type === 'line_type' ? 1.5 : item.prefix ? 2 : 1, minWidth: '40px', fontSize: '12px', color: COLORS.gray5, borderRight: '1px solid var(--light-border-color)' }}>
			{
				// item.prefix === 'line_weight' ?
				// 	<div className='flex_property mx-1' style={{flex: 1, minWidth: '20px'}}>
				// 		<div style={{ width: '100%', height: `${input_val}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
				// 	</div>
				// 	:
				// 	item.prefix === 'line_color' ?
				// 		<div className='flex_property mx-1' style={{flex: 1, minWidth: '20px'}}>
				// 			<div style={{ width: '16px', height: '16px', backgroundColor: input_val }}></div>
				// 		</div>
				// 		:
						item.prefix ?
							<div className='flex_property mx-1' style={{ whiteSpace: 'nowrap'}}>
								<div>{item.prefix}</div>
							</div>
							: ''
			}
			<div className='flex_property px-1' style={{flex: 1, minWidth: '20px'}}>
			{
				item.type === 'number' || item.type == 'text' ?
					<Input style={{ ...styles_panel.input_styles, border: 'none', paddingLeft: '0px', padding: '0px 0px 0px 0px', textAlign:'left' }} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { set_input_val(item.default_value); triggered_by.esc = true; e.target.blur() } }} type={item.type} value={input_val} onChange={handleChange} onBlur={handleSubmit}/>
					:
					item.type === 'line_weight' ?
						// <div className='flex_center mx-1'>
						<React.Fragment>
							<div className='mr-2' style={{ width: '24px', height: `${input_val}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
							{item.prefix ? '' : <Input style={{ ...styles_panel.input_styles, border: 'none', paddingLeft: '0px', padding: '0px 0px 0px 0px', textAlign:'left' }} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { set_input_val(item.default_value); triggered_by.esc = true; e.target.blur() } }} type={item.type} value={input_val} onChange={handleChange} onBlur={handleSubmit}/>}
						</React.Fragment>
						// </div>
						:
						item.type === 'line_color' ?
							// <div className='flex_center mx-1'>
							<React.Fragment>
								<div className='mr-2' style={{ width: '16px', height: '16px', backgroundColor: input_val }}></div>
								{item.prefix ? '' : <div>{input_val}</div>}
							</React.Fragment>
							// </div>
							:
							item.type === 'value' ?
								// <div className='flex_center mx-1'>
									<div>{input_val}</div>
								// </div>
								:
								item.type === 'line_type' ?
								<React.Fragment>
									{
										input_val === 'solid' ?
											<React.Fragment>
												<div style={{ minWidth: '24px', flex: 1, borderTop: '2px solid' }}></div>
												<div className='ml-2'>{t(`Solid`)}</div>
											</React.Fragment>
											: input_val === 'dashed' ?
												<div style={{ minWidth: '24px', flex: 1, borderTop: '2px dashed' }}></div>
												: input_val === 'dotted' ?
													<div style={{ minWidth: '24px', flex: 1, borderTop: '4px dotted' }}></div>
													: ''
									}
								</React.Fragment>
								
								: ''
			}
			</div>
		</div>
	)
}
const colors = ['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E'];

const MCD_ColorPicker = ({ color, handleChange, dropdown_open, toggle_dropdown }) => {
	const [open, set_open] = useState(false);
	const toggle = () => set_open(!open)
    const {t} = useTranslation()

	const handleKeyUp = (e) => {
		if(dropdown_open && e.key === "Escape"){
			e.stopPropagation()
			toggle_dropdown();
		}
	}

	useEffect(() => {
		window.addEventListener("keyup",handleKeyUp);
		return () => {
			window.removeEventListener("keyup",handleKeyUp);
		}

	},[])
	
	return (
		<div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '6px 12px', fontSize: '12px'}} className='panel-mcd-color-picker'>
			<div>
				<div>{t(`Presets`)}</div>
				<div>
					<div className="inf-presets-color-picker">
						<div className="inf-presets-color-grid">
							{colors.map(col => (
								<div
									key={col}
									className={`inf-presets-color-option ${color === col ? 'selected' : ''}`}
									style={{ backgroundColor: col }}
									onClick={() => {handleChange({hex: col}); toggle_dropdown()}}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
			<hr/>
			<div style={{width: '100%'}}>
				<div onClick={toggle} style={{fontSize: '12px', color: open ? '' : '#0078ff', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
					Custom color
					<i className={`ml-2 ${open ? 'fa fa-caret-up' : 'fa fa-caret-down'}`}/>
				</div>
			</div>
			{
				open ?
					<ChromePicker className='mcd_color_picker' color={color} onChange={handleChange} disableAlpha/>
					: ''
			}
		</div>
	)
}

const MultiComponentDropdown = ({json_item, on_click, common_onchange_handler, icon_on_click, index, is_grouped}) => {
	const [open, set_open] = useState(false);
	const [color, set_color] = useState('');
	const toggle = () => set_open(!open)
	const onChangeColor = (color) => {
		set_color(color.hex)
	}

	const submitColor = (color, set_color) => {
		if(color){
			// Set color in core
			icon_on_click({route: json_item.onchange_route, form: {id: json_item.id, value: color}})
			set_color('')
		}
	}

	useEffect(() => {
		if(!open){
			submitColor(color, set_color)
		}
	}, [open]);


	return(
		<Card style={{ border: '0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }} className={json_item.disabled ? 'panel_disabled MCD----------MCD class_position_unset' : 'MCD----------MCD class_position_unset'}>
			<CardBody className='class_position_unset' style={{ padding: '8px 0px', backgroundColor: COLORS.panel_card_item }} >
				<div className='px-2'>
					<div style={{fontSize: '12px', textAlign: 'left'}}>{json_item.name}</div>
					<div className='flex_property' style={{ display: 'flex', height: '100%', width: '100%', border: '1px solid var(--light-border-color)', paddingLeft: '2px', borderRadius:'2px' }}>
						{/* <div className='class_position_unset' style={{ height: '100%', flex: 1, border: '1px solid rgb(206, 212, 217)', borderRight: 'none', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
							<div style={{ width: '100%', height: input_val + 'px', backgroundColor: 'black', borderWidth: input_val + 'px' }}></div>
						</div> */}
						<div style={{flex: 1, minWidth: "40px", display: 'flex'}}>
							{
								!json_item.mixed && json_item.components && json_item.components.length ? json_item.components.map((item) => (
									<MCD_SingleItem item={item} common_onchange_handler={common_onchange_handler} icon_on_click={icon_on_click} />
								))
									: json_item.mixed ?
										<div style={{fontSize: '12px', padding: '0px 8px'}}>Mixed</div>
										: ''
							}
						</div>
						
						<Dropdown direction='down' isOpen={open} toggle={toggle} className='class_position_unset' style={{ height: '100%', display: 'flex' }}>
							{/* <Tooltip color='#FFFFFF' placement='bottom' showArrow={false} trigger={'click'} title={ */}
								{/* <div> */}
								<DropdownMenu style={{marginTop: '48px', marginRight: is_grouped && !index%2 ? '-120px' : '',  width: '240px', maxHeight: json_item.options && json_item.options.find(option => option.type === 'colorpicker') ? '' : '216px', overflow: 'auto', position: 'absolute'}}>
								{/* {(json_item.components.length == 1) ? <>
									<DropdownItem style={{ listStyle: 'none', padding: '4px 12px', color: '#676878', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => { }}>
										<div style={{ paddingRight: '20px' }}>By layer:</div>
										<div style={{ width: '16px', height: json_item.options.find(item => item.id == json_item.layer_value).name + 'px', backgroundColor: 'black', borderWidth: json_item.options.find(item => item.id == json_item.layer_value).name + 'px', paddingRight: '8px' }}></div>
										<div>
											{json_item.options.find(item => item.id == json_item.layer_value).name}
										</div>
									</DropdownItem>
									<DropdownItem divider />
								</> : ''}
								{json_item.options.map((item, idx) => (
									<DropdownItem style={{ listStyle: 'none', padding: '4px 12px', color: '#676878', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '20px' }}>
										<div style={{ width: '32px', height: item.name + 'px', backgroundColor: 'black', borderWidth: item.name + 'px' }}></div>
										<div>
											{item.name}
										</div>
									</DropdownItem>
								))} */}
								{
									json_item.options && json_item.options.length ? json_item.options.map((option) => (
										option.type === 'divider' ?
											<DropdownItem divider />
											: option.type === 'colorpicker' ?
											<MCD_ColorPicker color={color} handleChange={onChangeColor} dropdown_open={open} toggle_dropdown={toggle}/>
											:
											<DropdownItem style={{display: 'flex', fontSize: '12px', alignItems: 'center', padding: '6px 12px'}} onClick={() => {icon_on_click({route: json_item.onchange_route, form: {id: json_item.id, prefix: option.prefix, value: option.value}})}}>
												{
													option.prefix ?
													<div className='mr-2'>{option.prefix}</div>
													: ''
												}
												{
													option.type === 'line_weight' ?
														<React.Fragment>
															<div className='mr-2' style={{ width: '40px', height: `${option.value}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
															<div>{option.value}</div>
														</React.Fragment>
														:
														option.type === 'line_color' ?
															<React.Fragment>
																<div className='mr-2' style={{ width: '16px', height: '16px', backgroundColor: option.value }}></div>
																<div>{option.value}</div>
															</React.Fragment>
															: option.type === 'line_type' ?
																<React.Fragment>
																	{
																		option.value === 'solid' ?
																			<div style={{ width: '100%', borderTop: '2px solid' }}></div>
																			: option.value === 'dashed' ?
																				<div style={{ width: '100%', borderTop: '2px dashed' }}></div>
																				: option.value === 'dotted' ?
																					<div style={{ width: '100%', borderTop: '4px dotted' }}></div>
																					: ''
																	}
																</React.Fragment>
																:
																<div>{option.value}</div>

												}
											</DropdownItem>
									))
									:''
								}
							</DropdownMenu>
							{/* </div> */}
							{/* }> */}
							<DropdownToggle style={{ display: 'flex', alignItems: 'center', background: 'white', color: '#676878', padding: '0px', boxShadow: 'none', fontSize: '12px', border: 'none' }}>
								<div className='flex_center' style={{padding: '8px'}} >
									<img src="/resources/toolbar_icons_new/dropdown.svg" width={'8px'} />
								</div>
							</DropdownToggle>
							{/* </Tooltip> */}
							
							
						</Dropdown>
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

export const KelvinColorPicker = ({json_item, common_onchange_handler, on_click}) => {
	const [color, set_color] = useState('');
	const [color_final, set_color_final] = useState('');
	useEffect(() => {
		set_color(json_item.default_value)
		set_color_final(json_item.default_value)
	}, [json_item.default_value]);

	// const [kelvin, setKelvin] = useState(5000); // Initial Kelvin temperature
	// const [tab, setTab] = useState('kelvin'); // Active tab ('kelvin' or 'regular')

	// const handleKelvinChange = (event) => {
	// 	setKelvin(event.target.value);
	// };

	// const handleColorChange = (color) => {
	// 	// Do something with the selected color
	// };

	// const kelvinToRGB = (kelvin) => {
	// 	// Conversion logic from Kelvin to RGB
	// 	// ...

	// 	// return [red, green, blue];
	// };

	// const handleTabChange = (selectedTab) => {
	// 	setTab(selectedTab);
	// };

	useEffect(() => {
		if(color_final && color_final != json_item.default_value){
			on_click()
		}
	}, [color_final]);

	return (
		// <div>
		// 	<div className="tab-container">
		// 		<button className={tab === 'kelvin' ? 'active' : ''} onClick={() => handleTabChange('kelvin')}>
		// 			Kelvin Color Picker
		// 		</button>
		// 		<button className={tab === 'regular' ? 'active' : ''} onClick={() => handleTabChange('regular')}>
		// 			Regular Color Picker
		// 		</button>
		// 	</div>
		// 	{tab === 'kelvin' ? (
		// 		<div>
		// 			<input
		// 				type="range"
		// 				min="1000"
		// 				max="10000"
		// 				step="100"
		// 				value={kelvin}
		// 				onChange={handleKelvinChange}
		// 			/>
		// 			<div>
		// 				<div>Kelvin: {kelvin}</div>
		// 				<div style={{ width: '100px', height: '100px', background: `rgb(${kelvinToRGB(kelvin)})` }}></div>
		// 			</div>
		// 			<ChromePicker color={kelvinToRGB(kelvin)} onChange={handleColorChange} />
		// 		</div>
		// 	) : (
		// 		<div>
		// 			<SketchPicker onChange={handleColorChange} />
		// 		</div>
		// 	)}
		// </div>
		<div style={{display:'flex'}}>
			<Input type="color" onChange={(e) => {common_onchange_handler(); set_color(e.target.value)}} onBlur={(e) => {set_color_final(e.target.value)}} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = (json_item.default_value); e.target.blur() } }} value={color} key={"key_color" + json_item.id} id={json_item.id + 'displayed'} style={{ ...styles_panel.input_styles, border: 'none', width: '24px', height: '24px', padding: 0 }} />
			<Input type="color" value={color_final} key={"key_color_hidden" + json_item.id} id={json_item.id} style={{display:'none'}} />
			{/* <Input type="color" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = (json_item.default_value); e.target.blur() } }} defaultValue={(json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{ ...styles_panel.input_styles, border: 'none', width: '24px', height: '24px', padding: 0 }} /> */}
			<Input type="text" onChange={() => { common_onchange_handler() }} onBlur={(e) => { if (e.target.value != '') {set_color_final(kelvinToHex(e.target.value)); e.target.value = ''} }} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = ''; e.target.blur() } }} key={"key_color" + json_item.id} id={json_item.id} style={{ ...styles_panel.input_styles, border: 'none', flex: 5, height: '24px', padding: 0, marginLeft:'8px' }} placeholder='In Kelvin'/>
		</div>
	);
};

const ReferenceLevellDropdown = ({ json_item, on_click }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [rename_enabled, set_rename_enabled] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        // <Row className='class_position_unset' style={{margin:0}}>
        //     <Col className='class_position_unset' style={{padding:0, display:'flex', alignItems:'center'}}>
                <Dropdown direction="up" className='class_position_unset' isOpen={dropdownOpen} style={{all:'unset', listStyle: 'none'}} toggle={json_item && json_item.disabled?null:toggle}>
                    <DropdownToggle  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'13px', padding:'0px', border:0}}>
						{/* <NavItem style={{listStyleType:'none'}}> */}
							<img style={{color:COLORS.white, marginLeft:'5px'}} width='10px' src="/resources/toolbar_icons_new/dropdown.svg" />
							{/* <div style={{width:0, borderRight:'2px solid ' + COLORS.gray3, height: 24}}></div> */}
						{/* </NavItem> */}
                    </DropdownToggle>
                    <DropdownMenu className='ref_level_dropdown_menu'>
                        {json_item&&json_item.ref_level_options?([{name:"Value" + (json_item.relative_ref_level?" (Fixed)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")),value:"no_ref_level"}]).concat(json_item.ref_level_options).map((o,idx) => (
                            <DropdownItem style={{listStyle: 'none'}} onClick={() => {on_click?on_click(o.value):''}} key={idx}>
                                <div style={{display:'flex',flexFlow:'row',fontSize:'13px'}}>
                                    <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{json_item.relative_ref_level&&o.value!="no_ref_level"?'Up to ' + o.name:o.name}</div>
                                </div>
                            </DropdownItem>
                        )):''}
                    </DropdownMenu>
                </Dropdown>
                
        //     </Col>
        // </Row>
    );
}

const timeElapsed = (date_string) => {
	var seconds = Math.floor((new Date() - new Date(date_string)) / 1000);

	var interval = Math.floor(seconds / 31536000);

	if (interval > 1) {
		return interval + "yrs ago";
	}
	interval = Math.floor(seconds / 2592000);
	if (interval > 1) {
		return interval + "mon ago";
	}
	interval = Math.floor(seconds / 86400);
	if (interval > 1) {
		return interval + "days ago";
	}
	interval = Math.floor(seconds / 3600);
	if (interval > 1) {
		return interval + "hrs ago";
	}
	interval = Math.floor(seconds / 60);
	if (interval > 1) {
		return interval + "min ago";
	}
	return Math.floor(seconds) + "sec ago";

}

const CommentBox = ({placeholder, onSubmit, common_onchange_handler, autoFocus}) => {
	const [comment, setComment] = useState('');
	const textareaRef = useRef(null);

	const handleChange = (event) => {
		common_onchange_handler()
		setComment(event.target.value);
	};

	const triggerEscKey = () => {
		const event = new KeyboardEvent('keydown', { key: 'Escape' });
		document.dispatchEvent(event);
	  };

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && comment) {
			if (!event.shiftKey) {
				event.preventDefault();
				// Call your submit function here
				onSubmit(comment);
				setComment("")
			}
		}else if(event.key === 'Escape'){
			setComment("");
			event.target.blur()
			triggerEscKey()
		}
	  };
	

	useEffect(() => {
		const textareaElement = textareaRef.current;
		textareaElement.style.height = 'auto';
		textareaElement.style.height = `${textareaElement.scrollHeight}px`;
	}, [comment]);

	useEffect(() => {
		if (autoFocus && textareaRef.current) {
			// textareaRef.current.scrollIntoView({ behavior: 'smooth' });
			textareaRef.current.focus();
		}
	}, [autoFocus]);

	return (
		<div className="comment-input">
			<textarea
				ref={textareaRef}
				value={comment}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				// onKeyPress={() => {if(comment)onSubmit(comment)}}
				placeholder={placeholder}
				// autoFocus
			/>
			{/* <button onClick={handleKeyPress}>Submit</button> */}
		</div>
	)
}

const Comment = ({json_item, on_click, common_onchange_handler}) => {
	// console.log(json_item, "comment")
	const [reply_open, set_reply_open] = useState(false);
	// const [new_reply, set_new_reply] = useState(false);
	// const [comment, setComment] = useState('');
	const toggle_reply = () => set_reply_open(!reply_open)
	// const toggle_new_reply = () => set_new_reply(!new_reply)

	// const onClickAddComment = () => {
	// 	// toggle_new_reply()
	// 	if(!reply_open) toggle_reply()
	// }

	// const handleKeyPress = (event) => {
	// 	if (event.key === 'Enter' && event.target.value != '') {
	// 		// Handle submitting the comment
	// 		// e.g., call an API to save the comment
	// 		console.log('Submit Comment:', comment);
	// 		setComment('');
	// 		toggle_new_reply()
	// 	}
	// };

	const submitReply = (reply) => {
		window.Module.create_new_comment(reply, json_item.thread_id, json_item.component_id)
		// toggle_new_reply()
	}

	const submitNewComment = (reply) => {
		window.Module.create_new_comment(reply, "new_thread", json_item.component_id)
		// toggle_new_reply()
	}

	const zoomToFit = () => {
		window.Module.zoom_to_fit_specific_element(json_item.component_id)
	}

	return(
		<div style={{textAlign:'left', fontSize:'12px'}}>
			<div style={{padding:'12px'}}>
				<div className='flex_property mb-2 justify-content-between'>
					<div className='flex_property' style={{width:'calc( 100% - 48px )'}}>
						<div style={{width:'24px', height:'24px', borderRadius:'50%', backgroundColor: COLORS.gray3, textTransform: 'capitalize'}} className='mr-3 flex_center'>{json_item.comment_user ? json_item.comment_user[0] : '-'}</div>
						<div style={{width: 'calc( 100% - 48px )'}} className='lines1_elipsis' title={json_item.comment_user ? json_item.comment_user : ''}>{json_item.comment_user ? json_item.comment_user : ''}</div>
					</div>
					<img src='/resources/comments_icons/zoom to selected.svg' width={'24px'} height={'24px'} style={{cursor: 'pointer'}} onClick={zoomToFit} title='Zoom to Commented Element'></img>
				</div>
				{/* <div className='flex_property mb-2' onClick={zoomToFit} style={{cursor:'pointer'}}>
					<div className='flex_property mr-2 p-1' style={{borderRadius:'4px', backgroundColor: COLORS.gray2}}>
						<img className='mr-1' src='/resources/comments_icons/3Dobject_small.svg'></img>
						<div>3d object</div>
					</div>
					<div className='lines1_elipsis' title={json_item.comment_path} style={{color:COLORS.gray4, fontSize:'10px', width:'calc(100% - 100px)', textAlign: 'right', fontWeight:500}}>
						{json_item.comment_path}
					</div>
				</div> */}
				{
					json_item.thread_id ? 
						<div className='mb-2' title={json_item.comment}>
							{json_item.comment}
						</div>
						:
						<CommentBox placeholder={"Enter the comment here"} common_onchange_handler={common_onchange_handler} autoFocus onSubmit={submitNewComment}/>
				}
				
				{
					json_item.thread_id ?
						<div className='flex_property justify-content-between cp'>
							<div className='flex_property'>
								<div onClick={toggle_reply} style={{ color: COLORS.blue6, fontSize: '10px' }} className='mr-3'>{`${reply_open ? "Close Thread" : "Open Thread "} (${json_item.replies ? json_item.replies.length : 0})`}</div>
								{/* <img onClick={onClickAddComment} src='/resources/comments_icons/add_icon_small.svg'></img> */}
							</div>
							<div>{timeElapsed(json_item.created_at ? json_item.created_at : Date.now())}</div>
						</div>
						: ''
				}
			</div>
			{
				json_item.thread_id ?
					<Collapse isOpen={reply_open} style={{ width: '100%', overflowX: 'hidden', opacity: 1, backgroundColor: '#F5F6F8' }} className='px-3 py-2'>
						{
							json_item.replies && json_item.replies.length ? json_item.replies.map((reply, idx) => (
								<div className='p-2' style={{ borderBottom: '1px solid ' + COLORS.gray3 }}>
									<div style={{ color: COLORS.gray5, fontWeight: 500 }} className='flex_property justify-content-between mb-2'>
										<div className='lines1_elipsis' style={{ maxWidth: 'calc( 100% - 56px )', fontStyle: 'italic' }} title={reply.reply_user}>{reply.reply_user}</div>
										<div style={{ fontSize: '10px' }}>{timeElapsed(reply.created_at)}</div>
									</div>
									<div style={{ fontSize: '11px' }}>
										{reply.reply}
									</div>
								</div>
							))
								: ''
						}
						{
							// new_reply ?
							<CommentBox placeholder={"Enter the comment here"} common_onchange_handler={common_onchange_handler} autoFocus onSubmit={submitReply} />
							// :''
						}
					</Collapse>
					:
					''
			}
		</div>
	)
}

const ImageMapSelect = ({json_item, on_click, common_onchange_handler, icon_on_click}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	const [value, set_value] = useState('');
	const [img_src, set_img_src] = useState('');
	const [options, set_options] = useState([]);

	useEffect(() => {
		let url;
		if(json_item){
			set_value(json_item.default_value)
			if(json_item.icon !== img_src){
				url = (json_item.icon.includes('FS') ? get_image_url_from_FS(json_item.icon) : json_item.icon)
				set_img_src(url)
			}
		}
		return () => {
			if(json_item.icon.includes('FS') && url){
				URL.revokeObjectURL(url)
			}
		}
	}, [json_item.default_value, json_item.icon]);

	useEffect(() => {
		let options_local;
		if(json_item.options){
			options_local = (json_item.options.map(option => {
				let option_copy = JSON.parse(JSON.stringify(option))
				option_copy.icon = option_copy.icon.includes('FS') ? get_image_url_from_FS(option_copy.icon) : option_copy.icon
				return option_copy
			}))
			set_options(options_local)
		}

		return () => {
			if(json_item.options && options_local){
				for(let i in json_item.options){
					if(json_item.options[i].icon.includes('FS') && options_local[i] && options_local[i].icon){
						URL.revokeObjectURL(options_local[i].icon)
					}
				}
			}
		}
	}, [json_item.options]);

	useEffect(() => {
		if(value && value != json_item.default_value){
			on_click()
		}
	}, [value]);

	//TODO: Can further extend the styling based on the requirement
	
	return (
		<div className='px-2' style={{ paddingTop: '15px' }}>
			<input style={{display: 'none'}} value={value} id={json_item.id}/>
			<div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
				{json_item.image_placement === 'inline_left' ? <img className='mr-2' src={img_src} height={'32px'} style={{maxWidth:'64px', objectFit: 'cover', border: '1px solid ' + COLORS.gray9, borderRadius: '2px'}}></img> : ''}
				<Dropdown className='class_position_unset' isOpen={dropdownOpen} style={{ all: 'unset', listStyle: 'none', flex: 1, minWidth: '40px' }} toggle={json_item && json_item.disabled ? null : toggle}>
					<DropdownToggle style={{ display: 'flex', flexFlow: 'row', backgroundColor: 'white', cursor: 'pointer', color: 'black', fontSize: '12px', padding: '4px 12px', border: '1px solid  ' + COLORS.panel_item_borders, alignItems: 'center', width: '100%', boxShadow: 'none' }}>
						<div style={{ display: 'flex', flexFlow: 'row', fontSize: '12px', alignItems: 'center', flex: 1, minWidth: 0, overflow: 'auto'}}>
							<div title={value && json_item.options && json_item.options.find(x => x.value == value) ? json_item.options.find(x => x.value == value).name : ''} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{value && json_item.options && json_item.options.find(x => x.value == value) ? json_item.options.find(x => x.value == value).name : ''}</div>
						</div>
						<span><i className='fa fa-caret-down' /></span>
					</DropdownToggle>
					<DropdownMenu className="image_map_select_dropdown">
						{options && options.map((option, idx) => (
							<DropdownItem title={option.name} style={{ listStyle: 'none', padding: '4px' }} onClick={() => {common_onchange_handler(); set_value(option.value) }} key={idx}>
								<div className='flex_property'>
									<img className='mr-2'  height={'40px'} width={'80px'} style={{objectFit: 'cover'}} src={option.icon} ></img>
									<div className='lines1_elipsis'>{option.name}</div>
								</div>
							</DropdownItem>
						))}
					</DropdownMenu>
				</Dropdown>
				{
					json_item.buttons && json_item.buttons.length ? <ButtonsArray button_list={json_item.buttons} icon_on_click={icon_on_click} style={{marginLeft: '8px'}}/> : ''
				}
			</div>
			{!json_item.image_placement ? <img className='mt-2' src={img_src} width={'100%'} style={{border: '1px solid ' + COLORS.gray9, borderRadius: '2px'}}></img> : ''}
		</div>
	)
}

const Slider = ({json_item, on_click, common_onchange_handler, icon_on_click, slider_type}) => {
	const [value, setValue] = useState(0);

	const handleChange = (event) => {
		common_onchange_handler()
		let val = event.target.value ? parseFloat(event.target.value) : 0
		setValue(String(val));
	};

	useEffect(() => {
		if(json_item.mixed){
			setValue('')
		}
		else if(json_item.default_value){
			setValue(json_item.default_value)
		}else{
			setValue(0)
		}
	}, [json_item.default_value]);

	return (
		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'II----------II'}>
			<CardBody style={{ padding:'0px 15px',/* paddingTop:index ? 0 : 15,*/ backgroundColor:COLORS.panel_card_item}} >
			<Row>
				<Col style={{fontSize:12,alignItems:"center", cursor:"default", paddingLeft:'8px', marginBottom:'12px', fontWeight:500, color:COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name ? json_item.name : ''}</span></Col>
			</Row>
			<Row style={{/*marginBottom:'15px'*/}}>
				<Col xs={slider_type?12:8} style={{fontSize:14,display:json_item.onchange_route ? 'flex' : 'none',alignItems:"center", padding:'0px 8px'}}>
					{/* <div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}> */}
						{/* <div className='createcard_input_container' style={{display:json_item.onchange_route ? 'flex' : 'none', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}> */}
							{/* <div style={{flex:1, color:json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name}</div> */}
							<div style={{width:'100%'}}>
								<div className='flex_property'>
									<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
									<input
										type="range"
										min={json_item.min ? json_item.min : 0}
										max={json_item.max ? json_item.max : 100}
										step={json_item.step? json_item.step: 1}
										value={value}
										onChange={handleChange}
										onMouseUp={on_click}
										className='slider'
									/>
									<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
								</div>
								{slider_type ?
								<div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
								<span>{'left'}</span>
								<span>{'right'}</span>
								</div>:
								<div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
									<span>{json_item.min ? json_item.min : 0}</span>
									<span>{json_item.max ? json_item.max : 100}</span>
								</div>}
							</div>
						{/* </div> */}
					{/* </div> */}
				</Col>
				<Col xs={4} style={{fontSize:12,display: slider_type?'none':'flex',alignItems:"center", justifyContent:'flex-end', padding:'0px 8px'}}>
					{/* <div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15 }}> */}
						<div className='createcard_input_container' style={{ display: json_item.onchange_route ? 'flex' : 'none', width: '100%', border: '1px solid ' + COLORS.panel_item_borders, padding: '0px 4px' }}>
							<input key={"key_" + json_item.id} id={json_item.id} value={value} onChange={(e) => {handleChange(e)}} onBlur={(e) => { on_click() }} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = json_item.mixed ? '' : (json_item.default_value || 0); e.target.blur() } }} type="number" style={{ ...styles_panel.input_styles, border: 'none', flex: 5, height: '22px', outline: 'none', display: json_item.onchange_route ? 'block' : 'none' }} placeholder={json_item.mixed ? "Mixed" : ''}/>
						</div>
					{/* </div> */}
				</Col>
			</Row>
			</CardBody>
		</Card>
	)
}

const NumericInput = ({json_item, on_click, common_onchange_handler, is_grouped, configuration_type}) => {

	const [value, setValue] = useState(0);
	const [input_changed, set_input_changed] = useState(false);

	const handleChange = (event) => {
		set_input_changed(true)
		common_onchange_handler()
		setValue(String(parseFloat(event.target.value ? event.target.value : 0)));
	};

	useEffect(() => {
		set_input_changed(false)
		if(json_item.default_value){
			setValue(json_item.default_value ? String(Math.floor(parseFloat(json_item.default_value))) : 0)
		}else{
			setValue(0)
		}
	}, [json_item.default_value]);

	return (
		<Card key={json_item.id} style={{ border: '0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }} className={json_item.disabled ? 'panel_disabled_opacity' : 'TI----------TI'}>
			<CardBody style={{ padding: '15px', paddingBottom: 0, backgroundColor: COLORS.panel_card_item }} >
				{
					is_grouped ?
						<div style={{ maxWidth: '100%' }}>
							<div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none' }}>
								<div style={{ display: 'flex', width: '100%', padding: '0px 0px' }}>
									<div className='lines1_elipsis' style={{ color: json_item.color ? json_item.color : COLORS.gray6, fontSize: '12px', fontWeight: 300 }} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
									{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
								</div>
							</div>
							<div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15 }}>
								<div className='createcard_input_container' style={{ display: 'flex', width: '100%', border: '1px solid ' + COLORS.panel_item_borders, padding: '0px 4px', overflow: 'auto' }}>
									<div style={{ flex: 1, color: json_item.color ? json_item.color : COLORS.gray6, fontSize: '12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>{json_item.name ? json_item.name[0] : ''}</div>
									{
										<div className={json_item.numeric_integer_input ? 'panel_numeric_integer_input flex_property' : ''} data-decimal={json_item.numeric_integer_input ? json_item.default_value ? json_item.default_value % 1 != 0 ? String((json_item.default_value % 1).toFixed(2)).substring(1) : '' : '' : ''}>
											<Input disabled={json_item.disabled} type="text" onChange={handleChange} onBlur={on_click} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { setValue((json_item.default_value) || ''); e.target.blur() } }} value={value} key={"key_" + json_item.id + "display"} id={json_item.id + "display"} style={{ ...styles_panel.input_styles, border: 'none', width: `${(value.length * 7) + 7}px` , height: '24px', minWidth: '10px' }} placeholder="0"></Input>
											<Input disabled={json_item.disabled} type="text" value={input_changed ? value : json_item.default_value} key={"key_" + json_item.id} id={json_item.id} style={{ display: 'none' }} placeholder="0"></Input>
										</div>
									}
								</div>
							</div>
						</div>
						:
						<Row>
							<Col xs={6} style={{ fontSize: 12, display: 'flex', alignItems: "center", cursor: "default", paddingLeft: '8px', paddingRight: '8px' }}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
							<Col xs={6} style={{ fontSize: 12, display: 'flex', alignItems: "center", paddingLeft: '8px', paddingRight: '8px' }}>
								<div className='createcard_input_container' style={{ border: '1px solid ' + COLORS.panel_item_borders, overflow: 'auto' }}>
									<div className={json_item.numeric_integer_input ? 'panel_numeric_integer_input flex_property' : ''} data-decimal={json_item.numeric_integer_input ? json_item.default_value ? json_item.default_value % 1 != 0 ? String((json_item.default_value % 1).toFixed(2)).substring(1) : '' : '' : ''}>
										<Input disabled={json_item.disabled} key={"key_" + json_item.id + "display"} onChange={handleChange} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = (json_item.default_value) || ''; e.target.blur() } }} value={value} type="text" id={json_item.id + "display"} style={{ ...styles_panel.input_styles, border: 'none', width: `${(value.length * 7) + 7}px` }} placeholder="0"></Input>
										<Input disabled={json_item.disabled} type="text" value={input_changed ? value : json_item.default_value} key={"key_" + json_item.id} id={json_item.id} style={{ display: 'none' }} placeholder="0"></Input>
									</div>
								</div>
							</Col>
						</Row>
				}
			</CardBody>
		</Card>
	)
}

const DynamicComponent = ({json_item, icon_on_click, handle_ui_response, update_view, is_panel_pop_up, on_click, set_page_loader, inside_tab}) => {
	const [edit_mode, set_edit_mode] = useState(false);
	const [triggered_by, set_triggered_by] = useState({esc: false});
	const [img_src, set_img_src] = useState('');
	useEffect(() => {
		let url = (get_image_url_from_FS(json_item.icon))
		set_img_src(url)

		return () => {
			if(url){
				URL.revokeObjectURL(url)
			}
		}
	}, [json_item]);

	const alert = useAlert()

	const handleKeyDown = (e) => {
		if (e.key === 'Enter'){
			e.target.blur()
		}else if(e.key === 'Escape') {
			triggered_by.esc = true
			e.target.blur()
		}
	}

	const handleOnBlur = async (e) => {
		var form = {};
		if(!triggered_by.esc){
			var promise_resp;
			try{
				form[json_item.id] = e.target.value
				if(window.debug_mode){
					window.add_debug_log(json_item.route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
				}
				promise_resp = window.Module[ json_item.route](JSON.stringify(form));
			}catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu onclick error at " + json_item.route + " : " + err.name
				console.error(err);
				window.sentry_capture_exception("",err);
				alert.error("Something went wrong")
				set_page_loader({
					show: false,
					text: 'Please wait'
				});
				return;
				
			}
			var ui_response = await window.Promisify(promise_resp);
			
			handle_ui_response(ui_response);
			// repopulate_panel_pop_up();
			is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			update_view()
		}else{
			triggered_by.esc = false
		}
		// mode == 'search' ? populate_panel_search(search_string_panel) :''
		set_edit_mode(false);

	}

	const onClickDropdownItem = (...props) => {
		console.log("all props", ...props);
	}

	const getDropdownOptions = (dropdown_buttons) => {
		try{
			let dropdown_buttons_copy = []
			if(dropdown_buttons && Array.isArray(dropdown_buttons)){
				dropdown_buttons_copy = JSON.parse(JSON.stringify(dropdown_buttons))
			}
			let rename_component = {
				icon: "/resources/view_bar_icons/rename_view.svg",
				display_name: "Rename Component",
				customOnClick: (e) => { set_edit_mode(true) }
			}
			if(json_item && json_item.allow_text_edit){
				dropdown_buttons_copy.splice(0,0,rename_component)
			}
			return dropdown_buttons_copy
		}catch(err){
			console.error(`Error in getDropdownOptions `, err)
			handleError(err);
		}

		return dropdown_buttons
	}

	// const get_dropdown_options = () => {
	// 	let options = json_item && json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) => {
	// 		return {
	// 			key: item.name,
	// 			route: item.route,
	// 		label: item.divider ?
	// 		<div className='sheet-dropdown-divider'></div>
	// 		:
	// 		<div onClick={(e) => {e.stopPropagation(); icon_on_click({ route: item.onclick, form: { id: item.name } });}}>
	// 			<div style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}}>
	// 				<img src={item.icon} style={{height: '16px', width: '21px'}} />
	// 				<div className='options_font'>{item.name}</div>
	// 			</div>
	// 		</div>
	// 		}
	// 	})
	// 	:[]

	// 	return {items: options, onClick: onClickDropdownItem}

	// }

	useEffect(() => {
		if(edit_mode){
			let input = document.getElementById("rename" + json_item.id);
			if(input){
				input.focus()
				const inputLength = input.value.length;
    			input.setSelectionRange(inputLength, inputLength);
			}
		}
	}, [edit_mode]);

	return (
		<div key={"key_" + json_item.id} id={json_item.id + "_button"} className={json_item.disabled ? 'panel_disabled' : json_item.highlight_and_fade ? 'BI----------BI highlight_and_fade_bi' : 'BI----------BI'} onClick={json_item.disabled ? null : on_click} style={{ cursor: 'pointer', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: json_item.route ? '1px solid' : '3px solid', borderBottomColor: !json_item.route ? COLORS.panel_item_separation : inside_tab ? '#E6E9EE' : '#C5C7CF', padding: '8px', backgroundColor: COLORS.panel_card_item, textDecorationColor: json_item.color ? json_item.color : COLORS.black_left_menu }}>
			{
				json_item.icon ? (
					<div style={{ border: '1px solid ' + COLORS.gray9 }}>
						<img height='48px' width='72px' title={json_item.name} src={img_src} />
					</div>
				)
					: ''
			}
			<div className='flex_property pl-2' style={{ display: 'flex', justifyContent: 'space-between', width: json_item.icon ? 'calc( 100% - 74px )' : '100%' }}>
				<div title={edit_mode ? '' : json_item.name} className={'mr-2'} id={json_item.id} style={{ flex: 1, minWidth: '20px', fontSize: '12px', color: json_item.color && json_item.color != '#0078FF' ? json_item.color : json_item.route ? COLORS.black_left_menu : '#A7A8B2', textAlign: 'left' }} >
					{
						!edit_mode ?
							<div className='lines1_elipsis' onDoubleClick={json_item.allow_text_edit ? (e) => {e.stopPropagation(); set_edit_mode(true)} : null}>{json_item.name}</div>
							:
							<Input style={{fontSize: '12px'}} id={"rename" + json_item.id} defaultValue={json_item.name} type="text" onKeyDown={(e) => { handleKeyDown(e) }} onBlur={(e) => { handleOnBlur(e) }} />
					}
				</div>
				{/* {json_item.allow_text_edit ? edit_mode ? '' : <img height='12px' onClick={(e) => {e.stopPropagation(); set_edit_mode(true)}} style={{marginRight:'12px'}} src='/resources/panel_icons_new/pencil_outine.svg' title='Edit Name'></img> : ''} */}
				{/* {
					json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) => (
						<div style={{ marginRight: '12px', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={(e) => { e.stopPropagation(); icon_on_click({ route: item.onclick, form: { id: item.name } }) }}><img key={idx} src={item.icon} title={item.disabled_message ? item.disabled_message : ''} className={item.disabled ? 'panel_disabled' : 'button_panel scale_110'} style={{ height: '17px' }} /></div>
					))
						: ''
				} */}
				<ButtonsArray className='inf-mx-2' button_list={json_item.buttons} icon_on_click={icon_on_click} />
				{/* {
					<AntdDropdown trigger={'click'} placement='bottomRight' menu={get_dropdown_options()}>
						<Button style={{ display: 'flex', alignItems: 'center', background: 'transparent', padding: '4px' , aspectRatio: '1', border: 'none', boxShadow: 'unset'}}>
							<img src='/resources/icons/options.svg'></img>
						</Button>
					</AntdDropdown>
				} */}
				{/* <UncontrolledDropdown>
					<DropdownToggle style={{ display: 'flex', alignItems: 'center', background: 'transparent', padding: '4px' , aspectRatio: '1', border: 'none', boxShadow: 'unset'}}>
						<img src='/resources/icons/options.svg'></img>
					</DropdownToggle>
					<DropdownMenu right container={'body'} className='DMSans' style={{padding: '4px', marginTop: '40px'}}>
						{
							json_item && json_item.dropdown_buttons && json_item.dropdown_buttons.length ? json_item.dropdown_buttons.map((item, idx) => (
								item.divider ?
									// <div className='sheet-dropdown-divider'></div>
									<DropdownItem divider />
									:
									<DropdownItem style={{padding: '6px 12px'}} onClick={(e) => { icon_on_click({ route: item.onclick, form: { id: item.name } }); }}>
										<div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
											<img src={item.icon} style={{ height: '16px', width: '16px' }} />
											<div>{item.display_name}</div>
										</div>
									</DropdownItem>
							))
							:''
						}
						<DropdownItem style={{ padding: '6px 12px' }} onClick={(e) => { set_edit_mode(true) }}>
							<div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
								<img style={{ height: '16px', width: '16px' }} src="/resources/view_bar_icons/rename_view.svg"></img>
								<div>Rename Component</div>
							</div>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown> */}
				<ButtonsDropdown button_list={getDropdownOptions(json_item.dropdown_buttons)} icon_on_click={icon_on_click} />
			</div>
		</div>
	)
}

const ButtonItem = ({json_item, on_click, common_onchange_handler}) => {
	return(
		<div title={json_item.tooltip ? json_item.tooltip : ''} className={json_item.disabled ? 'panel_disabled_opacity' : 'flex_property'} style={{color: '#676878', fontWeight: 600, padding: '4px 8px', fontSize: '12px', textAlign: 'left', gap: '8px', cursor: "pointer"}} onClick={(e) => { e.stopPropagation(); if(!json_item.disabled){on_click()} }}>
			{json_item.icon ? <img src={json_item.icon} style={{ height: '16px' }} /> : ''}
			{json_item.display_name ? <div style={{flex: 1, minWidth: '0px'}} className='lines1_elipsis'>{json_item.display_name}</div> : ''}
		</div>
	)
}

const RotationInput = ({json_item, on_click, common_onchange_handler, icon_on_click}) => {
	const [rotation_input, set_rotation_input] = useState('');
	const [rotation_input_final, set_rotation_input_final] = useState('');

	useEffect(() => {
		if(json_item.default_value){
			set_rotation_input(json_item.default_value)
			set_rotation_input_final(json_item.default_value)
		}
	}, []);

	useEffect(() => {
		if(json_item.mixed){
			set_rotation_input('')
		}else if(json_item.default_value){
			set_rotation_input(json_item.default_value)
		}else{
			set_rotation_input(0)
		}
	}, [json_item.default_value]);

	// useEffect(() => {
	// 	set_rotation_input_final(rotation_input)
	// }, [rotation_input]);

	useEffect(() => {
		if(rotation_input_final !== '' &&  rotation_input_final != json_item.default_value){
			on_click()
		}
	}, [rotation_input_final]);

	return (
		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'RI----------RI'}>
			<CardBody style={{ padding:'15px',paddingBottom:0,/* paddingTop:index ? 0 : 15,*/ backgroundColor:COLORS.panel_card_item}} >
			{
				json_item.onchange_route ?
				<Row>
					<Col style={{fontSize:12,alignItems:"center", cursor:"default", paddingLeft:'8px', marginBottom:'8px', fontWeight:500, color:COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none'}}><span title={json_item.name} className='lines1_elipsis' >{"Rotation(deg)"}</span></Col>
				</Row> : ''
			}
			<input key={"key_"+json_item.id} value={rotation_input_final} type="number"  id={json_item.id} style={{display:'none'}} readOnly />
			<Row style={{/*marginBottom:'15px'*/}}>
				<Col xs={4} style={{fontSize:14,display: 'flex',alignItems:"center"}}>
					<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
						{
							json_item.onchange_route ?
								<div className='createcard_input_container' style={{display:json_item.onchange_route ? 'flex' : 'none', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
									<div style={{flex:1, color:json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name}</div>
									<input key={"key_"+json_item.id + "1"} value={rotation_input} onChange={(e) => {common_onchange_handler(); set_rotation_input(e.target.value)}} onBlur={(e) => {set_rotation_input_final(e.target.value)}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.default_value || 0 ; e.target.blur()}}} type="number"  id={json_item.id + "1"} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px', outline:'none', display:json_item.onchange_route ? 'block' : 'none'}} placeholder={json_item.mixed ? 'Mixed' : ''}/>
								</div>
							: <div style={{fontSize: '12px', color: '#676878'}}>Rotation:</div>
						}
					</div>
				</Col>
				<Col xs={8} style={{fontSize:12,display:'flex',alignItems:"center", justifyContent:'flex-end', padding:'0px 8px'}}>
					{/* {
						json_item.buttons && json_item.buttons.length ? json_item.buttons.map(item => (
							<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); window.Module[item.onclick](JSON.stringify({id: item.name}))}}/>
						))
						:''
					} */}
					<span style={{ marginRight:'8px', fontSize:'10px', maxWidth:'110px', textOverflow:'ellipsis', color:COLORS.gray5}} title={json_item.text_content ? json_item.text_content : ''}>{json_item.onchange_route ? "Rotate by " + (json_item.text_content ? json_item.text_content : '90') + ' deg' : json_item.text_content}</span>
					<img src={"/resources/panel_icons_new/rotate left.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input_final(rotation_input - (json_item.text_content ? json_item.text_content : 90)); /*icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}}) on_click()*/  } : () => {icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}})} }/>
					<img src={"/resources/panel_icons_new/rotate right.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input_final(rotation_input - ('-' + (json_item.text_content ? json_item.text_content : 90))); /*icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}}) on_click()*/ } : () => {icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}})}}/>

				</Col>
			</Row>
			</CardBody>
		</Card>
	)
}

const TextInfo = ({json_item, icon_on_click, handle_ui_response, is_panel_pop_up, update_view, is_section}) => {
	const [edit_mode, set_edit_mode] = useState(false);
	const alert = useAlert()

	const handleOnBlur = async (e) => {
		var form = {};
		var promise_resp;
		try{
			form[json_item.id] = e.target.value;
			if(window.debug_mode){
				window.add_debug_log(json_item.onchange_route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
			}
			promise_resp = window.Module[ json_item.onchange_route](JSON.stringify(form));
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Panel menu onclick error at " + json_item.onchange_route + " : " + err.name
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			// set_page_loader({
			// 	show: false,
			// 	text: 'Please wait'
			// });
			return;
			
		}
		var ui_response = await window.Promisify(promise_resp);
		
		handle_ui_response(ui_response);
		// repopulate_panel_pop_up();
		is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
		// mode == 'search' ? populate_panel_search(search_string_panel) :''
		set_edit_mode(false);
		update_view()

	}

	useEffect(() => {
		if(edit_mode){
			let input = document.getElementById("rename" + json_item.id);
			if(input){
				input.focus()
				const inputLength = input.value.length;
    			input.setSelectionRange(inputLength, inputLength);
			}
		}
	}, [edit_mode]);

	return (
		<div style={{height: is_section ? '' : '36px', fontSize: '12px', textAlign:'left', background: json_item.background_color ? json_item.background_color : COLORS.panel_card_item}} className='flex_property'>
			{
				json_item.editable ?
				!edit_mode ?
					<div className='flex_property' style={{padding:is_section ? '0px' : '4px 8px', width:'100%', height:'100%', cursor:'pointer'}}>
						<div style={{width: 'calc( 100% - 24px )', color: 'var(--inf-theme-gray-600)'}} className='lines1_elipsis inf-font-medium' title={json_item.name ? json_item.name : ''} >Mesh: {json_item.name ? json_item.name : '<No Name>'}</div>
						{/* <img height='12px' className='ml-2' src='/resources/panel_icons_new/pencil.svg'></img> */}
						<div style={{cursor:'pointer'}} onClick={() => {set_edit_mode(true) }}>
							{/* <PencilSvg className='ml-2 pencil-svg' /> */}
							<Icons name={'edit'} title={json_item.edit_tooltip ? json_item.edit_tooltip : "Edit name"} style={{ fontSize: '16px', cursor: 'pointer' }} className='blue_hover ml-2' />
						</div>
					</div>
					:
					<div style={{padding: '4px 8px', width:'100%', height:'100%'}}>
						<Input id={'rename' + json_item.id} className="rename_group" style={{fontSize: '12px'}} defaultValue={json_item.name} type="text" onKeyDown={(e) => { (e.key === 'Enter' || e.key === 'Escape') && (e.target.blur()); }} onBlur={(e) => { handleOnBlur(e) }} />
					</div>
				:
				<div className='lines1_elipsis' title={json_item.name ? json_item.name : ''} style={{padding:'4px 8px', width:'100%', height:'100%'}}>{json_item.name ? json_item.name : 'No Name'}</div>	
			}
		</div>
		
	)
}

const PresentationSheet = ({json_item, icon_on_click, on_click, inside_tab, is_draggable}) => {
	const [img_src, set_img_src] = useState('');
	const [prev_img_src, set_prev_img_src] = useState('');
	useEffect(() => {
		let url = (get_image_url_from_FS(json_item.icon));
		set_prev_img_src(img_src);
		set_img_src(url)
	}, [json_item]);

	useEffect(() => {
		if(prev_img_src){
			// Revoke prev url when new url is set
			URL.revokeObjectURL(prev_img_src)
		}
	}, [prev_img_src]);

	useEffect(() => {
		return () => {
			if(img_src){
				// Revoke current url when component is destroyed
				URL.revokeObjectURL(img_src)
			}
		}
	}, []);
	return (
		<div key={"key_" + json_item.id} id={json_item.id + "_button"} className={json_item.disabled ? 'panel_disabled' : json_item.route ? 'PS----------PS' : 'PS----------PS'} onClick={json_item.disabled ? null : on_click} style={{ cursor: 'pointer', width: '100%', height: '100px', display: 'flex', borderBottom: json_item.route ? '1px solid' : '3px solid', borderBottomColor: !json_item.route ? COLORS.panel_item_separation : inside_tab ? '#E6E9EE' : '#C5C7CF', padding: '8px', backgroundColor: COLORS.panel_card_item, textDecorationColor: json_item.color ? json_item.color : COLORS.black_left_menu, outline: json_item.active_sheet ? '1.6px solid ' + COLORS.blue6 : 'none', outlineOffset: '-1.6px' }}>
			{
				is_draggable ?
				<img style={{marginRight:'10px'}} src='/resources/icons/drag_handle.svg'/> : ''
			}
			{
				json_item.icon ? (
					<div style={{ paddingRight: '2px' }}>
						<img height='84px' width='120px' style={{ border: '1px solid ' + COLORS.gray9 }} title={json_item.name} src={img_src} />
					</div>
				) : ''
			}
			<div className='py-1 pl-2' style={{ display: 'flex', width: json_item.icon ? 'calc( 100% - 64px )' : '100%', height: '100px', flexDirection: 'column', justifyContent: "center" }}>
				<div title={json_item.name} className={'lines1_elipsis'} id={json_item.id} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '12px', color: json_item.color && json_item.color != '#0078FF' ? json_item.color : json_item.route ? COLORS.black_left_menu : '#A7A8B2', textAlign: 'left'}} >
					<div style={{display: 'flex', alignItems: 'center'}}>
						{json_item.name}
					</div>
					<div className=''>
						{json_item.buttons && json_item.buttons.length ?
						<UncontrolledDropdown className='class_position_unset'>
							<DropdownToggle className='show_on_hover_sheets' outline style={{ outline: 'unset', padding: 'unset', border: 'unset', boxShadow: 'unset', display: 'flex', alignItems: 'center', background: 'transparent', padding: '2px 6px 2px 2px', maxHeight: '24px', aspectRatio: '1'}} onClick={e => {e.stopPropagation(); var analytics_data = {}; analytics_data.category = "Sheet Image Options opened"; send_analytics(analytics_data,true);}}>
								<img src='/resources/icons/options.svg'></img>
							</DropdownToggle>
							<DropdownMenu container='body' style={{position: 'absolute', marginTop: '54px', paddingTop: '6px', paddingBottom: '6px'}}>
								{
									json_item.buttons.map((item, idx) => (
										item.type == 'divider' ?
										<div className='sheet-dropdown-divider'></div>
										:
										<DropdownItem style={{padding: '6px 10px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({ route: item.route, form: { id: item.id, sheet_id: json_item.sheet_id }, item: item});}}>
											<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px'}}>
												<img src={item.icon} style={{height: '20px', width: '20px'}} />
												<div className='options_font'>{item.name}</div>
											</div>
										</DropdownItem>
									))
								}
							</DropdownMenu>
						</UncontrolledDropdown>
						: ''}
					</div>
				</div>
			</div>
		</div>
	)
}

// const DynamicComponent = ({json_item, on_click, icon_on_click, inside_tab}) => {
// 	const [img_src, set_img_src] = useState('');
// 	useEffect(() => {
// 		let url = (get_image_url_from_FS(json_item.icon))
// 		set_img_src(url)

// 		return () => {
// 			if(url){
// 				URL.revokeObjectURL(url)
// 			}
// 		}
// 	}, [json_item]);
// 	return (
// 		<div key={"key_" + json_item.id} id={json_item.id + "_button"} className={json_item.disabled ? 'panel_disabled' : json_item.route ? 'BI----------BI' : 'BI----------BI'} onClick={json_item.disabled ? null : on_click} style={{ cursor: 'pointer', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: json_item.route ? '1px solid' : '3px solid', borderBottomColor: !json_item.route ? COLORS.panel_item_separation : inside_tab ? '#E6E9EE' : '#C5C7CF', padding: '8px', backgroundColor: COLORS.panel_card_item, textDecorationColor: json_item.color ? json_item.color : COLORS.black_left_menu }}>
// 			{
// 				json_item.icon ? (
// 					<div style={{ border: '1px solid ' + COLORS.gray9 }}>
// 						<img height='48px' width='72px' title={json_item.name} src={img_src} />
// 					</div>
// 				)
// 					: ''
// 			}
// 			<div className='flex_property pl-2' style={{ display: 'flex', justifyContent: 'space-between', width: json_item.icon ? 'calc( 100% - 74px )' : '100%' }}>
// 				<div title={json_item.name} className={'lines1_elipsis'} id={json_item.id} style={{ fontSize: '12px', color: json_item.color && json_item.color != '#0078FF' ? json_item.color : json_item.route ? COLORS.black_left_menu : '#A7A8B2', textAlign: 'left' }} >{json_item.name}</div>
// 				{
// 					json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) => (
// 						<div style={{ marginRight: '12px', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={(e) => { e.stopPropagation(); icon_on_click({ route: item.onclick, form: { id: item.name } }) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/ }}><img key={idx} src={item.icon} title={item.disabled_message ? item.disabled_message : ''} className={item.disabled ? 'panel_disabled' : 'button_panel scale_110'} style={{ height: '17px' }} /></div>
// 					))
// 						: ''
// 				}
// 			</div>
// 		</div>
// 	)
// }

const TabDropdown = ({json_item, icon_on_click, panel_key, set_page_loader, handle_ui_response, metric_system, approximation_mm, approximation_ft, update_view, set_panel_has_unapplied_edits, approxMM, mmToFeetParts, panel_explode, tab_on_click, is_panel_pop_up, mode, panel_search_json, is_search_mode, set_is_search_mode, search_string_panel, set_search_string_panel, panel_search_string, onSubmitCallback, is_section}) => {
	const [active_tab, set_active_tab] = useState('');
	// const [active_tab_index, set_active_tab_index] = useState(-1);
	const [tab_count, set_tab_count] = useState(0);

	const reset_dropdown_status = (id) =>{
		if(json_item.tabs && json_item.tabs.length){
			json_item.tabs.map((item, idx) => {
				if(item.id == id){
					if(!check_dropdown_state(item.id, mode)){
						tab_on_click(item.id, mode)
					}
				}
			})
			json_item.tabs.map((item, idx) => {
				if(item.id != id){
					if(check_dropdown_state(item.id, mode)){
						tab_on_click(item.id, mode)
					}
				}
			})
		}
	}

	useEffect(() => {
		if(json_item && json_item.tabs && json_item.tabs.length){
			set_tab_count(json_item.tabs.length)
			let open_dropdowns =  json_item.tabs.filter(x => check_dropdown_state(x.id, mode))

			if(open_dropdowns.length == 1){
				set_active_tab(open_dropdowns[0].id)
			}
			else{
				set_active_tab(json_item.tabs[0].id);
				reset_dropdown_status(json_item.tabs[0].id)
			}
		}else{
			set_tab_count(0)
		}
	}, [json_item]);

	// useEffect(() => {
	// 	// if(json_item && json_item.tabs && json_item.tabs.length){
	// 	// 	set_active_tab(json_item.tabs[0].id);
	// 	// 	// tab_on_click(json_item.tabs[0].id);
	// 	// }

	// 	if(json_item && json_item.tabs && json_item.tabs.length){
	// 		let open_dropdowns =  json_item.tabs.filter(x => check_dropdown_state(x.id, mode))

	// 		if(open_dropdowns.length == 1){
	// 			set_active_tab(open_dropdowns[0].id)
	// 		}
	// 		else{
	// 			set_active_tab(json_item.tabs[0].id);
	// 			reset_dropdown_status(json_item.tabs[0].id)
	// 		}
	// 	}

	// }, []);

	return(
		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/, display:json_item.tabs.length ? 'flex' : 'none'}} className={json_item.disabled?'panel_disabled CT----------CT':'CT----------CT'}>
			<CardBody style={{ padding:'15px',paddingBottom:0, paddingTop: is_section ? '0px' : '15px', backgroundColor:COLORS.panel_card_item}} >
			<Row>
				<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
					{
						json_item.tab_configuration == 'dropdown' && json_item.tabs.length ? 
							<div style={{display: 'flex', padding:is_panel_pop_up ? '0px 8px 12px 8px' : !is_section ? '0px 8px' : '0px', width:'100%'}}>
								<div className='createcard_input_container' style={{marginRight:'8px', flex:1, display:'flex'}}>
									<div className='tab_index_panel' style={{}}>{json_item && json_item.tabs ? json_item.tabs.findIndex(item => item.id == active_tab)+1 : 0}/{tab_count}</div>
									<Input type='select' value={active_tab} onChange={(e) => {set_active_tab(e.target.value); reset_dropdown_status(e.target.value)}} style={{...styles_panel.input_styles, border:'none', outline:'none'}}>
										{
											json_item.tabs.map((child_tab, idx) => (
												<option key={idx} value={child_tab.id}>{child_tab.name}</option>
											))
										}
									</Input>
								</div>
								{
										json_item.tabs.map((child_tab, idx) => (
											<div key={idx} style={{display:active_tab == child_tab.id ? 'flex' : 'none', alignItems: 'center'}}>
												{
													child_tab.buttons && child_tab.buttons.length ? child_tab.buttons.map((item, idx) => (
														item.type == "switch" ? 
														<Switch key={idx} disabled={item.disabled} style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
														: item.type == 'label' ? 
														<span key={idx} style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
														: item.icon == "+" ?
														<span key={idx} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize', display:'flex'}} onClick={(e) => {icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}><img style={{marginRight: '4px'}} height='12px' src='/resources/panel_icons_new/blue_add.svg'></img> Add </span>
														:
														<img key={idx} src={item.icon} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{ marginRight:'8px', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
														))
														: ''
												}
											</div>
										))

								}
							</div>
						:

						<div style={{padding: !is_section ? '0px 8px' : '0px', display:'flex', flexFlow: 'row', flexWrap:'wrap', width:'100%', paddingBottom:'6px'}}>
							{
								json_item.tabs.map((child_tab, idx) => (
									<div key={idx} className={child_tab.disabled? 'panel_disabled_opacity':''} style={active_tab == child_tab.id ? {padding:'4px 0px', margin:'4px 8px', fontSize:'12px', fontWeight:700, borderBottom:'1px solid ' + COLORS.blue6, color:COLORS.blue6, display:'flex', cursor:'pointer'} : {padding:'4px 0px', margin:'4px 8px', fontSize:'12px', borderBottom:'1px solid #E6E9EE', color: COLORS.panel_group_heading, display:'flex', cursor:'pointer'}} onClick={active_tab == child_tab.id || child_tab.disabled ? null : () => {set_active_tab(child_tab.id); reset_dropdown_status(child_tab.id)}} >
										<div style={{marginRight: '8px'}}>{child_tab.name}</div>
										<div style={{display:active_tab == child_tab.id ? 'flex' : 'none', alignItems: 'center'}}>
										{
											child_tab.buttons && child_tab.buttons.length ? child_tab.buttons.map((item, idx) => (
												item.type == "switch" ? 
												<Switch key={idx} disabled={item.disabled} style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
												: item.type == 'label' ? 
												<span key={idx} style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
												: item.icon == "+" ?
												<span key={idx} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize', display:'flex'}} onClick={(e) => {icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}><img style={{marginRight: '4px'}} height='12px' src='/resources/panel_icons_new/blue_add.svg'></img> Add </span>
												// <span key={idx} style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
												:
												<img key={idx} src={item.icon} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{ marginRight:'8px',height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
												))
												: ''
										}
										</div>
									</div>
									))
							}
						</div>
					}
				</Col>

				{
					json_item.tabs.map((child_tab, idx) => (
						<div key={idx} className='d--------------------d' style={{ width:'100%', overflowX: 'hidden', padding: is_panel_pop_up ? '0px' : !is_section ? '8px' : '8px 0px', display:active_tab == child_tab.id ? 'block' : 'none', borderBottom:'1px solid #c5c7cf'}}>
							<Nav navbar style={{width:'100%', overflowX: 'hidden', backgroundColor: is_panel_pop_up ? '' : COLORS.gray2, borderRadius:'6px', border: '1px solid #e6e9ee' }}>
								
								{
									(!child_tab.disabled) && child_tab.submenu && child_tab.submenu.length ? child_tab.submenu.map((sub_item,idx) =>(
										<NavItem key={child_tab.id+idx} style={{textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
											<Create_card 
												panel_key={panel_key}
												set_page_loader={set_page_loader}
												handle_ui_response={handle_ui_response}
												key={child_tab.id+idx}
												metric_system={metric_system}
												approximation_mm={approximation_mm}
												approximation_ft={approximation_ft}
												parent={child_tab}
												parent_id={child_tab.id}
												parent_menu={child_tab.submenu}
												update_view={update_view}
												json_item={sub_item}
												set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
												approxMM={approxMM}
												mmToFeetParts={mmToFeetParts}
												panel_explode={panel_explode}
												is_grouped={child_tab.group_child_inputs}
												index={idx}
												configuration_type={child_tab.configuration_type}
												inside_tab={true}
                                                is_panel_pop_up={is_panel_pop_up}
												panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
												mode={mode}
                                            />
										</NavItem>
									)):''
									
								}
								{/* {
									json_item.submenu && json_item.submenu.length && !(json_item.submenu[json_item.submenu.length-1].type == 'list-item' || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button') ?
										<Create_card 
											panel_key={panel_key}
											set_page_loader={set_page_loader}
											handle_ui_response={handle_ui_response}
											metric_system={metric_system}
											approximation_mm={approximation_mm}
											approximation_ft={approximation_ft}
											// parent_menu={panel_json}
											update_view={update_view}
											json_item={{type:'margin_bottom'}}
											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
											approxMM={approxMM}
											mmToFeetParts={mmToFeetParts}
											panel_explode={false}
										/>
									:''
								}	 */}
							</Nav>
						</div>
					))

				}
			</Row>
			</CardBody>
		</Card>
	)
}

const TabToolbar = ({json_item, child_menu, panel_key, set_page_loader, handle_ui_response, metric_system, approximation_mm, approximation_ft, update_view, set_panel_has_unapplied_edits, approxMM, mmToFeetParts, panel_explode, is_panel_pop_up, tab_on_click, index, panel_search_json, is_search_mode, set_is_search_mode, search_string_panel, set_search_string_panel, panel_search_string}) => {
	const [active_tab, set_active_tab] = useState('');
	const [edit_mode, set_edit_mode] = useState(false);
	const [triggered_by, set_triggered_by] = useState({esc: false});
	// const [search_string_panel, set_search_string_panel, panel_search_string] = useState('');
	// const reset_dropdown_status = (id) =>{
	// 	if(json_item.tabs && json_item.tabs.length){
	// 		json_item.tabs.map((item, idx) => {
	// 			if(item.id == id){
	// 				if(!check_dropdown_state(item.id)){
	// 					tab_on_click(item.id)
	// 				}
	// 			}
	// 		})
	// 		json_item.tabs.map((item, idx) => {
	// 			if(item.id != id){
	// 				if(check_dropdown_state(item.id)){
	// 					tab_on_click(item.id)
	// 				}
	// 			}
	// 		})
	// 	}
	// }

	const populate_panel_search = async(search_string) => {
		// var active_design = window.Module.get_active_design();
        // var active_view = active_design.active_view;
		var promise_resp;
		if(window.Module){
			try{
				promise_resp = window.Module.populate_panel_search(JSON.stringify({"search": search_string}));
			}
			catch(err){
				// alert.error("Something went wrong with search")
				return
			}
		}

		var ui_response = await window.Promisify(promise_resp);
		handle_ui_response(ui_response);

		update_view()

	}

	const reset_dropdown_status = (id, mode) =>{
		if(json_item.tabs && json_item.tabs.length){
			json_item.tabs.map((item, idx) => {
				if(item.id == id){
					if(!check_dropdown_state(item.id, mode)){
						tab_on_click(item.id, mode)
					}
				}
			})
			json_item.tabs.map((item, idx) => {
				if(item.id != id){
					if(check_dropdown_state(item.id, mode)){
						tab_on_click(item.id, mode)
					}
				}
			})
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter'){
			e.target.blur()
		}else if(e.key === 'Escape') {
			triggered_by.esc = true
			e.target.blur()
		}
	}

	const handleOnBlur = async (e) => {
		var form = {};
		if(!triggered_by.esc){
			var promise_resp;
			try{
				form[json_item.id] = e.target.value
				if(window.debug_mode){
					window.add_debug_log(json_item.route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
				}
				promise_resp = window.Module[ json_item.route](JSON.stringify(form));
			}catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu onclick error at " + json_item.route + " : " + err.name
				console.error(err);
				window.sentry_capture_exception("",err);
				alert.error("Something went wrong")
				set_page_loader({
					show: false,
					text: 'Please wait'
				});
				return;
				
			}
			var ui_response = await window.Promisify(promise_resp);
			
			handle_ui_response(ui_response);
			// repopulate_panel_pop_up();
			is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			update_view()
		}else{
			triggered_by.esc = false
		}
		// mode == 'search' ? populate_panel_search(search_string_panel) :''
		set_edit_mode(false);

	}

	useEffect(() => {
		// if(json_item && json_item.tabs && json_item.tabs.length){
		// 	set_active_tab(json_item.tabs[0].id);
		// 	// tab_on_click(json_item.tabs[0].id);
		// }

		if(is_search_mode){
			set_active_tab('search');
			if(document.getElementById('panel_search_input')){
				document.getElementById('panel_search_input').focus()
			}
		}else{
			if(json_item && json_item.tabs && json_item.tabs.length){
				let open_dropdowns =  json_item.tabs.filter(x => check_dropdown_state(x.id, index))

				if(open_dropdowns.length == 1){
					set_active_tab(open_dropdowns[0].id)
				}
				else{
					set_active_tab(json_item.tabs[0].id);
					reset_dropdown_status(json_item.tabs[0].id, index)
				}
			}
		}
		// if(json_item && json_item.tabs && json_item.tabs.length){
		// 	set_active_tab(json_item.tabs[0].id)
		// }

	}, [is_search_mode]);

	useEffect(() => {
		child_menu && child_menu.length ? child_menu.map(child_tab => {
			if(!child_tab.tab_ids || !child_tab.tab_ids.length){
				if(window.debug_mode){
					console.log("missing ", child_tab.id)
				}
				// window.Module.tab_id_missing_for_dropdown(JSON.stringify({dropdown_id: child_tab.id}))
				// let err = new Error(err)
				// err.name = "Missing Elements in Panel with id " + child_tab.id
				// window.sentry_capture_exception("",err);

			}
		})
		:''
	}, [child_menu]);

	return(
		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled TT----------TT':'TT----------TT'}>
			<CardBody style={{ padding:'15px',paddingBottom:0, paddingTop:0}} >
			<Row>
				<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center', backgroundColor:"var(--inf-highlight-background-color, #788394)", position:'fixed', width:'268px', zIndex:5, flexWrap:'wrap'}}>
					<div style={{padding:'0px', display:'flex', flexFlow: 'row', flexWrap:'wrap', width:'100%', justifyContent:'space-between'}}>
						<div style={{padding:'0px', display:'flex', flexFlow: 'row', flexWrap:'wrap', flex:1}}>
							{
								json_item.type === 'top_level_tabs' ?
								json_item.tabs.map((child_tab, idx) => (
									<div title={child_tab.id.includes('quick_access') ? 'Quick Access Options' : child_tab.id.includes('customize') ? 'Customize' : child_tab.id.includes('construction') ? 'Construction' : child_tab.id.includes('internal_tree') ? 'Tree' : ''} key={idx} className={is_search_mode ? 'disabled_top_tab_panel' : active_tab == child_tab.id ? "active_top_tab_panel" : child_tab.id.includes("quick_access_") || (child_menu && child_menu.length && child_menu.filter(item => item.tab_ids && item.tab_ids.includes(child_tab.id)).length || child_tab.id === 'internal_tree') ? "top_tab_panel" : "disabled_top_tab_panel"} onClick={is_search_mode ? null : child_tab.id.includes("quick_access_") || (child_menu && child_menu.length && child_menu.filter(item => item.tab_ids && item.tab_ids.includes(child_tab.id)).length || child_tab.id === 'internal_tree') ? active_tab == child_tab.id ? null : () => {set_active_tab(child_tab.id); reset_dropdown_status(child_tab.id, index)} : null}>
										<img src={child_tab.icon}></img>
									</div>
								))
								:
								json_item.type === 'top_level_name' && json_item.editable ?
									!edit_mode ?
									<div className='top_name_panel' onClick={() => {set_edit_mode(true)}}>
										<img height='12px' style={{marginRight:'6px'}} src='/resources/panel_icons_new/pencil.svg'></img>
										<div>{json_item.name ? json_item.name : ''}</div>
									</div>
									: 
									<div className='rename_group_input_container'>
										<Input className="rename_group" defaultValue={json_item.name} type="text" onKeyDown={(e) => {handleKeyDown(e)}} onBlur={(e) => {handleOnBlur(e)}}/>
									</div>
								:
								<div className='top_name_panel'>{json_item.name ? json_item.name : ''}</div>

							}
						</div>
						<div title='Search' className={active_tab == "search" ? "active_top_tab_panel_search" : "top_tab_panel"} onClick={active_tab == "search" ? null : () => {set_active_tab("search"); set_is_search_mode(true); set_panel_search_show(true)}}>
							<img src= {active_tab == 'search' ? '/resources/panel_icons_new/search_selected.svg' : '/resources/panel_icons_new/search.svg'}></img>
						</div>
					</div>
					<div className='PS----------PS' style={{display: active_tab == 'search' ? 'flex' : 'none'}}>
						<div className={'search_component_container_ideal'} style={{width:'100%', margin:0, display: active_tab == 'search' ? 'flex' : 'none', height:'32px'}}>
							<div><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></div>
							<input id='panel_search_input' className={'search_component_inner_container'} placeholder= {'Search any Property'} type='text' value={search_string_panel} onChange={(e) => set_search_string_panel(e.target.value)} onKeyDown={(e) => {if(e.key === 'Enter'){set_search_string_panel(e.target.value.trim()); populate_panel_search(e.target.value.trim())}}}></input>
							<div className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:search_string_panel ? 'visible':'hidden', fontStyle:'italic'}} onClick={() => {set_search_string_panel('');populate_panel_search('')}}>Clear</div>
							{/* <span><i style={{paddingRight:'12px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-times' onClick={clear_search}/></span> */}
							<div className='flex_center' style={{width:'30px', cursor:'pointer', height: '100%', borderLeft: '1px solid #C5C7CF'}} onClick={() => {set_search_string_panel(search_string_panel.trim()); populate_panel_search(search_string_panel.trim())}}>
								<img src='/resources/panel_icons_new/arrow_right.svg'  />
							</div>
						</div>
					</div>
				</Col>
				<Col xs={12} className='zero_padding' style={{height: active_tab == 'search' ? 90 : 40, width:'100%'}}>
					
				</Col>



				{
					child_menu && child_menu.length ? child_menu.map((child_tab, idx) => (
						((child_tab.tab_ids && child_tab.tab_ids.includes(active_tab)) || (json_item.type == 'top_level_name' && active_tab != 'search')) ?
							<Create_card 
								panel_key={panel_key}
								set_page_loader={set_page_loader}
								handle_ui_response={handle_ui_response}
								key={child_tab.id+idx}
								metric_system={metric_system}
								approximation_mm={approximation_mm}
								approximation_ft={approximation_ft}
								update_view={update_view}
								json_item={child_tab}
								set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
								approxMM={approxMM}
								mmToFeetParts={mmToFeetParts}
								panel_explode={panel_explode}
								index={idx}
								is_panel_pop_up={is_panel_pop_up}
								mode={active_tab}
							/>
						:''
					)) : ''

				}
				{
					(json_item.type == 'top_level_tabs' && active_tab.includes("quick_access_")) ? 
						child_menu && child_menu.length ? child_menu.filter(x => (x.quick_access && (x.quick_priority || x.quick_priority == 0))).sort(function(a,b){return a.quick_priority - b.quick_priority}).map((child_tab, idx) => (
							<Create_card 
								panel_key={panel_key}
								set_page_loader={set_page_loader}
								handle_ui_response={handle_ui_response}
								key={child_tab.id+idx}
								metric_system={metric_system}
								approximation_mm={approximation_mm}
								approximation_ft={approximation_ft}
								update_view={update_view}
								json_item={child_tab}
								set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
								approxMM={approxMM}
								mmToFeetParts={mmToFeetParts}
								panel_explode={panel_explode}
								index={idx}
								is_panel_pop_up={is_panel_pop_up}
								mode={"quick_access"}
								quick_dropdown_open={child_tab.quick_dropdown_open}
							/>
						))
						:''
					:''
				}
				{
					(json_item.type == 'top_level_tabs' && json_item.tabs.find(x => x.id === 'internal_tree')) ? 
						// child_menu && child_menu.length ? child_menu.filter(x => (x.quick_access && (x.quick_priority || x.quick_priority == 0))).sort(function(a,b){return a.quick_priority - b.quick_priority}).map((child_tab, idx) => (
							<div style={{display: active_tab === 'internal_tree' ? '' : 'none', width: '100%'}}>
								<Create_card 
									panel_key={panel_key}
									set_page_loader={set_page_loader}
									handle_ui_response={handle_ui_response}
									metric_system={metric_system}
									approximation_mm={approximation_mm}
									approximation_ft={approximation_ft}
									update_view={update_view}
									json_item={
										{
											type: 'tree',
											tree_data: [
												JSON.parse(json_item.tabs.find(x => x.id === 'internal_tree').value ? json_item.tabs.find(x => x.id === 'internal_tree').value : {})
											],
											route: json_item.tabs.find(x => x.id === 'internal_tree').route
										}
									}
									set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
									approxMM={approxMM}
									mmToFeetParts={mmToFeetParts}
									panel_explode={panel_explode}
									is_panel_pop_up={is_panel_pop_up}
									// mode={"internal_tree"}
								/>
							</div>
						// ))
						// :''
					:''
				}
				{
					active_tab == 'search' ?

						// <div> Search Properties </div>
						
							panel_search_json && panel_search_json.length ? panel_search_json.map((child_tab, idx) => (
								// (child_tab.tab_ids && child_tab.tab_ids.includes(active_tab)) ?
									// child_tab.show_search_everything ?
									<Create_card 
										panel_key={panel_key}
										set_page_loader={set_page_loader}
										handle_ui_response={handle_ui_response}
										key={child_tab.id+idx}
										metric_system={metric_system}
										approximation_mm={approximation_mm}
										approximation_ft={approximation_ft}
										update_view={update_view}
										json_item={child_tab}
										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
										approxMM={approxMM}
										mmToFeetParts={mmToFeetParts}
										panel_explode={true}
										index={idx}
										is_panel_pop_up={is_panel_pop_up}
										is_search_mode={is_search_mode}
										mode={"search"}
										search_string_panel={search_string_panel}
									/>

									// :

									// <Create_card 
									// 	panel_key={panel_key}
									// 	set_page_loader={set_page_loader}
									// 	handle_ui_response={handle_ui_response}
									// 	key={child_tab.id+idx}
									// 	metric_system={metric_system}
									// 	approximation_mm={approximation_mm}
									// 	approximation_ft={approximation_ft}
									// 	update_view={update_view}
									// 	json_item={child_tab}
									// 	set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
									// 	approxMM={approxMM}
									// 	mmToFeetParts={mmToFeetParts}
									// 	panel_explode={panel_explode}
									// 	index={idx}
									// 	is_panel_pop_up={is_panel_pop_up}
									// 	is_search_mode={is_search_mode}
									// />
									// ''
								// :''
							)) : ''
		
						
					:''
				}
			</Row>
			</CardBody>
		</Card>
	)
}

const LayerInput = ({json_item, on_click, configuration_type, common_onchange_handler, is_section}) => {
	const [select_input_value, set_select_input_value] = useState('');
	const [selected_layer, set_selected_layer] = useState('');

	const switch_layer = (layer) => {
		common_onchange_handler()
		set_selected_layer(layer)
		set_select_input_value(layer.value)
	}
	
	useEffect(() => {
		if(select_input_value && (!(!json_item.mixed && select_input_value == json_item.default_value)))
		{
			on_click()
		}
	}, [select_input_value]);

	useEffect(() => {
		if(json_item.mixed){
			set_select_input_value('')
		}else{
			set_select_input_value(json_item.default_value)
			let selected_item = json_item.options && json_item.options.find(item => item.value === json_item.default_value) || ''
			set_selected_layer(selected_item)
		}
	}, [json_item.default_value]);
	return(
		<Card style={{ border: '0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }} className={json_item.disabled ? 'panel_disabled GSI----------GSI class_position_unset' : 'GSI----------GSI class_position_unset'}>
			<CardBody className='class_position_unset' style={{ padding: is_section ? '0px' : '8px 0px', backgroundColor: COLORS.panel_card_item }} >
				<div className='px-2'>
					<div className='flex_property' style={{ width: '100%' }}>
						<img src={'/resources/toolbar_icons_new/layers.svg'} className='mr-2' style={{ width: '20px', height: '20px' }} />
						<UncontrolledDropdown className='class_position_unset' style={{ flex: 1, minWidth: '40px', display: 'flex'}}>
							<DropdownToggle style={{ flex: 1, minWidth: '40px', display: 'flex', border: '1px solid ' + COLORS.panel_item_borders, background: 'white', padding: '0px', boxShadow: 'none', fontSize: '12px', borderRadius: '2px' }}>
								<div className={`${is_section ? '' : 'py-1'} px-2 flex_center`} style={{ height: '100%', borderRight: '1px solid ' + COLORS.panel_item_borders }} >
									<div style={{ width: '16px', height: '16px', borderRadius: '5px', background: selected_layer && selected_layer.layer_color, border: '1px solid var(--light-border-color)' }} />
								</div>
								<div className={`${is_section ? '' : 'py-1'} px-2 flex_center`} style={{ height: '100%', borderRight: '1px solid ' + COLORS.panel_item_borders }} >
									<div style={{ width: '24px', height: `${selected_layer && selected_layer.layer_thickness}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
								</div>
								<div className={`${is_section ? '' : 'py-1'} px-2`} style={{ height: is_section ? '20px' : '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, minWidth: '24px', color: '#676878' }}>
									<div className='lines1_elipsis' title={selected_layer.name} style={{width: 'calc(100% - 24px)', textAlign: 'left'}}>{selected_layer.name}</div>
									<img src='/resources/toolbar_icons_new/dropdown.svg' width={'8px'} />
								</div>
							</DropdownToggle>
							<DropdownMenu container={'body'} style={{marginTop: is_section ? '16px' : '32px', width: is_section ? '208px' : '224px', maxHeight: '216px', overflow: 'auto', padding: '0px'}}>
								{
									json_item.options.map((layer, idx) => (
										<DropdownItem style={{ listStyle: 'none', padding: '0px', color: '#676878', fontSize: '12px' }} onClick={() => { switch_layer(layer) }} key={idx}>
											<div style={{ display: 'flex', minWidth: '40px'/*, border: '1px solid ' + COLORS.panel_item_borders*/ }}>
												<div className='p-2 flex_center' style={{ /*borderRight: '1px solid ' + COLORS.panel_item_borders*/ }} >
													<div style={{ width: '16px', height: '16px', borderRadius: '1px', background: layer && layer.layer_color, border: '1px solid var(--light-border-color)' }} />
												</div>
												<div className='p-2 flex_center' style={{ /*borderRight: '1px solid ' + COLORS.panel_item_borders*/ }} >
													<div style={{ width: '24px', height: `${layer && layer.layer_thickness}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
												</div>
												<div className='p-2 lines1_elipsis' title={layer.name} style={{textAlign: 'left', flex: 1, minWidth: '12px'}}>
													{layer.name}
												</div>
											</div>
										</DropdownItem>
									))
								}
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				</div>

				<input value={select_input_value} id={json_item.id} style={{ display: 'none' }} />
			</CardBody>
		</Card>
	)
}

const SelectInput = ({json_item, on_click, configuration_type, common_onchange_handler}) => {
	const [select_input_value, set_select_input_value] = useState('');
	const [select_input_value_changed, set_select_input_value_changed] = useState({status:false});

	useEffect(() => {
		if(select_input_value && select_input_value_changed.status /* && (!(!json_item.mixed && select_input_value == json_item.default_value)) */)
		{
			on_click()
			select_input_value_changed.status = false
		}
	}, [select_input_value]);

	useEffect(() => {
		select_input_value_changed.status = false
		if(json_item.mixed){
			set_select_input_value('')
		}else{
			set_select_input_value(json_item.default_value)
		}
	}, [json_item.default_value]);
	return(
		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
			<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
			<Row>
				{
					json_item.options.length == 2 && json_item.onchange_route && !(configuration_type == 'group_by_2') && !(json_item.type == 'layer_input')?
						configuration_type == 'names_above' ? 
						<Col xs={12} style={{paddingLeft:'8px', paddingRight:'8px'}}>
							<div style={{maxWidth:'100%'}}>
								<div style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", color: json_item.color ? json_item.color : COLORS.gray6, marginBottom:'4px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
								<div style={{display:"flex", alignItems:"center"}}>
									<div style={{width:'100%', display:'flex', fontSize:'12px'}}>
										<Input key={"key_"+json_item.id} id={json_item.id} style={{display:'none'}} type="text" value={select_input_value} readOnly/>
										<div className='lines1_elipsis flex_center' key={1} onClick={() => {select_input_value_changed.status = true; set_select_input_value(json_item.options[0].value); common_onchange_handler()/*; on_click(); on_select_input_change(json_item.options[0].value)*/}} style={json_item.options[0].value == json_item.default_value ? {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderRightColor: json_item.options[1].value == json_item.default_value ? 'transparent' : '#C5C7CF', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[0].name}>
											{json_item.options[0].icon ? <img className='mr-2' src={json_item.options[0].icon}></img> : ''}
											<div>{json_item.options[0].name}</div>
										</div>
										<div className='lines1_elipsis flex_center' key={2} onClick={() => {select_input_value_changed.status = true; set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/}} style={json_item.options[1].value == json_item.default_value ? {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderLeftColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[1].name}>
											{json_item.options[1].icon ? <img className='mr-2' src={json_item.options[1].icon}></img> : ''}
											<div>{json_item.options[1].name}</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
							:
							<React.Fragment>
							<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
							<Col xs={9} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft:'8px', paddingRight:'8px'}}>
								{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
									<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
										{json_item.options.map((o,idx) => (
											<option key={idx} value={o.value}>{o.name}</option>
										))}
									</Input>
								</FormGroup> */}
								<div style={{width:'100%', display:'flex', fontSize:'12px'}}>
									<Input key={"key_"+json_item.id} id={json_item.id} style={{display:'none'}} type="text" value={select_input_value} readOnly/>
									<div className='lines1_elipsis flex_center' key={1} onClick={() => {select_input_value_changed.status = true; set_select_input_value(json_item.options[0].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[0].value)*/}} style={json_item.options[0].value == json_item.default_value ? {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderRightColor: json_item.options[1].value == json_item.default_value ? 'transparent' : '#C5C7CF', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[0].name}>
										{json_item.options[0].icon ? <img className='mr-2' src={json_item.options[0].icon}></img> : ''}
										{json_item.options[0].name}
									</div>
									<div className='lines1_elipsis flex_center' key={2} onClick={() => {select_input_value_changed.status = true; set_select_input_value(json_item.options[1].value); common_onchange_handler();/*; on_click(); on_select_input_change(json_item.options[1].value)*/}} style={json_item.options[1].value == json_item.default_value ? {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderLeftColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={json_item.options[1].name}>
										{json_item.options[1].icon ? <img className='mr-2' src={json_item.options[1].icon}></img> : ''}
										{json_item.options[1].name}
									</div>
								</div>
							</Col>
						</React.Fragment>
					
					:
					!(configuration_type == 'group_by_2' || configuration_type == 'names_above') || json_item.type == 'layer_input' ? 
					<React.Fragment>
						<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
						<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft:'8px', paddingRight:'8px'}}>
							<FormGroup className='createcard_input_container' style={{marginBottom:0, width:'100%', border:'1px solid ' + COLORS.panel_item_borders}}>
								<Input onChange={(e) => {select_input_value_changed.status = true; set_select_input_value(e.target.value); common_onchange_handler();} } value={select_input_value !== '' ? select_input_value : json_item.mixed ? '' : json_item.default_value} key={"key_"+json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none'}} type="select">
									{
										json_item.mixed ? <option value={''}>{'Mixed'}</option> : ''
									}
									{json_item.options.map((o,idx) => (
										<option key={idx} value={o.value}>{o.name}</option>
									))}
								</Input>
							</FormGroup>
						</Col>
					</React.Fragment>
					:
					<Col xs={12} style={{paddingLeft:'8px', paddingRight:'8px'}}>
						<div style={{maxWidth:'100%'}}>
							<div style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", color: json_item.color ? json_item.color : COLORS.gray6}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
							<div style={{display:"flex", alignItems:"center"}}>
								<FormGroup className='createcard_input_container' style={{marginBottom:0, width:'100%', border:'1px solid ' + COLORS.panel_item_borders}}>
									<Input onChange={(e) => {select_input_value_changed.status = true; set_select_input_value(e.target.value); common_onchange_handler();} } value={select_input_value !== '' ? select_input_value : json_item.mixed ? '' : json_item.default_value} key={"key_"+json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', height:'24px'}} type="select">
										{
											json_item.mixed ? <option value={''}>{'Mixed'}</option> : ''
										}
										{json_item.options.map((o,idx) => (
											<option key={idx} value={o.value}>{o.name}</option>
										))}
									</Input>
								</FormGroup>
							</div>
						</div>
					</Col>
				}
				{/* <Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
				<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
					<FormGroup style={{marginBottom:0, width:'100%'}}>
						<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
							{json_item.options.map((o,idx) => (
								<option key={idx} value={o.value}>{o.name}</option>
							))}
						</Input>
					</FormGroup>
				</Col> */}
			</Row>
			</CardBody>
		</Card>
	)
}

const LayersetPanelPopupSingleItem = ({json_item, layer_type, update_view, single_layer, on_click, common_onchange_handler, view_id}) => {
	const [ show_edit_layer, set_show_edit_layer ] = useState(false);
	const toggle_edit_layer = () => set_show_edit_layer(!show_edit_layer)

	const [new_layer_line_color, set_new_layer_line_color] = useState('#000000');
	const [new_layer_line_thickness, set_new_layer_line_thickness] = useState('10');
	const [new_line_thickness_type, set_new_line_thickness_type] = useState('mm');
	const [new_layer_line_type, set_new_layer_line_type] = useState('solid');
	const [new_layer_name, set_new_layer_name] = useState('');
	const [panel_layer_name, set_panel_layer_name] = useState('');
	const [rename_enabled, set_rename_enabled] = useState(false);
	const [delete_layer_id, set_delete_layer_id] = useState("");

	const update_layerset_layer_properties = (layer_id, color, thickness, thickness_type, line_type) => {
		if(window.debug_mode){
            window.add_debug_log("update_layerset_layer_properties(\"" + layer_id + "\",\"" + color + "\"," + thickness + ",\""+thickness_type+"\",\"" + line_type + "\",\"panel\");");
        }
		if(view_id){ // Atharv you can add your function here for updating layerset properties with view id = view_id
			if(view_id=="GLOBAL_LAYER") window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness), thickness_type, line_type, "panel", view_id);
			else window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness), thickness_type, line_type, "", view_id);
		}
		else{
			window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness), thickness_type, line_type, "panel", "");
		}
		repopulate_panel();
		update_view()	
	}

	const toggle_layer_visibility_onclick = (layer_id) => {
		if(view_id){ // Atharv you can add your function here for toggling layer visibility with view id = view_id
			if(window.debug_mode){
				window.add_debug_log("toggle_layer_visibility_onclick(\"" + layer_id + "\",\"" + (view_id=="GLOBAL_LAYER"?"panel":"") + "\",\"" + view_id + "\");");
				window.add_debug_log("Change ID appropriately");
			}
			if(view_id=="GLOBAL_LAYER") window.Module.toggle_layer_visibility_onclick(layer_id, "panel", view_id);
			else window.Module.toggle_layer_visibility_onclick(layer_id, "", view_id);
		}
		else{
			window.Module.toggle_layer_visibility_onclick(layer_id, "panel", "");
		}
		repopulate_panel();
		update_view()
	}

	const reset_layer_onclick = (layer_id) => {
		if(view_id){ // Atharv you can add your function here for toggling layer visibility with view id = view_id
			window.Module.reset_default_layers_onclick(layer_id, view_id);
			repopulate_panel();
			update_view()
		}
		else{
			window.Module.reset_default_layers_onclick(layer_id, "");
			repopulate_panel();
			update_view()
		}
	}

	const delete_layer_onclick = (selected_layer) => {
		if(window.debug_mode){
            window.add_debug_log("delete_layer_onclick(\"" + selected_layer.id + "\");");
        }
		let json_copy = json_item;
		json_copy.delete_shift={};
		json_copy.delete_shift.layer_name = selected_layer.name;
		json_copy.delete_shift.layer_id=selected_layer.id;
		window.Module.delete_layer_onclick(JSON.stringify(json_copy));
		// repopulate_panel();
		// update_view();
	}

	useEffect(() => {
		if(rename_enabled && single_layer && single_layer.id){
			let layer_name_input = document.getElementById('layer_name_input'+single_layer.id)
			if(layer_name_input) {
				layer_name_input.focus();
				layer_name_input.setSelectionRange(0, layer_name_input.value.length);
			}
		}
	}, [rename_enabled])

	useEffect(() => {
		set_panel_layer_name(single_layer && single_layer.name)
		set_new_layer_name(single_layer && single_layer.name)
		set_new_layer_line_color(json_item.active_layerset && json_item.active_layerset.layer_line_colors && json_item.active_layerset.layer_line_colors[single_layer.id])
		set_new_layer_line_thickness(json_item.active_layerset && json_item.active_layerset.layer_line_thickness && json_item.active_layerset.layer_line_thickness[single_layer.id])
		set_new_layer_line_type(json_item.active_layerset && json_item.active_layerset.layer_line_type && json_item.active_layerset.layer_line_type[single_layer.id])
		set_new_line_thickness_type(json_item.active_layerset && json_item.active_layerset.layer_line_thickness_type && json_item.active_layerset.layer_line_thickness_type[single_layer.id])
	}, [json_item]);

	const edit_layer = (
		<div className='flex_property px-3 py-2' style={{color: '#676878'}}>
			<div className='mr-3' style={{minWidth: '300px'}}>
				<div style={{display: layer_type=='user_layer'?'':'none', justifyContent:'space-between'}} className='flex_property mb-2'>
					<div style={{flex: 1}}>Layer name</div>
					<div className="createcard_input_container" style={{display: 'flex', marginLeft: '8px', alignItems: 'center', flex: 1, border: '1px solid ' + COLORS.gray9, height: '24px'}}>
						<input style={{ width: '100%', height: '100%', border: 'none', outline: 'none', fontSize: '12px'}} type='text' value={new_layer_name} onChange={(e) => {set_new_layer_name(e.target.value); common_onchange_handler()}} onBlur={(e) => {if(single_layer && single_layer.id) {window.Module.rename_layer_onclick(single_layer.id, e.target.value);}}} onKeyDown={(e) => {if(e.key == 'Enter'){if(single_layer && single_layer.id) {e.target.blur()}}}} onClick={(e) => {e.target.focus(); e.target.setSelectionRange(0, e.target.value.length);}}/>
					</div>
				</div>
				{/* <div className='flex_property mb-2'> */}
					<div style={{justifyContent: 'space-between'}} className='mb-2 flex_property'><div style={{flex: 1}}>Line Color</div><input type='color' value={new_layer_line_color} onChange={(e) => {set_new_layer_line_color(e.target.value); common_onchange_handler()}} onBlur={(e) => {set_new_layer_line_color(e.target.value); update_layerset_layer_properties(single_layer.id, e.target.value, new_layer_line_thickness, new_line_thickness_type, new_layer_line_type)}} style={{flex: 1, width: '80px', height: '24px', marginLeft: '12px', borderColor: COLORS.gray9, cursor: 'pointer' }} /></div>
					<div className='mb-2' style={{ flex: 5, display: 'flex', justifyContent: 'space-between' }}>
						<div style={{flex: 1}}>Line Type</div>
						<select value={new_layer_line_type} onChange={(e) => {set_new_layer_line_type(e.target.value); common_onchange_handler()}} onBlur={(e) => {set_new_layer_line_type(e.target.value); update_layerset_layer_properties(single_layer.id, new_layer_line_color, new_layer_line_thickness, new_line_thickness_type, e.target.value)}} style={{ width: 'fit-content', borderColor: COLORS.gray9, height: '24px', marginLeft: '8px', cursor: 'pointer', backgroundColor: 'white', outline: 'none', flex: 1}}>
							<option value={'solid'}>Solid</option>
							<option value={'dotted'}>Dotted</option>
							<option value={'dashed'}>Dashed</option>
						</select>
					</div>
				{/* </div> */}

				<div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
					<div style={{flex: 1}}>Line Thickness</div> 
					<div style={{flex: 1, display: 'flex'}}>
						<div className='ml-2' style={{display: 'flex', alignItems: 'center', flex: 1, border: '1px solid ' + COLORS.gray9, height: '24px'}}>
							<div className='ml-2' style={{ width: '24px', height: `${new_layer_line_thickness}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
							<input type='number' value={new_layer_line_thickness} onChange={(e) => { set_new_layer_line_thickness(e.target.value); common_onchange_handler() }} onBlur={(e) => { set_new_layer_line_thickness(e.target.value); update_layerset_layer_properties(single_layer.id, new_layer_line_color, e.target.value, new_line_thickness_type, new_layer_line_type) }} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } }} style={{ flex: 1, height: '100%', width: '60px', fontSize: '12px', marginLeft: '8px', border: 'none', outline: 'none' }} />
						</div>
						<select value={new_line_thickness_type} onChange={(e) => {set_new_line_thickness_type(e.target.value); common_onchange_handler()}} onBlur={(e) => {set_new_line_thickness_type(e.target.value); update_layerset_layer_properties(single_layer.id, new_layer_line_color, new_layer_line_thickness, e.target.value, new_layer_line_type)}} style={{ width: 'fit-content', height: '24px', borderColor: COLORS.gray9, cursor: 'pointer', backgroundColor: 'white', borderLeft: 'none', outline: 'none' }}>
							<option value={'mm'}>mm</option>
							<option value={'pt'}>pt</option>
						</select>
					</div>
				</div>
				{(view_id == "GLOBAL_LAYER" && layer_type=='user_layer')?
					<div style={{ display: 'flex', alignItems: 'right', justifyContent:'space-between' }}>
						<Button style={{backgroundColor:COLORS.button_danger,  border:'0px', flex: 1, fontSize: '12px', marginTop: '8px'}} onClick={() => delete_layer_onclick(single_layer)}>Delete Layer</Button>
					</div>
				:''}
			</div>
			<img src='/resources/nav_icons_new/close.svg' className='cp' onClick={toggle_edit_layer} height='16px'></img>
		</div>
	)

	return (
		<div className={'visible_on_hover px-2' + (json_item.active_layerset && json_item.active_layerset.overridden && json_item.active_layerset.overridden[single_layer.id] ? ' overriden_layer_item' : ' normal_layer_item')} style={{ minHeight: '32px', width: '100%', display: 'flex', borderBottom: '1px solid var(--light-border-color)'}}>
			<div className='flex_property'>
				<img src={json_item.active_layerset && json_item.active_layerset.visible && json_item.active_layerset.visible[single_layer.id] ? (json_item.show_content || '/resources/nav_icons_new/view layer.svg') : (json_item.hide_content || '/resources/nav_icons_new/hide layer.svg')} style={{ height: '16px', width: '16px', marginRight: '8px', cursor: 'pointer' }} onMouseDown={common_onchange_handler} onClick={() => {toggle_layer_visibility_onclick(single_layer.id, 'panel') }} />
			</div>
			<div className='mr-2' style={{ borderRight: '1px solid var(--light-border-color)' }} />
			<div className='flex_property' style={{ flex: 1, minWidth: '40px' }}>
				<div className='mr-2' style={{ width: '16px', height: '16px', borderRadius: '5px', background: json_item.active_layerset && json_item.active_layerset.layer_line_colors && json_item.active_layerset.layer_line_colors[single_layer.id], border: '1px solid var(--light-border-color)' }}></div>
				<div className='mr-2' style={{ width: '24px', height: `${json_item.active_layerset && json_item.active_layerset.layer_line_thickness && json_item.active_layerset.layer_line_thickness[single_layer.id]}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
				{
				rename_enabled && layer_type == 'user_layer' ? 
				<div className='createcard_input_container' style={{display: 'flex', alignItems: 'center', flex: 1, border: '1px solid ' + COLORS.gray9, height: '24px', minWidth: '24px', maxWidth: '124px', borderRadius: '2px'}}>
					<input style={{border: 'none', outline: 'none', fontSize: '12px', width: '100%'}} id={'layer_name_input'+single_layer.id} value={panel_layer_name} onChange={(e) => set_panel_layer_name(e.target.value)} onBlur={(e) => {if(single_layer && single_layer.id) {window.Module.rename_layer_onclick(single_layer.id, e.target.value); set_rename_enabled(false)}}} onKeyDown={(e) => {if(e.key == 'Enter') e.target.blur()}}/>
				</div> :
				<div onDoubleClick={layer_type == 'user_layer' ? (e) => {set_rename_enabled(true);} : null} className='lines1_elipsis' title={single_layer.name} style={{ flex: 1, minWidth: '24px', maxWidth: '124px', textAlign: 'left' }}>
					{single_layer.name}
				</div>}
				{ json_item.active_layerset.overridden[single_layer.id] ? 
				<Tooltip title={"Reset layer to global"}>
					<img src="/resources/panel_icons_new/reset_layer.svg" height='12px' width='12px' className='item_invisible cp ml-2' onClick={() => reset_layer_onclick(single_layer.id)} style={{visibility: show_edit_layer ? 'visible' : ''}} />
				</Tooltip> : ''
				}

				<Tooltip
					title={edit_layer}
					color="white"
					placement={'bottomRight'}
					trigger={['click']}
					style={{ borderRadius: '4px' }}
					fresh={true}
					open={show_edit_layer}
					onOpenChange={toggle_edit_layer}
					overlayStyle={{maxWidth: 'unset'}}
				>
					<img src="/resources/panel_icons_new/pencil_outine.svg" height='12px' width='12px' className='item_invisible cp ml-2' style={{visibility: show_edit_layer ? 'visible' : ''}} />
				</Tooltip>
			</div>
			<div style={{display: show_edit_layer?'':'none', position:'fixed',backgroundColor:'rgba(20,20,20,0.5)',left:0,top:0,width:'100%',height:'100%',zIndex:1000}}></div>
		</div>
	)
}

const CreateViewModal = ({is_open, json_item, handle_close, on_click, onCreateCallback, update_view}) => {

	const [new_view_name, set_new_view_name] = useState('');
	const alert = useAlert()

	const create_and_link_view = () => {
		if(new_view_name) { 
			try{
			/*Atharv you can call your function with view name = new_view_name and id = (json_item && json_item.id), json_item. */ 
				window.Module.create_new_view_from_image(new_view_name, json_item.current_view_id, json_item.sheet_image_id);
				repopulate_panel();
				update_view()
				alert.success('New view created and linked with viewport')
			handle_close();
			} catch(err){
				console.log(err);
				alert.error(`Couldn't create and link the view`);
			}
		}
	}

	return (
		<Modal className='modal-dialog-centered' isOpen={is_open} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>
				Create a new view
			</IModalHeader>
			<IModalBody>
				<div style={{display: 'flex', gap: '16px', alignItems: 'center'}}>
					<div style={{flex: 1}}>View name</div>
					<Input id='create_view_input' value={new_view_name} onChange={(e) => set_new_view_name(e.target.value)} placeholder='Enter view name' style={{flex: 3, ...styles_panel.input_styles, height: '32px'}} />
				</div>
				<div style={{color: '#A7A8B2', marginTop: '16px'}}>
					This view will be automatically linked to this view port image
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={handle_close} className='secondary_button_default'>Close</Button>
				<Button style={{opacity: new_view_name ? 1 : 0.5}} onClick={create_and_link_view} className='primary_button_default'>Create & Link</Button>
			</IModalFooter>
		</Modal>
	)
}

export const ViewSelector = ({trigger_icon, json_item, linked_view_id, design_views, style, handle_ui_response, update_view, on_click, common_onchange_route, callbackOnchangeViewID}) => {
	// const [design_views, set_design_views] = useState('')

	const [create_view_modal, set_create_view_modal] = useState(false);
	const [view_manager_upper_menu, set_view_manager_upper_menu] = useState([
        {
            icon: '/resources/view_bar_icons/floorplan_type.svg',
            name: 'Floor plans',
            view_type: 'floorplan',
        },
        {
            icon: '/resources/view_bar_icons/custom_plan_type.svg',
            name: 'Custom plans',
            view_type: 'custom_plan',
        },
        {
            icon: '/resources/view_bar_icons/3d_type.svg',
            name: '3D',
            view_type: '3d',
        },
        {
            icon: '/resources/view_bar_icons/elevation_type.svg',
            name: 'Elevations',
            view_type: 'elevation',
        },
    ])

    const [workbench_menu, set_workbench_menu] = useState([])
	const [linked_view_item, set_linked_view_item] = useState('')
	const alert = useAlert();

	const linked_view_onchange = async(filtered_view) => {/*Atharv you can call your function with selected view id = filtered_view.id or view object = filtered_view */
		try{
			set_linked_view_item(filtered_view)
			if (common_onchange_route != ""){
				var resp = await window.Promisify(window.Module[common_onchange_route](JSON.stringify({view_id :filtered_view.id})));
				handle_ui_response(resp);
				repopulate_panel();
				update_view();
			}
			// alert.success(`Viewport image linked to ${filtered_view && filtered_view.name} successfully`)
		} catch(err){
			console.log(err);
			alert.error('View could not be linked');
		}
	}
	
    useEffect(() => {
        if(design_views){
			let linked_view = design_views.length && design_views.find(item => item.id == linked_view_id);
			if (!linked_view_item){
				set_linked_view_item(linked_view)
			} 
            let temp = view_manager_upper_menu.filter(item => item.view_type != 'workbench').map((item, idx) => {
                return {
                    key: String(idx+2),
                    label: <div className='view_type_menu' style={{display: "flex", gap: '10px', alignItems: 'center', fontSize: '12px', margin: '4px 0'}}> <img src={item.icon} height='20px' width='20px' style={{height: item.view_type=='3d'?'16px':''}}/> <div>{item.name + ' (' + String(design_views.filter(view => view.type && view.type.includes(item.view_type)).length) + ')'}</div></div>,
                    children: design_views.filter(view => view.type && view.type.includes(item.view_type)).map((filtered_view, view_idx) => {return {view_value: filtered_view, label: <Tooltip placement='right' title={filtered_view.name} mouseEnterDelay={0.5}><div onClick={() => linked_view_onchange(filtered_view)} 
					style={{display: 'flex', gap: '10px', maxWidth: '200px', fontSize: '12px'}}><img src={filtered_view.locked?'/resources/view_bar_icons/locked_state.svg':'/resources/view_bar_icons/unlocked_state.svg'}></img> <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{filtered_view.name}</div> </div></Tooltip>, key: String(idx+2) + '-' + String(view_idx+1)}}),
                    disabled: design_views.filter(view => view.type && view.type.includes(item.view_type)).length ? false : true
                }
            })
			temp.unshift( {
				key: '1',
				type: 'divider',
			  });
			if(json_item && json_item.allow_add_view)
				temp.unshift({
					key: '0',
					type: 'group',
					label: <div onClick={(e) => {set_create_view_modal(true)}} style={{cursor: 'pointer', textAlign: 'center', padding: '6px 0', borderRadius: '4px 4px 0 0', display: 'flex', gap: '8px', alignItems: 'center', fontSize: '12px'}}>
						<img style={{marginTop: '2px', height: '16px', width: '16px'}} src={'/resources/toolbar_icons_new/add.svg'} /> 
						<div style={{color: '#323338'}}>Create new linked view from this viewport</div>
					</div>,
					// children: [{key: '1-1', label: <div style={{display: "flex", alignItems: 'center', gap: '10px', marginLeft: '-9px'}}> <img src={view_manager_upper_menu.find(item => item.view_type == 'workbench').icon} height={'60%'} /> <div>{view_manager_upper_menu.find(item => item.view_type == 'workbench').name}</div></div>,}]
				});
            set_workbench_menu(temp);
        }
    }, [view_manager_upper_menu, design_views])

	useEffect(() => {
		if (callbackOnchangeViewID){
			callbackOnchangeViewID(linked_view_item)
		}
	}, [linked_view_item])

	// useEffect(() => {
	// 	var new_design_views = JSON.parse(window.Module.get_design_views_info()); // replace with view_options (TODO)

	// 	set_design_views(new_design_views)
	// }, [])

	const view_options_trigger = () => {
		return (
			<div style={{cursor: 'pointer', background: 'white', border: '1px solid #C5C7CF', borderRadius: '4px', alignItems: 'center', fontSize: '12px', display: 'flex', ...style}}>
				<div style={{borderRight: '1px solid #CED4D9', padding: '0 8px', display: 'flex', gap: '2px', alignItems: 'center'}}>
					<img src='/resources/icons/paper_clip.svg' />
					<div>to view</div>
				</div>
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 8px', flex: 1}}>
					<div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
						<img src={view_manager_upper_menu && view_manager_upper_menu.length && view_manager_upper_menu.find(item => item.view_type == linked_view_item.type) && view_manager_upper_menu.find(item => item.view_type == linked_view_item.type).icon} style={{height: linked_view_item.type == '3d' ? '12px' : '16px', width: linked_view_item.type == '3d' ? '12px' : '16px', display: linked_view_item ? '' : 'none'}}/>
						<div>{linked_view_item ? linked_view_item.name : 'Select a view to link'}</div>
					</div>
					<img sty src='/resources/panel_icons_new/dropdown_closed.svg'/>
				</div>
			</div>
		)
	}

	return (
		<>
		<CreateViewModal json_item={json_item} on_click={on_click} is_open={create_view_modal} update_view={update_view} handle_close={() => set_create_view_modal(false)} onCreateCallback={() => {}} />
		<AntDropdown
			menu={{items:workbench_menu, expandIcon: trigger_icon ? trigger_icon : <img src='/resources/view_bar_icons/toggle_view_manager.svg'/>}} trigger={['click']}
			style={{height: '100%'}}
		>
				{view_options_trigger()}
		</AntDropdown>
		</>
	)

}


const ViewOptionsBar = ({linked_view_id, view_options, handle_ui_response, on_click, update_view, set_page_loader, style, common_onchange_handler}) => {

	// useEffect(() => {
	// 	console.log("kpp view options are", view_options)
	// }, [view_options])

	const alert = useAlert();

	const toolbar_on_click = async (id, item) => {
		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner" || id == "production_details_$fulfillment_tags"){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}


		var promise_resp;
		try{
			// common_onchange_handler()
            if(item){
                var analytics_data = item
                analytics_data.category = "toolbar button clicked";
                send_analytics(analytics_data,true)
            };
            if(window.debug_mode){
                window.add_debug_log("change_view_properties(\"" + linked_view_id + "\", \"" + id + "\");");
                window.add_debug_log("Change Id here ^");
            }
			// Atharv you can replace below function with your new function with id and view_id = linked_view_id

			console.log("Reached here");
            promise_resp = window.Module.change_view_properties(linked_view_id, id);

		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
		}

		var ui_response = await window.Promisify(promise_resp);

		handle_ui_response(ui_response);

		repopulate_panel();
		update_view();

		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner" || id == "production_details_$fulfillment_tags"){
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}
	}
	
	return (
		<div className='dynamic_view_options' style={{height: '32px', border: '1px solid #C5C7CF', backgroundColor: 'white', ...style}}>
			<div style={{color: '#A7A8B2', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #C5C7CF', fontSize: '12px', padding: '0 8px'}}>Mode:</div>
			{view_options && view_options.length ? view_options.map((view_option) => (
				<Tooltip title={view_option.tooltip} mouseEnterDelay={0.5} placement='top'>
					<div className={(view_option.selected) ? 'active_view_option' : 'inactive_view_option'} onClick={() => {send_analytics({category: 'Quick Action Menu Toggled', button_id: view_option.id, button_action: view_option.active ? "OFF" : "ON", button_visibility: view_option.disabled ? "Disabled" : "Enabled"}); if(!view_option.disabled){ toolbar_on_click(view_option.id) }}} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1', borderRight: '1px solid white', cursor: !(view_option.disabled) ? 'pointer' : 'not-allowed' }}>
						<Icons name={view_option.src} style={{ fontSize: '20px', opacity: !view_option.disabled ? '1' : '0.2', color: view_option.selected ? "#214097" : "#676878" }} />
					</div>
				</Tooltip>
			))
			:''}
		</div>
	)
}

const ViewLayersPanel = ({json_item, update_view, handle_ui_response, set_page_loader, set_modal_mode, common_onchange_handler, on_click}) => {

	const [view_options, set_view_options] = useState([]);
	const [design_views, set_design_views] = useState([])

	useEffect(() => {
		if(json_item && json_item.view_options_list) {
			set_view_options(json_item.view_options_list)
		}
		if(json_item && json_item.view_list) {
			set_design_views(json_item.view_list)
		}
	}, [json_item])
	
	return (
		<div style={{padding: '8px', backgroundColor: '#E7E8ED'}}>
			<ViewSelector json_item={json_item} on_click={on_click} common_onchange_route={json_item && json_item.change_view_route} linked_view_id={json_item && json_item.current_view_id} design_views={design_views} handle_ui_response={handle_ui_response} update_view={update_view} style={{marginBottom: '8px', height: '24px'}}/>
			<ViewOptionsBar linked_view_id={json_item && json_item.current_view_id}  view_options={view_options} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader} on_click={on_click} common_onchange_handler={common_onchange_handler} style={{marginBottom: '8px'}}/>
			<LayersetPanelPopup on_click={on_click} options={{disable_add_layer: true}} view_id={json_item && json_item.current_view_id} json_item={json_item} update_view={update_view} set_modal_mode={set_modal_mode} common_onchange_handler={common_onchange_handler} handle_ui_response={handle_ui_response} style_main={{padding: 0, backgroundColor: 'white'}} style_heading={{background: 'white', border: '1px solid #C5C7CF'}} />
		</div>
	)
}

export const LayersetPanelPopup = ({json_item, update_view, set_given_layerset, set_layerset_mode, set_modal_mode, common_onchange_handler, on_click, view_id, handle_ui_response, style_main, style_heading, options={}}) => {
	const [showing_all_layersets, set_showing_all_layersets] = useState(false);
	const [showing_layerset_settings, set_showing_layerset_settings] = useState(false);
	const [system_layers, set_system_layers] = useState([]);
	const [user_layers, set_user_layers] = useState([]);
	const [overriden_tip, set_overriden_tip] = useState(false);
	const [show_system_layers, set_show_system_layers] = useState(true);
	const toggle_show_system_layers = () => (set_show_system_layers(true))
	const toggle_show_user_layers = () => (set_show_system_layers(false))
    const {t} = useTranslation()

	const onClickAddNewLayer = async() => {
		try{
			common_onchange_handler(); 
			let resp = await window.Promisify(window.Module.show_create_new_layer_modal())
			handle_ui_response(resp)
			set_modal_mode('panel')
		}catch(err){
			console.error(`Error in onClickAddNewLayer `, err)
			handleError(err);
		}
	}

	const resetAllLayers = async() => {
		try{ 
			let resp = await window.Promisify(window.Module.reset_all_default_layers_modal_open(view_id))
			handle_ui_response(resp)
		}catch(err){
			console.error(`Error in resetAllLayers `, err)
			handleError(err);
		}
	}

	useEffect(() => {
		let all_layers = (JSON.parse(window.Module.get_add_layersets_from_templates('edit_layer_properties')));
		if(all_layers){
			if(all_layers.system_layers){
				set_system_layers(all_layers.system_layers.map(x => x.id))
			}else{
				set_system_layers([])
			}

			if(all_layers.user_layers){
				set_user_layers(all_layers.user_layers.map(x => x.id))
			}else{
				set_user_layers([])
			}
		}
		if(json_item && json_item.active_layerset && json_item.active_layerset.overridden){
			if(Object.keys(json_item.active_layerset.overridden).find(item => json_item.active_layerset.overridden[item]))
				set_overriden_tip(true)
			else
				set_overriden_tip(false)
		}
	}, [json_item]);

	return (
		<Card style={{ border: '0px solid', backgroundColor: COLORS.gray3, padding: '0px 10px', height: '100%', ...style_main}}>
			<div className='flex_property' style={{ justifyContent: 'space-between', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400, lineHight: '16px', letterSpacing: '0em', textAlign: 'left', padding: '8px 5px 4px 5px',  ...style_heading}}>
				<div style={{color: '#676878', display: (overriden_tip ) ?'flex':'none', alignItems: 'center', gap: '3px', flex: 1}}>
					<div style={{width: '12px', height: '12px', border: '1px solid #676878', borderRadius: '2px', background: '#FEF2C1'}}></div>
					<span>:</span>
					<span>Overridden</span>
					<div className='ml-auto cp' style={{color: "#0078ff"}} onClick={resetAllLayers}> Reset All Layers</div>
				</div>
			</div>
			{/*<div className='flex_property' style={{ padding: '5px 10px', backgroundColor: COLORS.panel_card_item_parent, justifyContent: 'space-between', fontSize: '14px', height: '30px' }}>
				<span title={json_item && json_item.active_layerset ? json_item.active_layerset.name : ''} style={{}}>{json_item && json_item.active_layerset ? (json_item.active_layerset.name.length <= 25 ? json_item.active_layerset.name : json_item.active_layerset.name.substring(0, 25) + '...') : ''}</span>
				<div className='flex_property' >
					<i className={showing_all_layersets ? 'fa fa-angle-up' : 'fa fa-angle-down'} style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} onClick={() => { set_showing_layerset_settings(false); set_showing_all_layersets(!showing_all_layersets) }} />
					<div id="tooltip1" className="bottom" style={{ left: '10px', top: '46px', backgroundColor: 'white', display: showing_all_layersets ? 'block' : 'none', boxShadow: '#efeaea 0px 4px 4px 0px', padding: '8px' }}>
						<div className="tooltip-arrow" style={{ left: '84%', borderBottomColor: 'white' }}></div>
						<div className="tooltip-label display_onhover flex_property" style={{ height: '32px', width: '233px', backgroundColor: 'white', color: COLORS.blue6, cursor: 'pointer', textAlign: 'left', paddingLeft: '8px' }} onClick={() => { set_modal_type('create_layerset_modal'); set_modal_mode('panel'); set_layerset_mode('create') }}>{t(`+ Create new layer set`)}</div>
						{
							json_item && json_item.all_layersets && json_item.all_layersets.length ? json_item.all_layersets.map((single_layerset, idx) => (
								<div key={idx} className="tooltip-label dropdown_visibility_on_hover flex_property display_onhover" style={{ height: '32px', width: '233px', backgroundColor: 'white', color: COLORS.gray7, justifyContent: 'space-between', cursor: 'pointer' }}>
									<div title={single_layerset.name} style={{ flex: '6', textAlign: 'left', overflowX: 'auto', whiteSpace: 'nowrap' }} onClick={() => { window.Module.switch_layerset_onclick(single_layerset.id, 'panel'); update_view() }}>{single_layerset.name.length <= 25 ? single_layerset.name : single_layerset.name.substring(0, 25) + '...'}</div>
									<div style={{ flex: '2' }} className='flex_property dropdown_visibility_on_hover'>
										<img style={{ cursor: 'pointer', marginLeft: '4px', marginRight: '10px' }} width='16px' onClick={(e) => { e.stopPropagation(); set_modal_type('create_layerset_modal'); set_modal_mode('panel'); set_layerset_mode('copy'); set_given_layerset(single_layerset) }} className='dropdown_item_hide' src={single_layerset.copy_content} />
										<img style={{ cursor: 'pointer', display: !single_layerset.delete_lock ? 'block' : 'none' }} width='16px' onClick={(e) => { e.stopPropagation(); window.Module.delete_layerset_onclick(single_layerset.id, 'panel'); }} className='dropdown_item_hide' src={single_layerset.delete_content} />
									</div>
								</div>
							)) : ''
						}
					</div>

					<img src={"/resources/nav_icons_new/settings 16px.svg"} style={{ paddingLeft: '4px', marginLeft: '4px', cursor: 'pointer', height: '17px', borderLeft: '1px solid #E6E9EE' }} onClick={() => { set_showing_all_layersets(false); set_showing_layerset_settings(!showing_layerset_settings) }} />
					<div id="tooltip1" className="bottom" style={{ left: '10px', top: '46px', backgroundColor: 'white', display: showing_layerset_settings ? 'block' : 'none', boxShadow: '#efeaea 0px 4px 4px 0px', padding: '8px' }}>
						<div className="tooltip-arrow" style={{ left: '92.5%', borderBottomColor: 'white' }}></div>
						<div className="tooltip-label display_onhover flex_property" style={{ height: '32px', width: '233px', backgroundColor: 'white', color: COLORS.gray7, textAlign: 'left', cursor: 'pointer' }} onClick={() => { window.Module.toolbar_router("define_layerset"); update_view(); }} >Define</div>
						<div className="tooltip-label display_onhover flex_property" style={{ height: '32px', width: '233px', backgroundColor: 'white', color: COLORS.gray7, textAlign: 'left', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); set_modal_type('create_layerset_modal'); set_modal_mode('panel'); set_layerset_mode('rename'); set_given_layerset(json_item.active_layerset) }}>Rename</div>
						<div className="tooltip-label display_onhover flex_property" style={{ height: '32px', width: '233px', backgroundColor: 'white', color: COLORS.gray7, textAlign: 'left', cursor: 'pointer' }} onClick={() => window.Module.toolbar_router("unhide_all_layers_layerset")}>UnHide all layers in this set</div> */}
						{/* <div className="tooltip-label display_onhover flex_property" style={{height:'32px', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}}>UnLock all layers in this set</div> */}
						{/* <div className="tooltip-label display_onhover flex_property" style={{ height: '32px', width: '233px', backgroundColor: 'white', color: '#FF5247', textAlign: 'left', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); window.Module.delete_layerset_onclick(json_item.active_layerset.id, 'panel'); }}>Delete this layer set</div>
					</div>
				</div>
			</div> */}
			<div className='flex_property px-2' style={{ backgroundColor: COLORS.panel_card_item_parent, justifyContent: 'space-between', fontSize: '12px', marginTop: '8px', marginBottom: '4px', height: '32px' }}>
				<div className='flex_property' style={{flex: 1, minWidth: '40px', height: '100%'}}>
					<div className='flex_property mr-2' style={show_system_layers ? {padding:'4px 0px', height: '100%', fontSize:'12px', fontWeight:700, border:'2px solid transparent', borderBottomColor: COLORS.blue6, color:COLORS.blue6, display:'flex', cursor:'pointer'} : {padding:'4px 0px', height: '100%', fontSize:'12px', border:'2px solid transparent', borderBottomColor: 'transparent', color: COLORS.panel_group_heading, display:'flex', cursor:'pointer'}} onClick={toggle_show_system_layers}>{`System (${json_item && json_item.layers_in_design && json_item.layers_in_design.filter(x => ( system_layers.includes(x.id))).length})`}</div>
					<div className='flex_property mr-2' style={!show_system_layers ? {padding:'4px 0px', height: '100%', fontSize:'12px', fontWeight:700, border:'2px solid transparent', borderBottomColor: COLORS.blue6, color:COLORS.blue6, display:'flex', cursor:'pointer'} : {padding:'4px 0px', height: '100%', fontSize:'12px', border:'2px solid transparent', borderBottomColor: 'transparent', color: COLORS.panel_group_heading, display:'flex', cursor:'pointer'}} onClick={toggle_show_user_layers}>{`Custom (${json_item && json_item.layers_in_design && json_item.layers_in_design.filter(x => ( (user_layers.includes(x.id)) )).length})`}</div>
				</div>
				{/* <div style={{ color: COLORS.blue6, cursor: 'pointer', border: '1px solid #0078ff', borderRadius: '50%'}} onClick={() => { set_modal_type('create_layer_modal'); set_modal_mode('panel') }}>+</div> */}
				<Tooltip title={show_system_layers ? "Cannot create system layers. Switch to custom layers to create new layers." : 'Create new layer'}>
					<img src="/resources/panel_icons_new/blue_add.svg" className='cp' height='16px' width='16px' style={{opacity: show_system_layers ? 0.5 : 1, display: options && options.disable_add_layer ? 'none' : ''}} onClick={show_system_layers ? null : onClickAddNewLayer}/>
				</Tooltip>
				{/* <span style={{ color: COLORS.gray9 }}>{json_item && json_item.layers_in_design ? json_item.layers_in_design.length : 0} Layers</span> */}
			</div>
			<div style={{ backgroundColor: COLORS.panel_card_item_parent, fontSize: '12px' }}>
				{
					json_item && json_item.layers_in_design && json_item.layers_in_design.length ? json_item.layers_in_design.filter(x => ( (show_system_layers && system_layers.includes(x.id)) || (!show_system_layers && user_layers.includes(x.id)) )).sort((a,b) => {if(a.name > b.name){return 1} return -1}).map((single_layer, idx) => (
						<LayersetPanelPopupSingleItem key={(show_system_layers ? "system_layers" : "user_layers") + idx} layer_type={show_system_layers ? "system_layer" : "user_layer"} json_item={json_item} update_view={update_view} single_layer={single_layer} common_onchange_handler={common_onchange_handler} view_id={view_id} on_click={on_click}/>
					)) : ''
				}
			</div>
		</Card>
	)

}

const RefLevelDimensionInput = ({json_item, common_onchange_handler, metric_system, approximation_mm, approximation_ft, mmToFeetParts, approxMM, index, on_click, is_grouped}) => {
	const [ ref_level_value, set_ref_level_value ] = useState("");
	const [trigger_on_click, set_trigger_on_click] = useState({value: false});


	const get_active_ref_level_name = () => {
		if((!ref_level_value) || ref_level_value=="no_ref_level"){
			return ((json_item.relative_ref_level?["Value", "Amount"]:(json_item.global_ref_level?["From", "Global Origin"]:["From", "Floor Base Top"])));
			// return ("Value" + (json_item.relative_ref_level?" (Amount)":(json_item.global_ref_level?" (From Global Origin)":" (From Floor Base Top)")));
		}else if(json_item.ref_level_options&&json_item.ref_level_options.find(o=>o.value == ref_level_value)){
			var name_find = json_item.ref_level_options.find(o=>o.value == ref_level_value).name;
			// var name_find = json_item.ref_level_options.find(o=>o.value == ref_level_value).name + " with offset ";
			return json_item.relative_ref_level?["Up to ",name_find]:["From ", name_find];
		}else{
			return ['', 'Reference Level Deleted'];
		}
	}

	const dropdown_on_click = (val) => {
		common_onchange_handler()
		set_ref_level_value(val);
		trigger_on_click.value = true
		// on_click()
	}

	useEffect(() => {
		if(ref_level_value && !(ref_level_value == json_item.default_ref_level)){
			if(trigger_on_click.value){
				on_click()
				trigger_on_click.value = false
			}
		}
	}, [ref_level_value]);

	useEffect(() => {
		set_ref_level_value(json_item.default_ref_level);
	},[json_item.default_ref_level]);

	return (
		<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset':'dI----------dI class_position_unset'}>
			<CardBody className='class_position_unset' style={{ padding:'15px',paddingBottom:0, /*paddingTop:index ? 0 : 15,*/ backgroundColor: COLORS.panel_card_item}} >
			{/* <Row> */}
				{/* <Col style={{display:'flex',justifyContent:'center', alignItems:"center"}}> */}
				<Input type="text" value={ref_level_value} style={{display:"none"}} key={"key_"+json_item.id + "_ref_level_select"} id={json_item.id + "_ref_level_select"} readOnly/>
				<Row className='class_position_unset' style={{/*marginBottom:'15px'*/}}>
					<Col className='class_position_unset' sm={12} style={{fontSize:12, display:"flex", alignItems:"left",justifyContent:'left', cursor:"default", fontWeight:500, color:COLORS.panel_group_heading, marginBottom:'12px'}}>
						<div style={{marginLeft:'-15px', marginRight:'-15px', paddingLeft:'8px', paddingRight:'8px'}} title={json_item.name} className='lines2_elipsis' >{json_item.name}</div>
					</Col>
					<Col className='class_position_unset' sm={6} style={{fontSize:12, display:"flex", alignItems:"center", cursor:"default", paddingRight:'8px', paddingLeft:'8px'}}>
						{/* <div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}> */}
							<div className='createcard_input_container' style={{display:'flex', border:'1px solid ' + COLORS.panel_item_borders, paddingLeft:'8px', paddingRight:'8px', alignItems:'center', height:'24px', width:'100%'}}>
								<div style={{fontSize:'12px', marginRight:2}}>{get_active_ref_level_name()[0]}</div>
								<div style={{flex:1, fontSize:'12px', textAlign:'end'}} className='lines1_elipsis' title={json_item.mixed ? 'Mixed' : get_active_ref_level_name()[1]}>{json_item.mixed ? 'Mixed' : get_active_ref_level_name()[1]}</div>
								<ReferenceLevellDropdown json_item={json_item} on_click={dropdown_on_click} />
							</div>
						{/* </div> */}
					</Col>
					{metric_system=='mm'?(
						<React.Fragment>
							{((!ref_level_value) || ref_level_value=="no_ref_level")?(
								<Col className='class_position_unset' sm={6} style={{fontSize:12, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:8, paddingLeft:'8px'}}>
									<div className='createcard_input_container' style={{border:'1px solid ' + COLORS.panel_item_borders}}>
										<Input type="text" id={json_item.id} key={"key_"+json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.mixed ? '' : (approxMM(json_item.default_value)||0)) ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : (approxMM(json_item.default_value)||0)} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder={json_item.mixed ? 'Mixed' : "0"}/>
									</div>
								</Col>
							):(
								<Col className='class_position_unset' sm={6} style={{fontSize:12, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:8, paddingLeft:'8px'}}>
									<div className='createcard_input_container' style={{border:'1px solid ' + COLORS.panel_item_borders}}>
										<Input type="text" id={json_item.id + "_offset"} key={"key_"+json_item.id + "_offset"} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.mixed ? '' : (approxMM(json_item.default_ref_level_offset)||0)) ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : (approxMM(json_item.default_ref_level_offset)||0)} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder={json_item.mixed ? 'Mixed' : "0"}/>
									</div>
								</Col>
							)}
						</React.Fragment>
					):(
						<React.Fragment>
							{((!ref_level_value) || ref_level_value=="no_ref_level")?(
								<Col xs={6} style={{padding: '0px 8px 0px 0px'}}>
									<FocusWithin className='createcard_input_container blue_highlight_focuswithin' onBlur={json_item.onchange_route ? on_click : null} style={{ display:'flex', width:'100%', border: '1px solid ' + COLORS.panel_item_borders}}>
										{/* <Col> */}
											<Col className='flex_property class_position_unset zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders, width: '40%'}}>
												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[0] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
												<div className='pr-1 flex_property' style={{fontSize:10, textAlign:'right', cursor:"default", color: COLORS.gray5, height: '24px'}}>ft</div>
											</Col>
											<Col className='flex_property class_position_unset zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders, width: '30%'}}>
												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[1] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
												<div className='pr-1 flex_property' style={{fontSize:10, textAlign:'right', cursor:"default", color: COLORS.gray5, height: '24px'}}>in</div>
											</Col>
											<Col className='flex_property class_position_unset zero_padding' style={{width: '30%'}}>
												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_value)[2] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
												<div className='pr-1 flex_property' style={{fontSize:10, textAlign:'right', cursor:"default", color: COLORS.gray5, height: '24px'}}>{approximation_ft=='1/8_inch'?'1/8':'1/16'}</div>
											</Col>
											{/* <Col className='class_position_unset' sm={1} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>

											</Col> */}
										{/* </Col> */}
									</FocusWithin>
								</Col>
							):(
								<Col xs={6} style={{padding: '0px 8px 0px 0px'}}>
									<FocusWithin className='createcard_input_container blue_highlight_focuswithin' onBlur={json_item.onchange_route ? on_click : null} style={{ display:'flex', width:'100%', border: '1px solid ' + COLORS.panel_item_borders}}>
										{/* <Col> */}
											<Col className='flex_property class_position_unset zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders, width: '40%'}}>
												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_ref_level_offset)[0] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[0]||0} key={"key_" + json_item.id + "_offset" + "_ft"} id={json_item.id + "_offset" + "_ft"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
												<div className='pr-1 flex_property' style={{fontSize:10, textAlign:'right', cursor:"default", color: COLORS.gray5, height: '24px'}}>ft</div>
											</Col>
											<Col className='flex_property class_position_unset zero_padding' style={{borderRight:'1px solid ' + COLORS.panel_item_borders, width: '30%'}}>
												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_ref_level_offset)[1] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[1]||0} key={"key_" + json_item.id + "_offset" + "_in"} id={json_item.id + "_offset" + "_in"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
												<div className='pr-1 flex_property' style={{fontSize:10, textAlign:'right', cursor:"default", color: COLORS.gray5, height: '24px'}}>in</div>
											</Col>
											<Col className='flex_property class_position_unset zero_padding' style={{width: '30%'}}>
												<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=mmToFeetParts(json_item.default_ref_level_offset)[2] || 0 ; e.target.blur()}}} defaultValue={mmToFeetParts(json_item.default_ref_level_offset)[2]||0} key={"key_" + json_item.id + "_offset" + "_parts"} id={json_item.id + "_offset" + "_parts"} style={{...styles_panel.input_styles, border:'none', height:'24px'}} placeholder="0"/>
												<div className='pr-1 flex_property' style={{fontSize:10, textAlign:'right', cursor:"default", color: COLORS.gray5, height: '24px'}}>{approximation_ft=='1/8_inch'?'1/8':'1/16'}</div>
											</Col>
											{/* <Col className='class_position_unset' sm={1} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}>

											</Col> */}
										{/* </Col> */}
									</FocusWithin>
								</Col>
							)}
						</React.Fragment>
					)}
					{/* <Col className='class_position_unset' sm={2}>
						<ReferenceLevellDropdown json_item={json_item} on_click={dropdown_on_click} />
					</Col> */}
				</Row>
				{/* </Col> */}
			{/* </Row> */}
			</CardBody>
		</Card>
	);
}

export const ParametricDimensionInput = ({json_item, common_onchange_handler, on_click, configuration_type, is_section}) =>{
	const [parsed_expression, set_parsed_expression] = useState('');
	const [value, set_value] = useState('');
	const [is_valid, set_is_valid] = useState(true);
	const [suggestions, set_suggestions] = useState([]);
	const inputRef = useRef(null);
	const [preventBlur, set_preventBlur] = useState({is_valid: false});
	// var preventBlur = false

	const handleSuggestionClick = (suggestion) => {
		if(document.getElementById(json_item.id)){
			document.getElementById(json_item.id).value = value + suggestion
		}
		set_value(value + suggestion);
		set_suggestions([]);
	};

	const handleTabPress = (event) => {
		if (event.key === 'Tab' && suggestions.length > 0) {
			event.preventDefault(); // Prevent default Tab behavior
			event.target.value = value + suggestions[0]
			set_value(value + suggestions[0]);
			set_suggestions([]);
		}
	};

	const inputOnBlur = (event) => {
		if (preventBlur.is_valid) {
			event.preventDefault(); 
			event.target.focus()
			const inputLength = event.target.value.length;
    		event.target.setSelectionRange(inputLength, inputLength);
			preventBlur.is_valid = false

		} else {
			on_click(); set_suggestions([])
		}
	}

	const onMouseDownSuggestion = (event, suggestion) => {
		preventBlur.is_valid = true; 
		event.stopPropagation(); 
		handleSuggestionClick(suggestion)
	}

	useEffect(() => {
		set_value(json_item.parameter_expression ? json_item.parameter_expression : json_item.default_value ? String(json_item.default_value) : '0')
	}, []);
	
	// useEffect(() => {
	// 	set_parsed_expression(window.Module.parse_expression_interface(json_item.parameter_expression, JSON.stringify(json_item.expression_available_parameters)))
	// }, [json_item.parameter_expression]);

	useEffect(() => {
		let _is_valid = (window.Module.validate_expression_interface(value, JSON.stringify(json_item.expression_available_parameters)) === 'success')
		set_is_valid(_is_valid)
		let last_element = value.split(' ')
		if(last_element && last_element.length){
			last_element = last_element[last_element.length - 1]
		}
		if(value){
			set_suggestions(JSON.parse(window.Module.autofill_expression_interface(last_element, JSON.stringify(json_item.expression_available_parameters))).filter(x => x))
		}else{
			set_suggestions([])
		}

		set_parsed_expression(isNaN(value) && _is_valid ? window.Module.parse_expression_interface(value, JSON.stringify(json_item.expression_available_parameters)) : undefined)

	}, [value]);

	useEffect(() => {
		// Position the suggestion list relative to the input element
		if(suggestions.length && inputRef.current) {
			const inputRect = inputRef.current.getBoundingClientRect();
			const left = inputRect.left;
			const top = inputRect.top + inputRect.height;
			const suggestionList = document.getElementById('suggestion-list');
			if(suggestionList){
				suggestionList.style.left = `${left}px`;
				suggestionList.style.top = `${top}px`;
			}
		}
	}, [suggestions]);

	const isUserTyping = () => {
		// Get the input element
		const input = document.getElementById(json_item.id);
		if (!input) return false;
		// check if input is focused
		if (document.activeElement !== input) return false;
		return true;
	}
	
	return (
		<div style={{ width: '100%' }}>
			<div style={is_section ? {} : { paddingRight: '8px', paddingLeft: '8px', paddingTop: '15px' }}>
				{configuration_type == 'names_above' ? <div title={json_item.name ? json_item.name : ''} style={{ color: json_item.color ? json_item.color : COLORS.gray5, fontSize: '12px', display: 'flex'}}>{json_item.name ? json_item.name : ''}</div> : ''}
				<div className={`createcard_input_container ${is_valid ? 'accept' : 'reject'} ${json_item.disabled ? 'panel_disabled' : ''}`} style={{ display: 'flex', width: '100%', border: '1px solid ' + COLORS.panel_item_borders, paddingLeft: '4px' }}>
					{configuration_type != 'names_above' ? <div title={json_item.name ? json_item.name : ''} style={{ color: json_item.color ? json_item.color : COLORS.gray5, fontSize: '12px', display: 'flex', alignItems: 'center' }}>{json_item.name ? json_item.name[0] : ''}</div> : ''}
					<Tooltip
						title={
							<div>
								{
									json_item.expression_available_parameters && Object.keys(json_item.expression_available_parameters).map(p => (
										<div className='flex_property mb-2'>
											<div className='mr-2'>
												{p}
											</div>
											<div>
												{json_item.expression_available_parameters[p]}
											</div>
										</div>
									))
								}
							</div>
						} 
						placement="right" mouseEnterDelay={1} overlayInnerStyle={{width:300}}>
						<img className={configuration_type != 'names_above' ? 'pr-1 pl-2 ' : 'pr-1'} src='/resources/icons/info.svg'></img>
					</Tooltip>
					<Tooltip title={parsed_expression} placement="bottom" mouseEnterDelay={1}>
						<input ref={inputRef} autoComplete='off' type="text" onChange={(e) => { common_onchange_handler(); set_value(e.target.value) }} onBlur={inputOnBlur} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = (json_item.parameter_expression) || json_item.default_value; e.target.blur() } else { handleTabPress(e) } }} defaultValue={json_item.parameter_expression || json_item.default_value} key={"key_" + json_item.id} id={json_item.id} style={{ ...styles_panel.input_styles, border: 'none', flex: 5, height: '24px', outline: 'none' }} placeholder="0" />
						{/* <UncontrolledTooltip target={json_item.id} autohide={false} placement='right'>
						{parsed_expression}
					</UncontrolledTooltip> */}
					</Tooltip>
					<div style={{ width: '4px', backgroundColor: is_valid ? '#CCF4E3' : '#F9DADE', /*borderLeft: is_valid ? '1px solid #23C16B' : '1px solid var(--red_default)'*/ }}></div>
				</div>
			</div>
			{isUserTyping() && suggestions.length > 0 && (
				<div className="suggestion-list" id="suggestion-list" style={{position:'fixed', zIndex: 3, background: 'white'}}>
					{suggestions.map((suggestion, index) => (
						<div className='single-suggestion' key={index} onMouseDown={(event) => {onMouseDownSuggestion(event, suggestion)}} /*onClick={() => {handleSuggestionClick(suggestion)}}*/>
							{suggestion}
						</div>
					))}
				</div>
			)}
		</div>

	)
}

export const ButtonsDropdown = ({button_list, icon_on_click, customToggle, customStyle={menu:{}}}) => {
	return(	
		button_list && button_list.length ?
			// <UncontrolledDropdown direction='right'>
			// 	<DropdownToggle style={{ display: 'flex', alignItems: 'center', background: 'transparent', border: 'none', boxShadow: 'unset' }}>
			// 		{customToggle ? customToggle : <img style={{padding: '4px', aspectRatio: '1',}} src='/resources/icons/options.svg'></img>}
			// 	</DropdownToggle>
			// 	<DropdownMenu right container={'body'} className='DMSans' style={{ padding: '4px', marginTop: '40px', ...customStyle.menu }}>
			// 		{
			// 			button_list && button_list.length ? button_list.map((item, idx) => (
			// 				item.type == "divider" ?
			// 					// <div className='sheet-dropdown-divider'></div>
			// 					<DropdownItem divider />
			// 					:
			// 					<DropdownItem style={{ padding: '6px 12px', color: item.color ? item.color : '' }} disabled={item.disabled} onClick={(e) => { if (item.customOnClick) { item.customOnClick() } else { icon_on_click({ route: item.onclick, form: { id: item.name, additional_data: item.additional_data ? item.additional_data : '' } }); } }}>
			// 						<div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
			// 							<img src={item.icon} style={{ height: '16px', width: '16px' }} />
			// 							<div>{item.display_name}</div>
			// 						</div>
			// 					</DropdownItem>
			// 			))
			// 				: ''
			// 		}
			// 	</DropdownMenu>
			// </UncontrolledDropdown>
			<AntDropdown trigger={['click']} menu={{items: 
						button_list.map((item, idx) => {
							return{
								label:
									item.type == "divider" ?
									// <div className='sheet-dropdown-divider'></div>
									''
									:
									<div style={{ color: item.color ? item.color : '' }} disabled={item.disabled} onClick={(e) => { if (item.customOnClick) { item.customOnClick({id: item.name, additional_data: item.additional_data}) } else if(icon_on_click) { icon_on_click({ route: item.onclick, form: { id: item.name, additional_data: item.additional_data ? item.additional_data : '' } }); } }}>
										<div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
											{item.icon ? <img src={item.icon} style={{ height: '16px', width: '16px' }} /> : ''}
											<div style={{color: item.color ? item.color : ''}}>{item.display_name}</div>
										</div>
									</div>,
								key: idx,
								type: item.type
							}
			})
			
			}}>
				{customToggle ? customToggle : <img style={{padding: '4px', aspectRatio: '1',}} src='/resources/icons/options.svg'></img>}
			</AntDropdown>
			: ''
			
	)
}

//Common component for buttons array. extend this for further improvements
export const ButtonsArray = ({button_list, icon_on_click, ...props}) => {
	return(
		<div className='flex_property panel_button_array' {...props}>
			{
				button_list && button_list.length ? button_list.map((item, idx) => (
					item.type == "info_tooltip" ?
						<TriggerTooltip title={item.display_name} placement="right" />
						: item.type === "multiline_tooltip" ?
							<TriggerTooltip title={item.multiline_tooltip ? item.multiline_tooltip.entries : ""} placement="right" overlayStyle={{maxWidth: 'unset'}} trigger={item.icon ? <div><img src={item.icon} width={'16px'} height={'16px'} /></div> : ''} />
							: item.type == "switch" ?
								<Switch key={idx} disabled={item.disabled} style={{ color: 'green' }} size='small' checked={item.value == "Off" ? false : true} onClick={(checked, e) => { e.stopPropagation(); icon_on_click({ route: item.onclick, form: { id: item.name } }) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/ }} />
								: item.type == 'label' ?
									<div key={idx} style={{ cursor: 'pointer', fontSize: '12px', color: COLORS.black, textTransform: 'capitalize', fontWeight: 400 }}>{item.name}</div>
									: item.type && item.type.includes('ghost_button') ? //have ghost_button_primary and so on for color requirements
										<div key={idx} title={item.disabled_message ? item.disabled_message : ''} className={`${item.disabled ? 'panel_disabled_opacity' : ''}`} style={{ cursor: 'pointer', fontSize: '12px', color: COLORS.blue6, textTransform: 'capitalize', fontWeight: 400 }} onClick={item.disabled ? null : (e) => { icon_on_click({ route: item.onclick, form: { id: item.name } }) }}>{item.name}</div>
										: item.icon == "+" ?
											<span key={idx} title={item.disabled_message ? item.disabled_message : ''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{ fontSize: '12px', color: COLORS.blue6, textTransform: 'capitalize', display: 'flex' }} onClick={(e) => { if (props.dropdown_open) { e.stopPropagation() }; icon_on_click({ route: item.onclick, form: { id: item.name } }) /*; window.Module[item.onclick](JSON.stringify({}))*/ }}><img style={{ marginRight: '4px' }} height='12px' src='/resources/panel_icons_new/blue_add.svg'></img> Add </span>
											:
											<img key={idx} src={item.icon} title={item.tooltip ? item.tooltip : ''} className={item.disabled ? 'panel_disabled_opacity' : 'button_panel'} style={{ height: '16px' }} onClick={(e) => { e.stopPropagation(); if(!item.disabled){icon_on_click({ route: item.onclick, form: { id: item.name } })} /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/ }} />
				))
					: ''
			}
		</div>
	)
}

const ParentPanelTreeItem = ({ item, route, common_onchange_handler, depth = 0, is_last_child}) => {
	const [open, set_open] = useState(depth < 3);
	const toggle = () => {
		// common_onchange_handler()
		set_open(!open)
	}

	const onClickNode = () => {
		if(route){
			// common_onchange_handler()
			if(window.debug_mode){
				window.add_debug_log(route + "(\"" + item.id + "\");");
			}
			window.Module[route](item.id)
		}
	}

	return (
		<React.Fragment>
			<div style={{ height: '36px', display: 'flex', alignItems: 'center' }} className={`panel_tree_item_container ${is_last_child ? 'last_tree_item' : ''}`}>
				{
					depth > 0 ?
						<div className='panel_tree_leaf_indicator_container' style={{ height: '100%', position: 'relative' }}>
							<div className='panel_tree_leaf_indicator'></div>
						</div>
						:
						''
				}
				<div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }} className='panel_tree_parent_header'>

					{depth === 0 ? '' : <img onClick={toggle} style={{ cursor: 'pointer', marginRight: '8px' }} src={open ? '/resources/panel_icons_new/dropdown_open.svg' : '/resources/panel_icons_new/dropdown_closed.svg'}></img>}
					<div onClick={depth === 0 ? null : onClickNode} className={depth == 0 ? 'root_tree_item' : 'panel_tree_item'}>
						{item.src ? <img style={{ cursor: 'pointer', marginRight: '8px', height: '16px' }} src={item.src}></img> : ''}
						<div style={{flex: 1, minWidth: '20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden'}} title={item.name}>{item.name}</div>
					</div>
				</div>
			</div>
			<div style={{ display: open || depth === 0 ? 'block' : 'none', paddingLeft: '12px', position: 'relative' }} className='panel_tree_parent_collapse'>
				{
					depth > 0 && !is_last_child ?
						<div className='panel_tree_collapse_indicator_container' style={{ height: '100%', position: 'absolute' }}>
							<div className='panel_tree_collapse_indicator'></div>
						</div>
						:
						''
				}
				{
					item.children && item.children.length ? item.children.map((child, idx ) => (
						child.children && child.children.length ?
							<ParentPanelTreeItem key={idx} item={child} route={route} common_onchange_handler={common_onchange_handler} depth={depth + 1} is_last_child={idx === (item.children.length - 1)}/>
							:
							<LeafPanelTreeItem key={idx} item={child} route={route} common_onchange_handler={common_onchange_handler} is_last_child={idx === (item.children.length - 1)}/>
					))
						: ''
				}
			</div>
		</React.Fragment>
	)
}

const LeafPanelTreeItem = ({ item, route, common_onchange_handler, is_last_child }) => {
	const onClickNode = () => {
		if(route){
			// common_onchange_handler();
			if(window.debug_mode){
				window.add_debug_log(route + "(\"" + item.id + "\");");
			}
			window.Module[route](item.id)
		}
	}
	return (
		<React.Fragment>
			<div style={{ display: 'flex', alignItems: 'center', height: '36px' }} className={`panel_tree_item_container ${is_last_child ? 'last_tree_item' : ''}`}>
				<div className='panel_tree_leaf_indicator_container' style={{height: '100%', position:'relative'}}>
					<div className='panel_tree_leaf_indicator'></div>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }} className='panel_tree_leaf' onClick={onClickNode}>
					<div className='panel_tree_item'>
						{item.src ? <img style={{ cursor: 'pointer', marginRight: '8px', height: '16px' }} src={item.src}></img> : ''}
						{/* {item.name} */}
						<div style={{flex: 1, minWidth: '20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden'}} title={item.name}>{item.name}</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

const PanelTreeCard = ({ json_item, on_click, common_onchange_handler }) => {
	return (
		<div style={{ textAlign: 'left', padding: '8px 0px' }}>
			{
				json_item.tree_data && json_item.tree_data.length ? json_item.tree_data.map((item, idx) => (
					item.children && item.children.length ?
						<ParentPanelTreeItem item={item} route={json_item.route} common_onchange_handler={common_onchange_handler}/>
						:
						<LeafPanelTreeItem item={item} route={json_item.route} common_onchange_handler={common_onchange_handler}/>
				))
					: ''
			}
		</div>
	)
}

const EdgeBandCard = ({json_item, on_click, icon_on_click, common_onchange_handler}) => {
	const [ hovered_edge_band, set_hovered_edge_band ] = useState('');
	const [ selected_edge_band, set_selected_edge_band ] = useState({
		front: false,
		left: false,
		back: false,
		right: false
	});
	const [ selection_mode, set_selection_mode ] = useState(false);

	const onClickEdgeBandItem = (edge_band_type) => {
		if(json_item && json_item[edge_band_type] && !json_item[edge_band_type].src && json_item[edge_band_type].add_edge_band_route){
			icon_on_click({ route: json_item[edge_band_type].add_edge_band_route, form: { id: json_item[edge_band_type].add_edge_band_id } })
		}else{
			// set_selected_edge_band((curr_selection) => {
			// 	let modified_selection = { ...curr_selection };
			// 	modified_selection[edge_band_type] = !curr_selection[edge_band_type];
			// 	return modified_selection
			// })

			let default_selection = {
				front: false,
				left: false,
				back: false,
				right: false
			}
			default_selection[edge_band_type] = !selected_edge_band[edge_band_type];
			set_selected_edge_band(default_selection);
		}
	}

	useEffect(() => {
		if(selected_edge_band){
			let selection_count = 0;
			let last_selected = ''
			for(let i in selected_edge_band){
				if(selected_edge_band[i]){
					selection_count++
					last_selected = i
				}
			}
			if(!selection_count){
				set_selection_mode(false)
			}else if(selection_count > 1){
				set_selection_mode("multi")
			}else{
				set_selection_mode(last_selected)
			}
		}else{
			set_selection_mode(false)
		}
	}, [selected_edge_band]);

	const is_cut_edgeband_present = json_item && json_item.cut

	return (
		<div style={{textAlign: 'left', padding: '8px', width: '100%'}}>
			<div className='panel_edgeband_visualiser' style={{marginBottom: is_cut_edgeband_present ? 'calc(24px + var(--panel_edgeband_item_thickness))' : '' }}>
				<Tooltip title = {json_item.back.tooltip_info}>
				<div onClick={() => {onClickEdgeBandItem('back')}} className={`flex_center panel_edgeband_item back ${selected_edge_band.back ? 'selected' : ''} ${json_item && json_item.back ? !json_item.back.edgeband_present ? 'no_edge_band' : '' : ''}`} onMouseEnter={() => set_hovered_edge_band('back')} onMouseLeave={() => set_hovered_edge_band('')} style={{ backgroundImage: json_item && json_item.back && json_item.back.src ? `url(${get_image_src(json_item.back.src)})` : '', position: 'absolute', height: 'var(--panel_edgeband_item_thickness)', top: '0px', width: 'calc(100% - var(--panel_edgeband_item_gap_x) - var(--panel_edgeband_item_gap_x))', left: 'var(--panel_edgeband_item_gap_x)'}}>
					<ButtonsDropdown customStyle={{menu: {margin: '0px'}}} button_list={json_item.back.dropdown_buttons} icon_on_click={icon_on_click} customToggle={<div className='flex_property inf-gap-2 edge_band_options_dropdown_toggle'><div className='edge_band_options_dropdown_toggle_text' title={json_item.back.default_value}>{json_item.back.default_value}</div> <Icons name={'chevron_left'} style={{ fontSize: '8px', transform: 'rotate(270deg)'}}/></div>}/>
				</div>
				</Tooltip>
				<Tooltip title = {json_item.left.tooltip_info}>
				<div onClick={() => {onClickEdgeBandItem('left')}} className={`flex_center panel_edgeband_item left ${selected_edge_band.left ? 'selected' : ''} ${json_item && json_item.left ? !json_item.left.edgeband_present ? 'no_edge_band' : '' : ''}`} onMouseEnter={() => set_hovered_edge_band('left')} onMouseLeave={() => set_hovered_edge_band('')} style={{ backgroundImage: json_item && json_item.left && json_item.left.src ? `url(${get_image_src(json_item.left.src)})` : '', position: 'absolute', width: 'var(--panel_edgeband_item_thickness)', left: '0px', height: 'calc(100% - var(--panel_edgeband_item_gap_y) - var(--panel_edgeband_item_gap_y))', top: 'var(--panel_edgeband_item_gap_y)'}}>
					<ButtonsDropdown customStyle={{menu: {margin: '0px'}}} button_list={json_item.left.dropdown_buttons} icon_on_click={icon_on_click} customToggle={<div className='flex_property inf-gap-2 edge_band_options_dropdown_toggle'> <Icons style={{fontSize: '8px'}} name={'chevron_left'}/> <div className='edge_band_options_dropdown_toggle_text' title={json_item.left.default_value}>{json_item.left.default_value}</div></div>}/>
				</div>
				</Tooltip>
				<Tooltip title = {json_item.front.tooltip_info}>
				<div onClick={() => {onClickEdgeBandItem('front')}} className={`flex_center panel_edgeband_item front ${selected_edge_band.front ? 'selected' : ''} ${json_item && json_item.front ? !json_item.front.edgeband_present ? 'no_edge_band' : '' : ''}`} onMouseEnter={() => set_hovered_edge_band('front')} onMouseLeave={() => set_hovered_edge_band('')} style={{ backgroundImage: json_item && json_item.front && json_item.front.src ? `url(${get_image_src(json_item.front.src)})` : '', position: 'absolute', height: 'var(--panel_edgeband_item_thickness)', bottom: '0px', width: 'calc(100% - var(--panel_edgeband_item_gap_x) - var(--panel_edgeband_item_gap_x))', left: 'var(--panel_edgeband_item_gap_x)'}}>
					<ButtonsDropdown customStyle={{menu: {margin: '0px'}}} button_list={json_item.front.dropdown_buttons} icon_on_click={icon_on_click} customToggle={<div className='flex_property inf-gap-2 edge_band_options_dropdown_toggle'><div className='edge_band_options_dropdown_toggle_text' title={json_item.front.default_value}>{json_item.front.default_value}</div> <Icons name={'chevron_left'} style={{ fontSize: '8px', transform: 'rotate(270deg)'}}/></div>}/>
				</div>
				</Tooltip>
				<Tooltip title = {json_item.right.tooltip_info}>
				<div onClick={() => {onClickEdgeBandItem('right')}} className={`flex_center panel_edgeband_item right ${selected_edge_band.right ? 'selected' : ''} ${json_item && json_item.right ? !json_item.right.edgeband_present ? 'no_edge_band' : '' : ''}`} onMouseEnter={() => set_hovered_edge_band('right')} onMouseLeave={() => set_hovered_edge_band('')} style={{ backgroundImage: json_item && json_item.right && json_item.right.src ? `url(${get_image_src(json_item.right.src)})` : '', position: 'absolute', width: 'var(--panel_edgeband_item_thickness)', right: '0px', height: 'calc(100% - var(--panel_edgeband_item_gap_y) - var(--panel_edgeband_item_gap_y))', top: 'var(--panel_edgeband_item_gap_y)'}}>
					<ButtonsDropdown customStyle={{menu: {margin: '0px'}}} button_list={json_item.right.dropdown_buttons} icon_on_click={icon_on_click} customToggle={<div className='flex_property inf-gap-2 edge_band_options_dropdown_toggle'> <Icons style={{fontSize: '8px'}} name={'chevron_left'}/> <div className='edge_band_options_dropdown_toggle_text' title={json_item.right.default_value}>{json_item.right.default_value}</div></div>}/>
				</div>
				</Tooltip>

				{ 
					is_cut_edgeband_present ? 
						<Tooltip title = {json_item.cut.tooltip_info}>
						<div onClick={() => {onClickEdgeBandItem('cut')}} className={`flex_center panel_edgeband_item cut ${selected_edge_band.cut ? 'selected' : ''} ${json_item && json_item.cut ? !json_item.cut.edgeband_present ? 'no_edge_band' : '' : ''}`} onMouseEnter={() => set_hovered_edge_band('cut')} onMouseLeave={() => set_hovered_edge_band('')} style={{ backgroundImage: json_item && json_item.cut && json_item.cut.src ? `url(${get_image_src(json_item.cut.src)})` : '', position: 'absolute', height: 'var(--panel_edgeband_item_thickness)', bottom: 'calc(-24px - var(--panel_edgeband_item_thickness))', width: 'calc(100% - var(--panel_edgeband_item_gap_x) - var(--panel_edgeband_item_gap_x))', left: 'var(--panel_edgeband_item_gap_x)'}}>
							<ButtonsDropdown customStyle={{menu: {margin: '0px'}}} button_list={json_item.cut.dropdown_buttons} icon_on_click={icon_on_click} customToggle={<div className='flex_property inf-gap-2 edge_band_options_dropdown_toggle'><div className='edge_band_options_dropdown_toggle_text' title={json_item.cut.default_value}>{json_item.cut.default_value}</div> <Icons name={'chevron_left'} style={{ fontSize: '8px', transform: 'rotate(270deg)'}}/></div>}/>
						</div>
						</Tooltip>
					: ''
				}

				{is_cut_edgeband_present ? <div className={`panel_edgeband_info_text ${hovered_edge_band || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 100%)'}}>Cut</div> : ''}
				<div className={`panel_edgeband_info_text ${hovered_edge_band || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', top: 'var(--panel_edgeband_item_thickness)', left: '50%', transform: 'translateX(-50%)'}}>Back</div>
				<div className={`panel_edgeband_info_text ${hovered_edge_band || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', left: 'var(--panel_edgeband_item_thickness)', top: '50%', transform: 'translateY(-50%)'}}>Left</div>
				<div className={`panel_edgeband_info_text ${hovered_edge_band || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', bottom: 'var(--panel_edgeband_item_thickness)', left: '50%', transform: 'translateX(-50%)'}}>Front</div>
				<div className={`panel_edgeband_info_text ${hovered_edge_band || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', right: 'var(--panel_edgeband_item_thickness)', top: '50%', transform: 'translateY(-50%)'}}>Right</div>

				{is_cut_edgeband_present ? <div className={`panel_edgeband_info_text ${!(hovered_edge_band === 'cut') || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', bottom: '0px', left: '50%', transform: 'translate(-50%, 100%)'}}>{json_item && json_item.cut && json_item.cut.name ? json_item.cut.name : json_item.cut.src ? '-' : ''}</div> : ''}
				<div className={`panel_edgeband_info_text ${!(hovered_edge_band === 'back') || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', top: 'var(--panel_edgeband_item_thickness)', left: '50%', transform: 'translateX(-50%)'}}>{json_item && json_item.back && json_item.back.name ? json_item.back.name : json_item.back.src ? '-' : ''}</div>
				<div className={`panel_edgeband_info_text ${!(hovered_edge_band === 'left') || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', left: 'var(--panel_edgeband_item_thickness)', top: '50%', transform: 'translateY(-50%)'}}>{json_item && json_item.left && json_item.left.name ? json_item.left.name : json_item.left.src ? '-' : ''}</div>
				<div className={`panel_edgeband_info_text ${!(hovered_edge_band === 'front') || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', bottom: 'var(--panel_edgeband_item_thickness)', left: '50%', transform: 'translateX(-50%)'}}>{json_item && json_item.front && json_item.front.name ? json_item.front.name : json_item.front.src ? '-' : ''}</div>
				<div className={`panel_edgeband_info_text ${!(hovered_edge_band === 'right') || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', right: 'var(--panel_edgeband_item_thickness)', top: '50%', transform: 'translateY(-50%)'}}>{json_item && json_item.right && json_item.right.name ? json_item.right.name : json_item.right.src ? '-' : ''}</div>
				
				{/* <div className={`panel_edgeband_info_text ${!(hovered_edge_band) || selection_mode ? 'hide' : ''}`} style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap'}}>{json_item && json_item[hovered_edge_band] && !json_item[hovered_edge_band].src ? 'Click to add edge band' : ''}</div> */}
			
				{
					selection_mode ? <div className='panel_edgeband_actions_container'>
						<div className='lines1_elipsis' style={{flex: 1, minWidth: '0px'}}>{selection_mode === 'multi' ? 'Mixed' : json_item && json_item[selection_mode] && json_item[selection_mode].name ? json_item[selection_mode].name : '-'}</div>
						{/* <div className='panel_button_array'>
							{json_item[selection_mode] && json_item[selection_mode].change_edge_band_route ? <img src={'/resources/toolbar_icons_new/change.svg'} title={"Remove Edge Band"} className={'button_panel'} style={{ height: '16px' }} onClick={(e) => { e.stopPropagation(); icon_on_click({ route: json_item[selection_mode].change_edge_band_route, form: { id: json_item[selection_mode].change_edge_band_id } })}} /> : '' }
							{json_item[selection_mode] && json_item[selection_mode].remove_edge_band_route ? <img src={'/resources/icons/remove.svg'} title={"Remove Edge Band"} className={'button_panel'} style={{ height: '16px' }} onClick={(e) => { e.stopPropagation(); icon_on_click({ route: json_item[selection_mode].remove_edge_band_route, form: { id: json_item[selection_mode].remove_edge_band_id } })}} /> : '' }
						</div> */}
						<ButtonsArray button_list={json_item[selection_mode] && json_item[selection_mode].buttons_list} icon_on_click={icon_on_click} />
					</div>
						: ''
				}

			</div>
		</div>
	)
}

const check_dropdown_state = (id, mode) => {
	if(window.Module){
		if(mode){
			let state = window.Module.get_dropdown_open_close_state(id + mode);
			return state
		}
		else{
			let state = window.Module.get_dropdown_open_close_state(id);
			return state	
		}
	}

	return false;
}

export const Create_card = ({parent, parent_menu,panel_key, json_item, set_panel_has_unapplied_edits,update_view, metric_system, approximation_mm, parent_id, approximation_ft, set_page_loader,handle_ui_response, approxMM, mmToFeetParts, panel_explode, is_grouped, index, configuration_type, set_modal_mode, set_layerset_mode, set_given_layerset, inside_tab, is_panel_pop_up, inside_tab_toolbar, active_tab_toolbar, mode, quick_dropdown_open, panel_search_json, is_search_mode, set_is_search_mode, search_string_panel, set_search_string_panel, panel_search_string, show_search_everything, is_draggable, onSubmitCallback, is_section}) => {
	const [ dropdownOpen, setDropdownOpen ] = useState(false);

	const toggle = () => setDropdownOpen(!dropdownOpen);
	const [collapsed, setCollapsed] = useState(true);

	const [clone_general_material_allowed, set_clone_general_material_allowed] = useState(true);
    const {t} = useTranslation()
	
	useEffect(() => {
		if(window['Module'] && window['Module'].is_feature_enabled){
			set_clone_general_material_allowed(window.Module.is_feature_enabled("clone_general_material"));
		}
		// console.log("json item", json_item)
	},[json_item])


	useEffect(() => {
		if(panel_explode && json_item.type=='dropdown' && !is_dropdown_open()){
			// let temp = (mode == 'search' && (json_item.buttons && json_item.buttons.length && json_item.buttons.filter(x => (x.type != 'switch' && x.name.toLowerCase().includes(search_string_panel.toLowerCase()).length))))
			// if()
			// {
			// 	dropdown_on_click()
			// }
			if(mode == 'search'){
				if(json_item.buttons && json_item.buttons.length){
					let temp = json_item.buttons.filter(x => (x.type != 'switch' && (x.name.toLowerCase()).includes((search_string_panel.toLowerCase())))).length
					if(!temp){
						dropdown_on_click();
					}
				}else{
					dropdown_on_click()
				}
			}else{
				dropdown_on_click();
			}
		}
	}, [panel_explode]);

	// useEffect(() => {
	// 	if(!panel_explode && json_item.type=='dropdown' && !is_dropdown_open()){
	// 		dropdown_on_click()
	// 	}
	// }, [mode]);

	// useEffect(() => {
		
	// }, [search_string_panel]);

	const handleSelectInputChange = (event) => {
		event.target.blur();
	}

	useEffect(() => {
		if(json_item.type == 'colorpicker'){
			window.addEventListener('change', handleSelectInputChange);
			return () => {
				window.removeEventListener('change', handleSelectInputChange);
			}
		}
	}, []);

	useEffect(() => {
		if(quick_dropdown_open && json_item.type=='dropdown' && !is_dropdown_open()){
			dropdown_on_click()
		}
	}, [quick_dropdown_open]);
	// const [collapsed, setCollapsed] = useState((json_item && json_item.type == "dropdown" && json_item.open)?false:true);

	// Added by Akhil
	// need to use useEffect or something similar to set the default state of collapsed from json_item.open (this will be used for repopulating the panel in the same state)
	// 
	// useEffect(() => {
	// 	if(json_item && json_item.type == "dropdown" && json_item.open){
	// 		console.log(collapsed)
	// 		console.log(json_item.open)
	// 		setCollapsed(false)
	// 	}
	// },[json_item])

	const alert = useAlert();

  	const toggleNavbar = () => setCollapsed(!collapsed);

	const tab_on_click = async (id, mode) => {
		var active_design = window.Module.get_active_design()
		var active_view = active_design.active_view;
		if(mode){
			if(window.debug_mode){
				window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + (id + mode) + "\");");
			}
			active_view.toggle_dropdown_as_open_or_close(id + mode)
		}
		else{
			if(window.debug_mode){
				window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + (id) + "\");");
			}
			active_view.toggle_dropdown_as_open_or_close(id)
		}
		toggleNavbar();
		active_design.delete();
		active_view.delete();
	}
	
	// const populate_panel_search = async(search_string) => {
	// 	// var active_design = window.Module.get_active_design();
	// 	// var active_view = active_design.active_view;
	// 	var promise_resp;
	// 	if(window.Module){
	// 		try{
	// 			promise_resp = window.Module.populate_panel_search(JSON.stringify({"search": search_string}));
	// 		}
	// 		catch(err){
	// 			// alert.error("Something went wrong with search")
	// 			return
	// 		}
	// 	}
	
	// 	var ui_response = await window.Promisify(promise_resp);
	// 	handle_ui_response(ui_response);
	
	// 	update_view()
	
	// }	

  	const get_dimension = (id, dimension_item) => {
  		if(((dimension_item && dimension_item.expression_available_parameters && Object.keys(dimension_item.expression_available_parameters).length))){
			let el = document.getElementById(id)
			if(!el){
				return "Invalid Inputs";
			}
			if(isNaN(el.value)){
				var valid_expr = window.Module.validate_expression_interface(el.value, JSON.stringify(dimension_item.expression_available_parameters))
				if(valid_expr == "success"){
					return {expression:el.value}
				}else{
					return "Invalid Inputs";
				}
			}else{
				return Number(el.value);
			}
			//passing 0 when mixed. Might need to send empty string in future.
		}else if (metric_system == 'mm' || dimension_item.is_unitless_true ){
			let el = document.getElementById(id)
			if(!el || isNaN(el.value)){
				return "Invalid Inputs";
			}
			//passing 0 when mixed. Might need to send empty string in future.
			return /*dimension_item.mixed && document.getElementById(id).value === '' ? '' : */Number(el.value);
		}
		else if(metric_system == 'ft'){
			let el_ft = document.getElementById(id + "_ft")
			let el_in = document.getElementById(id + "_in")
			let el_pt = document.getElementById(id + "_parts")
			if(!el_ft || isNaN(el_ft.value) || !el_in || isNaN(el_in.value) || !el_pt || isNaN(el_pt.value)){
				return "Invalid Inputs";
			}

			var ft_val = Number(el_ft.value);
			var in_val = Number(el_in.value);
			var parts_val = Number(el_pt.value);

			var ft_inch_only =  Number(ft_val*304.8)+(in_val*(25.4));
			if(approximation_ft == '1/8_inch'){
				var fraction =  (parts_val*(25.4/8));
			}else if(approximation_ft == '1/16_inch'){
				var fraction =  (parts_val*(25.4/16));
			}

			return Number(ft_inch_only) + Number(fraction);
		}
  	}

	const fetch_store_materials_onclick = () => {

		var analytics_data = {};
        analytics_data.category = "Material fetch from store clicked";
		analytics_data.element_type = window.Module.get_selection_element_type();
        send_analytics(analytics_data,true);
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => { 
			var active_design = window.Module.get_active_design();
			var active_view = active_design.active_view;
			active_view.active_material_select_item_id = json_item.id;
			if(window.debug_mode){
				window.add_debug_log("fetch_store_materials_onclick(\"" + json_item.id + "\", \"" + json_item.route + "\", \"" + json_item.material_type.replace(/"/g, '\\"') + "\", \"" + json_item.active_category_id + "\", " + json_item.pass_params_to_carousel_onclick + ", \"" + json_item.parameter_expression_self_element_id + "\", \"" + json_item.parameter_expression_map_key + "\");");
			}
			window.Module.fetch_store_materials_onclick(json_item.id, json_item.route, json_item.material_type, json_item.active_category_id, json_item.pass_params_to_carousel_onclick, json_item.parameter_expression_self_element_id, json_item.parameter_expression_map_key);
			update_view();
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
			active_design.delete();
			active_view.delete();
		},0)
	};

	const on_click_picker = async() => {
		// if(json_item && json_item.type == "material_select"){
		// 	icon_on_click({route: "trigger_material_picker_apply", form: {json_item: json_item, switch_material: switch_material, is_panel_pop_up: is_panel_pop_up}}, false)
		// }
		if(json_item && json_item.type == "material_select"){
			try{
				var active_design = window.Module.get_active_design();
				var active_view = active_design.active_view;
				active_view.active_material_select_item_id = json_item.id;
				
				var to_pass = {};
				to_pass.is_panel_pop_up = is_panel_pop_up ? true : false;
				to_pass.material_type = json_item.material_type;

				to_pass.id = json_item.id;
				to_pass.route = json_item.route;
				to_pass.pass_params_to_carousel_onclick = json_item.pass_params_to_carousel_onclick;


				if(window.debug_mode){
					window.add_debug_log("trigger_material_picker_apply" + "(\"" + (JSON.stringify(to_pass).replace(/"/g, '\\"')) + "\");");
				}
				window.Module['trigger_material_picker_apply'](JSON.stringify(to_pass))
				update_view();
				active_design.delete();
				active_view.delete();
			} catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu (material picker) onclick error at " + "trigger_material_picker_apply" + " : " + err.name // change reset_function to actual reset function above
				console.error(err);
				window.sentry_capture_exception("",err);
			}
		}
	}

	const open_material_settings = async() => {
		if(json_item && json_item.type == "material_select" && json_item.active_material){
			try{
				var active_design = window.Module.get_active_design();
				var active_view = active_design.active_view;
				active_view.active_material_select_item_id = json_item.id;
				if(window.debug_mode){
					window.add_debug_log("trigger_material_edit_modal" + "(\"" + json_item.active_material.id + "\");");
				}
				window.Module['trigger_material_edit_modal'](json_item.active_material.id)
				update_view();
				active_design.delete();
				active_view.delete();
			} catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu (reset to default for material) onclick error at " + "reset_function" + " : " + err.name // change reset_function to actual reset function above
				console.error(err);
				window.sentry_capture_exception("",err);
			}
		}
	}

	const reset_material = async() => {
		if(json_item && json_item.type == "material_select"){
			try{
				if(window.debug_mode){
					window.add_debug_log("reset_original_material_general" + "(\"" + JSON.stringify(json_item).replace(/"/g, '\\"') + "\");");
				}
				window.Module['reset_original_material_general'](JSON.stringify(json_item))
				update_view();
			} catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu (reset to default for material) onclick error at " + "reset_function" + " : " + err.name // change reset_function to actual reset function above
				console.error(err);
				window.sentry_capture_exception("",err);
			}
		}
	}

	const switch_material = async (mat_id, json_item, is_panel_pop_up) => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.active_material_select_item_id = json_item.id;
        var onclick_params = {}
        onclick_params.panel_id = json_item.id
        onclick_params.sku_id = mat_id

        var final_params = json_item.pass_params_to_carousel_onclick?JSON.stringify(onclick_params):mat_id;
        if(window.debug_mode){
            window.add_debug_log(json_item.route + "(\"" + final_params.replace(/"/g, '\\"') + "\");");
        }
        var resp = await window.Promisify(window.Module[json_item.route](final_params));
        handle_ui_response(resp);
        if(is_panel_pop_up){
            repopulate_panel_pop_up();
        }else{
            repopulate_panel();
        }
        update_view();

        active_design.delete();
        active_view.delete();
    }
	
	const clone_active_modal_onclick = () => {
		if(json_item && json_item.type == "material_select" && json_item.active_material){
			var active_design = window.Module.get_active_design();
			var active_view = active_design.active_view;
			active_view.active_material_select_item_id = json_item.id;

			var apply_data = {};
			apply_data.id = json_item.id;
			apply_data.pass_params_to_carousel_onclick = json_item.pass_params_to_carousel_onclick;
			apply_data.route = json_item.route;
			apply_data.is_panel_pop_up = is_panel_pop_up;

			if(window.debug_mode){
				window.add_debug_log("clone_active_modal_onclick(\"" + json_item.active_material.id + "\", \"" + json_item.id + "\", \"" + json_item.route + "\", \"" + JSON.stringify(apply_data).replace(/"/g, '\\"') + "\");");
			}

			window.Module.clone_active_modal_onclick(json_item.active_material.id, json_item.id, json_item.route, JSON.stringify(apply_data));
			update_view();
			active_design.delete();
			active_view.delete();

			var analytics_data = {};
			analytics_data.category = "Material clone clicked";
			analytics_data.element_type = window.Module.get_selection_element_type();
			send_analytics(analytics_data,true);
		}
	};
	
	const get_active_index = (composites, active_id) => {
		return window._.findIndex(composites,o => o.id == active_id);
	}

	const get_active_hatching_index = (categories, active_id) => {
		for(let i=0; i< (categories && categories.length); i++){
			for(let j=0; j< (categories[i].hatchings && categories[i].hatchings.length); j++){
				if(categories[i].hatchings[j].hatchingPattern === active_id){
					return categories[i].hatchings[j];
				}
			}
		}
		// return window._.findIndex(hatchings,o => o.hatchingPattern == active_id);
	}

	const composite_select_onclick = (composite_id) => {
		if(json_item.route){
			if(window.debug_mode){
				window.add_debug_log(json_item.route + "(\"" + composite_id + "\");");
			}
			window.Module[json_item.route](composite_id);
			is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			// mode == 'search' ? populate_panel_search(search_string_panel) :''
			update_view();
		}
	}

	const common_onchange_handler = (e) => {
		set_panel_has_unapplied_edits(true);
	}
	
	// Can depricate on_select_input_change
	const on_select_input_change = async (value) => {
		if(json_item.onchange_route && json_item.onchange_route != ""){
			var form = {
				id: parent_id
			}
			form[json_item.id] = value
			
			var promise_resp;
			try{
				if(window.debug_mode){
					window.add_debug_log(json_item.onchange_route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
				}
				promise_resp = window.Module[json_item.onchange_route](JSON.stringify(form));
			}catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu onclick error at " + json_item.onchange_route + ", form=" + JSON.stringify(form) + " : " + err.name
				console.error(err);
				window.sentry_capture_exception("",err);
				alert.error("Something went wrong")
				return;
				
			}
			var ui_response = await window.Promisify(promise_resp);
			common_onchange_handler();

			handle_ui_response(ui_response);
			// repopulate_panel_pop_up();
			is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			// mode == 'search' ? populate_panel_search(search_string_panel) :''
			
			update_view();
  		}
	}

	const icon_on_click_inner_fn = async({route, form, update_panel}) => {
		try{
			// common_onchange_handler();
			if(window.debug_mode){
				window.add_debug_log(route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
			}
			let promise_resp = window.Module[route](JSON.stringify(form));
			var ui_response = await window.Promisify(promise_resp);
			
			window.handle_ui_response(ui_response);
			// repopulate_panel_pop_up();
			if (update_panel)
				is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			// mode == 'search' ? populate_panel_search(search_string_panel) :''

			window.update_view();
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Panel menu onclick error at " + route + ", form=" + JSON.stringify(form) + " : " + err.name
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
			
		}
	}

	const icon_on_click = async ({route, form, item, update_panel=true}) => {
		if(item && item.call_with_loader){
			set_page_loader({show: true, text: "Please Wait..."}, async() => {
				await sleep(10)
				await icon_on_click_inner_fn({route, form, update_panel})
				set_page_loader({show: false, text: "Please Wait..."})
			})
		}else{
			await icon_on_click_inner_fn({route, form, update_panel})
		}

		try{

			// const top_level_component = window.get_panel_top_level_element()
			// if(top_level_component){
			// 	let analytics_data = {}
			// 	analytics_data.category = `${top_level_component} edited`;
			// 	analytics_data.option = `${window.get_current_panel_level()}.${(parent ? (parent.name + '.') : '') + route}`
			// 	send_analytics(analytics_data, true)
				
			// }
		}catch(err){
			console.error("Could not send analytics_data for left panel property edited")
			handleError(err)
		}
	}

  	const on_click = async (arg = {}) => {

		let _json_item = json_item;
		let _parent_menu = parent_menu;
		let _parent_id = parent_id;

		if(arg.json_item){
			_json_item = arg.json_item
		}

		if(arg.parent_menu){
			_parent_menu = arg.parent_menu
		}

		if(arg.parent_id){
			_parent_id = arg.parent_id
		}


  		if(_json_item.route || _json_item.onchange_route){
  			const { item_value } = arg;
			var form = {
  				id: _json_item.type == 'button' || _json_item.type == 'button_item' || _json_item.type == 'list-item' || _json_item.type == 'toggle' || _json_item.type == 'presentation_sheet' || _json_item.type == 'dynamic-component-item' ? _json_item.id : ((_json_item.type == 'select_input' || _json_item.type == 'layer_input') && _json_item.use_this_id) ? _json_item.use_this_id :  _parent_id
  			}
			

			// console.log("form ", form, _parent_id)

  			if(_parent_menu){
  				for (var i = 0; i < _parent_menu.length; i++) {
					// if(current_item.mixed && current_item.id != _json_item.id){
					// 	continue;
					// }

					let current_item = _parent_menu[i]
					
  					if(current_item.type == "text_input"){
  						if(document.getElementById(current_item.id)){
							form[current_item.id] = document.getElementById(current_item.id).value;
  						}
  					}else if(current_item.type == "dimension_input"){
						var dim_parse = get_dimension(current_item.id, current_item);
						if(dim_parse == "Invalid Inputs"){
							alert.error(dim_parse);
							return;
						}else if(!(current_item.mixed && dim_parse === '') && current_item.min_max_enabled && (dim_parse > Number(current_item.upper_limit) || dim_parse < Number(current_item.lower_limit))){
							alert.error(`Input not in range: [${current_item.lower_limit}, ${current_item.upper_limit}]`)
							return;
						}else{
							form[current_item.id] = dim_parse;
						}
  					}else if(current_item.type == "ref_level_dimension_input"){
						if(document.getElementById(current_item.id + "_ref_level_select")){
							var current_val = document.getElementById(current_item.id + "_ref_level_select").value;
							if((!current_val) || current_val == "no_ref_level"){
								var dim_parse = get_dimension(current_item.id, current_item);
								if(dim_parse == "Invalid Inputs"){
									// if(dim_parse.constructor.name == "String"){
									alert.error(dim_parse);
									return;
								}else if(!(current_item.mixed && dim_parse === '') && current_item.min_max_enabled && (dim_parse > Number(current_item.upper_limit) || dim_parse < Number(current_item.lower_limit))){
									alert.error(`Input not in range: [${current_item.lower_limit}, ${current_item.upper_limit}]`)
									return;
								}else{
									form[current_item.id] = dim_parse;
								}
							}else{
								var dim_parse = get_dimension(current_item.id + "_offset", current_item);
								if(dim_parse == "Invalid Inputs"){
									alert.error(dim_parse);
									return;
								}else if(!(current_item.mixed && dim_parse === '') && current_item.min_max_enabled && (dim_parse > Number(current_item.upper_limit) || dim_parse < Number(current_item.lower_limit))){
									alert.error(`Input not in range: [${current_item.lower_limit}, ${current_item.upper_limit}]`)
									return;
								}else{
									form[current_item.id] = {
										ref_level: current_val,
										offset: dim_parse
									};
								}
							}
						}
					}else if(current_item.type == "select_input" || current_item.type == "layer_input" || current_item.type == "rich_layer_input"){
  						if(document.getElementById(current_item.id)){
  							form[current_item.id] = document.getElementById(current_item.id).value;
  						}
  					}else if(current_item.type == "image_map_select"){
						if(document.getElementById(current_item.id)){
							form[current_item.id] = document.getElementById(current_item.id).value;
						}
					}else if(current_item.type == 'slider' || current_item.type == 'slider_noshow'){
						if(document.getElementById(current_item.id)){
							let value = Number(document.getElementById(current_item.id).value);
							if(value < Number(current_item.min) || value > Number(current_item.max)){
								alert.error(`Input not in range: [${current_item.min}, ${current_item.max}]`)
								return;
							}
							form[current_item.id] = value;
						}
					}
					else if(current_item.type == "colorpicker"){
  						if(document.getElementById(current_item.id)){
  							form[current_item.id] = document.getElementById(current_item.id).value;
  						}
  					}else if(current_item.type == 'special_dimension_input'){
						form[current_item.id.width] = document.getElementById(current_item.id + '_x')
						form[current_item.id.height] = document.getElementById(current_item.id + '_y')
						form[current_item.id.depth] = document.getElementById(current_item.id + '_z')
					}else if(current_item.type == 'rotation_input'){
						if(document.getElementById(current_item.id)){
							form[current_item.id] = Number(document.getElementById(current_item.id).value);
						}
					}else if(current_item.type == 'select_and_dimension_input'){
						form[current_item.id_select] = document.getElementById(current_item.id_select + 'st').value;
						var dim_parse = get_dimension(current_item.id_dim, current_item);
						if(dim_parse == "Invalid Inputs"){
							alert.error(dim_parse);
							return;
						}else if(current_item.min_max_enabled && (dim_parse > Number(current_item.upper_limit_dim) || dim_parse < Number(current_item.lower_limit_dim))){
							alert.error(`Input not in range: [${current_item.lower_limit_dim}, ${current_item.upper_limit_dim}]`)
							return;
						}else{
							form[current_item.id_dim] = dim_parse;
						}
					}else if(current_item.type == 'toggle'){
						var current_val = true;
						if(document.getElementById(current_item.id).attributes['aria-checked']){
							current_val = document.getElementById(current_item.id).attributes['aria-checked'].value == 'true';
						}
						// console.log("toggle ", current_val);
						form[current_item.id] = !(current_val);
						// form[current_item.id] = !(document.getElementById(current_item.id).checked);
					}else if(current_item.type == "checkbox"){
						if(document.getElementById(current_item.id)){
							form[current_item.id] = (document.getElementById(current_item.id).checked);
						}
					}
  				}
  			}

			if(item_value && typeof(item_value) !== undefined){
				form[_json_item.id] = item_value
			}

  			//special cases
  			if(_json_item.id == "blend_medium" || _json_item.id == "blend_high" || _json_item.id == "blend_presentation" || _json_item.id == "3ds" || _json_item.id == "glb_export_with_khr" || _json_item.id == "glb_export_without_khr" || _json_item.id == "3ds_export"){
				var cam_pos = window.Module.gl_get_camera_position();
        		var cam_target = window.Module.gl_get_camera_target();
  				form.camera_pos = {
  					x: cam_pos.x,
  					y: cam_pos.y,
  					z: cam_pos.z
  				}

  				form.camera_rot = {
  					x: cam_target.x,
  					y: cam_target.y,
  					z: cam_target.z
  				}

  				form.aspect = window.Module.gl_get_camera_aspect();
				form.fov = window.Module.gl_get_camera_fov();
				cam_pos.delete();
				cam_target.delete();
				set_page_loader({
					show: true,
					text: 'Please wait'
				});

				await dummy_tick(0);
			}

			if(_json_item.id == "active_sales_channel_id_apply" || _json_item.id == "modify_menu_cam_flow" || _json_item.id == "refresh_admin_cache" || _json_item.id == "pricing_quotation" || _json_item.id == "cabinet_composite_schedule" || _json_item.route == "open_kitchen_preferences_carousel" || _json_item.route == "check_for_violations_and_proceed_to_output" || _parent_id=="livspace_dropdown" || _parent_id=="spacewood_dropdown" || _parent_id=="copy-specific-floor" || _parent_id=="copy-specific-building"){
				set_page_loader({
					show: true,
					text: 'Please wait'
				});
				await dummy_tick(0);
			}
			  
			if(_json_item.id == "design_thumbnail"){
				form.img_data = window.get_snapshot_data(450,253)
			}
			
			var promise_resp;
			try{
				// promise_resp = window.Module[json_item.type == 'button' || json_item.type == 'list-item' || json_item.type == 'presentation_sheet' ? json_item.route : json_item.onchange_route](JSON.stringify(form));
				var final_route = _json_item.type == 'button' || _json_item.type === 'button_item' || _json_item.type == 'list-item' || _json_item.type == 'presentation_sheet' || _json_item.type == 'dynamic-component-item' ? _json_item.route : _json_item.onchange_route;
				if(window.debug_mode){
					window.add_debug_log(final_route + "(\"" + JSON.stringify(form).replace(/"/g, '\\"') + "\");");
				}
				try{

					// const top_level_component = window.get_panel_top_level_element()
					// if(top_level_component){
					// 	let show_json_item = true;
					// 	if(json_item.type === 'list-item' && json_item.is_material){
					// 		show_json_item = false
					// 	}
					// 	let analytics_data = {}
					// 	analytics_data.category = `${top_level_component} edited`;
					// 	analytics_data.option = `${window.get_current_panel_level()}${(parent ? ('.' + parent.name) : '')}${_json_item.name && show_json_item ? ('.' + _json_item.name) : ''}`
					// 	send_analytics(analytics_data, true)
						
					// }
				}catch(err){
					console.error("Could not send analytics_data for left panel property edited")
					handleError(err)
				}
				promise_resp = window.Module[final_route](JSON.stringify(form));
			}catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Panel menu onclick error at " + (_json_item.route||_json_item.onchange_route) + ", form=" + JSON.stringify(form) + " : " + err.name
				console.error(err);
				window.sentry_capture_exception("",err);
				alert.error("Something went wrong")
				set_page_loader({
					show: false,
					text: 'Please wait'
				});
				return;
				
			}
			var ui_response = await window.Promisify(promise_resp);
			
			var repopulation_allowed = true;
			let update_view_allowed = true;
			if(ui_response!=""){
				var resp = JSON.parse(ui_response);
				if(resp.dont_repopulate && resp.dont_repopulate==true){
					repopulation_allowed = false;
				}
				if(resp.dont_update_view && resp.dont_update_view==true){
					update_view_allowed = false;
				}
			}
			// repopulate_panel_pop_up();
			if(repopulation_allowed){
				is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			}
			// mode == 'search' ? populate_panel_search(search_string_panel) :''

			if(_json_item.id == "active_sales_channel_id_apply" || _json_item.id == "modify_menu_cam_flow" || _json_item.id == "blend_medium" || _json_item.id == "blend_high" || _json_item.id == "blend_presentation" || _json_item.id == "3ds" || _json_item.id == "glb_export_with_khr" || _json_item.id == "glb_export_without_khr" || _json_item.id == "3ds_export" || _json_item.id == "refresh_admin_cache" || _json_item.id == "pricing_quotation" || _json_item.id == "cabinet_composite_schedule" || _json_item.route == "open_kitchen_preferences_carousel" || _json_item.route == "check_for_violations_and_proceed_to_output" || parent_id=="livspace_dropdown" || parent_id=="spacewood_dropdown" || parent_id=="copy-specific-floor" || parent_id=="copy-specific-building"){
				set_page_loader({
					show: false,
					text: 'Please wait'
				});
				await dummy_tick(0);
			}
			
			if(update_view_allowed){
				update_view();
			}
			
			if(onSubmitCallback){
				onSubmitCallback()
			}
			handle_ui_response(ui_response);
  		}
  	}

	const dropdown_on_click = async () => {
		var active_design = window.Module.get_active_design()
		var active_view = active_design.active_view;
		if(mode){
			if(window.debug_mode){
				window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + (json_item.id + mode) + "\");");
			}
			active_view.toggle_dropdown_as_open_or_close(json_item.id + mode)
		}
		else{
			if(window.debug_mode){
				window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + (json_item.id) + "\");");
			}
			active_view.toggle_dropdown_as_open_or_close(json_item.id)
		}
		toggleNavbar();
		active_design.delete();
		active_view.delete();
	}

	const is_dropdown_open = () => {
		if(window.Module){
			if(mode){
				return window.Module.get_dropdown_open_close_state(json_item.id + mode);
			}
			else{
				return window.Module.get_dropdown_open_close_state(json_item.id);
			}
		}

		return false;
	}

	const get_dropdown_label = () => {
		if(json_item.type == 'dropdown' && json_item.submenu && json_item.submenu.length && json_item.submenu.filter(x => x.type == 'dimension_input' || x.type == 'ref_level_dimension_input').length){
			if(metric_system == 'mm'){
				return "(in mm)"
			}else {
				if(approximation_ft=='1/8_inch'){
					return '(ft,in,¹/₈")'
				}else{
					'(ft,in,¹/₁₆")'
				}
			}
		}
		return ''
	}

	const onDragEnd = async(result) => {
		if(!result || !result.destination) return;
        const src = result.source;
        const dest = result.destination;
		if(!src || !dest) return;
		if(result.destination.index == result.source.index) return;
		let form = {}
		form['src_idx'] = src.index;
		form['dest_idx'] = dest.index;
		form['src_id'] = json_item.submenu[src.index].id;
		form['dest_id'] = json_item.submenu[dest.index].id;
		// console.log("KPP the form is", form)
		let resp = ''
        try{
            resp = await window.Promisify(window.Module.move_presentation_sheet(JSON.stringify(form)))
        }
        catch(err){
            console.log(err)
        }
        handle_ui_response(resp)
        // update_view();
	}

	const type_fn = () => {
		// const focusNextRef = useFocusNext();
		// if(json_item && json_item.section_type === "dimension"){
		// 	return <DimensionSection json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} />
		// }
		if(json_item && (json_item.component_type === "section" || json_item.component_type === "sub_section") ){
			return(
				<PanelSection
					on_click={on_click}
					icon_on_click={icon_on_click}
					common_onchange_handler={common_onchange_handler}
					panel_key={panel_key}
					set_page_loader={set_page_loader}
					handle_ui_response={handle_ui_response}
					metric_system={metric_system}
					approximation_mm={approximation_mm}
					approximation_ft={approximation_ft}
					// parent_menu={panel_json}
					update_view={update_view}
					json_item={json_item}
					set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
					approxMM={approxMM}
					mmToFeetParts={mmToFeetParts}
					panel_explode={false}
					is_panel_pop_up={is_panel_pop_up}
					mode={mode}
					panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
				/>
			)
		}
		if(json_item && json_item.type){
			// console.log(json_item)
			if(json_item.type=='blank'){
				return(<div></div>)
			}
			else if(json_item.type && typeof(json_item.type) === 'string' && json_item.type === 'edge_band_component'){
				return <EdgeBandCard json_item={json_item} on_click={on_click} icon_on_click={icon_on_click}/>
			}
			else if((json_item && json_item.type=='dropdown' && json_item.configuration_type=='dnd')){
				const create_card_props = {
					is_draggable: true,
					panel_key:panel_key,
					set_page_loader: set_page_loader,
					handle_ui_response: handle_ui_response,
					metric_system: metric_system,
					approximation_mm: approximation_mm,
					approximation_ft: approximation_ft,
					parent_id: json_item.id,
					update_view: update_view,
					set_panel_has_unapplied_edits: set_panel_has_unapplied_edits,
					approxMM: approxMM,
					mmToFeetParts: mmToFeetParts,
					panel_explode: panel_explode,
					is_grouped: json_item.group_child_inputs,
					configuration_type: json_item.configuration_type,
					inside_tab: inside_tab,
					is_panel_pop_up: is_panel_pop_up,
					mode: mode,
					panel_search_json: panel_search_json,
					is_search_mode: is_search_mode,
					set_is_search_mode:  set_is_search_mode,
					search_string_panel: search_string_panel, 
					set_search_string_panel: set_search_string_panel,
					panel_search_string: panel_search_string,
					onSubmitCallback:onSubmitCallback
				}
				return (
					<DnD Single_Component_arr={json_item.submenu} Single_Component={Create_card} Single_Component_props={create_card_props} onDragEnd={onDragEnd}/>
				)
			}
			else if(json_item && json_item.type=='dropdown' && ((json_item.group_child_inputs && !json_item.collapsable) || json_item.configuration_type == 'not_dropdown')){
				let json_item_submenu_copy = JSON.parse(JSON.stringify(json_item.submenu));
				// json_item_submenu_copy && json_item_submenu_copy.length ? json_item_submenu_copy.map((sub_item) =>(
				// 	sub_item.type = 'special_dimension_input'
				// )): ''
				// json_item_submenu_copy.type = 'special_dimension_input'
				// json_item_submenu_copy.route = json_item.onChangeRoute
				// json_item_submenu_copy.name = json_item.name
				// json_item_submenu_copy.id = json_item.id
				let child_tab_json = {}
				child_tab_json.tabs = json_item_submenu_copy
				child_tab_json.type = 'child_tabs'
				child_tab_json.tab_configuration = json_item.tab_configuration
				return(
					<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
						<div className={json_item.submenu && (json_item.submenu.length || (json_item.buttons && json_item.buttons.length))?'':'panel_disabled'} style={{height:'36px', width:'100%',padding:'0px 8px', overflow: 'hidden', display:json_item.remove_dropdown || (!json_item.submenu.length && !(json_item.buttons && json_item.buttons.length) ) ? 'none' :'flex', flexDirection:'row', alignItems:json_item.configuration_type == "is_name"?'center':'end', fontWeight:700, borderBottom:json_item.configuration_type == "is_name" ? '2px solid ' + COLORS.panel_item_separation : 'none'}}>
							<span style={{flex:8 , textAlign:'left' }} >
								<span id={json_item.id} title={json_item.name /*+ get_dropdown_label()*/} className='lines2_elipsis dropdown_heading_panel' style={{ color:COLORS.gray6, fontStyle: 'italic', fontWeight:'500'}} ><span style={{textDecoration:'underline', color:COLORS.gray6}}>{json_item && json_item.name ? json_item.name + ' ':''}</span> {/*<span style={{ fontSize:'12px', color:COLORS.gray6, fontWeight:'normal'}}>{get_dropdown_label()}</span>*/}</span>
								{/* <span id={json_item.id + 'label'} title={get_dropdown_label()} className='lines2_elipsis' style={{ fontSize:'14px', color:COLORS.panel_group_heading}} >{get_dropdown_label()}</span> */}
							</span>
							<div style={{flex:3, display:json_item.buttons && json_item.buttons.length ? 'flex' : 'none', alignItems:'center', justifyContent:'flex-end'}}>
							{
									json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) => (
										item.type == "switch" ? 
											<Switch key={idx} disabled={item.disabled} style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
										:
										item.type === "multiline_tooltip" ?
											<TriggerTooltip title={item.multiline_tooltip ? item.multiline_tooltip.entries : ""} placement="right" overlayStyle={{maxWidth: 'unset'}}/>
										: item.type == 'label' ? 
											<span key={idx} style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
										: item.icon == "+" ?
											<span key={idx} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize', display:'flex'}} onClick={(e) => {icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}><img style={{marginRight: '4px'}} height='12px' src='/resources/panel_icons_new/blue_add.svg'></img> Add </span>
											// <span key={idx} style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {is_dropdown_open() ? e.stopPropagation() : null; icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
										:
											<img key={idx} src={item.icon} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{ marginRight:'8px', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
									))
									: ''
								}
							</div>
						</div>

						<Collapse className='d--------------------d' isOpen={json_item.disabled ? false : true} navbar={true} style={json_item.buttons && json_item.buttons.length && json_item.buttons.filter(x => x.icon == "/resources/panel_icons_new/lock layer.svg").length ? { width:'100%', overflowX: 'hidden', opacity: 0.5, pointerEvents:'none' } : { width:'100%', overflowX: 'hidden', opacity: 1}}>
							{
								json_item.make_child_tabs ? 
									<Create_card 
										set_modal_mode={set_modal_mode}
										panel_key={panel_key}
										set_page_loader={set_page_loader}
										handle_ui_response={handle_ui_response}
										metric_system={metric_system}
										approximation_mm={approximation_mm}
										approximation_ft={approximation_ft}
										// parent_menu={panel_json}
										update_view={update_view}
										json_item={child_tab_json}
										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
										approxMM={approxMM}
										mmToFeetParts={mmToFeetParts}
										panel_explode={false}
                                        is_panel_pop_up={is_panel_pop_up}
										mode={mode}
										panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback} is_section={is_section}
									/>
									:''
							}

							{
								json_item.submenu && json_item.submenu.length && (json_item.submenu[0].type == 'top_level_tabs' || json_item.submenu[0].type == 'top_level_name') && !(mode == 'search') ? 
									<TabToolbar 
										panel_key={panel_key}
										set_page_loader={set_page_loader}
										handle_ui_response={handle_ui_response}
										metric_system={metric_system}
										approximation_mm={approximation_mm}
										approximation_ft={approximation_ft}
										// parent_menu={panel_json}
										update_view={update_view}
										json_item={json_item.submenu[0]}
										child_menu={json_item.submenu.slice(1)}
										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
										approxMM={approxMM}
										mmToFeetParts={mmToFeetParts}
										panel_explode={false}
                                        is_panel_pop_up={is_panel_pop_up}
										tab_on_click={tab_on_click}
										index={index}
										panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
									/>
									:''
							}
							
							{
								json_item.make_child_tabs || (json_item.submenu && json_item.submenu.length && (json_item.submenu[0].type == 'top_level_tabs' || json_item.submenu[0].type == 'top_level_name') && !(mode == 'search')) ? ''
								:
								<Nav navbar style={{/*borderLeft:'4px solid #A7A8B2',*/ width:'100%', overflowX: 'hidden', flexDirection:'row', flexWrap:'wrap' , display:json_item.make_child_tabs ? 'none' : 'flex'}}>
									{
										json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
											idx != json_item.submenu.length-1 && sub_item.type != 'dropdown' && sub_item.type != 'list-item' && json_item.submenu[idx + 1].type == 'dropdown' ?
											<NavItem key={json_item.id+idx} style={{textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'row', flexWrap:'wrap', width: json_item.configuration_type == 'group_by_2' && ((sub_item.type == 'dimension_input' && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'toggle' || sub_item.type == 'select_input' || sub_item.type == 'colorpicker' || sub_item.type === 'multi_component_dropdown' || sub_item.type === 'button_item' ) ? '50%' : json_item.group_child_inputs && ((sub_item.type == 'dimension_input' && (metric_system == 'mm' || sub_item.is_unitless_true) && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'colorpicker' || (sub_item.type == 'select_input'))? '33.3%' :'100%', overflowX: 'hidden'}}>
												<Create_card 
													set_modal_mode={set_modal_mode}
													panel_key={panel_key}
													set_page_loader={set_page_loader}
													handle_ui_response={handle_ui_response}
													key={json_item.id+idx}
													metric_system={metric_system}
													approximation_mm={approximation_mm}
													approximation_ft={approximation_ft}
													parent={json_item}
													parent_id={json_item.id}
													parent_menu={json_item.submenu}
													update_view={update_view}
													json_item={sub_item}
													set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
													approxMM={approxMM}
													mmToFeetParts={mmToFeetParts}
													panel_explode={panel_explode}
													is_grouped={json_item.group_child_inputs}
													index={idx}
													configuration_type={json_item.configuration_type}
													inside_tab={inside_tab}
													is_panel_pop_up={is_panel_pop_up}
													mode={mode}
													panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
												/>
												<Create_card 
													set_modal_mode={set_modal_mode}
													panel_key={panel_key}
													set_page_loader={set_page_loader}
													handle_ui_response={handle_ui_response}
													metric_system={metric_system}
													approximation_mm={approximation_mm}
													approximation_ft={approximation_ft}
													// parent_menu={panel_json}
													update_view={update_view}
													json_item={{type:'margin_bottom', margin:json_item.submenu[json_item.submenu.length-1].type == 'list-item' ? '0px' : '16px'}}
													set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
													approxMM={approxMM}
													mmToFeetParts={mmToFeetParts}
													panel_explode={false}
													inside_tab={inside_tab}
												/>
											</NavItem>
											:
											<NavItem key={json_item.id+idx} style={{textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'row', width: json_item.configuration_type == 'group_by_2' && ((sub_item.type == 'dimension_input' && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'toggle' || sub_item.type == 'select_input' || sub_item.type == 'colorpicker' || sub_item.type === 'multi_component_dropdown' || sub_item.type === 'button_item' ) ? '50%' : json_item.group_child_inputs && ((sub_item.type == 'dimension_input' && (metric_system == 'mm' || sub_item.is_unitless_true) && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'colorpicker' || (sub_item.type == 'select_input'))? '33.3%' :'100%', overflowX: 'hidden'}}>
												<Create_card 
													set_modal_mode={set_modal_mode}
													panel_key={panel_key}
													set_page_loader={set_page_loader}
													handle_ui_response={handle_ui_response}
													key={json_item.id+idx}
													metric_system={metric_system}
													approximation_mm={approximation_mm}
													approximation_ft={approximation_ft}
													parent={json_item}
													parent_id={json_item.id}
													parent_menu={json_item.submenu}
													update_view={update_view}
													json_item={sub_item}
													set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
													approxMM={approxMM}
													mmToFeetParts={mmToFeetParts}
													panel_explode={panel_explode}
													is_grouped={json_item.group_child_inputs}
													index={idx}
													configuration_type={json_item.configuration_type}
													inside_tab={inside_tab}
													is_panel_pop_up={is_panel_pop_up}
													mode={mode}
													panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
												/>
											</NavItem>
										)):''
									}
								{
									json_item.submenu && json_item.submenu.length && !((json_item.submenu[json_item.submenu.length-1].type == 'presentation_sheet') || (json_item.submenu[json_item.submenu.length-1].type == 'list-item') || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button' || json_item.submenu[json_item.submenu.length-1].type == 'input_info') ?
										<Create_card 
											set_modal_mode={set_modal_mode}	
											panel_key={panel_key}
											set_page_loader={set_page_loader}
											handle_ui_response={handle_ui_response}
											metric_system={metric_system}
											approximation_mm={approximation_mm}
											approximation_ft={approximation_ft}
											// parent_menu={panel_json}
											update_view={update_view}
											json_item={{type:'margin_bottom', margin:json_item.submenu[json_item.submenu.length-1].type == 'list-item' ? '0px' : '16px'}}
											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
											approxMM={approxMM}
											mmToFeetParts={mmToFeetParts}
											panel_explode={false}
											inside_tab={inside_tab}
										/>
									:''
								}
							</Nav>
						}
						</Collapse>
					</div>
				)
			}
			// else if(json_item && json_item.type=='dropdown' && json_item.is_layer){
			// 	return(
					
			// 			<Collapse className='d--------------------d' isOpen={true} navbar={true} style={{ width:'100%', overflowX: 'hidden'}}>
			// 				<Nav navbar style={{borderLeft: '3px solid', borderLeft:'4px solid', borderLeftColor:'#A7A8B2', width:'100%', overflowX: 'hidden' }}>
			// 					{
			// 						json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
			// 							<NavItem key={json_item.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
			// 								<Create_card set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={json_item.id+idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_id={json_item.id} parent_menu={json_item.submenu} update_view={update_view} json_item={sub_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts} panel_explode = {panel_explode}/>
			// 							</NavItem>
			// 						)):''
									
			// 					}		
			// 				</Nav>
			// 			</Collapse>
			// 	)
			// }
			else if(json_item && json_item.type=='dropdown'){
				let json_item_submenu_copy = JSON.parse(JSON.stringify(json_item.submenu));
				// json_item_submenu_copy && json_item_submenu_copy.length ? json_item_submenu_copy.map((sub_item) =>(
				// 	sub_item.type = 'special_dimension_input'
				// )): ''
				// json_item_submenu_copy.type = 'special_dimension_input'
				// json_item_submenu_copy.route = json_item.onChangeRoute
				// json_item_submenu_copy.name = json_item.name
				// json_item_submenu_copy.id = json_item.id
				let child_tab_json = {}
				child_tab_json.tabs = json_item_submenu_copy
				child_tab_json.type = 'child_tabs'
				child_tab_json.tab_configuration = json_item.tab_configuration
				return(
					<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
						
						<div className={json_item.submenu && (json_item.submenu.length || (json_item.buttons && json_item.buttons.length)) ?'dropdown_panel':'dropdown_panel panel_disabled'} onClick={json_item.disabled || !(json_item.submenu && (json_item.submenu.length)) ?null:dropdown_on_click} style={{width:'100%',padding:'0px 12px', overflow: 'hidden', display:json_item.remove_dropdown || (!json_item.submenu.length && !(json_item.buttons && json_item.buttons.length) ) ? 'none' :'flex', flexDirection:'row', alignItems:'center', borderBottom:'1px solid', borderBottomColor: is_dropdown_open() ? '#E6E9EE' : '#C5C7CF', borderLeftColor:is_dropdown_open() ? '#676878' : COLORS.toolbar_background_color, borderLeftWidth: '4px', borderLeftStyle:'solid', backgroundColor: COLORS.toolbar_background_color}}>
							{	
								json_item.icon?
								<span style={{paddingRight:'10px', flex:1}}>
									<img height='24px' width='24px' style={{maxHeight: '100%', overflow: 'hidden'}} title={json_item.name} src={json_item.icon} />
								</span>	
								:''
							}
							
							<span style={{flex:8 , textAlign:'left', opacity: json_item.submenu.length ? 1 : 0.5 }} >
								<span id={json_item.id} title={json_item.name/* + get_dropdown_label()*/} className='lines1_elipsis dropdown_heading_panel' style={{color:COLORS.black_left_menu}} >{json_item && json_item.name ? json_item.name /*+ get_dropdown_label()*/:''}</span>
							</span>
							<div style={{flex:3, display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
								{/* <FormGroup style={{marginBottom:0, width:'100%', display:json_item.switch_show?'block':'none'}}> */}
								{/* <div style={{display:json_item.switch_show?'block':'none'}} className='toggle_switch'>
									<Input style={{display:'none', margin:'-6px 0px 0px 20px'}} onChange={common_onchange_handler} type="checkbox"></Input>
									<span className='switch'></span>
								</div> */}
								{/* </FormGroup> */}
								{/* <div style={{display:json_item.switch_show?'block':'none', fontSize:'8px'}}>
								<label class="toggleSwitch nolabel" onclick="">
									<input type="checkbox" checked />
									<span>
										<span>OFF</span>
										<span>ON</span>
									</span>
									<a></a>
								</label>
								</div> */}
								{/* <input style={{display:json_item.switch_show?'block':'none', marginRight:'8px'}} onChange={common_onchange_handler} type="checkbox"></input> */}
								{
									json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) => (
										item.type == "switch" ? 
											<Switch key={idx} disabled={item.disabled} style={{marginRight:'12px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
										: item.type == 'label' ? 
											<span key={idx} style={{cursor:'pointer',marginRight:'6px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize'}} onClick={(e) => {if(item.onclick){e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}}>{item.name}</span>
										: item.icon == "+" ? 
											<span key={idx} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{marginRight:'12px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize', display:'flex'}} onClick={(e) => {!is_dropdown_open() ? dropdown_on_click() : null; e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}><img style={{marginRight: '4px'}} height='12px' src='/resources/panel_icons_new/blue_add.svg'></img> Add </span>
											// <span key={idx} style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {is_dropdown_open() ? e.stopPropagation() : null; icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
										:
											<div style={{ marginRight:'12px', width: '20px', height: '20px', display:'flex', alignItems: 'center', justifyContent: 'center'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}><img key={idx} src={item.icon} title={item.disabled_message?item.disabled_message:''} className={item.disabled ? 'panel_disabled' : 'button_panel scale_110'} style={{height:'17px'}} /></div>
									))
									: ''
								}
								<span style={{ fontSize:'14px', color:COLORS.black_left_menu, opacity: json_item.submenu.length ? 1 : 0.5  }} ><b>{!is_dropdown_open()?<img width='10px' style={{fontSize:'12px', color: COLORS.black_left_menu}} src='/resources/panel_icons_new/dropdown_closed.svg'/>:<img width='10px' style={{fontSize:'12px', color: COLORS.black_left_menu}} src='/resources/panel_icons_new/dropdown_open.svg'/>}</b></span>
							</div>
						</div>

						{
							json_item.id == 'snap_settings' ? 
								<Create_card 
									set_modal_mode={set_modal_mode}
									panel_key={panel_key}
									set_page_loader={set_page_loader}
									handle_ui_response={handle_ui_response}
									metric_system={metric_system}
									approximation_mm={approximation_mm}
									approximation_ft={approximation_ft}
									// parent_menu={panel_json}
									update_view={update_view}
									json_item={{type:'snap_text'}}
									set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
									approxMM={approxMM}
									mmToFeetParts={mmToFeetParts}
									panel_explode={false}
								/>
								:''
						}
						
						<Collapse className='d--------------------d' isOpen={json_item.remove_dropdown ? true : is_dropdown_open()} navbar={true} style={json_item.buttons && json_item.buttons.length && json_item.buttons.filter(x => x.icon == "/resources/panel_icons_new/lock layer.svg").length ? { width:'100%', overflowX: 'hidden', opacity: 0.5, pointerEvents:'none' } : { width:'100%', overflowX: 'hidden', opacity: 1}}>
							{
								json_item.make_child_tabs ? 
									<Create_card 
										set_modal_mode={set_modal_mode}
										panel_key={panel_key}
										set_page_loader={set_page_loader}
										handle_ui_response={handle_ui_response}
										metric_system={metric_system}
										approximation_mm={approximation_mm}
										approximation_ft={approximation_ft}
										// parent_menu={panel_json}
										update_view={update_view}
										json_item={child_tab_json}
										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
										approxMM={approxMM}
										mmToFeetParts={mmToFeetParts}
										panel_explode={false}
                                        is_panel_pop_up={is_panel_pop_up}
										mode={mode}
										panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback} is_section={is_section}
									/>
									:''
							}

							{
								json_item.submenu && json_item.submenu.length && (json_item.submenu[0].type == 'top_level_tabs' || json_item.submenu[0].type == 'top_level_name') && !(mode == 'search') ? 
									<TabToolbar 
										panel_key={panel_key}
										set_page_loader={set_page_loader}
										handle_ui_response={handle_ui_response}
										metric_system={metric_system}
										approximation_mm={approximation_mm}
										approximation_ft={approximation_ft}
										// parent_menu={panel_json}
										update_view={update_view}
										json_item={json_item.submenu[0]}
										child_menu={json_item.submenu.slice(1)}
										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
										approxMM={approxMM}
										mmToFeetParts={mmToFeetParts}
										panel_explode={false}
                                        is_panel_pop_up={is_panel_pop_up}
										tab_on_click={tab_on_click}
										index={index}
										panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
									/>
									:''
							}
							{
								json_item.make_child_tabs || (json_item.submenu && json_item.submenu.length && (json_item.submenu[0].type == 'top_level_tabs' || json_item.submenu[0].type == 'top_level_name') && !(mode == 'search')) ? ''
								: 
									<Nav navbar style={{borderLeft: json_item.remove_dropdown? 'none' : '4px solid #A7A8B2', width:'100%', overflowX: 'hidden', display:json_item.make_child_tabs ? 'none' : 'flex', flexDirection: json_item.group_child_inputs ? 'row' : 'column', flexWrap:'wrap' }}>
										
										{
											json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
												idx != json_item.submenu.length-1 && sub_item.type != 'dropdown' && sub_item.type != 'list-item' && json_item.submenu[idx + 1].type == 'dropdown'?
												<NavItem key={json_item.id+idx} style={json_item.group_child_inputs ? {textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'row', flexWrap:'wrap', width: json_item.configuration_type == 'group_by_2' && ((sub_item.type == 'dimension_input' && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'toggle' || sub_item.type == 'select_input' || sub_item.type == 'colorpicker' || sub_item.type === 'multi_component_dropdown' || sub_item.type === 'button_item' ) ? '50%' : json_item.group_child_inputs && ((sub_item.type == 'dimension_input' && (metric_system == 'mm' || sub_item.is_unitless_true) && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'colorpicker' || (sub_item.type == 'select_input'))? '33.3%' :'100%', overflowX: 'hidden'} : {textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
													<Create_card 
														set_modal_mode={set_modal_mode}
														panel_key={panel_key}
														set_page_loader={set_page_loader}
														handle_ui_response={handle_ui_response}
														key={json_item.id+idx}
														metric_system={metric_system}
														approximation_mm={approximation_mm}
														approximation_ft={approximation_ft}
														parent={json_item}
														parent_id={json_item.id}
														parent_menu={json_item.submenu}
														update_view={update_view}
														json_item={sub_item}
														set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
														approxMM={approxMM}
														mmToFeetParts={mmToFeetParts}
														panel_explode={panel_explode}
														is_grouped={json_item.group_child_inputs}
														index={idx}
														configuration_type={json_item.configuration_type}
														inside_tab={inside_tab}
														is_panel_pop_up={is_panel_pop_up}
														mode={mode}
														panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
													/>
													<Create_card 
														set_modal_mode={set_modal_mode}
														panel_key={panel_key}
														set_page_loader={set_page_loader}
														handle_ui_response={handle_ui_response}
														metric_system={metric_system}
														approximation_mm={approximation_mm}
														approximation_ft={approximation_ft}
														// parent_menu={panel_json}
														update_view={update_view}
														json_item={{type:'margin_bottom'}}
														set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
														approxMM={approxMM}
														mmToFeetParts={mmToFeetParts}
														panel_explode={false}
														inside_tab={inside_tab}
													/>
												</NavItem>
												:
												<NavItem key={json_item.id+idx} style={json_item.group_child_inputs ? {textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'row', width: json_item.configuration_type == 'group_by_2' && ((sub_item.type == 'dimension_input' && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'toggle' || sub_item.type == 'select_input' || sub_item.type == 'colorpicker' || sub_item.type === 'multi_component_dropdown' || sub_item.type === 'button_item' ) ? '50%' : json_item.group_child_inputs && ((sub_item.type == 'dimension_input' && (metric_system == 'mm' || sub_item.is_unitless_true) && !sub_item.expression_available_parameters) || sub_item.type == 'text_input' || sub_item.type == 'colorpicker' || (sub_item.type == 'select_input'))? '33.3%' :'100%', overflowX: 'hidden'} : {textAlign: 'left', display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
													<Create_card 
														set_modal_mode={set_modal_mode}
														panel_key={panel_key}
														set_page_loader={set_page_loader}
														handle_ui_response={handle_ui_response}
														key={json_item.id+idx}
														metric_system={metric_system}
														approximation_mm={approximation_mm}
														approximation_ft={approximation_ft}
														parent={json_item}
														parent_id={json_item.id}
														parent_menu={json_item.submenu}
														update_view={update_view}
														json_item={sub_item}
														set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
														approxMM={approxMM}
														mmToFeetParts={mmToFeetParts}
														panel_explode={panel_explode}
														is_grouped={json_item.group_child_inputs}
														index={idx}
														configuration_type={json_item.configuration_type}
														inside_tab={inside_tab}
														is_panel_pop_up={is_panel_pop_up}
														mode={mode}
														panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback}
													/>
												</NavItem>
											)):''
											
										}
								
										{
											json_item.submenu && json_item.submenu.length && !((json_item.submenu[json_item.submenu.length-1].type == 'presentation_sheet') || json_item.submenu[json_item.submenu.length-1].type == 'list-item' || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button' || json_item.submenu[json_item.submenu.length-1].type == 'input_info') ?
												<Create_card 
													set_modal_mode={set_modal_mode}
													panel_key={panel_key}
													set_page_loader={set_page_loader}
													handle_ui_response={handle_ui_response}
													metric_system={metric_system}
													approximation_mm={approximation_mm}
													approximation_ft={approximation_ft}
													// parent_menu={panel_json}
													update_view={update_view}
													json_item={{type:'margin_bottom'}}
													set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
													approxMM={approxMM}
													mmToFeetParts={mmToFeetParts}
													panel_explode={false}
													inside_tab={inside_tab}
												/>
											:''
										}	
									</Nav>
							}
						</Collapse>
					</div>		
				)
			}
			else if(json_item.type === 'tree'){
				return (
					<PanelTreeCard json_item={json_item} common_onchange_handler={common_onchange_handler}/>
				)
			}
			else if(json_item.type=='list-item'){
				return(
					is_section ? <ListItemNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} is_grouped={is_grouped} icon_on_click={icon_on_click}/>
					:
					<div key={"key_"+json_item.id} id={json_item.id + "_button"} className={json_item.disabled?'panel_disabled_opacity': json_item.route ? 'LI----------LI' : 'LI----------LI'} onClick={json_item.disabled?null:on_click} style={{cursor:'pointer', width:is_grouped ? 'auto' : '100%', display:'flex', flexDirection:'row', alignItems:'center', borderBottom: json_item.route ? '1px solid' : '3px solid', borderBottomColor: !json_item.route ? COLORS.panel_item_separation : inside_tab ? '#E6E9EE' : '#C5C7CF', padding:'0px 12px', backgroundColor:COLORS.panel_card_item, textDecorationColor:json_item.color ? json_item.color : COLORS.black_left_menu, flexWrap: 'wrap'}}>
						{
							json_item.full_image_url ?
								(
									<div className='p-2' style={{ width: '100%' }}>
										<img width='100%' style={{maxHeight: '120px', objectFit:'contain'}} title={json_item.name} src={json_item.full_image_url} />
									</div>
								)
								: ''
						}
						<div style={{ width: '100%', height: '36px' }} className='flex_property'>
							{
								json_item.is_material && ((!json_item.icon && !json_item.icon_color) || json_item.mixed) ? (
									<div style={{ paddingRight: '10px' }}>
										<div className='flex_center' style={{width:'24px',height:'24px', backgroundColor:'#E6E9EE', border: '1px dashed #A7A8B2', color: '#A7A8B2'}}>+</div>
									</div>
								) : json_item.icon ? (
									<div style={{ paddingRight: '10px' }}>
										<img height='24px' width='24px' style={{border: '1px solid var(--light-border-color)'}} title={json_item.name} src={json_item.icon} />
									</div>
								) : json_item.icon_color ? (
									<div style={{ paddingRight: '10px' }}>
										<div style={{ height: '24px', width: '24px', border: '1px solid var(--light-border-color)', backgroundColor: json_item.icon_color }} title={json_item.name} />
									</div>
								) :  ''
							}
							<div style={{ flex: 5, gap: '8px', minWidth: '0px', display: 'flex', justifyContent: 'space-between', maxWidth: json_item.icon || json_item.icon_color ? 'calc( 100% - 34px )' : '100%' }}>
								<Tooltip title={json_item.on_hover_text}>
									<div title={json_item.mixed ? "Mixed" : json_item.name} className={json_item.color && json_item.color != '#0078FF' ? 'lines1_elipsis' : 'lines1_elipsis list_item_blue_hover'} id={json_item.id} style={{ fontSize: '12px', minWidth: '0px', color: json_item.color && json_item.color != '#0078FF' ? json_item.color : json_item.route ? COLORS.black_left_menu : '#A7A8B2', fontWeight: json_item.route ? 500 : 700, textAlign: json_item.route ? 'left' : 'center', flex: 5 }} >{json_item.mixed ? "Mixed" : json_item.name}</div>
								</Tooltip>
								{
									json_item.route && !json_item.is_material && !json_item.buttons ? <img height='18px' src='/resources/panel_icons_new/click.svg' className='list_item_visible_hover' /> : ''
								}
								{
									json_item.is_material && json_item.change_button_required ? <div style={{ color: COLORS.blue6, paddingRight: json_item.buttons && json_item.buttons.length ? '0px' : '8px', display: 'flex', justifyContent: 'right', fontSize: '12px', alignItems: 'center' }}>{t(`Change`)}</div> : ''
								}
								{
									json_item.buttons && json_item.buttons.length ? <ButtonsArray button_list={json_item.buttons} icon_on_click={icon_on_click}/> : ''
								}
							</div>
						</div>
					</div>
				)
			}
			else if(json_item.type=='dynamic-component-item'){
				return(
					<DynamicComponent json_item={json_item} icon_on_click={icon_on_click} handle_ui_response={handle_ui_response} update_view={update_view} is_panel_pop_up={is_panel_pop_up} set_page_loader={set_page_loader} on_click={on_click} inside_tab={inside_tab}/>
				)
			}
			else if(json_item.type=='button'){
				return(
					<Card /*className='B----------B'*/ style={{ border:'0px solid', borderBottom:'1px solid', borderBottomColor:COLORS.panel_item_separation }} className={json_item.disabled?'panel_disabled':''} >
						<CardBody style={{ padding:'15px', backgroundColor:COLORS.panel_card_item}} >
							<Button className='panel_green_btn lines1_elipsis' style={{display:'inline-flex',alignItems:'center',height:'32px'}} key={"key_"+json_item.id} id={json_item.id} onClick={json_item.disabled?null:on_click} >{json_item ? json_item.name:''}</Button>
						</CardBody>
					</Card>
				)
			}

			else if(json_item.type=='text_input'){
				return(
					json_item.numeric_integer_input ? 
					<NumericInput json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} is_grouped={is_grouped} configuration_type={configuration_type}/>
					:
					is_section ? <TextInputNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} is_grouped={is_grouped} configuration_type={configuration_type} />
					:
					<Card key={json_item.id} style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled_opacity':'TI----------TI'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
							{
								is_grouped ? 
									<div style={{maxWidth:'100%'}}>
										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none'}}>
											<div style={{display:'flex', width:'100%', padding:'0px 0px'}}>
												<div className='lines1_elipsis' style={{color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', fontWeight:300}} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
												{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
											</div>
										</div>
										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
											<div className='createcard_input_container' style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
												<div style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name ? json_item.name[0] : ''}</div>
												<Input disabled={json_item.disabled} type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.mixed ? '' : ((json_item.default_value) || '') ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : ((json_item.default_value)||'')} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder={json_item.mixed ? "Mixed" : "0"}/>
											</div>
										</div>
									</div>
								:
									<Row>
										<Col xs={6} style={{fontSize:12,display:'flex',alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
										<Col xs={6} style={{fontSize:12,display:'flex',alignItems:"center", paddingLeft:'8px', paddingRight:'8px'}}>
											<div className='createcard_input_container' style={{border: '1px solid ' + COLORS.panel_item_borders}}>
												<Input disabled={json_item.disabled} key={"key_"+json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.mixed ? '' : ((json_item.default_value) || '') ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : ((json_item.default_value)||'')} type="text"  id={json_item.id} style={{...styles_panel.input_styles, border:'none'}} placeholder={json_item.mixed ? "Mixed" : "0"}/>
											</div>
										</Col>
									</Row>
							}
						</CardBody>
					</Card>
				)
			}

			// dummy value in image height, can be adjusted
			// else if(json_item.type=='card'){
			// 	return(
			// 		<Card style={{padding:'15px', margin:'10px'}} className={json_item.disabled?'panel_disabled':''}>  
			// 			<CardBody style={{padding:'0px'}}>
			// 				<Row style={{height:'152px', margin:0, marginBottom:'2px'}}> 
			// 					<Col style={{display:'flex', alignItems:"center", justifyContent:'center', backgroundColor:'white',padding:0}}>	
			// 						<img style={{cursor:'pointer'}} src= {json_item && json_item.name ?json_item.name:''} height="100%" width="auto" />
			// 					</Col>
			// 				</Row>
			// 				<Row style={{margin:0}}>
			// 					<Col style={{display:'flex', alignItems:"center", justifyContent:'center',padding:0, fontSize:'12px'}}>	
			// 						{json_item && json_item.name ?json_item.name:''}
			// 					</Col>
			// 				</Row>
			// 			</CardBody>
			// 		</Card>	
			// 	)
			// }
			else if(json_item.type=='dimension_input'){
				return(
					is_section ? <DimensionInput  json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} metric_system={metric_system} is_grouped={is_grouped} configuration_type={configuration_type} approxMM={approxMM} mmToFeetParts={mmToFeetParts} approximation_ft={approximation_ft} /> :
					json_item.expression_available_parameters ? <ParametricDimensionInput json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} configuration_type={configuration_type}></ParametricDimensionInput> :
						<Card key={json_item.id} style={{ border: '0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }} className={json_item.disabled ? 'panel_disabled' : 'dI----------dI'}>
							<CardBody style={{ padding: '15px', paddingBottom: '0px', /*paddingTop:(index < 3 && is_grouped && metric_system=='mm' || !index) ? 15 : 0,*/ backgroundColor: COLORS.panel_card_item }} >
								<Row style={{/*marginBottom:'15px'*/ }}>
									<Col style={{ display: 'flex', alignItems: 'center', paddingLeft: 15, paddingRight: 15, flexDirection: 'row' }}>
										{(metric_system == 'mm' || json_item.is_unitless_true) ? (
											is_grouped ?
												<div style={{ maxWidth: '100%' }}>
													<div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none' }}>
														<div style={{ display: 'flex', width: '100%', padding: '0px 0px' }}>
															<div className='lines1_elipsis' style={{ color: json_item.color && !(configuration_type == 'group_by_2') ? json_item.color : COLORS.gray6, fontSize: '12px', fontWeight: 300 }} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
															{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
														</div>
													</div>
													<div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15 }}>
														<div className='createcard_input_container' style={{ display: 'flex', width: '100%', border: '1px solid ' + COLORS.panel_item_borders, padding: '0px 4px' }}>
															<div style={{ flex: 1, color: json_item.color ? json_item.color : COLORS.gray5, fontSize: '12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>{json_item.name ? json_item.name[0] : ''}</div>
															<Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = json_item.mixed ? '' : approxMM(json_item.default_value) || 0; e.target.blur() } }} defaultValue={json_item.mixed ? '' : json_item.parameter_expression ? json_item.parameter_expression : (approxMM(json_item.default_value) || 0)} key={"key_" + json_item.id} id={json_item.id} style={{ ...styles_panel.input_styles, border: 'none', flex: 5, height: '24px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
														</div>
													</div>
												</div>
												:
												<div style={{ width: '100%' }}>
													<div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15 }}>
														<div style={{ display: 'flex', width: '100%' }}>
															<div style={{ fontSize: 12, display: "flex", alignItems: "center", cursor: "default", flex: 1, paddingRight: '8px', maxWidth: '50%' }}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
															<div style={{ fontSize: 12, display: "flex", alignItems: "center", flex: 1, paddingLeft: '8px', maxWidth: '50%' }}>
																<div className='createcard_input_container' style={{ border: '1px solid ' + COLORS.panel_item_borders }}>
																	<Input type="text" onChange={common_onchange_handler} id={json_item.id} key={"key_" + json_item.id} onBlur={json_item.onchange_route ? on_click : null} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = json_item.mixed ? '' : approxMM(json_item.default_value) || 0; e.target.blur() } }} defaultValue={json_item.mixed ? '' : json_item.parameter_expression ? json_item.parameter_expression : (approxMM(json_item.default_value) || 0)} style={{ ...styles_panel.input_styles, border: 'none' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																</div>
															</div>
														</div>
													</div>
												</div>
										) : (
											is_grouped ?
												<Row style={{ paddingLeft: '8px', paddingRight: '8px' }}>
													<Col sm={12} className='zero_padding' style={{ fontSize: 13, textAlign: 'left', cursor: 'default', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none' }}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
													<Col sm={configuration_type == 'group_by_2' ? 12 : 8} style={{ border: '1px solid ' + COLORS.panel_item_borders }} className='createcard_input_container blue_highlight_focuswithin'>
														{/* <Row style={{marginLeft:0, marginRight:0, display:configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none'}}>
															<Col sm={12} className='zero_padding' style={{fontSize:12, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
														</Row> */}
														<Row style={{ padding: '0px 4px' }}>

															<Col sm={1} className='zero_padding' style={{ fontSize: 13, textAlign: 'left', cursor: 'default', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems: 'center', textAlign: 'center', fontWeight: 300, justifyContent: 'center', color: json_item.color ? json_item.color : COLORS.gray6 }}><span title={json_item.name} className='lines2_elipsis' >{json_item.name && json_item.name.length ? json_item.name[0] : ''}</span></Col>

															<Col xs={configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 12 : 11} className='zero_padding'>
																<FocusWithin onBlur={on_click} style={{ display: 'flex', width: '100%' }}>
																	<Col className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
																		<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[0] || 0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px', padding: '0px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																		<div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>ft</div>
																	</Col>
																	<Col className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
																		<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[1] || 0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																		<div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>in</div>
																	</Col>
																	<Col className='flex_property zero_padding' style={{ width: '30%' }}>
																		<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[2] || 0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																		<div className='flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>{approximation_ft == '1/8_inch' ? '1/8' : '1/16'}</div>
																	</Col>
																</FocusWithin>
															</Col>
														</Row>
													</Col>
												</Row>
												:
												<Row>
													<Col sm={5} style={{ fontSize: 12, textAlign: 'left', cursor: 'default' }}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
													{/* <Col sm={2} className='zero_padding'>
														<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[0]||0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={styles_panel.input_styles} placeholder={json_item.mixed ? 'Mixed' : "0"}/>
														<span style={{fontSize:12, textAlign:'left', cursor:"default"}}>ft</span>
													</Col>
													<Col sm={2} className='zero_padding'>
														<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[1]||0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={styles_panel.input_styles} placeholder={json_item.mixed ? 'Mixed' : "0"}/>
														<span style={{fontSize:12, textAlign:'left', cursor:"default"}}>in</span>
													</Col>
													<Col sm={2} className='zero_padding'>
														<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item.default_value)[2]||0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={styles_panel.input_styles} placeholder={json_item.mixed ? 'Mixed' : "0"}/>
														<span style={{fontSize:12, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
													</Col> */}
													<Col sm={6} className='zero_padding createcard_input_container blue_highlight_focuswithin' style={{ height: 'fit-content' }}>
														<FocusWithin onBlur={on_click} style={{ display: 'flex', width: '100%' }}>
															<Col className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
																<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[0] || 0} key={"key_" + json_item.id + "_ft"} id={json_item.id + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px', padding: '0px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																<div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>ft</div>
															</Col>
															<Col className='flex_property zero_padding' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
																<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[1] || 0} key={"key_" + json_item.id + "_in"} id={json_item.id + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																<div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>in</div>
															</Col>
															<Col className='flex_property zero_padding' style={{ width: '30%' }}>
																<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value)[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value)[2] || 0} key={"key_" + json_item.id + "_parts"} id={json_item.id + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder={json_item.mixed ? 'Mixed' : "0"} />
																<div className='flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>{approximation_ft == '1/8_inch' ? '1/8' : '1/16'}</div>
															</Col>
														</FocusWithin>
													</Col>
												</Row>
										)}
										<br />
									</Col>
								</Row>
							</CardBody>
						</Card>
				)
			}
			else if(json_item.type=='ref_level_dimension_input'){
				return(
					is_section ?
					<RefLevelDimensionInputNew json_item={json_item} common_onchange_handler={common_onchange_handler} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} approxMM={approxMM} index={index} on_click={on_click} is_grouped={is_grouped} />
					:
					<RefLevelDimensionInput json_item={json_item} common_onchange_handler={common_onchange_handler} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} approxMM={approxMM} index={index} on_click={on_click} is_grouped={is_grouped}></RefLevelDimensionInput>
				)
			}
			else if(json_item.type=='heading'){
				return(<div></div>)
			}
			
			else if(json_item.type=='multi-buttons'){
				return(
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'MB----------MB'}>
						<CardBody style={{ padding:'10px', backgroundColor:COLORS.panel_card_item}} >
						<Row>
							<Col sm={6} style={{fontSize:14, display:'flex', alignItems:'center',justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
							<Col sm={4}>
								<Row><Col>
									<Row><Col><i title="increase" style={{padding:'5px', fontSize:14,cursor:'pointer' }} className="fa fa-chevron-up panel_color_green_arrow"/></Col></Row>
									<Row><Col><input style={styles_panel.input_styles} onChange={common_onchange_handler} placeholder="0"/></Col></Row>
									<Row><Col><i title="decrease" style={{padding:'5px', fontSize:14, cursor:'pointer' }} className="fa fa-chevron-down panel_color_green_arrow"/></Col></Row>
								</Col></Row>
							</Col>
							<Col sm={2} style={{fontSize:'13px', display:"flex", alignItems:"center", justifyContent:'center',paddingLeft:0, cursor:"default"}}>
								mm
							</Col>
						</Row>
						</CardBody>
					</Card>
				)
			}
			else if(json_item.type=='colorpicker'){
				return(
					is_section ? 
					<ColorInputNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} is_grouped={is_grouped} configuration_type={configuration_type} />
					:
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CP----------CP':'CP----------CP'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
							{
								is_grouped ? 
									<div style={{maxWidth: '100%'}}>
										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'flex' : 'none'}}>
											<div style={{display:'flex', width:'100%', padding:'0px 0px'}}>
												<div className='lines1_elipsis' style={{color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', fontWeight:500}} title={json_item.name ? json_item.name : ''}>{json_item.name ? json_item.name : ''}</div>
												{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
											</div>
										</div>
										<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
											<div className='createcard_input_container' style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
												{/* <div style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray5 , fontSize:'12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name ? json_item.name[0] : ''}</div> */}
												{
													!json_item.is_kelvin ? 
													<React.Fragment>
														<Input type="color" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) ; e.target.blur()}}} defaultValue={(json_item.mixed ? '' : json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex: json_item.mixed ? 'unset' : 5, height:'24px', padding:0, width: json_item.mixed ? '24px': '100%'}}/>
														{json_item.mixed ? <div className='flex_property' style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px', padding:0}} onClick={() => { document.getElementById(json_item.id).focus(); document.getElementById(json_item.id).click()}}>Mixed</div> : ''}
													</React.Fragment>
													:
													// <React.Fragment>
													// 	<Input type="color" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) ; e.target.blur()}}} defaultValue={(json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', width:'24px', height:'24px', padding:0}}/>
													// 	<Input type="text" onChange={() => {common_onchange_handler()}} onBlur={(e) => {if(e.target.value != ''){ on_click()}}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value='' ; e.target.blur()}}} defaultValue={(json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px', padding:0}}/>
													// </React.Fragment>
													<KelvinColorPicker json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} />
												}
											</div>
										</div>
									</div>
								:
									<Row>
										<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></Col>
										<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'flex-start', paddingLeft:'8px', paddingRight:'8px'}}>
											<FormGroup className='createcard_input_container' style={{margin:0, width:'100%', border: '1px solid ' + COLORS.panel_item_borders, display: 'flex'}}>
												{
													!json_item.is_kelvin ? 
													<React.Fragment>
														<Input id={json_item.id} key={"key_color_"+json_item.id} onChange={common_onchange_handler} onBlur={json_item.onchange_route ? on_click : null} defaultValue={json_item.mixed ? '' : json_item.default_value} style={{ padding: '0px', height: '30px', width: '100%', border:'none', width: json_item.mixed ? '24px': '100%'}} type="color" />
														{/* {json_item.mixed ? <div style={{ padding: '0px', height: '30px', width: '100%', border:'none' }} onClick={() => document.getElementById(json_item.id).click()}>Mixed</div> : ''} */}
														{json_item.mixed ? <div className='flex_property' style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px', padding:0}} onClick={() => { document.getElementById(json_item.id).focus(); document.getElementById(json_item.id).click()}}>Mixed</div> : ''}
													</React.Fragment>
														:
														<KelvinColorPicker json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} />
												}
											</FormGroup>
										</Col>
									</Row>
							}
						</CardBody>
					</Card>
					// <KelvinColorPicker/>
				)
			}
			else if(json_item.type=='multi_component_dropdown'){
				return (
					<MultiComponentDropdown json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} icon_on_click={icon_on_click} index={index} is_grouped={is_grouped}/>
				)
			}
			else if(json_item.type=='multi_component_input'){
				return (
					<MultiComponentInput json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} icon_on_click={icon_on_click} index={index} is_grouped={is_grouped}/>
				)
			}
			else if(json_item.type=='select_input' || (json_item.type == 'layer_input' && !json_item.colors)){
				// console.log("json_item", json_item);
				// const [select_input_value, set_select_input_value] = useState(json_item.default_value);
				// useEffect(() => {
				// 	if(select_input_value && select_input_value != json_item.default_value)
				// 	on_click()
				// }, [select_input_value]);
				// return(
				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
				// 		<Row>
				// 			{
				// 				json_item.options.length == 2 && json_item.onchange_route ?
				// 				<React.Fragment>
				// 					<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
				// 					<Col xs={9} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
				// 						{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
				// 							<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
				// 								{json_item.options.map((o,idx) => (
				// 									<option key={idx} value={o.value}>{o.name}</option>
				// 								))}
				// 							</Input>
				// 						</FormGroup> */}
				// 						<div style={{width:'100%', display:'flex', fontSize:'12px'}}>
				// 							<Input key={"key_"+json_item.id} id={json_item.id} style={{display:'none'}} type="text" value={select_input_value} />
				// 							<div key={1} onClick={() => {set_select_input_value(json_item.options[0].value)/*; on_click(); on_select_input_change(json_item.options[0].value)*/}} style={{width:'50%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: json_item.options[0].value == json_item.default_value ? COLORS.blue6 : '#C5C7CF', borderRightColor: json_item.options[0].value == json_item.default_value ? COLORS.blue6 : 'transparent', padding:'4px' }}>{json_item.options[0].name}</div>
				// 							<div key={2} onClick={() => {set_select_input_value(json_item.options[1].value)/*; on_click(); on_select_input_change(json_item.options[1].value)*/}} style={{width:'50%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: json_item.options[1].value == json_item.default_value ? COLORS.blue6 : '#C5C7CF', borderLeftColor: json_item.options[1].value == json_item.default_value ? COLORS.blue6 : 'transparent', padding:'4px' }}>{json_item.options[1].name}</div>
				// 						</div>
				// 					</Col>
				// 				</React.Fragment>
				// 				:
				// 				<React.Fragment>
				// 					<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
				// 					<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
				// 						<FormGroup style={{marginBottom:0, width:'100%'}}>
				// 							<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
				// 								{json_item.options.map((o,idx) => (
				// 									<option key={idx} value={o.value}>{o.name}</option>
				// 								))}
				// 							</Input>
				// 						</FormGroup>
				// 					</Col>
				// 				</React.Fragment>
				// 			}
				// 			{/* <Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'center', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
				// 			<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center'}}>
				// 				<FormGroup style={{marginBottom:0, width:'100%'}}>
				// 					<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} style={styles_panel.input_styles} type="select" defaultValue={json_item.default_value} >
				// 						{json_item.options.map((o,idx) => (
				// 							<option key={idx} value={o.value}>{o.name}</option>
				// 						))}
				// 					</Input>
				// 				</FormGroup>
				// 			</Col> */}
				// 		</Row>
				// 		</CardBody>
				// 	</Card>
				// )
				return(
					is_section ? 
					<SelectInputNew json_item={json_item} on_click={on_click} is_grouped={is_grouped} configuration_type={configuration_type} common_onchange_handler={common_onchange_handler} />
					:
					<SelectInput json_item={json_item} on_click={on_click} configuration_type={configuration_type} common_onchange_handler={common_onchange_handler}/>
				)
			}
			else if(json_item.type == 'rich_layer_input'){
				return(
					is_section ? 
					<LayerInputNew json_item={json_item} on_click={on_click} configuration_type={configuration_type} common_onchange_handler={common_onchange_handler}/>
					:
					<LayerInput json_item={json_item} on_click={on_click} configuration_type={configuration_type} common_onchange_handler={common_onchange_handler} is_section={is_section}/>
				)
			}
			else if(json_item.type=='material_select'){
				return(
					is_section ? 
					<MaterialSelectNew json_item={json_item} common_onchange_handler={common_onchange_handler} is_panel_pop_up={is_panel_pop_up} update_view={update_view} handle_ui_response={handle_ui_response} mode={mode} search_string_panel={search_string_panel} parent={parent} />
					:
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset MS----------MS':'class_position_unset MS----------MS'}>
						<CardBody className='class_position_unset' style={{padding:'12px 12px 12px 15px',paddingBottom: 0, paddingTop: json_item.is_mesh_material?0:'12px', backgroundColor:json_item.background_color ? json_item.background_color : COLORS.panel_card_item}} >
						<Row  className='class_position_unset' style={{paddingBottom: json_item.is_mesh_material ? '8px' : 0, borderBottom: json_item.is_mesh_material ? 'solid 1px #C5C7CF' : ''}}>
							<Col className='class_position_unset' style={{flex: 1, minWidth: '0px', display:'flex', alignItems:"center", justifyContent:'center',paddingLeft:is_section ? '0px' : '8px', paddingRight: 0}}>
								<MaterialDropdown parent={parent} material_json={json_item}  popup_panel={is_panel_pop_up} update_view={update_view} handle_ui_response={handle_ui_response} json_item={json_item} mode={mode} search_string_panel={search_string_panel} is_section={is_section}/>
							</Col>
							<Col style={{flex: 0, display:'flex', alignItems:"center", justifyContent:'center',marginLeft:'8px', paddingRight:'8px', paddingLeft: '0px'}}>	
								{/* {clone_general_material_allowed?(<img onClick={json_item.disabled?null:clone_active_modal_onclick} title='Clone Selected item' style={{marginRight:'10px', cursor:'pointer'}} src="/resources/icons/Duplicate.svg" height="16px" />):''} */}
								<div onClick={json_item.picker_disabled?null:on_click_picker} style={{paddingRight: '10px', cursor: json_item.picker_disabled ? 'not-allowed' : 'pointer', opacity: json_item.picker_disabled ? 0.5 : 1}}>
								<Icons className={json_item.picker_disabled?'':'blue_hover'} title='Material Picker: Choose existing material from viewport' style={{fontSize: '16px'}} name="material_picker_new" />
								</div>
								<div onClick={json_item.fetch_store_disabled?null:fetch_store_materials_onclick} style={{paddingRight: '6px', cursor: json_item.fetch_store_disabled ? 'not-allowed' : 'pointer', opacity: json_item.fetch_store_disabled ? 0.5 : 1}}>
								<Icons className={json_item.fetch_store_disabled?'':'blue_hover'} title='Change from catalogue: opens carousal'  name="add_from_store" style={{fontSize: '16px'}} />
								</div>
								{/* <img onClick={json_item.picker_disabled?null:on_click_picker} title='Material Picker: Choose existing material from viewport' style={{height: '16px', width: '16px', cursor: json_item.picker_disabled ? 'not-allowed' : 'pointer', opacity: json_item.picker_disabled ? 0.5 : 1}} src="/resources/icons/material_picker_new.svg" height="20px" width="20px" /> */}
								{/* <img onClick={json_item.fetch_store_disabled?null:fetch_store_materials_onclick} title='Change from catalogue: opens carousal' style={{height: '16px', width: '16px', cursor: json_item.fetch_store_disabled ? 'not-allowed' : 'pointer', opacity: json_item.fetch_store_disabled ? 0.5 : 1}} src="/resources/icons/add_from_store.svg" height="20px" width="20px" /> */}
								{
									<UncontrolledDropdown>
									<DropdownToggle style={{ display: 'flex', alignItems: 'center', background: 'transparent', padding: '0px', aspectRatio: '1', border: 'none', boxShadow: 'unset', color: '#676878' }}>
										<Icons name='vertical_options' className='blue_hover' style={{fontSize: '16px'}}/>
									</DropdownToggle>
									<DropdownMenu right container={'body'} className='DMSans' style={{ padding: '4px', marginTop: '40px' }}>
										<DropdownItem className={json_item.clone_disabled?'disable_feedback':''} style={{ padding: '6px 12px', color: '#676878', opacity: json_item.clone_disabled ? 0.5 : 1, cursor: json_item.clone_disabled ? 'not-allowed' : ''}} onClick={json_item.clone_disabled?null:clone_active_modal_onclick}>
											<div title={json_item.clone_disabled ? 'Can\'t clone materials imported with 3D models'  : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
												<img src='/resources/icons/Duplicate.svg' style={{ height: '16px', width: '16px' }} />
												<div>Clone material</div>
											</div>
										</DropdownItem>
										<DropdownItem className={json_item.settings_disabled?'disable_feedback':''} style={{ padding: '6px 12px', color: '#676878', opacity: json_item.settings_disabled ? 0.5 : 1, cursor: json_item.settings_disabled ? 'not-allowed' : ''}} onClick={json_item.settings_disabled ? null : open_material_settings}>
											<div title={json_item.settings_disabled ? '3d model\'s material can\'t be edited, please update to infurnia\'s material to access this option' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
												<img src='/resources/icons/material_settings.svg' style={{ height: '16px', width: '16px' }} />
												<div>Open Material Settings</div>
											</div>
										</DropdownItem>
										<DropdownItem className={json_item.reset_disabled?'disable_feedback':''} style={{ padding: '6px 12px', color: '#676878', opacity: json_item.reset_disabled ? 0.5 : 1, cursor: json_item.reset_disabled ? 'not-allowed' : ''}} onClick={json_item.reset_disabled ? null : reset_material}>
											<div title={json_item.reset_disabled ? 'Can\'t reset to default here' : ''} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
												<img src='/resources/icons/reset_default.svg' style={{ height: '16px', width: '16px' }} />
												<div>Reset to default</div>
											</div>
										</DropdownItem>
										{/* <DropdownItem divider />
										<DropdownItem style={{ padding: '6px 12px', color: '#F6633F'}} disabled={false} onClick={() => {}}>
											<div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
												<img src='/resources/icons/remove.svg' style={{ height: '16px', width: '16px' }} />
												<div>Remove</div>
											</div>
										</DropdownItem> */}
									</DropdownMenu>
								</UncontrolledDropdown>
								}
								{
									json_item.buttons && json_item.buttons.length ? 
									<ButtonsArray button_list={json_item.buttons} icon_on_click={icon_on_click}/>
									:''
								}
								{
									json_item.dropdown_buttons && json_item.dropdown_buttons.length ? 
										<ButtonsDropdown button_list={json_item.dropdown_buttons} icon_on_click={icon_on_click}/>
									:''
								}
							</Col>
						</Row>
						</CardBody>
					</Card>
				)
			}
			else if(json_item.type=='composite_select'){
				return(
					is_section ? <CompositeSelectNew json_item={json_item} is_panel_pop_up={is_panel_pop_up} update_view={update_view}/>
					:
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset CS----------CS':'class_position_unset CS----------CS'}>
						<CardBody className='class_position_unset' style={{ padding:'0px', backgroundColor:COLORS.panel_card_item}} >
						<Row className='class_position_unset' style={{height:'50px',overflowY:'', margin:0}}>
							<Col className='class_position_unset' xs={9} style={{display:'flex',position:'unset', alignItems:"center", padding:'0px 5px 0px 10px', height:'100%'}}>
								{/* <Dropdown className='class_position_unset' isOpen={dropdownOpen} toggle={toggle}>
									<DropdownToggle caret>
										Dropdown
									</DropdownToggle>
									<DropdownMenu className='class_position_unset'>
										<DropdownItem header>Header</DropdownItem>
										<DropdownItem>Some Action</DropdownItem>
										<DropdownItem text>Dropdown Item Text</DropdownItem>
										<DropdownItem disabled>Action (disabled)</DropdownItem>
										<DropdownItem divider />
										<DropdownItem>Foo Action</DropdownItem>
										<DropdownItem>Bar Action</DropdownItem>
										<DropdownItem>Quo Action</DropdownItem>
									</DropdownMenu>
								</Dropdown> */}
								<CompositeDropdown composites={json_item.composites} active_index={get_active_index(json_item.composites,json_item.active_composite_id)} on_click={composite_select_onclick} json_item={json_item}/>
							</Col>
						</Row>
						</CardBody>
					</Card>
				)
			}
			else if(json_item.type=='hatching_select'){
				return(
					is_section ? 
					<HatchingSelectnNew json_item={json_item} is_panel_pop_up={is_panel_pop_up} />
					:
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled class_position_unset CS----------CS':'class_position_unset CS----------CS'}>
						<CardBody className='class_position_unset' style={{ padding:'15px 0px 0px 0px', backgroundColor:COLORS.panel_card_item}} >
							<Row className='class_position_unset' style={{height:'auto',overflowY:'', margin:0}}>
								<Col className='class_position_unset mb-2' xs={12} style={{display:'flex',position:'unset', alignItems:"center", padding:'0px 5px 0px 10px', fontSize: '12px'}}>
									{json_item && json_item.name ? json_item.name : 'Select a type of hatch'}
								</Col>
								<Col className='class_position_unset' xs={12} style={{display:'block',position:'unset', alignItems:"center", padding:'0px 5px 0px 10px'}}>
									{/* <Dropdown className='class_position_unset' isOpen={dropdownOpen} toggle={toggle}>
										<DropdownToggle caret>
											Dropdown
										</DropdownToggle>
										<DropdownMenu className='class_position_unset'>
											<DropdownItem header>Header</DropdownItem>
											<DropdownItem>Some Action</DropdownItem>
											<DropdownItem text>Dropdown Item Text</DropdownItem>
											<DropdownItem disabled>Action (disabled)</DropdownItem>
											<DropdownItem divider />
											<DropdownItem>Foo Action</DropdownItem>
											<DropdownItem>Bar Action</DropdownItem>
											<DropdownItem>Quo Action</DropdownItem>
										</DropdownMenu>
									</Dropdown> */}
									<HatchingDropdown composites={json_item.categories} is_panel_pop_up={is_panel_pop_up} json_item={json_item} />
								</Col>
							</Row>
						</CardBody>
					</Card>
				)
			}
			else if(json_item.type == 'checkbox'){
                return(
					is_section ? <CheckboxInputNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} />
					:
                    <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CB----------CB':'CB----------CB'}>
						<CardBody style={{ padding:'15px 0px 0px 0px', paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
						<div className='flex_between inf-py-1 inf-px-2 hover_to_gray2' style={{fontSize: '12px', cursor: 'pointer'}} onClick={() => { document.getElementById(json_item.id).click() }}>
							<div style={{ display: "flex", alignItems: "center", gap: '6px', flex: 1, minWidth: '0px', textAlign: 'left' }}>
								{
									json_item.icon ? (
											<img height='16px' title={json_item.name} src={json_item.icon} />
									) : ''
								}
								<div title={json_item.name} style={{flex: 1, minWidth: '0px'}} className='lines1_elipsis' >{json_item.name}</div>
							</div>
							<Checkbox onClick={(e) => { e.stopPropagation(); common_onchange_handler()}} onChange={on_click} key={"key_" + json_item.id} id={json_item.id} type="checkbox" defaultChecked={json_item.selected} />
						</div>
						</CardBody>
					</Card>
				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CB----------CB':'CB----------CB'}>
				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
				// 		<Row>
				// 			<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
				// 				<div style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default",paddingLeft:'8px', paddingRight:'8px', flex:1}}><span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span></div>
				// 				<div style={{paddingRight:'8px'}}>
				// 					{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
				// 						<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
				// 					</FormGroup> */}
				// 					<Switch style={{}} size='small' defaultChecked = {json_item.selected} onClick={on_click}/>

				// 				</div>
				// 			</Col>
				// 		</Row>
				// 		</CardBody>
				// </Card>
                )
            }
			else if(json_item.type == 'text_info'){
                return(
                    <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled TI----------TI':'TI----------TI'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
						<Row>
							<Col xs={3} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.key} className='lines2_elipsis' style={{textAlign:'left',fontWeight:500}}>{json_item.key}</span></Col>
							<Col xs={1} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.key} className='lines2_elipsis' style={{textAlign:'left'}}>{"-"}</span></Col>
							<Col xs={8} style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default", paddingRight:'0px', flexWrap:'wrap'}}><span title={json_item.name} className='lines2_elipsis' style={{textAlign:'left'}}>{json_item.name}</span></Col>
						</Row>
						</CardBody>
					</Card>
                )
            }
			// else if(json_item.type == 'layer_select_layerset'){

			// }else if(json_item.type == 'layer_list_item'){
			// 	<div key={"key_"+json_item.id} id={json_item.id + "_button"} className={json_item.disabled?'panel_disabled':'hover_enabled_green_border LSL----------LSL'} onClick={json_item.disabled?null:on_click} style={{cursor:'pointer', height:42, width:'100%', display:'flex', flexDirection:'row', alignItems:'center', borderBottom: '2px solid', borderBottomColor:COLORS.panel_item_separation, padding:'15px', backgroundColor:COLORS.panel_card_item}}>
			// 			{	
			// 				json_item.icon?(
			// 					<span sm={2} style={{paddingRight:'10px'}}>
			// 						<img height='24px' width='24px' title={json_item.name} src={json_item.icon} />
			// 					</span>
			// 				):''
			// 			}	
			// 			<span sm={10}>
			// 				<span title={json_item.name} className='lines2_elipsis' id={json_item.id} style={{ fontSize:'12px', color:COLORS.black_left_menu }} >{json_item.name}</span>
			// 			</span>
			// 		</div>

			// }else if(json_item.type == 'add_layer'){

			// }
			else if(json_item.type == 'layerset_pop_up'){
				return(
					<LayersetPanelPopup json_item={json_item} update_view={update_view} set_layerset_mode={set_layerset_mode} set_given_layerset={set_given_layerset} set_modal_mode={set_modal_mode} common_onchange_handler={common_onchange_handler} handle_ui_response={handle_ui_response} view_id={json_item && json_item.active_layerset && json_item.active_layerset.view_id ? json_item.active_layerset.view_id : ''}/>
				)
			} else if(json_item.type === 'view_layer_input'){
				return (
					<ViewLayersPanel json_item={json_item} update_view={update_view} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} set_modal_mode={set_modal_mode} common_onchange_handler={common_onchange_handler} on_click={on_click} />
				)
			}
			else if(json_item.type == 'visibility_item'){
				return(
					<Card style={{ border:'0px solid'}} className={json_item.disabled?'panel_disabled VI----------VI':'VI----------VI'}>
						<div style={{backgroundColor:COLORS.panel_card_item, fontSize:'12px', padding:'15px 12px 0px 12px'}}>
						{
							json_item && json_item.layers && json_item.layers.length ? json_item.layers.map((single_layer, idx) => (
								<div className='flex_property' style={{height:'30px', width:'100%'}} key={idx}>
									<img src={single_layer['visible'] ? (json_item.show_content ? json_item.show_content : '/resources/nav_icons_new/view layer.svg') : (json_item.hide_content ? json_item.hide_content : '/resources/nav_icons_new/hide layer.svg')} style={{height:'16px', width:'16px',marginRight:'8px', cursor:'pointer'}} onClick={() => {icon_on_click({route: json_item.route, form: {id: single_layer.id}})}}/>
									{single_layer.name}
								</div>
							)):''
						}
						</div>
					</Card>
				)
			}
			else if(json_item.type == 'special_dimension_input'){
				// console.log(json_item, "panel json")
				return(
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled':'dI----------dI'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
						<Row>
							<Col style={{display:'flex',justifyContent:'center', alignItems:"center", paddingLeft:'8px', paddingRight:'8px'}}>
							{metric_system=='mm'?(
								<div style={{width:'100%'}}>
									<Row style={{width:'100%', marginLeft:'0px', marginBottom:'8px'}}>
										<div style={{width:'100%', display:'flex', alignItems:'center'}}>
											{json_item.name}
										</div>
									</Row>
									<Row style={{width:'100%', marginLeft:'0px', marginBottom:'15px'}}>
										{/* <Col sm={6} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', cursor:"default"}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
										<Col sm={6} style={{fontSize:13, display:"flex", alignItems:"center",justifyContent:'center', width:'100%', paddingRight:0}}><Input type="text" onChange={common_onchange_handler} id={json_item.id} key={"key_"+json_item.id} defaultValue={approxMM(json_item.default_value)||0} style={styles_panel.input_styles} placeholder="0"/></Col> */}
										<Col xs={4} style={{paddingRight:'8px', paddingLeft:'8px'}}>
											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.black, padding:'0px 4px'}}>
												<div style={{flex:1, color:'#FF5247', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>X</div>
												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={approxMM(json_item[0].default_value)||0} key={"key_" + json_item[0].id} id={json_item[0].id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px'}} placeholder="0"/>
											</div>
										</Col>
										<Col xs={4} style={{paddingRight:'8px', paddingLeft:'8px'}}>
											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.black, padding:'0px 4px'}}>
												<div style={{flex:1, color:'#23C16B', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>Y</div>
												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={approxMM(json_item[1].default_value)||0} key={"key_" + json_item[1].id} id={json_item[1].id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px'}} placeholder="0"/>
											</div>
										</Col>
										<Col xs={4} style={{paddingRight:'8px', paddingLeft:'8px'}}>
											<div style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.black, padding:'0px 4px'}}>
												<div style={{flex:1, color:'#4597F7', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>Z</div>
												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} defaultValue={approxMM(json_item[2].default_value)||0} key={"key_" + json_item[2].id} id={json_item[2].id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px'}} placeholder="0"/>
											</div>
										</Col>
									</Row>
								</div>
							):(
								<div>
									<Row style={{width:'100%', marginLeft:'0px', marginBottom:'8px'}}>
										<div style={{width:'100%', display:'flex', alignItems:'center'}}>
											{json_item.name}
										</div>
									</Row>

									<Row>
										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}></Col>
										<Col sm={2} className='zero_padding'>
											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span>
										</Col>
										<Col sm={2} className='zero_padding'>
											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span>
										</Col>
										<Col sm={2} className='zero_padding'>
											<span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
										</Col>
									</Row>


									<Row style={{marginBottom:'8px'}}>
										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item[0].name} className='lines2_elipsis' >{json_item[0].name}</span></Col>
										<Col sm={6} className='zero_padding' style={{border:'1px solid ' + COLORS.black}}>
											<Row style={{marginLeft:0, marginRight:0}}>
												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[0].default_value)[0]||0} key={"key_" + json_item[0].id + "_ft"} id={json_item[0].id + "_ft"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
												</Col>
												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[0].default_value)[1]||0} key={"key_" + json_item[0].id + "_in"} id={json_item[0].id + "_in"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
												</Col>
												<Col sm={4} className='zero_padding'>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[0].default_value)[2]||0} key={"key_" + json_item[0].id + "_parts"} id={json_item[0].id + "_parts"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
												</Col>
											</Row>
										</Col>
										
									</Row>

									<Row style={{marginBottom:'8px'}}>
										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item[1].name} className='lines2_elipsis' >{json_item[1].name}</span></Col>
										<Col sm={6} className='zero_padding' style={{border:'1px solid ' + COLORS.black}}>
											<Row style={{marginLeft:0, marginRight:0}}>
												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[1].default_value)[0]||0} key={"key_" + json_item[1].id + "_ft"} id={json_item[1].id + "_ft"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
												</Col>
												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[1].default_value)[1]||0} key={"key_" + json_item[1].id + "_in"} id={json_item[1].id + "_in"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
												</Col>
												<Col sm={4} className='zero_padding'>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[1].default_value)[2]||0} key={"key_" + json_item[1].id + "_parts"} id={json_item[1].id + "_parts"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
												</Col>
											</Row>
										</Col>
									</Row>

									<Row style={{marginBottom:'8px'}}>
										<Col sm={5} style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item[2].name} className='lines2_elipsis' >{json_item[2].name}</span></Col>
										<Col sm={6} className='zero_padding' style={{border:'1px solid ' + COLORS.black}}>
											<Row style={{marginLeft:0, marginRight:0}}>
												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[2].default_value)[0]||0} key={"key_" + json_item[2].id + "_ft"} id={json_item[2].id + "_ft"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
												</Col>
												<Col sm={4} className='zero_padding' style={{borderRight:'1px solid ' + COLORS.black}}>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[2].default_value)[1]||0} key={"key_" + json_item[2].id + "_in"} id={json_item[2].id + "_in"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
												</Col>
												<Col sm={4} className='zero_padding'>
													<Input type="text" onChange={common_onchange_handler} defaultValue={mmToFeetParts(json_item[2].default_value)[2]||0} key={"key_" + json_item[2].id + "_parts"} id={json_item[2].id + "_parts"} style={{...styles_panel.input_styles, border: 'none'}} placeholder="0"/>
													{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
												</Col>
											</Row>
										</Col>
									</Row>
								</div>
								
							)}
						<br/>
					</Col></Row>
						</CardBody>
					</Card>
					
				)
			}
			else if(json_item.type=='layer_input'){
				return(
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SI----------SI':'SI----------SI'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
						<Row style={{/*marginBottom:'15px'*/}}>
							<Col xs={4} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft: '8px', paddingRight: '8px'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
							<Col xs={8} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft: '8px', paddingRight: '8px'}}>
								<FormGroup className='createcard_input_container' style={{marginBottom:0, width:'100%', border:'1px solid ' + COLORS.panel_item_borders}}>
									<Input onChange={(e) => {on_select_input_change(e.target.value)} } key={"key_"+json_item.id} id={json_item.id} type="select" defaultValue={json_item.default_value} style={{...styles_panel.input_styles , border:'none'}}>
										{json_item.options.map((o,idx) => (
											<option key={idx} value={o.value}>{o.name}</option>
										))}
									</Input>
								</FormGroup>
							</Col>
						</Row>
						</CardBody>
					</Card>
				)
			}
			// else if(json_item && json_item.type=='dropdown_light'){
			// 	return(
			// 		<div style={{width: '100%', overflow: 'hidden'}} className={json_item.disabled?'panel_disabled':''}>
						
			// 			<div className={json_item.submenu && json_item.submenu.length?'':'panel_disabled'} onClick={json_item.disabled || !(json_item.submenu && json_item.submenu.length) ?null:dropdown_on_click} style={{cursor:"pointer",height:'42px', width:'100%',padding:'0px 15px', overflow: 'hidden', display:'flex', flexDirection:'row', alignItems:'center', borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation, borderRightColor:COLORS.blue6, borderRightWidth: is_dropdown_open()?'5px':'0px', borderRightStyle:'solid', backgroundColor:COLORS.toolbar_background_color}}>
			// 				{	
			// 					json_item.icon?
			// 					<span style={{paddingRight:'10px', flex:1}}>
			// 						<img height='24px' width='24px' style={{maxHeight: '100%', overflow: 'hidden'}} title={json_item.name} src={json_item.icon} />
			// 					</span>	
			// 					:''
			// 				}
							
			// 				<span style={{flex:8 , textAlign:'left' }} >
			// 					<span id={json_item.id} title={json_item.name} className='lines2_elipsis' style={{ fontSize:'14px', color:COLORS.black_left_menu}} >{json_item && json_item.name ? json_item.name:''}</span>
			// 				</span>
			// 				<span style={{flex:3}}>
			// 					<FormGroup style={{marginBottom:0, width:'100%'}}>
			// 						<Input onChange={common_onchange_handler} type="checkbox"></Input>
			// 					</FormGroup>
			// 					<img src={"/resources/nav_icons_new/delete 16px.svg"} style={{paddingLeft:'4px', marginLeft:'4px', cursor:'pointer', height:'17px', borderLeft:'1px solid #E6E9EE'}}/>
			// 					<span style={{ fontSize:'14px', color:COLORS.black_left_menu }} ><b>{!is_dropdown_open()?<i style={{fontSize:'12px', color: COLORS.black_left_menu}} className="fa fa-chevron-down"/>:<i className="fa fa-chevron-up"/>}</b></span>
			// 				</span>
			// 			</div>
			// 			<Collapse className='d--------------------d' isOpen={is_dropdown_open()} navbar={true} style={{ width:'100%', overflowX: 'hidden'}}>
			// 				<Nav navbar style={{borderLeft: '3px solid', borderLeft:'4px solid', borderLeftColor:'#A7A8B2', width:'100%', overflowX: 'hidden' }}>
								
			// 					{
			// 						json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item,idx) =>(
			// 							<NavItem key={json_item.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
			// 								<Create_card set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} key={json_item.id+idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} parent_id={json_item.id} parent_menu={json_item.submenu} update_view={update_view} json_item={sub_item} set_panel_has_unapplied_edits={set_panel_has_unapplied_edits} approxMM={approxMM} mmToFeetParts={mmToFeetParts} panel_explode = {panel_explode}/>
			// 							</NavItem>
			// 						)):''
									
			// 					}	
								
			// 				</Nav>
			// 			</Collapse>
			// 		</div>		
			// 	)
			// }
			else if(json_item.type == 'rotation_input'){
				// const [rotation_input, set_rotation_input] = useState(0);
				// return (
				// 	<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'RI----------RI'}>
				// 		<CardBody style={{ padding:'15px',paddingBottom:0,/* paddingTop:index ? 0 : 15,*/ backgroundColor:COLORS.panel_card_item}} >
				// 		<Row>
				// 			<Col style={{fontSize:14,alignItems:"center", cursor:"default", paddingLeft:'8px', marginBottom:'8px', fontWeight:700, color:COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none'}}><span title={json_item.name} className='lines2_elipsis' >{"Rotation(deg)"}</span></Col>
				// 		</Row>
				// 		<Row style={{/*marginBottom:'15px'*/}}>
				// 			<Col xs={4} style={{fontSize:14,display:'flex',alignItems:"center"}}>
				// 				<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}>
				// 					<div style={{display:'flex', width:'100%', border:json_item.onchange_route ? '1px solid ' + COLORS.panel_item_borders : 'none', padding:'0px 4px'}}>
				// 						<div style={{flex:1, color:json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name}</div>
				// 						<input key={"key_"+json_item.id} value={rotation_input} onChange={(e) => {common_onchange_handler(); set_rotation_input(e.target.value)}} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.default_value || 0 ; e.target.blur()}}} defaultValue={json_item.default_value||0} type="number"  id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'22px', outline:'none', display:json_item.onchange_route ? 'block' : 'none'}} />
				// 					</div>
				// 				</div>
				// 			</Col>
				// 			<Col xs={8} style={{fontSize:14,display:'flex',alignItems:"center", justifyContent:'flex-end', padding:'0px 8px'}}>
				// 				{/* {
				// 					json_item.buttons && json_item.buttons.length ? json_item.buttons.map(item => (
				// 						<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); window.Module[item.onclick](JSON.stringify({id: item.name}))}}/>
				// 					))
				// 					:''
				// 				} */}
				// 				<span style={{ marginRight:'8px', fontSize:'10px', maxWidth:'110px', textOverflow:'ellipsis', color:COLORS.gray5}} title={json_item.text_content ? json_item.text_content : ''}>{json_item.onchange_route ? "Rotate by " + (json_item.text_content ? json_item.text_content : '90') + ' deg' : json_item.text_content}</span>
				// 				<img src={"/resources/panel_icons_new/rotate left.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input(rotation_input - (json_item.text_content)); /*icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}})*/ on_click() } : () => {icon_on_click({route: json_item.left_rotation_route, form:{id: json_item.left_id}})} }/>
				// 				<img src={"/resources/panel_icons_new/rotate right.svg"} style={{ marginRight:'8px', cursor:'pointer', height:'20px'}} onClick={json_item.onchange_route ? () => {common_onchange_handler(); set_rotation_input(rotation_input - ('-' + json_item.text_content)); /*icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}})*/ on_click()} : () => {icon_on_click({route: json_item.right_rotation_route, form:{id: json_item.right_id}})}}/>

				// 			</Col>
				// 		</Row>
				// 		</CardBody>
				// 	</Card>
				// )

				return(
					is_section ? 
					<RotationInputNew key={json_item && json_item.id ? json_item.id + 'key_component' : 'nokey'} json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} icon_on_click={icon_on_click}/>
					:
					<RotationInput key={json_item && json_item.id ? json_item.id + 'key_component' : 'nokey'} json_item={json_item} on_click={on_click} common_onchange_handler={common_onchange_handler} icon_on_click={icon_on_click}/>
				)
			}
			else if(json_item.type === 'button_item'){
				return (
					<ButtonItem json_item={json_item} on_click={on_click} />
				)
			}
			else if(json_item.type=='select_and_dimension_input'){
				return(
					is_section ? 
					<SelectAndDimensionInputNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} metric_system={metric_system} approxMM={approxMM} mmToFeetParts={mmToFeetParts} approximation_ft={approximation_ft} />
					:
					<Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled SDI----------SDI':'SDI----------SDI'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
							<Row>
								<Col xs={json_item.full_select ? 6 : 0} style={{fontSize:'11px', display:json_item.full_select ? 'flex' : "none", alignItems:"center", cursor:"default", padding:'8px'}}><span title={json_item.name_select} className='lines1_elipsis' >{json_item.name_select}</span></Col>
								<Col xs={json_item.full_select ? 6 : 0} style={{fontSize:'11px', display:json_item.full_select ? 'flex' : "none", alignItems:"center", cursor:"default", padding:'8px'}}><span title={json_item.name_dim} className='lines1_elipsis' >{json_item.name_dim}</span></Col>
							</Row>

						<Row style={{/*marginBottom:'15px'*/}}>
							<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'center', paddingLeft:'8px', paddingRight:'0px'}}>
								<Row className='createcard_input_container' style={{/*marginBottom:'15px'*/border:'1px solid ' + COLORS.panel_item_borders, margin:0, padding:'0px 4px', width:'100%'}}>
									<Col xs={json_item.full_select ? 0 : 6} style={{fontSize:'11px', display:json_item.full_select ? 'none' : "flex", alignItems:"center", cursor:"default", padding:'0px'}}><span title={json_item.name_select} className='lines1_elipsis' >{json_item.name_select}</span></Col>
									<Col xs={json_item.full_select ? 12 : 6} style={{display:"flex", alignItems:"center", justifyContent:'center', padding:0}}>
										<FormGroup style={{marginBottom:0, width:'100%'}}>
											<Input onChange={json_item.apply_on_select ? () => {common_onchange_handler(); on_click()} : () => {common_onchange_handler()}} key={"key_"+json_item.id_select + 'st'} id={json_item.id_select + 'st'} type="select" defaultValue={json_item.default_value_select} style={{...styles_panel.input_styles , border:'none', height:'24px'}}>
												{json_item.options_select.map((o,idx) => (
													<option key={idx} value={o.value}>{o.name}</option>
												))}
											</Input>
										</FormGroup>
									</Col>
								</Row>
							</Col>
							<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default"}}>
							{metric_system=='mm'?(
										<div style={{paddingRight:'8px', paddingLeft:'0px', marginLeft: -15, marginRight: -15}}>
											<div className='createcard_input_container' style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
												<div className='lines1_elipsis' style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray3 , fontSize:'11px', display:json_item.full_select ? 'none' :'flex', alignItems:'center', justifyContent:'center'}} title={json_item.name_dim}>{json_item.name_dim && json_item.name_dim.length ? json_item.name_dim[0] : ''}</div>
												<Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value_dim) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value_dim)||0} key={"key_" + json_item.id_dim} id={json_item.id_dim} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/>
											</div>
										</div>
										
								):(
									<Row style={{paddingLeft:'8px', paddingRight:'8px'}}>
										{/* <Col sm={5} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col> */}
										<Col sm={12} className='createcard_input_container' style={{border:'1px solid ' + COLORS.panel_item_borders}}>
											{/* <Row style={{marginLeft:0, marginRight:0}}>
												<Col sm={12} className='zero_padding' style={{fontSize:13, textAlign:'left', cursor:'default'}}><span title={json_item.name} className='lines2_elipsis' >{json_item.name}</span></Col>
											</Row> */}
											<Row style={{ padding: '0px 4px' }}>

												<Col sm={json_item.full_select ? 0 : 1} className='zero_padding' style={{ fontSize: 13, textAlign: 'left', cursor: 'default', display: json_item.full_select ? 'none' : 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center', color: json_item.color ? json_item.color : COLORS.gray3 }}><span title={json_item.name_dim} className='lines2_elipsis' >{json_item.name_dim && json_item.name_dim.length ? json_item.name_dim[0] : ''}</span></Col>

												<Col xs={json_item.full_select ? 12 : 11} className='zero_padding'>
													<FocusWithin onBlur={on_click} style={{ display: 'flex', width: '100%' }}>
														<Col className='zero_padding flex_property' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '40%' }}>
															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value_dim)[0] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value_dim)[0] || 0} key={"key_" + json_item.id_dim + "_ft"} id={json_item.id_dim + "_ft"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px', padding: '0px' }} placeholder="0" />
															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>ft</span> */}
															<div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>ft</div>
														</Col>
														<Col className='zero_padding flex_property' style={{ borderRight: '1px solid ' + COLORS.panel_item_borders, width: '30%' }}>
															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value_dim)[1] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value_dim)[1] || 0} key={"key_" + json_item.id_dim + "_in"} id={json_item.id_dim + "_in"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>in</span> */}
															<div className='pr-1 flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>in</div>
														</Col>
														<Col className='zero_padding flex_property' style={{ width: '30%' }}>
															<Input type="text" onChange={common_onchange_handler} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = mmToFeetParts(json_item.default_value_dim)[2] || 0; e.target.blur() } }} defaultValue={mmToFeetParts(json_item.default_value_dim)[2] || 0} key={"key_" + json_item.id_dim + "_parts"} id={json_item.id_dim + "_parts"} style={{ ...styles_panel.input_styles, border: 'none', height: '24px' }} placeholder="0" />
															{/* <span style={{fontSize:13, textAlign:'left', cursor:"default"}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span> */}
															<div className='flex_property' style={{ fontSize: 10, textAlign: 'right', cursor: "default", color: COLORS.gray5, height: '24px' }}>{approximation_ft=='1/8_inch'?'1/8':'1/16'}</div>
														</Col>
													</FocusWithin>
												</Col>
											</Row>
										</Col>
									</Row>
								)}
							</Col>
						</Row>
						</CardBody>
					</Card>
				)
			}
			else if(json_item.type == 'toggle'){
                return(
					is_section ? <ToggleInputNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} />
					:
                    <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled TG----------TG':'TG----------TG'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
						<Row>
							<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
								<div style={{fontSize:'12px', display:"flex", alignItems:"center", justifyContent:'flex-start', cursor:"default",paddingLeft:'8px', paddingRight:'8px', flex:1}}>
									<span title={json_item.name} className='lines1_elipsis' >{json_item.name}</span>
								</div>
								<div style={{paddingRight:'8px'}}>
									{/* <FormGroup style={{marginBottom:0, width:'100%'}}>
										<Input onChange={common_onchange_handler} key={"key_"+json_item.id} id={json_item.id} type="checkbox" defaultChecked = {json_item.selected}></Input>
									</FormGroup> */}
									<Switch style={{}} size='small' disabled={json_item.disabled} key={"key_"+json_item.id} id={json_item.id} checked = {json_item.value} onClick={() => {on_click(); common_onchange_handler()}}/>

								</div>
							</Col>
						</Row>
						</CardBody>
					</Card>
                )
            }
			else if(json_item.type == 'child_tabs'){
				// console.log("childtabs", json_item)
				// const [active_tab, set_active_tab] = useState('');
                // return(
                //     <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CT----------CT':'CT----------CT'}>
				// 		<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
				// 		<Row>
				// 			<Col xs={12} className='zero_padding' style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
				// 				<div style={{padding:'0px 8px', display:'flex', flexFlow: 'row', flexWrap:'wrap', width:'100%'}}>
				// 					{
				// 						json_item.tabs.map((child_tab, idx) => (
				// 							<div key={idx} style={check_dropdown_state(child_tab.id) ? {padding:'4px 0px', margin:'0px 8px', fontSize:'12px', fontWeight:700, borderBottom:'1px solid ' + COLORS.blue6, color:COLORS.blue6, display:'flex'} : {padding:'4px 0px', margin:'0px 8px', fontSize:'12px', borderBottom:'1px solid #E6E9EE', color: COLORS.panel_group_heading, display:'flex'}} onClick={check_dropdown_state(child_tab.id) ? null : () => {tab_on_click(active_tab); set_active_tab(child_tab.id); tab_on_click(child_tab.id)}} >
				// 								<div style={{marginRight: '8px'}}>{child_tab.name}</div>
				// 								{
				// 									child_tab.buttons && child_tab.buttons.length ? child_tab.buttons.map(item => (
				// 										item.type == "switch" ? 
				// 											<Switch style={{marginRight:'8px', color:'green'}} size='small' checked = {item.value == "Off" ? false : true} onClick={(checked, e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
				// 										: item.type == 'label' ? 
				// 											<span style={{cursor:'pointer',marginRight:'4px', fontSize:'12px', color:COLORS.black, textTransform:'capitalize', fontWeight:400}}>{item.name}</span>
				// 										: item.icon == "+" ?
				// 											<span style={{cursor:'pointer',marginRight:'8px', fontSize:'12px', color:COLORS.blue6, textTransform:'capitalize'}} onClick={(e) => {is_dropdown_open() ? e.stopPropagation() : null; icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({}))*/}}>+ Add </span>
				// 										:
				// 											<img src={item.icon} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.onclick, form:{id: item.name}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
				// 									))
				// 									: ''
				// 								}
				// 							</div>
				// 							))
				// 					}
				// 				</div>
				// 			</Col>

				// 			{
				// 				json_item.tabs.map((child_tab, idx) => (
				// 					<div key={idx} className='d--------------------d' isOpen={check_dropdown_state(child_tab.id)} navbar={true} style={{ width:'100%', overflowX: 'hidden', display:check_dropdown_state(child_tab.id) ? 'block' : 'none'}}>
				// 						<Nav navbar style={{width:'100%', overflowX: 'hidden' }}>
											
				// 							{
				// 								child_tab.submenu && child_tab.submenu.length ? child_tab.submenu.map((sub_item,idx) =>(
				// 									<NavItem key={child_tab.id+idx} style={{display:'flex', alignItems:'center', flexDirection:'column', width:'100%', overflowX: 'hidden'}}>
				// 										<Create_card 
				// 											panel_key={panel_key}
				// 											set_page_loader={set_page_loader}
				// 											handle_ui_response={handle_ui_response}
				// 											key={child_tab.id+idx}
				// 											metric_system={metric_system}
				// 											approximation_mm={approximation_mm}
				// 											approximation_ft={approximation_ft}
				// 											parent_id={child_tab.id}
				// 											parent_menu={child_tab.submenu}
				// 											update_view={update_view}
				// 											json_item={sub_item}
				// 											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
				// 											approxMM={approxMM}
				// 											mmToFeetParts={mmToFeetParts}
				// 											panel_explode={panel_explode}
				// 											is_grouped={child_tab.group_child_inputs}
				// 											index={idx}
				// 											configuration_type={child_tab.configuration_type}/>
				// 									</NavItem>
				// 								)):''
												
				// 							}
				// 							{/* {
				// 								json_item.submenu && json_item.submenu.length && !(json_item.submenu[json_item.submenu.length-1].type == 'list-item' || json_item.submenu[json_item.submenu.length-1].type == 'dropdown' || json_item.submenu[json_item.submenu.length-1].type == 'button') ?
				// 									<Create_card 
				// 										panel_key={panel_key}
				// 										set_page_loader={set_page_loader}
				// 										handle_ui_response={handle_ui_response}
				// 										metric_system={metric_system}
				// 										approximation_mm={approximation_mm}
				// 										approximation_ft={approximation_ft}
				// 										// parent_menu={panel_json}
				// 										update_view={update_view}
				// 										json_item={{type:'margin_bottom'}}
				// 										set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
				// 										approxMM={approxMM}
				// 										mmToFeetParts={mmToFeetParts}
				// 										panel_explode={false}
				// 									/>
				// 								:''
				// 							}	 */}
				// 						</Nav>
				// 					</div>
				// 				))

				// 			}
				// 		</Row>
				// 		</CardBody>
				// 	</Card>
                // )
				return(
					<TabDropdown 
						json_item={json_item}
						icon_on_click={icon_on_click}
						panel_key={panel_key}
						set_page_loader={set_page_loader}
						handle_ui_response={handle_ui_response}
						metric_system={metric_system}
						approximation_mm={approximation_mm}
						approximation_ft={approximation_ft}
						update_view={update_view}
						set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
						approxMM={approxMM}
						mmToFeetParts={mmToFeetParts}
						panel_explode={panel_explode}
						tab_on_click={tab_on_click}
                        is_panel_pop_up={is_panel_pop_up}
						mode={mode}
						panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string} onSubmitCallback={onSubmitCallback} is_section={is_section}
					/>
				)

            }
			else if(json_item.type == 'comment'){
				return(
					<Comment json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click}/>
				)
			}
			else if(json_item.type == 'presentation_sheet'){
				return(
					<PresentationSheet json_item={json_item} icon_on_click={icon_on_click} on_click={on_click} inside_tab={inside_tab} is_draggable={is_draggable}/>
				)
			}
			else if(json_item.type == 'button_array'){
                return(
                    <Card style={{ border:'0px solid'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled BA----------BA':'BA----------BA'}>
						<CardBody style={{ padding:'15px',paddingBottom:0, backgroundColor:COLORS.panel_card_item}} >
						<Row>
							<Col xs={12} className='zero_padding' style={{display: 'flex', alignItems:'center', flexWrap:'wrap'}}>
								{
									json_item.buttons && json_item.buttons.length ? json_item.buttons.map((item, idx) =>(
										<img src={item.icon} key={idx} style={{ marginRight:'8px', cursor:'pointer', height:'17px'}} onClick={(e) => {e.stopPropagation(); icon_on_click({route:item.route, form:{id: item.id}}) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/}}/>
									))
									:''
								}
							</Col>
						</Row>
						</CardBody>
					</Card>
                )
            }
			else if(json_item.type == 'slider' || json_item.type == 'slider_noshow'){
				return(
					<Slider json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} slider_type={json_item.type == 'slider' ? false : true}/>
				)
			}
			else if(json_item.type == 'image_map_select'){
				return(
					is_section ?
					<ImageMapSelectNew json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} icon_on_click={icon_on_click}/>	
					:
					<ImageMapSelect json_item={json_item} common_onchange_handler={common_onchange_handler} on_click={on_click} icon_on_click={icon_on_click}/>	
				)
			}
			else if(json_item.type == 'margin_bottom'){
				return(<div style={{height: json_item.margin ? json_item.margin : '16px', borderBottom: json_item.border ? json_item.border : inside_tab ? '1px solid #E6E9EE' : '1px solid #C5C7CF', margin: inside_tab ? '0px 8px' : '0px', backgroundColor: json_item.backgroundColor ? json_item.backgroundColor : ''}}></div>)
			}
			else if(json_item.type == 'demarcator'){
				return(<div style={{height: '16px', backgroundColor:COLORS.gray3}}></div>)
			}
			else if(json_item.type == 'snap_text'){
				return(<div style={{textAlign:'center', padding:'12px 8px', fontSize:'12px', color:COLORS.gray5}}>Press <span style={{fontWeight: 500, fontStyle:'italic'}}>shift</span> to temporarily disable snaps </div>)
			}else if(json_item.type == 'input_info'){
				return(
					<TextInfo json_item={json_item} handle_ui_response={handle_ui_response} is_panel_pop_up={is_panel_pop_up} update_view={update_view} is_section={is_section}/>
				)
			}
			else{
				return(<div></div>)
			}
		}	
	}

	return( 
		<div style={{width: '100%', overflowX: 'hidden'}}>
			{type_fn()}
		</div>
	)
}

export default Create_card;
