import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import {useTranslation} from 'react-i18next'
import camJsonData from '../../models/core_jsons/cam_structure.json';
import { useAlert } from "react-alert";
import DragDropComponent from "../DragDropCompoment";
import { Modal } from "reactstrap";
import { IModalBody, IModalFooter, IModalHeader } from "../../shared_components/ui_component_library/IModal";
import IButton from "../../shared_components/ui_component_library/IButton";
import { Tooltip } from "antd";
import { wait_for_all_core_threads } from "../../utils/wait_for_all_core_threads";
import { isNonEmptyArray } from "../../utils/array";
let default_cam_structure = camJsonData;
if(typeof default_cam_structure == 'string') default_cam_structure = JSON.parse(default_cam_structure)
const custom_field_ids_cutlist = ['custom_field1', 'custom_field2', 'custom_field3'];
const custom_cutlist_variables = ['core_material_sku_name', 'core_material_model_no', 'internal_finish_sku_name', 'internal_finish_model_no', 'external_finish_sku_name', 'external_finish_model_no'];

const Help_modal = ({open2, close_help_modal}) => {
    const {t} = useTranslation()
    return (
        <Modal size='md' centered style={{marginRight: '550px'}} isOpen={open2} toggle={close_help_modal}>
            <IModalHeader toggle={close_help_modal}>Help</IModalHeader>
            <IModalBody>
                <div>
                    <div style={{paddingBottom: '5px', fontWeight: 'bold'}}>{t(`Syntax for Formula:`)}</div>
                    <div style={{paddingBottom: '10px'}}><span style={{color: '#6A1B9A'}}>{"<Variable1>"}</span> <span style={{color: '#0000FF'}}>+</span>  <span style={{color: '#008080'}}>'constant'</span> <span style={{color: '#0000FF'}}>+</span>  <span style={{color: '#6A1B9A'}}>{"<Variable2>"}</span> + ...</div>
                    <div style={{marginBottom: '5px', paddingBottom: '15px'}}>{t(`Variables are Infurnia defined fields and can be selected from suggestions given when entering formula. Constants are user-defined values and should be enclosed in single or double quotes`)}</div>
                    <div style={{display: 'flex', alignSelf: 'center', gap: '10px'}}>
                        <div style={{flex: 1, alignSelf:'start', fontWeight: 'bold'}}>
                            Example: 
                        </div>
                        <div style={{paddingLeft: '10px'}}>
                            <div><span style={{color: '#6A1B9A'}}>core_material_sku_name</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#008080'}}>'_'</span> <span style={{color: '#0000FF'}}>+</span> </div> 
                            <div><span style={{color: '#6A1B9A'}}>internal_finish_sku_name</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#008080'}}>'_'</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#6A1B9A'}}>external_finish_model_no</span> <span style={{color: '#0000FF'}}>+</span> </div> 
                            <div><span style={{color: '#6A1B9A'}}>internal_finish_sku_name</span></div> 
                        </div>
                    </div>
                </div>
            </IModalBody>
            <IModalFooter>
                <IButton onClick={close_help_modal}>OK</IButton>
            </IModalFooter>
        </Modal>
    )
}

const Boolean_switcher = ({init, onchange_callback, title, isLast=false}) => {

	const [isChecked, set_isChecked] = useState(false)

	useEffect(() => {
		set_isChecked(init);
	}, [])

	return (
	<div className="flex_between">
		<div className="inf-font-bold">{title}</div>
		<div style={{minHeight: '32px', display: 'flex', alignItems: 'center'}}>
			<Switch
			onChange={() => {onchange_callback(); set_isChecked(!isChecked)}} 
			checked={isChecked}
			uncheckedIcon={false}
			checkedIcon={false}
			offColor='#e6e6e6'
			offHandleColor='#808080'
			onColor='#0066ff'
			onfHandleColor='#b3d1ff'
			height={15}
			width={30}	
			/>
		</div>
	</div>
	)
}

const Select_option = ({init, options, onchange_callback, title, isLast=false}) => {
	const [sel_val, set_sel_val] = useState('');

	useEffect(() => {
		set_sel_val(init);
	}, [])

	return (
		<div className="flex_property">
			<div className="flex-1-mw inf-font-bold">{title}</div>
			<div className="flex-1-mw createcard_input_container" style={{height: '32px'}}>
			<select value={sel_val} onChange={(e) => {onchange_callback(e.target.value); set_sel_val(e.target.value)}} style={{width: '100%', height: '100%', border: 'none', outline: 'none'}}>
                {
                    isNonEmptyArray(options) ? options.map(option => (
                        <option value={option.value}>{option.name}</option>
                    ))
                        : ''
                }
			</select>
			</div>
		</div>
	)
}

const Number_input = ({init, onchange_callback, title, enabled }) => {
	const [sel_val, set_sel_val] = useState();

	useEffect(() => {
		set_sel_val(init);
	}, [])

    useEffect(() => {
        console.log("sel val - ", sel_val)
    }, [sel_val]);

	return (
		<div className="flex_property">
			<div className="flex-1-mw inf-font-bold">{title}</div>
			<div className="flex-1-mw createcard_input_container" style={{height: '32px'}}>
            {
                enabled ?
                    <input key={"enabled_input"} disabled={!enabled} value={sel_val} onChange={(e) => { onchange_callback(e.target.value); set_sel_val(e.target.value) }} style={{width: '100%', height: '100%', border: 'none', outline: 'none', cursor: enabled ? 'auto' : 'not-allowed'}} type="number" />
                :
                <Tooltip trigger={['hover']} title="Only applicable when Pre Milling Type is set as Same For All Panels">
                    <div style={{width: '100%', height: '100%'}}>
                        <input key={"disabled_input"} disabled={!enabled} value={sel_val} onChange={(e) => { onchange_callback(e.target.value); set_sel_val(e.target.value) }} style={{width: '100%', height: '100%', border: 'none', outline: 'none', cursor: enabled ? 'auto' : 'not-allowed'}} type="number" />
                    </div>
                </Tooltip>
            }
			</div>
		</div>
	)
}

const Add_Edit_Custom_Field_Modal = ({set_custom_name, item, fields, set_fields, open1, handle_close1, mode = 'edit', custom_cutlist_fields_arr, id = ''}) => {
    const [field_id, set_field_id] = useState('')
    const [display_name, set_display_name] = useState('')
    const [formula, set_formula] = useState('')
    const [custom_cutlist_fields, set_custom_cutlist_fields] = useState({})
    const [help_modal, set_help_modal] = useState(false)
    const [preventBlur, set_preventBlur] = useState({is_valid: false});
    const [display_suggestions, set_display_suggestions] = useState(false);
    const {t} = useTranslation()
    const alert = useAlert()

    useEffect(() => {
        let originalObject = {};
        let temp = JSON.parse(JSON.stringify(custom_cutlist_fields_arr))
        let garb1 = temp.map(obj => {
            let { id, ...rest } = obj;
            originalObject[id] = rest;
        });
        set_custom_cutlist_fields(originalObject)
    }, [custom_cutlist_fields_arr])

    const close_modal = () => {
        handle_close1()
        set_field_id('')
        set_display_name('')
        set_formula('')
    }

    const close_help_modal = () => {
        set_help_modal(false);
    }

    function isAlphabet(character) {
        const regex = /^[A-Za-z]$/
        return regex.test(character)
    }

    function isAlphanumeric(character) {
        const regex = /^[a-zA-Z0-9]$/
        return regex.test(character)
    }

    function isSpecialSymbol(character) {
        return character == '_' || character == ':' || character == '$'
    }
    
    const isValidVariableName = (name) => {
        if(name.trim().length == 0) 
            return false
        if(!(isAlphabet(name[0]) || isSpecialSymbol(name[0])))
            return false
        for(let i = 1; i < name.length; i++){
            if(!(isAlphanumeric(name[i]) || isSpecialSymbol(name[i])))
                return false
        }
        return true
    }

	function splitIgnoringQuotes(inputString) {
		const parts = [];
		let currentPart = '';
		let singleQuotes = false;
		for (let i = 0; i < inputString.length; i++) {
			const char = inputString[i];
			if (char === "'" || char === '"') {
					singleQuotes = !singleQuotes;
				currentPart += char;
			} else if (char === '+' && !singleQuotes) {
				parts.push(currentPart.trim());
				currentPart = '';
			} else {
				currentPart += char;
			}
		}
		parts.push(currentPart.trim());
		return parts;
	}

	const validateString = (exp) => {
		let cnt = 0;
		for(let i=0; i<exp.length; i++){
			if(exp[i] == "'" || exp[i] == '"')
				cnt++;
		}
		return cnt == 2;
	}


    const validate = (exp) => {
		if(exp == '' || exp.length == 0) return true
        let operands = splitIgnoringQuotes(exp)
        for(let i=0; i<operands.length; i++){
            let temp = operands[i].trim()
            if(temp.length > 1 && (temp[0] == '\'' || temp[0] == '\"') && (temp[temp.length - 1] == '\'' || temp[temp.length - 1] == '\"') && validateString(temp))
                continue;
            else if(isValidVariableName(temp) && custom_cutlist_variables.includes(temp))
                continue;
            return false;
        }
        return true
    }

    function replaceSingleQuotes(str) {
        return str.replace(/'/g, "\\'");
    }

    function replaceEscapedQuotes(str) {
        return str.replace(/\\'/g, "'");
    }
    function replaceDoubleQuotesWithSingleQuotes(str) {
        return str.replace(/"/g, "'");
    }
    

    // function requestBody() {
    //     return [{designing_preference_id: fields[id]['formula']['id'], value: replaceDoubleQuotesWithSingleQuotes(formula)}, {designing_preference_id: fields[id]['custom_name']['id'], value: display_name ? display_name : (id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id)}]
    // }

    const submit_fields = async () => {
        try {
			const temp_fields = JSON.parse(JSON.stringify(fields))
			const cust_field = temp_fields.find(item => item.id == id)
			if(cust_field){
				cust_field.custom_name = display_name ? display_name : (id ? (custom_cutlist_fields[id]["custom_name"] ? custom_cutlist_fields[id]["custom_name"] : '') : id)
				cust_field.formula = replaceDoubleQuotesWithSingleQuotes(formula)
			}
			set_fields(temp_fields)
			set_custom_name(display_name ? display_name : (id ? (custom_cutlist_fields[id]["custom_name"] ? custom_cutlist_fields[id]["custom_name"] : '') : id));
			handle_close1();
        } catch(err) {
            console.log(err);
        }
    }

    const handle_submit = async () => {
        if(!validate(formula)){
            alert.error('Formula is not valid')
            return
        }
        submit_fields(); 
        // console.log(requestBody())
    }

    useEffect(() => {
        if(open1){
            set_formula(id ? (custom_cutlist_fields[id]["formula"] ? custom_cutlist_fields[id]["formula"] : '')  : '')
        }
    }, [open1])


    const onMouseDownSuggestion = (event, suggestion) => {
		preventBlur.is_valid = true; 
		event.stopPropagation(); 
		handleSuggestionClick(suggestion)
        set_display_suggestions(false)
	}

    const handleSuggestionClick = (suggestion) => {
		if(document.getElementById('formula_input')){
			// document.getElementById('formula_input').value = value + suggestion
            set_formula(formula.substring(0, formula.length - getSuffix(formula).length) + suggestion);
		}
		// set_suggestions([]);
	};

    function getSuffix(str) {
        let suffix = '';
        for (let i = str.length - 1; i >= 0; i--) {
            const char = str[i];
            if (char === '+' || /\s/.test(char)) {
                break; // Stop traversing when encountering '+' or whitespace
            }
            suffix = char + suffix; // Prepend the character to the suffix
        }
        return suffix;
    }

    const inputOnBlur = (event) => {
		if (preventBlur.is_valid) {
			event.preventDefault(); 
			event.target.focus()
			const inputLength = event.target.value.length;
    		event.target.setSelectionRange(inputLength, inputLength);
			preventBlur.is_valid = false
            set_display_suggestions(true)
		} else {
            set_display_suggestions(false)
		}
	}

	const handleFocus = (event) => {
        if((formula == '' || formula.length==0))
            set_display_suggestions(true)
    }

    useEffect(() => {
		if(formula)
        	set_display_suggestions(custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0)
        // console.log("suffix", getSuffix(formula), custom_cutlist_variables, custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0)
    }, [formula])

	useEffect(() => {
		if(open1)
			set_display_name(item.custom_name ? item.custom_name : item.name)
	}, [item.custom_name, open1])


    return (
        <Modal size='md' centered isOpen={open1} toggle={close_modal} style={{marginRight: '550px'}}>
            <IModalHeader toggle={close_modal}>Edit {id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id}</IModalHeader>
            <IModalBody>
                {/* <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px'}}>
                    <div>Default Name</div>
                    <input disabled style={{opacity: '50%', maxWidth: '60%'}} type='text' value={id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id} />
                </div> */}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px'}}>
                    <div>{t(`Display Name`)}</div>
                    <input style={{maxWidth: '60%'}} type='text' value={display_name} onChange={e => set_display_name(e.target.value)} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
                    <div>{t(`Formula`)}</div>
                    <input id='formula_input' style={{maxWidth: '60%', border: validate(formula)? '' : '1px solid red'}} placeholder='Enter a formula' type='text' value={id ? formula : ''} onChange={e => {set_formula(e.target.value)}} onBlur={inputOnBlur} onFocus={handleFocus}/>
                    {custom_cutlist_variables && custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0 && (
                    <div className="suggestion-list-cutlist" id="suggestion-list-cutlist" style={{display: display_suggestions ? '' : 'none', zIndex: 3, background: 'white'}}>
                        {custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).map((suggestion, index) => (
                            <div className='single-suggestion-cutlist' key={index} onMouseDown={(event) => {onMouseDownSuggestion(event, suggestion)}}>
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
                </div>
            <Help_modal open2={help_modal} close_help_modal={close_help_modal}/>
            </IModalBody>
            <IModalFooter>
                <IButton onClick={() => {set_help_modal(true)}}>Help</IButton>
                <IButton onClick={handle_submit}>OK</IButton>
            </IModalFooter>
        </Modal>
    )
}

const Single_item_for_cutlist_pref = ({item, input_array, set_input_array}) => {

	const [name_editing_mode, set_name_editing_mode] = useState(false);
	const [id, set_id] = useState('')
    const [mode, set_mode] = useState('')
    const [add_edit_custom_field_modal, set_add_edit_custom_field_modal] = useState(false)
	const [custom_name, set_custom_name] = useState('')

	// TODO - name_editing_mode not used. Cleanup
	useEffect(() => {
		if(name_editing_mode){
			let input_name  = document.getElementById('edit_cutlist_pref_name')
			if(input_name){
				input_name.focus()
				input_name.select(0, input_name.value.length)
			}
		}
	}, [name_editing_mode]);

	const checkbox_onchange = (item) => {
		var field = input_array.find(o => o.id === item.id);

		if(field){
			field.visibility = !field.visibility;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

	const rename_cutlist_pref = (name) => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if(field){
			field.custom_name = name;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

	useEffect(() => {
		set_custom_name(item.custom_name ? item.custom_name : item.name)
	}, [item.custom_name])

    return(
        <div style={{height:'35px', display:'flex', alignItems:'center', width:'100%'}}>
			<div style={{marginRight:'15px', display:'flex', alignItems:'center'}}><i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/><i className='fa fa-ellipsis-v'/></div>
			<div style={{display:'flex', alignItems:'center', width:'100%'}}>
				<div style={{textTransform:'capitalize', fontSize:'14px', flex:1, minWidth: '10px', flexWrap: 'wrap'}}>{item.name}</div>
				<input id={'edit_cutlist_pref_name' + item.id} spellCheck={false} className='focus_input mr-2 flex items-center' style={{fontSize: '12px', height: '24px', flex: 1, minWidth: '10px'}} value={custom_name} onChange={(e) => {set_custom_name(e.target.value)}} onBlur={(e) => { rename_cutlist_pref(e.target.value); set_name_editing_mode(false) }} onKeyDown={(e) => { if (e.key === 'Enter') {rename_cutlist_pref(e.target.value); set_name_editing_mode(false)} else if (e.key == 'Escape') { set_name_editing_mode(false) } }} />
				<div style={{display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'center'}}>
				{item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id) ? 
                <i style={{cursor:'pointer', color: "#1070CA" }} onClick={() => {set_id(item.id); set_mode('edit'); set_add_edit_custom_field_modal(true);}} className='fa fa-edit pl-2 pr-2 pt-2 pb-2 mr-1'/> : ''}
					<Switch 
					onChange={() => {checkbox_onchange(item)}} 
					checked={item.visibility}
					uncheckedIcon={false}
					checkedIcon={false}
					offColor='#e6e6e6'
					offHandleColor='#808080'
					onColor='#0066ff'
					onfHandleColor='#b3d1ff'
					height={15}
					width={30}	
					/>
				</div>
			</div>
			<Add_Edit_Custom_Field_Modal set_custom_name={set_custom_name} item={item} fields={input_array} set_fields={set_input_array} open1={add_edit_custom_field_modal} handle_close1={() => {set_mode(''); set_id(''); set_add_edit_custom_field_modal(false);}} mode={mode} id={id} custom_cutlist_fields_arr={input_array.filter(item => item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id))}/>
		</div>
    )
}

const CutlistSetting = ({ handle_ui_response, modal_json, update_view }) => {

    const [open, set_open] = useState(false);
	const [dummy_modal_json, set_dummy_modal_json] = useState('')
	const [root_fields, set_root_fields] = useState('')
    const [table_type, set_table_type] = useState('')
    const [fields, set_fields] = useState([])
	const [dim_options, set_dim_options] = useState([{value: '1_mm', name: '1_mm'}, {value: '0.01_mm', name: '0.01_mm'}, {value: '0.1_mm', name: '0.1_mm'}])
    const [pre_milling_type, set_pre_milling_type] = useState('');

    const {t} = useTranslation()
    const alert = useAlert()

    const replaceAndCapitalize = (s) => {
        return s && s.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	}

	useEffect(() => {
		// console.log("kp2: ", modal_json, modal_type)
		if(modal_json && modal_json["table_type"] && modal_json["settings"]){
			set_dummy_modal_json(JSON.parse(JSON.stringify(modal_json)))
            set_table_type(modal_json["table_type"])
			try{
				set_root_fields(JSON.parse(JSON.stringify(modal_json["settings"])))
                set_pre_milling_type(modal_json["settings"]["pre_milling_type"])
                set_fields(Object.entries(modal_json["settings"].fields).map(([id, values]) => ({ id, ...values })).sort((a, b) => a.order - b.order))
			}
			catch(err){
				console.error(err)
			}
		}
	}, [modal_json, open])

	useEffect(() =>{
		if(fields && root_fields){
			let temp = JSON.parse(JSON.stringify(fields))
			let garb = temp.map((item, idx) => item.order = idx + 1)
			let originalObject = {};
			let garb1 = temp.map(obj => {
				let { id, ...rest } = obj;
				originalObject[id] = rest;
			});
			root_fields.fields = originalObject
		}
	}, [fields])

	const on_submit = async () => {
        let pre_milling_value = Number(root_fields["pre_milling_value"])
        if(Number.isNaN(pre_milling_value) || pre_milling_value < 0){
            alert.error('Pre milling value should be a positive number')
            return
        }

		let json1 = modal_json.cutlist_preferences, json2 = root_fields
        let json = {data: root_fields, cutlist_type: table_type["value"]}

		var resp = await window.Promisify(window.Module["update_partial_cutlist_preferences_from_modal"](JSON.stringify(json)));
		handle_ui_response(resp);
		handle_on_close();
	}

	const update_cutlist_modal = async () => {
		await wait_for_all_core_threads();
		let resp = window.Module.open_cutlist_modal_on_thread();
		await handle_ui_response(resp);
	}

	const handle_on_close = async() => {
		await update_cutlist_modal();
        cutlist_setting_toggle()
        // update_view();
	}

	const cutlist_setting_toggle = async () => {
        // if(open) {
        //     set_dummy_modal_json('')
        //     set_root_fields('')
        //     set_table_type('')
        //     set_fields([])
        // }
        set_open(!open)
	}	

    const get_active_cutlist_type_name = (modal_json) => {
        let id = modal_json.table_type.value
        let options = modal_json.table_type.options || [];
        let name = options.find(option => option.id == id)? options.find(option => option.id == id).name : ''
        return name ? name : ''
    }

	return (
        <Tooltip open={open} onOpenChange={(visible) => set_open(visible)} placement="leftTop" trigger={['click']} color="white" overlayStyle={{width: '450px', maxWidth: '450px'}} overlayInnerStyle={{maxHeight: 'unset', padding: '16px' }} title={
            <>
                <div className="flex_column inf-gap-2" style={{color: 'black'}}>
                    <b>Common Cutlist Settings for all machines</b>
                    <div className="flex_column inf-gap-2">
                        <Select_option init={pre_milling_type} options={root_fields && root_fields["pre_milling_options"]} onchange_callback={val => {root_fields["pre_milling_type"] = val; set_pre_milling_type(val)}} title={replaceAndCapitalize("pre_milling_type")} />
                        <Number_input enabled={pre_milling_type == "same_for_all_panels"} init={root_fields && root_fields["pre_milling_value"]} onchange_callback={val => root_fields["pre_milling_value"] = val} title={replaceAndCapitalize("pre_milling_value")} />
                    </div>
                    <b>{get_active_cutlist_type_name(modal_json)} specific settings</b>
                    <div className="flex_property inf-w-full">
                        <div className="flex_between inf-w-full">
                            <div className="flex-1-mw inf-font-bold">{t(`Default Name`)}</div>
                            <div className="flex-1-mw inf-font-bold">{t(`Name`)}</div>
                            <div className="flex-1-mw inf-font-bold">{t(`Visibility`)}</div>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '300px', overflowY: 'auto'}}>
                        <DragDropComponent style={{paddingBottom: '3px'}} items_from_backened={fields} set_items_from_backened={set_fields} modal_name='cutlist_pref' Single_component={Single_item_for_cutlist_pref} />
                    </div>
                    <div className="flex_column inf-gap-2">
                    {
                        Object.keys(root_fields).map((item, idx)  => (
                            item == 'dimension_accuracy' ?
                                <Select_option init={root_fields[item]} options={dim_options} onchange_callback={val => root_fields[item] = val} title={replaceAndCapitalize(item)} isLast={idx==Object.keys(root_fields).length-1}/> 
                                :
                                ['include_project_information', 'include_store_information'].includes(item) ?
                                    <Boolean_switcher init={root_fields[item]} onchange_callback={() => root_fields[item] = !root_fields[item]} title={replaceAndCapitalize(item)} isLast={idx==Object.keys(root_fields).length-1}/> : ''
                        )) 
                    }
                    </div>
                </div>
                <IButton onClick={() => {on_submit()}}>Save preferences</IButton>
            </>
        }>
            <div className='ml-4' id="cutlist_settings">
                <i className='fa fa-cog cp' style={{ fontSize: '18px' }} />
            </div>
        </Tooltip>
	)
}

export default CutlistSetting;
