import React, { useEffect, useState } from "react"
import { isNonEmptyArray } from "../utils/array"
import ConnectorCanvasComponent from "./ConnectorCanvasComponent"
import Icons from "../ui_component_library/Icons"
import { ButtonsDropdown } from "../ui_component_library/ButtonsDropdown"
import { get_sku_details_core_format, get_sku_display_url } from "../utils/sku"
import './styles.css'
import { Tooltip } from "antd"

const DefaultApplicatorCardWrapper = ({children}) => {
	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	)
}

export const ApplicatorCard = ({o, is_selected, collision_info, edit_selected_applicator, deselect_applicator, select_applicator, dropdown_buttons, ApplicatorCardWrapper=DefaultApplicatorCardWrapper, ApplicatorCardWrapperProps, sku_cache,  ...props}) => {

	const [ sku_details, set_sku_details ] = useState({});

	useEffect(() => {
		if(o.sku_id && o.sku_id != sku_details.id){
			if(sku_cache && sku_cache[o.sku_id]){
				set_sku_details(sku_cache[o.sku_id]);
			}else{
				(async() => {
					let _sku_details = await (get_sku_details_core_format(o.sku_id));
					set_sku_details(_sku_details);
				})()
			}
		}
	}, [o]);

	const get_quantity_from_applicator = (applicator) => {
		if(applicator.placement_styles.length > 1 || applicator.application_method.includes("center_")){
			return "Dynamic"
		}else if(applicator.placement_styles.length == 1 && applicator.placement_styles[0].length == 2){
			return applicator.placement_styles[0][1].offsets.length
		}

		return 0;
	}

	return (
		<ApplicatorCardWrapper {...ApplicatorCardWrapperProps} sku_details={sku_details}>
		<div className='cam_applicator_card' {...props}>
			<div className='flex_between inf-gap-2'>
				<div className="flex_property inf-gap-2">
					<div style={{fontWeight: 700}} >{o.name}</div>
					{
						collision_info ? 
							<Tooltip trigger={['click']} title={collision_info} placement="bottomRight" overlayStyle={{width: '400px', maxWidth: 'unset'}} overlayInnerStyle={{maxHeight: '500px', overflow: 'auto'}}>
								<Icons name={"warning"} style={{cursor: 'pointer', color: '#BF2600', fontSize: '16px'}} />
							</Tooltip> : ''
					}
				</div>
				<div className="flex_property" style={{ gap: '8px' }} onClick={(e) => e.stopPropagation()}>
					<Tooltip trigger={['click']} placement="bottomLeft" overlayStyle={{maxWidth: 'unset', width: '300px'}} overlayInnerStyle={{padding: '16px'}} title={
						<div className="flex_column inf-gap-3">
							<div className="flex_between"><b>Status:</b> <div>{o.is_fe ? "Created within Design" : "Published on Admin"}</div></div>
							<div className="flex_between"><b>ID:</b> <div>{o.id}</div></div>
							<div className="flex_between"><b>Owned:</b> <div>{o.is_owned !== undefined && !o.is_owned ? "Not Owned" : "Owned"}</div></div>
							<div className="flex_between"><b>Sku Serial No:</b> <div>{sku_details.sku_serial_number}</div></div>
							<div className="flex_between"><b>Sku Group:</b> <div>{sku_details.sku_group_name}</div></div>
							<div className="flex_between"><b>Sku Sub Category:</b> <div>{sku_details.sub_category_name}</div></div>
							<div className="flex_between"><b>Sku Category:</b> <div>{sku_details.category_name}</div></div>
						</div>
					}>
						<Icons name={'info'} className={'cp inf-px-1'} />
					</Tooltip>
					{edit_selected_applicator ? <Icons onClick={() => edit_selected_applicator(o)} name={'edit'} title={"Edit"} style={{ fontSize: '16px', cursor: 'pointer' }} /> : '' }
					{is_selected?(<img src="/resources/icons/remove.svg" onClick={() => deselect_applicator(o?o.id:"")} style={{cursor:"pointer"}} />):''}
					{
						isNonEmptyArray(dropdown_buttons) ? <ButtonsDropdown button_list={dropdown_buttons} customToggle={
							<div className='inf-p-1 rounded inf-border'>
								<Icons name={'horizontal_options'} style={{fontSize: '16px'}} />
							</div>
						} /> : ''
					}
				</div>
			</div>

			<div className='flex_between' style={{gap: '8px'}}>
				<div className='lines1_elipsis' title={sku_details && sku_details.sku_name || "N/A"}>
					{sku_details && sku_details.sku_name || "N/A"}
				</div>

				<div style={{whiteSpace: 'nowrap'}}>Qty: {get_quantity_from_applicator(o)}</div>
			</div>

			{o && o.placement_styles && o.placement_styles.length>0?(<div style={{display:"grid", gridTemplateColumns: "2fr 5fr",gap:8}} onClick={() => {if(select_applicator){select_applicator(o?o.id:"")}}}>
				<div style={{alignContent:"center",textAlign:"center"}}>
					{o.placement_styles.length==1?(
						<img style={{height:40}} src={sku_details && sku_details.display_pic && get_sku_display_url(sku_details.display_pic) || ''} />
					):(
						<React.Fragment>
							<img style={{height:40}} src={sku_details && sku_details.display_pic && get_sku_display_url(sku_details.display_pic) || ''} />
							{o.placement_styles.map((placement_style, idx) => (
								<div key={"range_"+idx} style={{height:o.applicator_type=="dowel_hinge"?120:300,display:"flex",justifyContent:"center",alignItems:"center"}}>
									{"From " + o.placement_styles[idx][0] + " to " + (idx==o.placement_styles.length-1?"Infinity":o.placement_styles[idx+1][0]) + " mm"}
								</div>
							))}
						</React.Fragment>
					)}
				</div>
				<div style={{textAlign:"center"}}>
					{o.placement_styles.length>1?(<div style={{height:40}}>&nbsp;</div>):''}
					{o.placement_styles.map((placement_style, idx) => 
                        <ConnectorCanvasComponent 
							curr_applicator={o}
							placement_style_idx={idx}
							with_controller={false}
							canvas_width={o.applicator_type=="dowel_hinge"?250:300} canvas_height={o.applicator_type=="dowel_hinge"?120:300}
							panel_width={placement_style[1].assumed_panel_width || 500} panel_depth={placement_style[1].assumed_panel_depth || 500} panel_thickness={30}
						/>
                    )}
					
				</div>
			</div>):''}
		</div>
		</ApplicatorCardWrapper>
	)
}

export default ApplicatorCard