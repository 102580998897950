/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import {FormGroup, CardHeader, Input, Button, Row, Col, Collapse, Card, CardBody, Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, CardFooter, UncontrolledTooltip, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialDropdown from '../../components/MaterialDropdown/';
import CompositeDropdown from '../../components/CompositeDropdown/';
import repopulate_panel from '../../utils/panel_repopulation';
import * as Sentry from "@sentry/react";
import { useAlert } from 'react-alert'
import './panel_style.css';
import { COLORS } from '../../utils/color';
import { Image_Icon, Fa_Icon, Text_Icon } from '../Unit/'
import { Tree, Switch, Tooltip } from 'antd';
import FocusWithin from '../FocusWithin';
import { consoleSandbox } from '@sentry/utils';
import Create_card from '../Create_card';
import set_panel_search_show from '../../utils/set_panel_search_show';
import { validate_design_name } from '../../utils/validate_name';
import { send_analytics } from '../../utils/send_analytics';
import NavigationInfo from './NavigationInfo';
import {useTranslation} from 'react-i18next'
import { Ping } from '../Animations';
import sleep from '../../utils/sleep';
import { handleError } from '../../utils/errors';
import PanelEmptyState from './PanelEmptyState';
import DesignViewContext from '../../views/EditDesign/DesignViewContext';

const dummy_tick = (t) => (new Promise((res,rej) => {
    var timer = setTimeout(() => {
        res();
        clearTimeout(timer);
    },t)
})); 

const styles_panel = {
    text_elipsis : {
        whiteSpace: "nowrap", 
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingTop: 5
	},
	input_styles:{
		padding: 0,
		height: '24px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		paddingLeft:'6px',
		borderRadius:'2px'
	},
	collapse:{
    	position:'absolute',
    	left:'71px',
	    top: '100px',
    	width: '270px',
        height: 'calc(100% - 100px)',
    },
};

const check_dropdown_state = (id) => {
	if(window.Module){
		let state = window.Module.get_dropdown_open_close_state(id);
		return state
	}

	return false;
}

const dropdown_on_click = async (id) => {
	var active_design = window.Module.get_active_design()
	var active_view = active_design.active_view;
	if(window.debug_mode){
		window.add_debug_log("active_design->active_view->toggle_dropdown_as_open_or_close(\"" + (id) + "\");");
	}
	active_view.toggle_dropdown_as_open_or_close(id)
	// toggleNavbar();
	active_design.delete();
	active_view.delete();
}

const PanelFooter = ({json_file}) => {
	const {toolbar_on_click} = useContext(DesignViewContext);

	return(
		<div style={{ height: 'var( --panel_footer_height, 42px)', zIndex: '10', backgroundColor: 'white', borderRight: '2px solid var(--inf-highlight-background-color, #788394)' }}>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{
					json_file && json_file.length ? json_file.map((json_item, idx) => (
						// <div style={{boxShadow:json_item.active?'0px 0px 7px #314456 inset':'none',cursor:json_item.disabled?'not-allowed':'pointer',opacity:json_item.disabled?'0.3':'1'}} key={json_item.id + String(idx)}>
						// background-color: #F5F6F8;
						// border-radius: 10px;
						<div className={`${json_item.active == 'true' ? 'toolbar_active ' : ''}hover_toolbar`} style={{ cursor: json_item.disabled == 'true' ? 'not-allowed' : 'pointer', opacity: json_item.disabled == 'true' ? '0.3' : '1' }} key={json_item.id + String(idx)}>
							{
								json_item.type === "image" ?
									<Image_Icon
										onClick={() => { !(json_item.disabled == 'true') ? toolbar_on_click(json_item.id, json_item) : '' }}
										unit_type={json_item.type ? json_item.type : ''}
										unit_id={json_item.id ? json_item.id : ''}
										unit_name={json_item.name ? json_item.name : ''}
										unit_content={json_item.content ? json_item.content : ''}
										unit_root={json_item.route ? json_item.route : ''}
										unit_show={json_item.show ? json_item.show : 'true'}
										unit_disabled={json_item.disabled == 'true' ? json_item.disabled : ''}
										unit_active={""}
										icon_placement='panel_footer'
									/> : ""
							}
							{
								json_item.type === "text" ?
									<Text_Icon
										onClick={() => { !(json_item.disabled == 'true') ? toolbar_on_click(json_item.id, json_item) : "" }}
										unit_type={json_item.type ? json_item.type : ''}
										unit_id={json_item.id ? json_item.id : ''}
										unit_name={json_item.name ? json_item.name : ''}
										unit_content={json_item.content ? json_item.content : ''}
										unit_root={json_item.route ? json_item.route : ''}
										unit_show={json_item.show ? json_item.show : 'true'}
										unit_disabled={json_item.disabled == 'true' ? json_item.disabled : ''}
										unit_active={""}
									/> : ""
							}
							{
								json_item.type === "icon" ?
									<Fa_Icon
										onClick={() => { !(json_item.disabled == 'true') ? toolbar_on_click(json_item.id, json_item) : '' }}
										unit_type={json_item.type ? json_item.type : ''}
										unit_id={json_item.id ? json_item.id : ''}
										unit_name={json_item.name ? json_item.name : ''}
										unit_content={json_item.content ? json_item.content : ''}
										unit_root={json_item.route ? json_item.route : ''}
										unit_show={json_item.show ? json_item.show : 'true'}
										unit_disabled={json_item.disabled == 'true' ? json_item.disabled : ''}
										unit_active={""}
									/> : ""
							}
							{
								json_item.type === "separator" ? " " : " "

							}
						</div>
					))
						: ''
				}
			</div>
		</div>
	)
}

const PanelNavigator = ({panel_json, selected_index, set_selected_index, is_search_mode, seller_specific, panel_pop_up_show}) => {
	const [panel_json_length, set_panel_json_length] = useState(0);
	const [total_height, set_total_height] = useState(0);
	const [visibility_map, set_visibility_map] = useState({0: true});
	const [visibility_idx, set_visibility_idx] = useState(100);
	const [occupied_height, set_occupied_height] = useState(0);
	const [calculating_visibility, set_calculating_visibility] = useState(false);
	const [curr_height, set_curr_height] = useState(0);
	const [top_tree, set_top_tree] = useState(0);

	useEffect(() => {
		set_panel_json_length(panel_json.length)
		set_visibility_idx(panel_json.length - 1)
		let t_height = document.getElementById('canvas_container_div').offsetHeight - 100
		set_total_height(t_height ? t_height : 0)
		// set_calculating_visibility(true)
	}, [panel_json]);

	useEffect(() => {
		set_visibility_idx(panel_json_length-1)

		let checkExist = setInterval(function() {
			// console.log('triggering uef222', total_width, bulk_selection_mode, item_json)
			if(panel_json && panel_json.length){
				let temp=1
				for(let i=0;i<panel_json.length;i++){
					if(!document.getElementById(panel_json[i].id)){
						temp=0
						// console.log("re_calculate_visibility NOT Exists!", temp);
						break
					}
				}
				if (temp) {
					// console.log("re_calculate_visibility Exists!", total_width);
					set_calculating_visibility(true)
					clearInterval(checkExist);
				}
			}
		}, 100);
		// set_calculating_visibility(true)
	}, [total_height]);

	// useEffect(() => {
    //     if(calculating_visibility){
    //         if(total_width){
    //             re_calculate_visibility()
    //          } 
    //          set_calculating_visibility(false)
    //     }
    // }, [calculating_visibility]);

	useEffect(() => {
		if(calculating_visibility){
			if(total_height){
				re_calculate_visibility()
			}
			set_calculating_visibility(false)
		}
	}, [calculating_visibility]);

	useEffect(() => {
		get_tree_offset_top()
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

	const handleWindowSizeChange = () => {
		if(!(document.getElementById('canvas_container_div'))){
			return;
		}
		var t_height = seller_specific ? document.getElementById('canvas_container_div').offsetHeight - 52 : document.getElementById('canvas_container_div').offsetHeight - 100; 
		// set_visibility_idx(panel_json_length-1)
		set_total_height(t_height?t_height:0)
		// console.log("total height", t_height)
	}

	const get_tree_offset_top = () => {
		var tree = window.$('#canvas_container_div');

		 set_top_tree(tree.offset().top);
	}

	const re_calculate_visibility = () => {
		if(panel_json && panel_json.length){
			// let cur_occupied_height = document.getElementById(panel_json[0].id).offsetHeight
            let cur_visibility_idx = -1
			let cur_occupied_height = 0
			// console.log("re_calculate_visibility", total_height, cur_occupied_height, cur_visibility_idx)
            for(let i=panel_json.length-1;i>=0;i--){
                if(!document.getElementById(panel_json[i].id)) break
                let cur_height = document.getElementById(panel_json[i].id).offsetHeight
                cur_occupied_height += cur_height
                if(total_height > cur_occupied_height){
					set_curr_height(cur_occupied_height)
                    cur_visibility_idx++
                    // console.log("re_calculate_visibility", total_height, cur_height, cur_occupied_height, cur_visibility_idx)
                }else{
                    break
                }
            }
            // console.log('re_calculate_visibility index', cur_visibility_idx)
            set_visibility_idx(cur_visibility_idx)
		}
	}

	const onSelect = () => {
		if(panel_json && panel_json.length){
			panel_json.map((item, idx) => {
				if(idx != selected_index[0]){
					if(check_dropdown_state(item.id)){
						dropdown_on_click(item.id)
					}
				}
			})
			panel_json.map((item, idx) => {
				if(idx == selected_index[0]){
					if(!check_dropdown_state(item.id)){
						dropdown_on_click(item.id)
					}
				}
			})
		}
	}

	useEffect(() => {
		if(selected_index && selected_index.length){
			onSelect()
		}
	}, [selected_index]);

	// useEffect(() => {
	// 	console.log('curr height', curr_height)
	// }, [curr_height]);

	// const sec_fn = (idx) => {
    //     if(total_width>occupied_width){
    //         console.log('called')
    //         set_visibility_map({...visibility_map, [idx+1]:true})
    //         set_visibility_idx(idx+1)
    //     }else{
    //         console.log('sdfnsjfsknfsjk', total_width, occupied_width)
    //         set_visibility_map({...visibility_map, [idx]:false})
    //         set_visibility_idx(idx)
    //     }
    // }


	return(
		<div style={{top:top_tree, maxHeight: seller_specific ? 'calc( 100% - 52px )' : 'calc( 100% - 172px )'}} className='panel_navigator_container' id='panel_navigator_container'>
			{panel_pop_up_show ? <div style={{position: 'absolute', width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', zIndex: 2}}></div> : ''}
			<div className='tree_panel_navigator'>
				<img style={{fontSize:'16px'}} src={"/resources/panel_icons_new/tree_hierarchy-black.svg"}/>
			</div>
			
			{
				panel_json && Array.isArray(panel_json) && panel_json.slice(0).reverse().map((item, idx) => (
					idx <= visibility_idx ? 
					<div key={idx} id={item.id} style={{display: idx <= visibility_idx ? 'block' : 'none'}} onClick={is_search_mode ? null : () => {set_selected_index([String(panel_json_length - idx - 1)])}} className= {is_search_mode ? 'disabled_panel_navigator_item' : selected_index && selected_index.length && selected_index[0] == panel_json_length - 1 - idx ? 'selected_panel_navigator_item' : 'panel_navigator_item'}>
						{item.name}
						{
							idx != 0 ? <div className='arrow_down_panel_navigator'></div> : ''
						}
					</div>
					:''
				))
			}
			<div className='panel_navigator_overflow_container' style={{display: (visibility_idx < panel_json_length - 1) ? 'block' : 'none'}}>
				<span id='panel_navigator_spread' className='panel_navigator_spread'>...</span>
				<div className='panel_navigator_overflow' style={{top: visibility_idx < 0 ? top_tree + 40 : curr_height + top_tree + 40}}>
					{
						panel_json && Array.isArray(panel_json) && panel_json.slice(0).reverse().map((item, idx) => (
							<div key={idx} id={item.id + "overflow"} style={{display: idx > visibility_idx ? 'block' : 'none'}} onClick={() => {is_search_mode ? null : set_selected_index([String(panel_json_length - idx - 1)])}} className= {is_search_mode ? 'disabled_panel_navigator_item_overflow' : selected_index && selected_index.length && selected_index[0] == panel_json_length - 1 - idx ? 'selected_panel_navigator_item_overflow' : 'panel_navigator_item_overflow'}>
								{item.name}
								{/* {
									idx != 0 ? <div className='arrow_down_panel_navigator'></div> : ''
								} */}
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}

const generate_panel_tree = ({json_item, set_tree_data}) => {

	if(json_item && json_item.length){

		// let temp_tree = []
		// let json_item_length = json_item.length

		// let treeParentNode = {}
		// treeParentNode.title = json_item[json_item_length - 1].name ? json_item[json_item_length - 1].name : ''
		// treeParentNode.children = []
		// treeParentNode.key = String(json_item_length-1)

		// json_item.map((item, idx) => {
		// 	if(idx < json_item_length-1){
		// 		let treeChildNode = {}
		// 		treeChildNode.title = item.name
		// 		treeChildNode.key = String(idx)


		// 		treeParentNode.children.push(treeChildNode)
		// 	}

		// 	return
		// })

		// console.log('tree', treeParentNode);

		// temp_tree.push(treeParentNode);

		// set_tree_data(temp_tree);

		// for(i = json_item.length)
		let temp_children = []
		for (let index = 0; index < json_item.length; index++) {
			let treeChildNode = {}
			treeChildNode.title = json_item[index].name
			treeChildNode.key = String(index)
			treeChildNode.children = temp_children
			// console.log('tree2', treeChildNode);
			// if(index < json_item.length - 1){
				// console.log('tree3', temp_children);
			temp_children = [treeChildNode]
				// console.log('tree4', temp_children);
			// }
			// else{
			// 	temp_children = treeChildNode
			// 	console.log('tree5', temp_children);
			// }
		}

		// console.log('tree1', temp_children);
		set_tree_data(temp_children)
	}else{
		set_tree_data([])
	}




}

const Panel = ({ panel_key, seller_specific, mes_editor, panel_show, panel_heading, panel_json, set_panel_has_unapplied_edits, metric_system, approximation_mm, approximation_ft, set_page_loader, set_modal_mode, update_view,handle_ui_response, active_left_menu_item={active_left_menu_item}, set_active_left_menu_item, scroll_pos, set_scroll_pos, approxMM, mmToFeetParts, panel_collapsed, json_file, panel_explode, project_name, project_id, project_client_name, design_name, panel_show_tree, selected_index, set_selected_index, panel_json_id, set_panel_json_id, show_tree, set_show_tree, panel_has_unapplied_edits, panel_search_json, is_search_mode, set_is_search_mode, search_string_panel, set_search_string_panel, panel_search_string, panel_heading_label, panel_heading_icon, panel_heading_icon_route, panel_json_default_tree_selection, perspective, show_panel_info_at_bottom, panel_info_list, is_comment_mode, panel_pop_up_show, panel_current_page_show, panel_current_page_json, panel_current_page_heading, set_panel_current_page_has_unapplied_edits, panel_current_page_key, toolbar_collapsed, carousel_show }) => {
	const alert = useAlert()
    const {t} = useTranslation()

	const [tree_data, set_tree_data] = useState([]);
	const [tree_key, set_tree_key] = useState('empty_panel');
	const [ get_more_presentation_sheets_fn, set_get_more_presentation_sheets_fn ] = useState({in_execution: false});

	const [sampleTreeData, set_sampleTreeData] = useState(
		[
			{
			  title: 'parent 1',
			  key: '0-0',
			  children: [
				{
				  title: 'parent 1-0',
				  key: '0-0-0',
				  children: [
					{
					  title: 'leaf',
					  key: '0-0-0-0',
					},
					{
					  title: 'leaf',
					  key: '0-0-0-1',
					},
					{
					  title: 'leaf',
					  key: '0-0-0-2',
					},
				  ],
				},
				{
				  title: 'parent 1-1',
				  key: '0-0-1',
				  children: [
					{
					  title: 'leaf',
					  key: '0-0-1-0',
					},
				  ],
				},
				{
				  title: 'parent 1-2',
				  key: '0-0-2',
				  children: [
					{
					  title: 'leaf',
					  key: '0-0-2-0',
					},
					{
					  title: 'leaf',
					  key: '0-0-2-1',
					},
				  ],
				},
			  ],
			},
		  ]
	);
	// const sample_tree = [
	// 	{
	// 	  "title": "Staircase",
	// 	  "key": "2",
	// 	  "children": 
	// 	  [
	// 		  {
	// 			  "title": "Staircase Section",
	// 			  "key": "1",
	// 			  "children": [
	// 				  {
	// 					  "title": "Staircase Step",
	// 					  "key": "0",
	// 					  "children": []
	// 				  }
	// 			  ]
	// 		  }
	// 	  ]
	//   }
	//   ];

	// const [show_tree, set_show_tree] = useState(false);
	const [tree_heading, set_tree_heading] = useState(['Select Node from Tree', '', '']);

	//Design Name Editing Related Open
	const [is_design_name_editing_mode, set_is_design_name_editing_mode] = useState(false);

	const open_design_name_editing_mode = () => set_is_design_name_editing_mode(true)
	const close_design_name_editing_mode = () => set_is_design_name_editing_mode(false)

	const rename_design = (name) => {
		if (validate_design_name(name)) {
			set_page_loader({
				show: true,
				text: 'Renaming design...'
			});

			setTimeout(async () => {
				let active_design = window.Module.get_active_design()
				try {
					var response = await window.Promisify(window.Module.rename_design(active_design.design_id, name))
					handle_ui_response(JSON.stringify({ "update_design_name": true }))
					alert.success("Design renamed successfully");
				} catch (error) {
					alert.error('Could not rename your design')
				}
				active_design.delete()

				set_page_loader({
					show: false,
					text: 'Please wait'
				});
			}, 0)
		} else {
			if (name && name.trim()) {
				alert.error('Special Characters Not permitted. Please modify the Design Name')
			} else {
				alert.error('Design Name cant be blank')
			}
		}
    }
	//Design Name Editing Related Closed

	const toolbar_on_click = async (id,item) => {
		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner"){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}


		var promise_resp;
		try{
			if(item){
                var analytics_data = item
				analytics_data.tab_id = "left_panel_bottom"
                analytics_data.category = "toolbar button clicked";
                send_analytics(analytics_data,true)
            };
			if(window.debug_mode){
                window.add_debug_log("toolbar_router(\"" + id.replace(/"/g, '\\"') + "\");");
            }
			promise_resp = window.Module.toolbar_router(id);
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			return;
		}

		var ui_response = await window.Promisify(promise_resp);
		// console.log('layerset toolbar click', id, ui_response, promise_resp)

		handle_ui_response(ui_response);
		
		update_view();

		if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner"){
			set_page_loader({
				show: false,
				text: 'Please wait'
			});
			await dummy_tick(0);
		}
	}
	
	const handle_close = () => {
		var active_design = window.Module.get_active_design();
		var active_view = active_design.active_view;
		active_view.hide_panel();
		update_view();
		set_active_left_menu_item('')
		active_design.delete();
		active_view.delete();
	}


	const handle_scroll = async(e) => {
		// console.log(e.target, "handle scroll", e.target.scrollTop)
		set_scroll_pos(e.target.scrollTop)
		if(panel_heading && typeof(panel_heading) === "string" && panel_heading.toLowerCase().includes('all sheets')){
			let element = e.target;
			if (Math.abs(element.scrollTop + element.clientHeight - element.scrollHeight) < 10 && window.Module.more_presentation_sheets_available_to_load() && !get_more_presentation_sheets_fn.in_execution) {
				get_more_presentation_sheets_fn.in_execution = true
				set_page_loader({show: true, text: "Loading more sheets ..."}, async() => {
					await sleep(10);
					try{
						let response = await window.Promisify(window.Module.get_more_presentation_sheets("panel"))
						handle_ui_response(response);
					}catch(err){
						console.error(err);
						handleError(err)
					}
					set_page_loader({
						show: false,
						text: "Loading more sheets ..."
					});	
					get_more_presentation_sheets_fn.in_execution = false
				})
				
				// await sleep(300);

				
			}
		}
	}

	useEffect(() => {
		// setTimeout(() => {
			set_tree_key(Math.random())
			
			if(panel_json && panel_json.length && window.Module.is_current_panel_fresh()){
				let default_selection = panel_json.map(x => x.id).indexOf(panel_json_default_tree_selection)
				if(default_selection > -1){
					set_selected_index([default_selection])
				}else{
					set_selected_index([String(panel_json.length - 1)])
				}
			}
			

			if(document.getElementById('panel_scroll_container')){
				requestAnimationFrame(() => {
					if(document.getElementById('panel_scroll_container')){
						document.getElementById('panel_scroll_container').scroll(0,scroll_pos);
					}
				})
			}
			
			// set_tree_key(Math.random())
			// set_selected_index(['0'])

		// },0)
	},[panel_json])

	useEffect(() => {
		if(!panel_has_unapplied_edits.status && document.getElementById('panel_scroll_container') && document.getElementById('panel_scroll_container').scrollTop != scroll_pos){
			document.getElementById('panel_scroll_container').scroll(0,scroll_pos);
		}
	}, [panel_has_unapplied_edits.status]);

	useEffect(() => {
		if(panel_show_tree){
			generate_panel_tree({json_item:panel_json, set_tree_data:set_tree_data})
		}
		else{
			set_tree_data([])
			set_show_tree(false)
		}
	}, [panel_show_tree, panel_json]);

	useEffect(() => {
		if(panel_show_tree && panel_json && selected_index && selected_index.length){
			set_tree_heading(panel_json[selected_index[0]] ? panel_json.length > 1 && panel_json.length - 1 != selected_index[0] ? selected_index[0] == panel_json.length - 2 ? [panel_json[panel_json.length - 1].name , ' / ' , panel_json[selected_index[0]].name] : [panel_json[panel_json.length - 1].name, ' / ... / ', panel_json[selected_index[0]].name] : [panel_json[selected_index[0]].name, '', ''] : ['', '', ''])
		}else{
			set_tree_heading(["Select Node from Tree", '', ''])
		}
	}, [selected_index, panel_json]);

	useEffect(() => {
		if(is_design_name_editing_mode){
			let design_name_input  = document.getElementById('panel_design_name_input')
			if(design_name_input){
				design_name_input.focus()
				design_name_input.select(0, design_name_input.value.length)
			}
		}
	}, [is_design_name_editing_mode]);

	// useEffect(()=>{
	// 	console.log(panel_collapsed + " panel show " + typeof(panel_collapsed));
	// },[panel_collapsed]);


	// useEffect(() => {
	// 	console.log("panel show tree", panel_show_tree)
	// }, [panel_show_tree]);

	const onSelect = (selectedKeys, info) => {
		// console.log('selected1', selectedKeys, info);
		if(selectedKeys && selectedKeys.length){
			set_selected_index(selectedKeys);
			if(panel_json && panel_json.length && panel_show_tree){
				panel_json.map((item, idx) => {
					if(idx != selectedKeys[0]){
						if(check_dropdown_state(item.id)){
							dropdown_on_click(item.id)
						}
					}
				})
				panel_json.map((item, idx) => {
					if(idx == selectedKeys[0]){
						if(!check_dropdown_state(item.id)){
							dropdown_on_click(item.id)
						}
					}
				})
			}
		}else{
			set_tree_key(Math.random());
		}
	};

	const populate_panel_search = async(search_string) => {
		// var active_design = window.Module.get_active_design();
        // var active_view = active_design.active_view;
		var promise_resp;
		if(window.Module){
			try{
				promise_resp = window.Module.populate_panel_search(JSON.stringify({"search": search_string}));
			}
			catch(err){
				// alert.error("Something went wrong with search")
				return
			}
		}

		var ui_response = await window.Promisify(promise_resp);
		handle_ui_response(ui_response);

		update_view()

	}

	const panel_back_on_click = async() => {
		let resp = await window.Promisify(window.Module['panel_go_back_onclick']())
		handle_ui_response(resp)
	}

	window.get_panel_top_level_element = () => {
		if(panel_json && panel_json.length){
			let top_level_element = panel_json[panel_json.length - 1].name
			if(top_level_element && typeof(top_level_element) === "string" && ["panel", "cabinet", "user component"].includes(top_level_element.toLowerCase())){
				return top_level_element
			}
		}

		return ""
	}

	window.get_current_panel_level = () => {
		if(panel_show_tree && panel_json && panel_json.length && panel_json[selected_index]){
			let panel_level_name = panel_json[selected_index].name
			if(panel_level_name){
				panel_level_name = panel_level_name.split('(')
				panel_level_name = panel_level_name[0]
			}
			return panel_level_name
		}

		return ""
	}

	return (
		!panel_collapsed ? (
			<React.Fragment>
			{
				panel_show_tree && panel_json && !panel_current_page_show ? <PanelNavigator panel_json={panel_json} selected_index={selected_index} set_selected_index={set_selected_index} is_search_mode={is_search_mode} seller_specific={seller_specific} panel_pop_up_show={panel_pop_up_show}></PanelNavigator> : ''
			}
	      	{/* <div navbar="true" style={{...styles_panel.collapse , display:panel_collapsed?'none':'block' , height:seller_specific? 'calc(100% - 52px)': 'calc(100% - 136px)', top:seller_specific ? '52px' : '136px', left:seller_specific?'0px':'0px', borderTopRightRadius:'0px', borderTop:'1px solid #c5c7cf'}}> */}
	      	<div navbar="true" style={{...styles_panel.collapse , display:panel_collapsed?'none':'block' , height: is_comment_mode || toolbar_collapsed ? 'calc(100% - 52px)' : 'calc(100% - 136px)', top: is_comment_mode || toolbar_collapsed ? '52px' : '136px', left:'0px', borderTopRightRadius:'0px', /*borderTop:'1px solid #c5c7cf',*/ zIndex:2}}>
	      		<Card style={{border:'none', height:'100%', borderTopRightRadius: '0px'}}>
						<CardHeader style={{backgroundColor:"var(--inf-highlight-background-color, #788394)",height:"42px", color:COLORS.panel_header_text, /*borderTopRightRadius: '10px',*/ display:'flex', alignItems:"center", justifyContent:'space-between', borderRadius:'0px', padding:'0px 0px 00px 12px'}}>
							{panel_show?(
								is_search_mode ?
								<span key={"abc" + panel_show} style={{ fontSize:'16px', fontWeight:'400', color:COLORS.panel_header_text , textAlign:'left', display:'flex'}} >
									<Tooltip title={"Click to go back to panel"} placement='bottomLeft' arrow={{pointAtCenter: true}}>
										<img style={{marginRight:'8px', cursor:'pointer'}} src='/resources/panel_icons_new/back.svg' onClick={() => {set_is_search_mode(false); set_panel_search_show(false); set_search_string_panel(''); populate_panel_search('')}}></img>
									</Tooltip>
									<span className='panel_heading_text'>Search</span></span>
								:
								<span key={"abc" + panel_show} style={{ fontSize:'16px', fontWeight:'400', color:COLORS.panel_header_text , textAlign:'left', display: 'flex'}} >{panel_current_page_show ? <img title='Back' style={{marginRight:'8px', cursor:'pointer'}} src='/resources/panel_icons_new/back.svg' onClick={panel_back_on_click}></img> : ''} {panel_current_page_show ? <span className='panel_heading_text'>{panel_current_page_heading}</span> : panel_show_tree && panel_json ? <span className='panel_heading_text'>{selected_index && selected_index.length && panel_json[selected_index[0]] && panel_json[selected_index[0]].name ? panel_json[selected_index[0]].name : ''}</span> : <span className='panel_heading_text'>{panel_heading}</span>}</span>
								// <span key={"abc" + panel_show} style={{ fontSize:'16px', fontWeight:'400', color:COLORS.panel_header_text , textAlign:'left'}} >{panel_show_tree ? <React.Fragment> <span onClick={tree_heading[2] ? () => {set_selected_index([String(panel_json.length - 1)]); set_tree_key(Math.random())} : null} style={tree_heading[2] ? {cursor:'pointer'} : {}}> <span title='Click to access Parent property' className={tree_heading[2] ? 'underline_onhover' : ''}> {tree_heading[0]} </span> <i style={{fontSize:'10px', color:'white', verticalAlign:'middle', visibility:tree_heading[2] ? 'visible' : 'hidden'}} className='fa fa-caret-down'></i> </span> <span> {tree_heading[1]} </span> <span style={{fontWeight: 700}}> {tree_heading[2]} </span></React.Fragment> : panel_heading}</span>
							):(
								<span key={"def" + panel_show}style={{ fontSize:'16px', fontWeight:'600', color:COLORS.panel_header_text }}>Viewport Navigation</span>
							)}
							{/* <span onClick={() => set_show_tree(!show_tree)} style={{color:COLORS.panel_header_text, opacity:'unset', fontWeight:'unset', display:panel_show_tree ? "flex" : 'none', alignItems:'center', backgroundColor:'#676878', height:'34px', padding:'0px 8px', borderRadius:'4px'}} type="button">
								<div style={{display:'flex', alignItems:'center'}}>
									<img style={{fontSize:'16px'}} src={"/resources/panel_icons_new/tree hierarchy.svg"}/>
									<i style={{fontSize:'10px', color:'white'}} className='fa fa-caret-down'></i>
								</div>
								<div onClick={(e) => e.stopPropagation()} id="tooltip1" className="bottom" style={{left:'10px', top:'46px', backgroundColor:'white', display:show_tree?'block':'none', boxShadow:'#efeaea 0px 4px 4px 0px', padding:'8px', marginTop:'4px', zIndex:12}}>
									<div className="tooltip-arrow" style={{left:'96.5%', borderBottomColor:'white'}}></div>
									<div className="tooltip-label flex_property" style={{height:'auto', width:'233px', backgroundColor:'white', color:COLORS.gray7, textAlign:'left', cursor:'pointer'}}>
										<Tree 
											key={tree_key}
											showLine
											defaultExpandAll
											switcherIcon={<DownOutlined/>}
											// defaultExpandedKeys={['1']}
											treeData={tree_data}
											onSelect={onSelect}
											defaultSelectedKeys={selected_index}
											// autoExpandParent={true}
										/>
									</div>
								</div>
							</span> */}

							<div style={{display:'flex'}}>
								{/* <img style={{marginRight:'8px', cursor:'pointer'}} src='/resources/panel_icons_new/back.svg' onClick={null}></img> */}
								{
									panel_heading_icon && panel_heading_icon_route ? 
										<div className='flex_property' style={{ padding: '0px 8px' }}>
											<img style={{ cursor: 'pointer' }} src={panel_heading_icon} onClick={async () => {
												let resp = await window.Promisify(window.Module[panel_heading_icon_route]())
												handle_ui_response(resp)
											}} />
										</div>
										:''
								}
								{
									panel_heading_label && panel_heading_label.label ?
										<span className='panel_heading_text' style={{marginRight: '12px', height:'42px', display:'flex', alignItems:'center'}}>
											{panel_heading_label && panel_heading_label.label ? "(" + panel_heading_label.label + ")" : ''}
										</span>
										:''
								}
								{	
									panel_heading_label && (panel_heading_label.sku_name || panel_heading_label.group_name || panel_heading_label.sub_category_name || panel_heading_label.category_name || panel_heading_label.partition || panel_heading_label.panel) ?
										<div style={{marginRight: '12px', height:'42px', display:'flex', alignItems:'center'}}>
											<img id='panel_sku_info' src='/resources/panel_icons_new/panel_sku_info.svg' />
											<UncontrolledTooltip autohide={false} placement='bottom' target={'panel_sku_info'} className='sku_info_toottip_container' style={{borderRadius:'8px', padding:'8px 12px', maxWidth:'400px'}}>
												{/* <div className='sku_info_toottip_container'> */}
													<div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray2}}>
														{/* <div>SKU Name:</div> */}
														<div style={{fontWeight:'bold'}}>{panel_heading_label.sku_name ? panel_heading_label.sku_name : '-'}</div>
													</div>
													<div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Serial Number: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.serial_number ? panel_heading_label.serial_number : '-'}</div>
													</div>
													<div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Group: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.group_name ? panel_heading_label.group_name : '-'}</div>
													</div>
													<div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Sub Category: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.sub_category_name ? panel_heading_label.sub_category_name : '-'}</div>
													</div>
													<div style={{marginBottom: (panel_heading_label.partition || panel_heading_label.panel || panel_heading_label.cabinet || panel_heading_label.corner_unit || panel_heading_label.user_element ?'16px':''), fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Category: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.category_name ? panel_heading_label.category_name : '-'}</div>
													</div>
													{panel_heading_label.partition ? <div style={{fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Partition: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.partition ? panel_heading_label.partition : '-'}</div>
													</div>:""}
													{panel_heading_label.panel ? <div style={{marginBottom:'16px',fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Panel: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.panel ? panel_heading_label.panel : '-'}</div>
													</div>:""}
													{panel_heading_label.panel_types ? <div style={{fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Panel Types: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.panel_types ? panel_heading_label.panel_types : '-'}</div>
													</div>:""}
													{panel_heading_label.cabinet ? <div style={{fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Cabinet: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.cabinet ? panel_heading_label.cabinet : '-'}</div>
													</div>:""}
													{panel_heading_label.corner_unit ? <div style={{fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"Corner Unit: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.corner_unit ? panel_heading_label.corner_unit : '-'}</div>
													</div>:""}
													{panel_heading_label.user_element ? <div style={{fontSize:'14px'/*, width:'100%', flex:1*/, textAlign:'left', color:COLORS.gray6, display:'flex'}}>
														<div className='mr-2'>{"User Element: "}</div>
														<div style={{fontWeight:'bold', color: COLORS.gray2, textAlign:'left'}}>{panel_heading_label.user_element ? panel_heading_label.user_element : '-'}</div>
													</div>:""}
												{/* </div> */}
											</UncontrolledTooltip>
										</div>
										:''
								}
								<div className='panel_navigator_left' style={{width: '10px', height:'42px', position:'relative', visibility: panel_show_tree && !panel_current_page_show ? 'visible': 'hidden'}}>
									<div className='right_angle_left_panel_navigator'></div>
								</div>
							</div>
						</CardHeader>
						<CardBody id="panel_scroll_container" className='compact_panel' onScroll={handle_scroll} style={{ padding:'0px', backgroundColor: COLORS.panel_body_background, height:'calc(100% - 78px)', overflowY:'auto'}}>
							<div style={{height:'100%', Width: 'var( --panel_width, 270px)', position: 'relative'}}>
								{
									panel_show ? 
										(
											<React.Fragment>
												<Nav key={"panel" + panel_show} vertical style={{ Width: '100%', backgroundColor: COLORS.panel_card_item_parent, display: panel_current_page_show ? 'none' : '' }}>
													{
														panel_json && panel_json.length ? panel_json.map((json_item, idx) =>
														((panel_show_tree && selected_index && selected_index.length && idx == selected_index[0]) || !panel_show_tree || (panel_show_tree && !selected_index.length) ?
															<Create_card
																set_modal_mode={set_modal_mode}
																panel_key={panel_key}
																set_page_loader={set_page_loader}
																handle_ui_response={handle_ui_response}
																key={idx}
																metric_system={metric_system}
																approximation_mm={approximation_mm}
																approximation_ft={approximation_ft}
																parent_menu={panel_json}
																update_view={update_view}
																json_item={json_item}
																set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
																approxMM={approxMM}
																mmToFeetParts={mmToFeetParts}
																panel_explode={panel_explode}
																panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string}
															/>
															: ''
														))
															:
															<div style={{ padding: '15px' }}>{t(`No Items`)}</div>
													}

												</Nav>

												<Nav key={"panel_current_page" + panel_show} vertical style={{ Width: '100%', backgroundColor: COLORS.panel_card_item_parent, display: panel_current_page_show ? '' : 'none' }}>
													{
														panel_current_page_json && panel_current_page_json.length ? panel_current_page_json.map((json_item, idx) =>
														(
															<Create_card
																set_modal_mode={set_modal_mode}
																panel_key={panel_current_page_key}
																set_page_loader={set_page_loader}
																handle_ui_response={handle_ui_response}
																key={idx}
																metric_system={metric_system}
																approximation_mm={approximation_mm}
																approximation_ft={approximation_ft}
																parent_menu={panel_json}
																update_view={update_view}
																json_item={json_item}
																set_panel_has_unapplied_edits={set_panel_current_page_has_unapplied_edits}
																approxMM={approxMM}
																mmToFeetParts={mmToFeetParts}
																panel_explode={panel_explode}
																panel_search_json={panel_search_json} is_search_mode={is_search_mode} set_is_search_mode={set_is_search_mode} search_string_panel={search_string_panel} set_search_string_panel={set_search_string_panel} panel_search_string={panel_search_string}
															/>

														))
															:
															<div style={{ padding: '15px' }}>{t(`No Items`)}</div>
													}

												</Nav>

												{
													(!is_search_mode && panel_json && panel_json.length && panel_json[panel_json.length - 1].type != 'dropdown' && panel_json[panel_json.length - 1].type != 'list-item' && panel_json[panel_json.length - 1].type != 'presentation_sheet') || (is_search_mode && panel_search_json && panel_search_json.length && panel_search_json[panel_search_json.length - 1].type != 'dropdown' && panel_search_json[panel_search_json.length - 1].type != 'list-item' && panel_json[panel_json.length - 1].type != 'presentation_sheet') ?
														<div
															style={{ minHeight: '16px', border: 'none', backgroundColor: COLORS.panel_card_item_parent }}
														/>
														: ''
												}

												{
													panel_json && panel_json.length ?
														<div
															style={{ minHeight: carousel_show ? 'calc(36px + 2*var(--spacing-2))' : '16px', border: 'none', backgroundColor: COLORS.gray3 }}
														/>
														: ''
												}
											</React.Fragment>
										)
										:
										(
											<PanelEmptyState perspective={perspective} />
										)
								}
								
								{
									show_panel_info_at_bottom ?
										<div style={{ padding: '0px 16px 16px' }}>
											<ul className='DMSans panel_info_list' style={{}}>
												{
													panel_info_list && panel_info_list.length ? panel_info_list.map((info, idx) => (
														<li key={idx}>{info}</li>
													))
														: ''
												}
											</ul>
										</div>
										: ''
								}
							</div>
							<React.Fragment>
								{
									is_search_mode && panel_search_json && panel_search_json.length ? ''
										: is_search_mode && panel_search_string ?
											<div style={{ /* height: 'calc( 100% - 106px )' */ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLORS.gray5 }}>{t(`No Results Found`)}</div>
											:
											is_search_mode && !panel_search_string ?
												<div style={{ /* height: 'calc( 100% - 106px )' */ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLORS.gray5, padding: '16px 32px' }}>{t(`Search any Property and press enter to show results`)}</div>
												: ''
								}
								{
									// BAD PRACTICE - CODE WRITTEN ON PANEL HEADING
									window.Module.is_feature_enabled('public_catalogue') && panel_heading === "Furnish" ?
									<div className='inf-px-4 inf-py-4' style={{ flex: panel_json && panel_json.length ? '' : 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '8px', color: COLORS.gray5 }}>
											{t(`Not enough categories in your organization?`)}
											<div className='primary_button_ghost' onClick={async () => {
												let resp = await window.Promisify(window.Module.open_public_catalogue_carousel())
												console.log(resp)
												handle_ui_response(resp)
											}}>
												Explore Public Catalogue
											</div>
										</div>
										: ''
									}
							</React.Fragment>
						</CardBody>
						<div className='flex_property' style={{ backgroundColor:'transparent', height:'16px', position:'absolute', bottom:'42px', width:'100%', display:seller_specific ? 'none' : 'flex'}}>
							<div className='flex_property' style={{backgroundColor:"var(--inf-highlight-background-color, #788394)", fontSize:'11px', width:'180%', color:'white', justifyContent:'center', padding:'0px 4px 0px 8px'}}>{t(`Global Preferences & Settings`)}</div>
							<div style={{height:'100%', width:'100%'}}>
								<div style={{height:'60%', width:'100%', position:'static'}}><div style={{width:'0px', height:"0px", borderLeftColor:"var(--inf-highlight-background-color, #788394)", borderTopColor:'transparent', borderStyle:'solid', borderWidth:'10px 0px 0px 8px'}}></div></div>
								<div style={{height:'40%', width:'100%', backgroundColor:"var(--inf-highlight-background-color, #788394)"}}></div>
							</div>
						</div>
						<PanelFooter json_file={json_file} />
	      		</Card>
	      	</div>
			</React.Fragment>
		) : ''
	);
}

export default Panel;

//style={{ background-image: linear-gradient(to right, COLORS.mainpage_locale 1%, green 1%)}}